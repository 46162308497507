import { useField } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Avatar } from '@shared-component/avatar/avatar';
import { PaymentEntityType } from '@shared-graphql';

import { CreatePartnerFormEnum } from '@component/modal/modals/create-partner/create-partner-form/create-partner-form.enum';

import { AvatarWrapper } from './create-partner-form-avatar.styles';

export const CreatePartnerFormAvatar = () => {
    const [{ value: entityType }] = useField(CreatePartnerFormEnum.EntityType);
    const [{ value: firstName }] = useField(CreatePartnerFormEnum.FirstName);
    const [{ value: companyName }] = useField(CreatePartnerFormEnum.CompanyName);

    const name = entityType === PaymentEntityType.individual ? firstName : companyName;
    return (
        <DndProvider backend={HTML5Backend}>
            <AvatarWrapper>
                <Avatar name={name} />
            </AvatarWrapper>
        </DndProvider>
    );
};
