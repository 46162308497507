export enum CurrenciesEnum {
    AED = 'AED',
    AUD = 'AUD',
    BGN = 'BGN',
    CAD = 'CAD',
    CHF = 'CHF',
    CNY = 'CNY',
    CZK = 'CZK',
    DKK = 'DKK',
    EUR = 'EUR',
    GBP = 'GBP',
    GHS = 'GHS',
    HKD = 'HKD',
    HRK = 'HRK',
    HUF = 'HUF',
    IDR = 'IDR',
    ILS = 'ILS',
    INR = 'INR',
    JOD = 'JOD',
    JPY = 'JPY',
    KES = 'KES',
    KWD = 'KWD',
    MUR = 'MUR',
    MXN = 'MXN',
    MYR = 'MYR',
    NOK = 'NOK',
    NZD = 'NZD',
    OMR = 'OMR',
    PHP = 'PHP',
    PLN = 'PLN',
    QAR = 'QAR',
    RON = 'RON',
    SAR = 'SAR',
    SEK = 'SEK',
    SGD = 'SGD',
    THB = 'THB',
    TND = 'TND',
    TRY = 'TRY',
    UGX = 'UGX',
    USD = 'USD',
    ZAR = 'ZAR',
}
