import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useAttachFileLocalization = (title: LocalizationEnum, description: LocalizationEnum) => {
    const attachFileTitle = useLocalizationText(title);
    const attachFileDescription = useLocalizationText(description);

    return {
        attachFileTitle,
        attachFileDescription,
    };
};
