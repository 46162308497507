import { values } from 'mobx';
import { types } from 'mobx-state-tree';

import { Currency } from '@shared-graphql';
import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';
import { getCurrencyList } from '@shared-util/get-currency-list';

import { currenciesActions } from './currencies-actions/currencies.actions';

export const CurrenciesModelType = types
    .model({
        elements: types.map(CurrencyModelType),
        loading: types.model({
            isLoading: types.boolean,
        }),
        error: types.optional(types.string, ''),
    })
    .views(self => ({
        get currencies() {
            return values(self.elements) as unknown as Currency[];
        },
    }))
    .views(self => ({
        get currenciesSelect() {
            return getCurrencyList(self.currencies);
        },
        get currenciesActiveSelect() {
            return getCurrencyList(self.currencies.filter(({ isActive }) => isActive));
        },
    }));

export const CurrenciesModel = CurrenciesModelType.actions(currenciesActions);

export const getInitialCurrenciesModel = () =>
    CurrenciesModel.create({
        elements: {},
        loading: {
            isLoading: false,
        },
    });
