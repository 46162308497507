import styled from 'styled-components';

import { ExternalLinkStyles } from '../../atoms/external-link/external-link.styles';

export const PaymentFilesWrapper = styled.div`
    padding-top: 16px;
    margin-bottom: 24px;
    ${ExternalLinkStyles} {
        text-decoration: none !important;
    }
`;
