import { object, string, boolean } from 'yup';

import { CurrenciesEnum } from '@shared-enum/currencies.enum';
import { PaymentEntityType } from '@shared-graphql';
import { isGBPCurrency } from '@shared-util/currencies';
import { bicValidation } from '@shared-util/validation';

import { BeneficiaryFormEnum, CreatePartnerFormEnum } from './create-partner-form.enum';

const getBeneficiaryFormValidation = (shouldValidateBic: boolean) => {
    const getFieldsValidation = (isIndividual: boolean) =>
        object().shape({
            [BeneficiaryFormEnum.FirstName]: isIndividual ? string().nullable().required() : string().nullable(),
            [BeneficiaryFormEnum.LastName]: isIndividual ? string().nullable().required() : string().nullable(),
            [BeneficiaryFormEnum.CompanyName]: isIndividual ? string().nullable() : string().nullable().required(),
            [BeneficiaryFormEnum.AcctNumber]: string().required(),
            [BeneficiaryFormEnum.BicSwift]: shouldValidateBic ? bicValidation : string().nullable(),
            [BeneficiaryFormEnum.Country]: string().nullable(),
            [BeneficiaryFormEnum.City]: string().nullable(),
            [BeneficiaryFormEnum.Postcode]: string().nullable(),
            [BeneficiaryFormEnum.Address]: string().nullable(),
        });

    return object().when(CreatePartnerFormEnum.EntityType, {
        is: (entityType: PaymentEntityType) => entityType === PaymentEntityType.individual,
        then: getFieldsValidation(true),
        otherwise: getFieldsValidation(false),
    });
};

export const CreatePartnerFormValidation = object().shape({
    [CreatePartnerFormEnum.Currency]: string().required(),
    [CreatePartnerFormEnum.ShouldRenderAddressFields]: boolean(),
    [CreatePartnerFormEnum.BeneficiaryInitial]: object().when(CreatePartnerFormEnum.Currency, {
        is: (currency: CurrenciesEnum) => isGBPCurrency(currency),
        then: getBeneficiaryFormValidation(false),
        otherwise: getBeneficiaryFormValidation(true),
    }),
});
