import { object } from 'yup';

import { fieldRequiredValidation } from '@shared-util/validation';

import { CurrencyExchangeEnum } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.enum';

export const currencyExchangeValidationSchema = object().shape({
    [CurrencyExchangeEnum.Sell]: fieldRequiredValidation(),
    [CurrencyExchangeEnum.Buy]: fieldRequiredValidation(),
    [CurrencyExchangeEnum.Amount]: fieldRequiredValidation(),
});
