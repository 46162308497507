import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Neutral200, Neutral500, Primary300, Primary500 } from '@shared-style/colors';

interface LinkDisableProps {
    isSecondary: boolean;
}

export const ItemLink = styled(Link)`
    height: 100%;
    text-decoration: none;
    color: ${Primary500};
`;
export const SecondaryItemLink = styled(Link)`
    height: 100%;
    text-decoration: none;
    color: ${Neutral500};
`;
export const LinkDisable = styled.span<LinkDisableProps>`
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: ${({ isSecondary }) => (isSecondary ? Neutral200 : Primary300)};
`;
