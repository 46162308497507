import styled from 'styled-components';

import { getAnimationStyle } from '../../styles/animation';

interface InformationModalWrapperProps {
    isVisible: boolean;
}

export const InformationModalWrapper = styled.div<InformationModalWrapperProps>`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 101;
    pointer-events: none;
    ${getAnimationStyle(['opacity'])}
`;
