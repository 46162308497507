import styled from 'styled-components';

import { Primary500 } from '@shared-style/colors';

type ExternalLinkStylesPropsType = {
    animated: boolean;
};

export const ExternalLinkStyles = styled.a<ExternalLinkStylesPropsType>`
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: ${Primary500};
    transition: all 0.2s ease-out;

    :hover {
        ${({ animated }) => animated && 'transform: scale(1.01) translateY(-5px);'}
        text-decoration: underline;
    }

    :active {
        text-decoration: none;
    }

    :visited {
        color: ${Primary500};
    }
`;
