import { OnEventType } from '@shared-type/on-event.type';

import { PaymentBreadcrumbInterface } from '@component/modal/modals/payment/payment-breadcrumbs/payment-breadcrumbs.options';

import { BreadcrumbsWrapper } from './payment-breadcrumbs.style';
import { Crumb } from './payment-crumb/payment-crumb';

interface PaymentFormBreadcrumbsProps {
    breadcrumbs: PaymentBreadcrumbInterface[];
    activeStep: number;
    onStep: OnEventType<number>;
}

export const PaymentBreadcrumbs = ({ breadcrumbs, activeStep, onStep }: PaymentFormBreadcrumbsProps) => {
    const handleStepClick = (step: number) => () => onStep(step);

    return (
        <BreadcrumbsWrapper>
            {breadcrumbs.map(({ title }, index: number) => (
                <Crumb
                    key={title}
                    title={title}
                    onClick={handleStepClick(index)}
                    isActive={index === activeStep}
                    isDisabled={index > activeStep}
                />
            ))}
        </BreadcrumbsWrapper>
    );
};
