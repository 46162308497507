import { Scroll } from '@shared-component/scroll/scroll';

import { DeletePartnerConfirmation } from '@component/delete-partner-confirmation/delete-partner-confirmation';

import { DeletePartnerWrapper, ContentWrapper } from './delete-partner-confirmation.styles';

export const DeletePartnerConfirmationModal = () => (
    <DeletePartnerWrapper>
        <Scroll>
            <ContentWrapper>
                <DeletePartnerConfirmation />
            </ContentWrapper>
        </Scroll>
    </DeletePartnerWrapper>
);
