import React from 'react';

import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { Neutral50, Neutral900 } from '@shared-style/colors';
import { isTrue } from '@shared-util/is-data';

import { usePaymentFlowSelectorOptions } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector-options/payment-flow-selector-options.hook';
import {
    PaymentFlowButton,
    PaymentFlowButtonIcon,
    PaymentFlowButtonWrapper,
    PaymentFlowSelectorOptionsWrapper,
    PaymentFlowSelectorSubtitle,
} from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector-options/payment-flow-selector-options.styles';
import { PaymentFlowSelectorProps } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector.interface';

export const PaymentFlowSelectorOptions = ({
    accounts,
    setCurrentPaymentFlow,
    setSelectedAccount,
}: PaymentFlowSelectorProps) => {
    const paymentFlowSelectorOptions = usePaymentFlowSelectorOptions({ accounts, setSelectedAccount });

    return (
        <PaymentFlowSelectorOptionsWrapper>
            <PaymentFlowSelectorSubtitle>
                <Translate langKey={LocalizationEnum.ChooseThePaymentType} />
            </PaymentFlowSelectorSubtitle>
            {paymentFlowSelectorOptions.map(({ paymentFlow, title, tooltipText, icon, dataFor }) => (
                <PaymentFlowButtonWrapper key={title}>
                    <Tooltip
                        dataFor={dataFor}
                        content={tooltipText as unknown as string}
                        isShow
                        backgroundColor={Neutral900}
                        textColor={Neutral50}
                        place="right"
                        clickable
                        effect="solid"
                    />
                    <PaymentFlowButton onClick={() => setCurrentPaymentFlow(paymentFlow)}>
                        <Translate langKey={title} />
                        {isTrue(icon) && <PaymentFlowButtonIcon src={icon} />}
                    </PaymentFlowButton>
                </PaymentFlowButtonWrapper>
            ))}
        </PaymentFlowSelectorOptionsWrapper>
    );
};
