export enum PaymentDetailsFormEnum {
    Reason = 'reason',
    AttachedFiles = 'attachedFiles',
    // COMMON
    BeneficiaryEntityType = 'beneficiary.beneficiaryEntityType',
    BeneficiaryAddress = 'beneficiary.beneficiaryAddress',
    BeneficiaryCity = 'beneficiary.beneficiaryCity',
    BeneficiaryCountry = 'beneficiary.beneficiaryCountry',

    // for PRIVATE
    BeneficiaryFirstName = 'beneficiary.beneficiaryFirstName',
    BeneficiaryLastName = 'beneficiary.beneficiaryLastName',
    // for LEGAL
    BeneficiaryCompanyName = 'beneficiary.beneficiaryCompanyName',

    // for CAD and MXN
    BeneficiaryStateOrProvince = 'beneficiary.beneficiaryStateOrProvince',
    BeneficiaryPostcode = 'beneficiary.beneficiaryPostcode',
}
