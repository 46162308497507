import { reaction } from 'mobx';
import { Instance } from 'mobx-state-tree';

import { initialCompanyModel } from '@shared-model/company-model/company-model.initial';

import { CompanyModel } from '@model/company-model/company-model';
import { UserModel } from '@model/user-model/user-model';

export const initializeCompanyReaction = (
    companyStore: Instance<typeof CompanyModel>,
    userStore: Instance<typeof UserModel>
) =>
    reaction(
        () => companyStore.company.id,
        companyId => userStore.user.legalStatus.setHasCompany(companyId !== initialCompanyModel.id)
    );
