import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import {
    ForgotPasswordSentMessageContainer,
    ForgotPasswordSentMessageDescription,
    ForgotPasswordSentMessageIcon,
    ForgotPasswordSentMessageTitle,
    ForgotPasswordSentMessageWrapper,
} from '@page/auth-pages/forgot-password/forgot-password-sent-message.styles';
import icon from '@page/auth-pages/login/assets/icon.svg';

export const ForgotPasswordSentMessage = () => (
    <ForgotPasswordSentMessageWrapper>
        <ForgotPasswordSentMessageContainer>
            <ForgotPasswordSentMessageIcon alt="forgot-password-sent-message-icon" src={icon} />
            <ForgotPasswordSentMessageTitle>
                <Translate langKey={LocalizationEnum.AnEmailWithPasswordReset} />
            </ForgotPasswordSentMessageTitle>
            <ForgotPasswordSentMessageDescription>
                <Translate langKey={LocalizationEnum.InstructionsHaveBeenSentToYourEmailAddress} />
            </ForgotPasswordSentMessageDescription>
        </ForgotPasswordSentMessageContainer>
    </ForgotPasswordSentMessageWrapper>
);
