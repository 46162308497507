import { useLazyQuery } from '@apollo/client';
import { useField } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { string } from 'yup';

import { cardMaskRegExp } from '@shared-atom/elpaso-kit/input/custom-input-card/input-card.helper';
import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { EventEmitterEnum } from '@shared-enum/event-emitter.enum';
import { Fee, IsCardAllowed, Query } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { UKRAINE_COUNTRY_CODE } from '@shared-util/constants';
import { emit } from '@shared-util/event-emitter';

import { PaymentToCardDetailsFormEnum } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-details-form/payment-to-card-details-form.enum';

interface FeeAndTotalHookInterface {
    totalAmount: string;
    paymentFee: string;
}

enum CardAvailableServerErrorsEnum {
    CardRestricted = 'cards restricted',
    CountryRestricted = 'country restricted',
    RegionRestricted = 'region restricted',
    RegionRequired = 'region required',
}

export const useFeeAndTotalHook = ({ total, amount }: Fee): FeeAndTotalHookInterface => {
    const [{ value: paymentCurrency }] = useField(PaymentToCardDetailsFormEnum.PaymentCurrency);

    const totalAmount = useCurrencyFormat(total, paymentCurrency);
    const paymentFee = useCurrencyFormat(amount, paymentCurrency);

    return {
        totalAmount,
        paymentFee,
    };
};

export const useCardAvailableHook = (updateValidationSchema: any) => {
    const { onOpen } = useContext(InformationModalContext);
    const [cardNumberErrorMessage, setCardNumberErrorMessage] = useState('');
    // const [regionErrorMessage, setRegionErrorMessage] = useState('');
    const [{ value: cardNumber }, , { setError: setCardNumberFieldError }] = useField(
        PaymentToCardDetailsFormEnum.CardNumber
    );
    // const [{ value: region }, , { setError: setRegionFieldError }] = useField(PaymentToCardDetailsFormEnum.Region);
    const [isNeedToAskRegion, setNeedToAskRegion] = useState(false);

    const onlyMastercardError = useLocalizationText(LocalizationEnum.OnlyMastercardCardsAreAvailable);
    const countryRestrictedError = useLocalizationText(LocalizationEnum.ThisCountryIsRestrictedForWithdrawals);
    const regionRestrictedError = useLocalizationText(LocalizationEnum.ThisRegionOfUkraineIsRestrictedForWithdrawals);
    const regionRequiredError = useLocalizationText(LocalizationEnum.ItIsNecessaryToEnterTheRegionForUkraine);
    const requiredFieldErrorText = useLocalizationText(LocalizationEnum.RequiredFieldErrorMessage);
    const paymentCanNotBeCreatedDueToCountryErrorText = useLocalizationText(
        LocalizationEnum.PaymentCannotBeCreatedBecauseOfTheBeneficiarysCountry
    );
    const invalidCardNumberErrorText = useLocalizationText(LocalizationEnum.InvalidCardNumber);

    const localizedCardNumberErrorMessage = useLocalizationText(cardNumberErrorMessage);
    // const localizedRegionErrorMessage = useLocalizationText(regionErrorMessage);

    const CardAvailableServerErrorsMapping = {
        [CardAvailableServerErrorsEnum.CardRestricted]: onlyMastercardError,
        [CardAvailableServerErrorsEnum.CountryRestricted]: countryRestrictedError,
        [CardAvailableServerErrorsEnum.RegionRestricted]: regionRestrictedError,
        [CardAvailableServerErrorsEnum.RegionRequired]: regionRequiredError,
    };

    const [isCardAllowed, { data: simpleCardAllowedData, loading: isSimpleCardAllowedDataLoading }] = useLazyQuery<
        Pick<Query, 'isCardAllowed'>
    >(IsCardAllowed, {
        onError: error => {
            if (Boolean(error) !== false) {
                onOpen(InformationModalEnum.FailureInformationModal);
                setCardNumberErrorMessage(invalidCardNumberErrorText);
            }
            emit(EventEmitterEnum.TransactionsUpdate);
        },
        onCompleted: result => {
            if (result === null) {
                onOpen(InformationModalEnum.FailureInformationModal);
                setCardNumberErrorMessage(invalidCardNumberErrorText);
            }
            emit(EventEmitterEnum.TransactionsUpdate);
        },
    });

    useEffect(() => {
        if (cardNumber.length === cardMaskRegExp.length) {
            setCardNumberErrorMessage('');
            // setRegionErrorMessage('');
            setCardNumberFieldError(undefined);
            // setRegionFieldError(undefined);
            setNeedToAskRegion(false);
            isCardAllowed({
                variables: {
                    cardNumber: cardNumber.replaceAll('-', ''),
                },
            });
        } else {
            setCardNumberErrorMessage('');
            // setRegionErrorMessage('');
            setCardNumberFieldError(undefined);
            // setRegionFieldError(undefined);
            setNeedToAskRegion(false);
        }
    }, [cardNumber]);

    // useEffect(() => {
    //     if (region !== '') {
    //         isCardAllowed({
    //             variables: {
    //                 cardNumber: cardNumber.replaceAll('-', ''),
    //                 region,
    //             },
    //         });
    //     }
    // }, [region]);

    useEffect(() => {
        if (simpleCardAllowedData?.isCardAllowed) {
            if (simpleCardAllowedData.isCardAllowed.countryCode === UKRAINE_COUNTRY_CODE) {
                // setNeedToAskRegion(true);
                setCardNumberErrorMessage(paymentCanNotBeCreatedDueToCountryErrorText);
            }
            if (!simpleCardAllowedData.isCardAllowed.isAllowed) {
                if (
                    [
                        CardAvailableServerErrorsEnum.RegionRestricted,
                        CardAvailableServerErrorsEnum.RegionRequired,
                    ].includes(simpleCardAllowedData.isCardAllowed.message as unknown as CardAvailableServerErrorsEnum)
                ) {
                    // setRegionErrorMessage(
                    //     CardAvailableServerErrorsMapping[
                    //         simpleCardAllowedData.isCardAllowed.message as unknown as CardAvailableServerErrorsEnum
                    //     ]
                    // );
                } else {
                    setCardNumberErrorMessage(
                        CardAvailableServerErrorsMapping[
                            simpleCardAllowedData.isCardAllowed.message as unknown as CardAvailableServerErrorsEnum
                        ]
                    );
                }
            }
            if (simpleCardAllowedData.isCardAllowed.isAllowed) {
                setCardNumberErrorMessage('');
                // setRegionErrorMessage('');
            }
        }
    }, [simpleCardAllowedData]);

    useEffect(() => {
        if (localizedCardNumberErrorMessage !== '') {
            const expressionSchema = {
                [PaymentToCardDetailsFormEnum.CardNumber]: string()
                    .required(requiredFieldErrorText)
                    .test(PaymentToCardDetailsFormEnum.CardNumber, localizedCardNumberErrorMessage, () => false),
            };

            updateValidationSchema(expressionSchema);
        } else {
            const expressionSchema = {
                [PaymentToCardDetailsFormEnum.CardNumber]: string().required(requiredFieldErrorText),
                // .test(PaymentToCardDetailsFormEnum.CardNumber, onlyMastercardError, value =>
                //     /(?<!\d)\d{16}(?!\d)/.test(`${value?.replaceAll('-', '') ?? ''}`)
                // ),
            };

            updateValidationSchema(expressionSchema);
        }
    }, [localizedCardNumberErrorMessage]);

    // useEffect(() => {
    //     if (localizedRegionErrorMessage !== '') {
    //         const expressionSchema = {
    //             [PaymentToCardDetailsFormEnum.Region]: string()
    //                 .required(requiredFieldErrorText)
    //                 .test(PaymentToCardDetailsFormEnum.Region, localizedRegionErrorMessage, () => false),
    //         };
    //
    //         updateValidationSchema(expressionSchema);
    //     } else {
    //         const expressionSchema = { [PaymentToCardDetailsFormEnum.Region]: string() };
    //
    //         updateValidationSchema(expressionSchema);
    //     }
    // }, [localizedRegionErrorMessage]);

    return {
        isNeedToAskRegion,
        isLoading: isSimpleCardAllowedDataLoading,
    };
};
