import { countries, ICountry } from 'countries-list';
import { sort } from 'fast-sort';

import { CountryCodeType } from './country-select.type';

type CountryListType = [CountryCodeType | string, ICountry];

const countryListMap = ([code, country]: CountryListType) => ({
    value: code,
    label: country.name,
});

export const countryList = sort(Object.entries(countries).map(countryListMap)).asc(item => item.label);
