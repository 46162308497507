import { observer } from 'mobx-react-lite';

import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { CustomInput } from '../../input/customInput';
import { CustomInputPassword } from '../../input/input-password/custom-input-password';
import { FormRow } from '../form-common/form-common.styles';
import { UserCredentialsStepFormEnum } from './user-credentials-step-form.enum';
import { useUserCredentialsStepFormFields } from './user-credentials-step-form.hook';

interface UserCredentialsStepFormFieldsProps {
    error?: string;
}

export const UserCredentialsStepForm = observer(({ error }: UserCredentialsStepFormFieldsProps) => {
    const { emailTitle, passwordTitle } = useUserCredentialsStepFormFields(error);
    const enterYourMailTitle = useLocalizationText(LocalizationEnum.EnterYourMail);

    return (
        <>
            <CustomInput
                id={UserCredentialsStepFormEnum.Email}
                title={emailTitle}
                name={UserCredentialsStepFormEnum.Email}
                placeholder={enterYourMailTitle}
                autofocus
            />
            <FormRow>
                <CustomInputPassword
                    title={passwordTitle}
                    name={UserCredentialsStepFormEnum.Password}
                    id={UserCredentialsStepFormEnum.Password}
                />
            </FormRow>
        </>
    );
});
