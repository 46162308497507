import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral50 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const ForgotPasswordSentMessageWrapper = styled.div`
    background: ${Neutral50};
    border-radius: 8px;
    width: 40%;
    margin: 50px auto 0;
`;
export const ForgotPasswordSentMessageContainer = styled.div`
    padding: 32px;
    ${Flex.Column.VerticalCenter.Style}
`;

export const ForgotPasswordSentMessageTitle = styled.h1`
    ${Typography.Paragraph18.SemiBold.Neutral900.Style};
    padding-top: 24px;
    padding-bottom: 8px;
`;
export const ForgotPasswordSentMessageDescription = styled.p`
    ${Typography.Paragraph14.Medium.Neutral500.Center.Style};
`;
export const ForgotPasswordSentMessageIcon = styled.img``;
