import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { SubMenuListInterface } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.interface';
import { MenuOpenContext } from '@shared-component/page-wrapper/page-wrapper.hook';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { ClickEventType } from '@shared-type/click-event.type';
import { OnEventsType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';

interface NavigationItemProps<T extends string> {
    pageName?: T;
    modalName?: T;
    children?: SubMenuListInterface<T>[];
}

export const useNavigationItem = <T extends string>({
    pageName,
    modalName,
    children,
}: NavigationItemProps<T>): [
    boolean,
    boolean,
    ClickEventType,
    OnEventsType<[T | undefined, Record<string, any> | undefined], boolean>
] => {
    const navigate = useRouterNavigate();
    const { name, params } = useCurrentNavigation();
    const { onOpen } = useContext(ModelContext);

    const [isMenuOpen, setMenuOpen] = useContext(MenuOpenContext);
    const [isItemOpen, setItemOpen] = useState(false);

    const isChildrenExist = Array.isArray(children);
    const isItemIncludeSubMenu = !isExist(pageName) && isChildrenExist;
    const isSomeChildrenActive = useMemo(
        () =>
            isChildrenExist
                ? (children as SubMenuListInterface<T>[]).some(
                      (child: SubMenuListInterface<T>) => name === child.pageName
                  )
                : false,
        [children, name]
    );
    const isSubmenuOpen = isItemOpen || isSomeChildrenActive;

    const handleMenuOpen = useCallback(
        () => !isMenuOpen && isChildrenExist && setMenuOpen(),
        [isMenuOpen, isChildrenExist]
    );

    const handleMenuClick = useCallback(() => {
        handleMenuOpen();
        navigate(pageName as PageEnum);
    }, [pageName, handleMenuOpen]);

    const handleModalOpen = useCallback(() => onOpen(modalName as unknown as ModalEnum), []);

    const handleToggleItemOpen = useCallback(() => {
        if (!isItemOpen) {
            handleMenuOpen();
        }
        setItemOpen((prevState: boolean) => !prevState);
    }, [handleMenuOpen, isItemOpen]);

    const handleClick = isItemIncludeSubMenu ? handleToggleItemOpen : handleMenuClick;

    const checkSubNavigationActive = useCallback(
        (subPageName: T | undefined, subPageParams?: Record<string, any>) => {
            const isCurrentPage = subPageName === name;

            if (isCurrentPage && subPageParams !== undefined) {
                return Object.entries(subPageParams).some(
                    ([paramName, paramValue]) => params[paramName] === paramValue
                );
            }

            return isCurrentPage;
        },
        [name, params]
    );

    const isCurrentPage = name === pageName;
    const isActive = isItemIncludeSubMenu ? isSubmenuOpen : isCurrentPage;
    const onClick = isExist(modalName) ? handleModalOpen : handleClick;

    useEffect(() => void ((isSomeChildrenActive || isCurrentPage) && handleMenuOpen()), []);

    useEffect(() => void (isItemOpen && !isSomeChildrenActive && setItemOpen(false)), [name]);

    return [isActive, isMenuOpen, onClick, checkSubNavigationActive];
};
