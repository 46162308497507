import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Error50 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

import DocumentClean from '../../../../assets/vector/document-clean.svg?react';
import Delete from '../../../../assets/vector/trash-1.svg?react';

export const DocumentUploadedWrapper = styled.div`
    text-decoration: none !important;
    ${Flex.Row.SpaceBetween.FlexStart.Style}
`;

export const FileInfo = styled.span`
    flex: 1;
    ${Flex.Column.Style}
`;
export const FileName = styled.span`
    ${Typography.Paragraph14.SemiBold.Neutral900.Left.Style}
`;
export const UploadInfo = styled.span`
    text-transform: lowercase;
    ${Typography.Paragraph12.Regular.Neutral500.Left.Style}
`;

export const ButtonContentWrapper = styled.div`
    width: 28px;
    height: 28px;
    background-color: ${Error50};
    border-radius: 50%;
    cursor: pointer;
    ${Flex.VerticalCenter.HorizontalCenter.Style};
`;

export const ImagePreview = styled(DocumentClean)`
    margin-right: 15px;
    height: 20px;
`;

export const DeleteIcon = styled(Delete)``;
