import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useCreatePartnerFormAddressLocalization = () => {
    const beneficiaryInfoTitle = useLocalizationText(LocalizationEnum.CreatePartnerFormBeneficiaryInfoTitle);
    const beneficiaryInfoDescription = useLocalizationText(
        LocalizationEnum.CreatePartnerFormBeneficiaryInfoDescription
    );
    const beneficiaryCountryTitle = useLocalizationText(LocalizationEnum.CreatePartnerFormBeneficiaryCountry);
    const beneficiaryCityTitle = useLocalizationText(LocalizationEnum.CreatePartnerFormBeneficiaryCity);
    const beneficiaryZipTitle = useLocalizationText(LocalizationEnum.CreatePartnerFormBeneficiaryZip);
    const beneficiaryAddressTitle = useLocalizationText(LocalizationEnum.CreatePartnerFormBeneficiaryAddress);

    return {
        beneficiaryInfoTitle,
        beneficiaryInfoDescription,
        beneficiaryCountryTitle,
        beneficiaryCityTitle,
        beneficiaryZipTitle,
        beneficiaryAddressTitle,
    };
};
