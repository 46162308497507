import { FC } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { ExternalLink } from '@shared-atom/external-link/external-link';
import { Translate } from '@shared-atom/translate/translate';
import {
    ButtonContentWrapper,
    DeleteIcon,
    DocumentUploadedWrapper,
    FileInfo,
    FileName,
    ImagePreview,
    UploadInfo,
} from '@shared-component/forms/private-document-form/document-uploaded/document-uploaded.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { ClickEventArgsType } from '@shared-type/click-event.type';
import { OnEventEmptyType } from '@shared-type/on-event.type';

interface DocumentUploadedInterface {
    filename: string;
    onDelete?: OnEventEmptyType;
    path?: string;
}

export const DocumentUploaded: FC<DocumentUploadedInterface> = ({ filename, path, onDelete }) => {
    const handleDelete = (event: ClickEventArgsType) => {
        event.preventDefault();
        if (onDelete !== undefined) {
            onDelete();
        }
    };

    return (
        <ExternalLink href={path ?? '#'}>
            <DocumentUploadedWrapper>
                <ImagePreview />
                <FileInfo>
                    <FileName>{filename}</FileName>
                    <UploadInfo>
                        100% <Translate langKey={LocalizationEnum.UploadDocumentSelectUploaded} />
                    </UploadInfo>
                </FileInfo>
                {onDelete !== undefined && (
                    <ButtonTransparent type={ButtonTypeEnum.Button} onClick={handleDelete}>
                        <ButtonContentWrapper>
                            <DeleteIcon />
                        </ButtonContentWrapper>
                    </ButtonTransparent>
                )}
            </DocumentUploadedWrapper>
        </ExternalLink>
    );
};
