import { useEffect, useState } from 'react';

import { OTHER_METHODS_BUTTON_VISIBILITY_DELAY } from '@shared-util/constants';

export const useLoginMethodSelector = () => {
    const [isOtherLoginMethodsAvailable, setOtherLoginMethodsAvailable] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setOtherLoginMethodsAvailable(true);
        }, OTHER_METHODS_BUTTON_VISIBILITY_DELAY);

        return () => clearTimeout(timeoutId);
    }, []);

    return { isOtherLoginMethodsAvailable };
};
