import React, { FC, memo, SVGProps } from 'react';

import { Translate } from '../../translate/translate';
import { ElpasoBadge, ElpasoBadgeIcon, ElpasoBadgeTitle } from './style';
import { ElpasoBadgeProps } from './types';

export const Badge = memo(({ title, size, type, iconAfter = null, iconBefore = null }: ElpasoBadgeProps) => {
    const IconComponentBefore = (iconBefore || null) as FC<SVGProps<SVGSVGElement>>;
    const IconComponentAfter = (iconAfter || null) as FC<SVGProps<SVGSVGElement>>;

    return (
        <ElpasoBadge size={size} typeStyle={type}>
            {iconBefore !== null && (
                <ElpasoBadgeIcon size={size} isBefore>
                    <IconComponentBefore />
                </ElpasoBadgeIcon>
            )}
            <ElpasoBadgeTitle size={size}>
                <Translate langKey={title} />
            </ElpasoBadgeTitle>
            {iconAfter !== null && (
                <ElpasoBadgeIcon size={size}>
                    <IconComponentAfter />
                </ElpasoBadgeIcon>
            )}
        </ElpasoBadge>
    );
});
