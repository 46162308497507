import styled from 'styled-components';

import { MaskedInputStyle } from '../../../../components/input/input.styles';
import { Typography } from '../../../typography/typography';

interface CardMaskedStyleProps {
    isMobile?: boolean;
}

export const CardMaskedStyle = styled(MaskedInputStyle)<CardMaskedStyleProps>`
    border: none;
    flex: 1;
    padding: 16px;
    background-color: transparent;
    ${({ isMobile = false }) =>
        isMobile ? Typography.Paragraph14.SemiBold.Neutral900.Style : Typography.Paragraph16.SemiBold.Neutral900.Style}
`;
