import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import ArrowLeft from '@shared-asset/vector/arrow-left.svg?react';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomInputPassword } from '@shared-component/input/input-password/custom-input-password';
import { FormRow, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';

import { PageEnum } from '@enum/page.enum';
import { FormButtonsWrapper } from '@page/registration/registration-additional-fields/registration-additional-fields.styles';
import { RegistrationPasswordFormInitial } from '@page/registration/registration-password/registration-password-form/registration-password-form.initial';
import {
    RegistrationPasswordFormEnum,
    RegistrationPasswordType,
    RegistrationPasswordSchema,
} from '@page/registration/registration-password/registration-password-form/registration-password-form.options';
import { useUserSelector } from '@selector/user/use-user.selector';

interface RegistrationPasswordFromProps {
    onSubmit: OnEventType<RegistrationPasswordType>;
}

export const RegistrationPasswordForm = observer(({ onSubmit }: RegistrationPasswordFromProps) => {
    const navigate = useRouterNavigate();
    const [{ password }, { isUserLoading: isLoading }] = useUserSelector();

    const [initialFormValues, setInitialFormValues] = useState(RegistrationPasswordFormInitial);

    const passwordTitle = useLocalizationText(LocalizationEnum.CommonPassword);
    const repeatPasswordTitle = useLocalizationText(LocalizationEnum.CommonPasswordRepeat);

    useEffect(() => {
        setInitialFormValues({
            ...RegistrationPasswordFormInitial,
            [RegistrationPasswordFormEnum.Password]: password,
        });
    }, [password]);

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={RegistrationPasswordSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
            enableReinitialize
        >
            <Form>
                <FormWrapper>
                    <FormRow>
                        <CustomInputPassword
                            name={RegistrationPasswordFormEnum.Password}
                            title={passwordTitle}
                            hasTooltipValidation
                            autofocus
                        />
                    </FormRow>
                    <FormRow>
                        <CustomInputPassword
                            name={RegistrationPasswordFormEnum.RepeatPassword}
                            title={repeatPasswordTitle}
                        />
                    </FormRow>
                </FormWrapper>

                <FormButtonsWrapper>
                    <Button
                        onClick={() => navigate(PageEnum.RegistrationAdditionalFields)}
                        type={ButtonTypeEnum.GHOST}
                        size={ButtonSizeEnum.L}
                        title={LocalizationEnum.CommonBack}
                        iconBefore={ArrowLeft}
                    />
                    <Button
                        title={LocalizationEnum.RegistrationLegalCompanyButtonTitle}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                        isSubmit
                        isDisabled={isLoading}
                    />
                </FormButtonsWrapper>
            </Form>
        </Formik>
    );
});
