import { useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import {
    DefaultAnswer,
    Mutation,
    MutationUserConfirmPasswordResetArgs,
    UserConfirmPasswordReset,
} from '@shared-graphql';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isExist, isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';

export const useResetPassword = () => {
    const navigate = useRouterNavigate();

    const { onOpen } = useContext(InformationModalContext);

    const [userConfirmPasswordReset, { data, loading: isLoading, error }] =
        useMutation<Pick<Mutation, 'userConfirmPasswordReset'>>(UserConfirmPasswordReset);
    const {
        params: { code },
    } = useCurrentNavigation();

    const handleResetPassword = useCallback(
        ({ password }: MutationUserConfirmPasswordResetArgs) =>
            userConfirmPasswordReset({ variables: { code, password } }),
        [userConfirmPasswordReset, code]
    );

    useEffect(() => {
        if (error) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: error?.message ?? LocalizationEnum.FailureCurrencyExchangeMessage,
                timeout: 5000,
            });
        }
    }, [error]);

    useEffect(() => {
        if (isExist((data?.userConfirmPasswordReset as DefaultAnswer)?.status)) {
            if (isTrue((data?.userConfirmPasswordReset as DefaultAnswer)?.status)) {
                navigate(PageEnum.Login, () => {
                    onOpen(InformationModalEnum.SuccessInformationModal, {
                        text: LocalizationEnum.PasswordHasBeenSuccessfullyChanged,
                        timeout: 5000,
                    });
                });
            } else {
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: LocalizationEnum.FailureCurrencyExchangeMessage,
                    timeout: 5000,
                });
            }
        }
    }, [data]);

    return { handleResetPassword, isLoading };
};
