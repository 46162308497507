import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { DarkBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const ConfirmationWrapper = styled.div`
    height: 100%;
    ${Flex.Column.HorizontalCenter.VerticalCenter.Style}
`;

export const ContentWrapper = styled.div`
    width: 100%;
    ${Flex.Column.VerticalCenter.Style}
`;

export const DescriptionWrapper = styled.p`
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : DarkBlue)};
    ${Typography.L.Style}
`;

export const ButtonsContainer = styled.div`
    margin-top: 32px;
    ${Flex.Style}
`;

export const ButtonWrapper = styled.div`
    margin-left: 16px;
`;
