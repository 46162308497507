import { BaseOptionInterface } from '@shared-atom/elpaso-kit/custom-select/custom-select.props';
import { SelectRowItem } from '@shared-atom/elpaso-kit/custom-select/custom-select.styles';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const customSelectRowRender = <T extends BaseOptionInterface>(item: T) => (
    <SelectRowItem>
        <Translate langKey={item?.label as LocalizationEnum} />
    </SelectRowItem>
);
