import styled from 'styled-components';

import chain from '@shared-asset/chain.png';
import chainMobile from '@shared-asset/dashed-line.svg';
import { Typography } from '@shared-atom/typography/typography';
import { Neutral50 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const ChainBlocksWrapper = styled.div`
    width: 50%;
    min-width: 556px;
    gap: 48px;
    background-image: url(${chain});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    ${Flex.VerticalCenter.HorizontalCenter.Column.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        background-image: url(${chainMobile});
        background-size: contain;
        min-width: unset;
    }
`;
export const ChainBlocksRow = styled.div`
    width: 100%;
    gap: 48px;
    ${Flex.HorizontalCenter.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.Column.Style};
    }
`;

export const ChainBlock = styled.div`
    padding: 40px 16px;
    width: 254px;
    min-height: 250px;
    background: ${Neutral50};
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(6px);
    border-radius: 32px;
    gap: 48px;
    ${Flex.VerticalCenter.Column.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
    }
`;

export const ChainBlockIcon = styled.img`
    width: auto;
    height: 47px;
    max-height: 47px;
`;
export const ChainBlockText = styled.p`
    ${Typography.Paragraph18.SemiBold.Center.Neutral700.Style}
`;
