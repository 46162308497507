import styled from 'styled-components';

import { ElpasoButton } from '@shared-atom/elpaso-kit/button/style';
import { Primary500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const LoginMethodSelectorWrapper = styled.div`
    ${Flex.Column.FlexStart.Style};
    gap: 16px;
    padding: 16px 20px 0;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;

    ${ElpasoButton}:not(:first-child) {
        width: 100%;
    }
    svg {
        fill: ${Primary500};
    }
`;
