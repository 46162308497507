import { memo, Suspense } from 'react';

import { FCWithChildrenType } from '@shared-type/common';

import { AnimationLoading } from '../animation/animation-loading/animation-loading';
import { LoaderAnimationWrapper, PageLoaderWrapper } from './page-loader.styles';

export const ViewPageLoader = memo(() => (
    <PageLoaderWrapper>
        <LoaderAnimationWrapper>
            <AnimationLoading isWhite />
        </LoaderAnimationWrapper>
    </PageLoaderWrapper>
));

export const PageLoader: FCWithChildrenType = ({ children }) => (
    <Suspense fallback={<ViewPageLoader />}>{children}</Suspense>
);
