import { observer } from 'mobx-react-lite';

import { Translate } from '@shared-atom/translate/translate';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PageEnum } from '@enum/page.enum';
import { AuthPageContentWrapper, AuthPageFormTitle } from '@page/auth-pages/common.styles';
import { RegistrationPasswordForm } from '@page/registration/registration-password/registration-password-form/registration-password-form';
import { RegistrationFormWrapper } from '@page/registration/registration-password/registration-password.styles';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';

interface HandleSubmitInterface {
    password: string;
    repeatPassword: string;
}

const RegistrationPassword = observer(() => {
    const navigate = useRouterNavigate();
    const { setUserData } = useUserActionsSelector();

    const handleSubmit = ({ password }: HandleSubmitInterface) => {
        setUserData({ password });
        navigate(PageEnum.RegistrationPhone);
    };
    return (
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={LocalizationEnum.CreatePasswordTitle} />
            </AuthPageFormTitle>
            <RegistrationFormWrapper>
                <RegistrationPasswordForm onSubmit={handleSubmit} />
            </RegistrationFormWrapper>
        </AuthPageContentWrapper>
    );
});

export default RegistrationPassword;
