import React, { useMemo } from 'react';

import { DateFormatWithTime } from '@shared-atom/elpaso-kit/input/input-date/input-date-format.helper';
import { Translate } from '@shared-atom/translate/translate';
import { UserSession } from '@shared-graphql';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { getDate } from '@shared-util/get-date';
import { isTrue } from '@shared-util/is-data';

import {
    ActivationInfo,
    AdditionalInfo,
    MainInfo,
    SessionContent,
    SessionInfo,
    SessionInfoRow,
    SessionPreview,
} from '@page/workspace/settings/section/security/subsections/sessions/session/session.styles';
import { getIconMappingByOs } from '@page/workspace/settings/section/security/subsections/sessions/utils';

export const Session = ({ device, browser, os, lastLogin, city, country, ip, isCurrentSession }: UserSession) => {
    const { lang } = useLocalizationStore();

    const source = useMemo(() => (isTrue(browser) ? browser : isTrue(os) ? os : ''), [browser, os]) as string;
    const instrument = useMemo(() => (isTrue(device) ? device : isTrue(os) ? os : ''), [device, os]);

    return (
        <SessionContent>
            <SessionPreview src={getIconMappingByOs(source.toLowerCase())} />
            <SessionInfo>
                {(isTrue(source) || isTrue(instrument)) && (
                    <SessionInfoRow>
                        <MainInfo>{`${isTrue(source) ? source : ''}${
                            isTrue(source) && isTrue(instrument) ? ' • ' : ''
                        }${isTrue(instrument) ? instrument : ''}`}</MainInfo>
                    </SessionInfoRow>
                )}
                {(isTrue(ip) || isTrue(city) || isTrue(country)) && (
                    <SessionInfoRow>
                        <AdditionalInfo>{`${isTrue(ip) ? `${ip} - ` : ''}${isTrue(city) ? `${city}, ` : ''}${
                            isTrue(country) ? `${country}` : ''
                        }`}</AdditionalInfo>
                    </SessionInfoRow>
                )}
                {isTrue(isCurrentSession) ? (
                    <ActivationInfo>
                        <Translate langKey={LocalizationEnum.CommonActiveNow} />
                    </ActivationInfo>
                ) : (
                    isTrue(lastLogin) && (
                        <SessionInfoRow>
                            <AdditionalInfo>
                                {getDate(new Date(Number(lastLogin)), DateFormatWithTime[lang])}
                            </AdditionalInfo>
                        </SessionInfoRow>
                    )
                )}
            </SessionInfo>
        </SessionContent>
    );
};
