import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useCreatePartnerFormAcctNumberLocalization = () => {
    const accountTitle = useLocalizationText(LocalizationEnum.CreatePartnerFormAccount);
    const statusTitle = useLocalizationText(LocalizationEnum.CreatePartnerFormAlreadyExist);

    return {
        accountTitle,
        statusTitle,
    };
};
