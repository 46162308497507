import styled from 'styled-components';

import { DarkGrey, Mystic } from '../../styles/colors';
import DefaultIcon from './assets/world.svg?react';

const iconStyle = `
    position: absolute;
    width: 24px;
    height: 24px;
    top: 16px;
    left: 16px;
    border-radius: 50%;
`;

export const FlagIcon = styled.img`
    border: 1px solid ${Mystic};
    ${iconStyle}
`;

export const DefaultFlagIcon = styled(DefaultIcon)`
    fill: ${DarkGrey};
    ${iconStyle}
`;
