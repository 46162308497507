import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import ArrowLeft from '@shared-asset/vector/arrow-left.svg?react';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomCountrySelect } from '@shared-atom/elpaso-kit/custom-country-select/custom-country-select';
import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { Translate } from '@shared-atom/translate/translate';
import { BaseOptionInterface } from '@shared-component/select/select.props';
import { ToOnboardingButton } from '@shared-component/to-onboarding-button/to-onboarding-button';
import { FormRow, FormRowTitle, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { UserType } from '@shared-graphql';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { AuthPageFormTitle } from '@page/auth-pages/common.styles';
import { legalRegistrationAdditionalFieldsFormValidationSchema } from '@page/registration/registration-additional-fields/registration-additional-fields-form/registration-additional-fields-form-legal//registration-additional-fields-form-legal.validation';
import { legalRegistrationAdditionalFieldsFormInitials } from '@page/registration/registration-additional-fields/registration-additional-fields-form/registration-additional-fields-form-legal/registration-additional-fields-form-legal.initial';
import { LegalRegistrationAdditionalFieldsFormType } from '@page/registration/registration-additional-fields/registration-additional-fields-form/registration-additional-fields-form-legal/registration-additional-fields-form-legal.type';
import { RegistrationAdditionalFieldsFormEnum } from '@page/registration/registration-additional-fields/registration-additional-fields.enum';
import { FormButtonsWrapper } from '@page/registration/registration-additional-fields/registration-additional-fields.styles';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';

export const RegistrationAdditionalFieldsLegalForm = observer(
    ({ countries }: { countries: BaseOptionInterface<string>[] }) => {
        const { setUserData } = useUserActionsSelector();
        const navigate = useRouterNavigate();
        const [{ companyName, legalCountry, postalCountry, type }] = useUserSelector();

        const initialFormValues = useMemo(
            () =>
                !isTrue(companyName) || !isTrue(companyName) || !isTrue(postalCountry)
                    ? legalRegistrationAdditionalFieldsFormInitials
                    : {
                          [RegistrationAdditionalFieldsFormEnum.CompanyName]: companyName,
                          [RegistrationAdditionalFieldsFormEnum.LegalCountry]: legalCountry,
                          [RegistrationAdditionalFieldsFormEnum.PostalCountry]: postalCountry,
                      },
            [companyName, legalCountry, postalCountry]
        );

        const handleSubmit = (values: LegalRegistrationAdditionalFieldsFormType) => {
            setUserData({
                [RegistrationAdditionalFieldsFormEnum.CompanyName]:
                    values[RegistrationAdditionalFieldsFormEnum.CompanyName],
                [RegistrationAdditionalFieldsFormEnum.LegalCountry]:
                    values[RegistrationAdditionalFieldsFormEnum.LegalCountry],
                [RegistrationAdditionalFieldsFormEnum.PostalCountry]:
                    values[RegistrationAdditionalFieldsFormEnum.PostalCountry],
            });
            navigate(PageEnum.RegistrationPassword);
        };

        return (
            <>
                <AuthPageFormTitle>
                    <Translate langKey={LocalizationEnum.CommonCompanyInfo} />
                </AuthPageFormTitle>
                <Formik
                    initialValues={initialFormValues}
                    onSubmit={handleSubmit}
                    validationSchema={legalRegistrationAdditionalFieldsFormValidationSchema}
                    validateOnChange={false}
                    enableReinitialize
                >
                    <Form>
                        <FormWrapper>
                            <FormRow>
                                <CustomInput
                                    label={LocalizationEnum.PaymentFormBeneficiaryStepCompanyName}
                                    name={RegistrationAdditionalFieldsFormEnum.CompanyName}
                                    placeholder={LocalizationEnum.PaymentFormBeneficiaryStepCompanyName}
                                    isMobile
                                />
                            </FormRow>
                            <FormRowTitle>
                                <Translate
                                    langKey={
                                        LocalizationEnum.SelectTheCountryOfCompanyRegistrationAndTheCountryOfCompanyActualAddress
                                    }
                                />
                            </FormRowTitle>
                            <FormRow>
                                <CustomCountrySelect
                                    label={LocalizationEnum.TheCountryOfCompanyRegistration}
                                    placeholder={LocalizationEnum.CommonChooseCountry}
                                    name={RegistrationAdditionalFieldsFormEnum.LegalCountry}
                                    countries={countries}
                                    isMobile
                                />
                            </FormRow>
                            <FormRow>
                                <CustomCountrySelect
                                    label={LocalizationEnum.TheCountryOfCompanyActualAddress}
                                    placeholder={LocalizationEnum.CommonChooseCountry}
                                    name={RegistrationAdditionalFieldsFormEnum.PostalCountry}
                                    countries={countries}
                                    isMobile
                                />
                            </FormRow>
                        </FormWrapper>
                        <FormButtonsWrapper>
                            <ToOnboardingButton
                                userType={type as UserType}
                                type={ButtonTypeEnum.GHOST}
                                size={ButtonSizeEnum.L}
                                title={LocalizationEnum.CommonBack}
                                iconBefore={ArrowLeft}
                            />
                            <Button
                                size={ButtonSizeEnum.L}
                                type={ButtonTypeEnum.COLOR}
                                title={LocalizationEnum.RegistrationLegalCompanyButtonTitle}
                                isSubmit
                            />
                        </FormButtonsWrapper>
                    </Form>
                </Formik>
            </>
        );
    }
);
