import styled from 'styled-components';

import { Typography } from '../../typography/typography';
import { LinkTypeEnum, LinkSizeEnum } from './types';
import { getStyleByType } from './utils';

interface ElpasoLinkProps {
    size: LinkSizeEnum;
    typeStyle: LinkTypeEnum;
    disabled: boolean;
}

export const ElpasoLink = styled.a<ElpasoLinkProps>`
    text-decoration: none;
    text-align: center;

    ${({ size }) => {
        if (size === LinkSizeEnum.S) {
            return Typography.Paragraph12.Medium.Style;
        }
        if (size === LinkSizeEnum.L) {
            return Typography.Paragraph14.Medium.Style;
        }
        return Typography.Paragraph16.Medium.Style;
    }};
    ${({ typeStyle, disabled }) => getStyleByType(typeStyle, disabled)}
`;
