import { countries } from 'countries-list';
import React from 'react';

import { Flag } from '@shared-atom/flag/flag';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ModalContentWrapper, ModalHeader, ModalTitle, ModalWrapper } from '@component/modal/client-modal.styles';
import { sortedEEUCountriesList } from '@component/modal/modals/registration-permitted-countries/registration-permitted-countries.options';
import {
    CountriesBlock,
    CountriesBlockCountry,
    CountriesBlockCountryName,
    CountriesBlockTitle,
    PermittedCountriesPageForEEUContent,
} from '@component/modal/modals/registration-permitted-countries/registration-permitted-countries.styles';

export const RegistrationPermittedCountriesModal = () => (
    <ModalWrapper>
        <ModalHeader>
            <ModalTitle>
                <Translate langKey={LocalizationEnum.PermittedCountriesTitle} />
            </ModalTitle>
        </ModalHeader>
        <ModalContentWrapper>
            <PermittedCountriesPageForEEUContent>
                {sortedEEUCountriesList.map(([[firstLetter], countryCodes]) => (
                    <CountriesBlock key={firstLetter}>
                        <CountriesBlockTitle>{firstLetter}</CountriesBlockTitle>
                        {countryCodes.map(countryCode => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            const countryName = countries[countryCode].name;
                            return (
                                <CountriesBlockCountry key={countryCode}>
                                    <Flag countryCode={countryCode} />
                                    <CountriesBlockCountryName>{countryName}</CountriesBlockCountryName>
                                </CountriesBlockCountry>
                            );
                        })}
                    </CountriesBlock>
                ))}
            </PermittedCountriesPageForEEUContent>
        </ModalContentWrapper>
    </ModalWrapper>
);
