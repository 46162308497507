import { ButtonTransparent } from '../../../../atoms/button/button-transparent';
import { Link } from '../../../../atoms/link/link';
import { MAX_MOBILE_SIZE } from '../../../../styles/media-sizes';
import { isExist } from '../../../../utils/is-data';
import { useNavigationItem } from './page-wrapper-navigation-item.hook';
import { PageWrapperNavigationItemProps } from './page-wrapper-navigation-item.interface';
import {
    NavigationItem,
    NavigationItemIcon,
    NavigationItemIconWrapper,
    NavigationItemTitle,
    NavigationItemWrapper,
    SubNavigationItem,
    SubNavigationList,
    SubNavigationTitle,
} from './page-wrapper-navigation-item.styles';

export const PageWrapperNavigationItem = <T extends string>({
    icon,
    iconActive,
    title,
    children,
    pageName,
    modalName,
    onClick,
}: PageWrapperNavigationItemProps<T>) => {
    const wd = window.innerWidth;
    const isShow = wd > MAX_MOBILE_SIZE;
    const [isNavigationItemActive, isMenuOpen, onMenuClick, checkSubNavigationActive] = useNavigationItem({
        pageName,
        modalName,
        children,
    });

    const shouldSubNavigationRender = isMenuOpen && isExist(children) && isNavigationItemActive;
    const handleMenuClick = isExist(onClick) ? onClick : onMenuClick;

    return (
        <NavigationItem>
            <NavigationItemWrapper isActive={isNavigationItemActive}>
                <ButtonTransparent isFull onClick={handleMenuClick}>
                    <NavigationItemIconWrapper>
                        <NavigationItemIcon as={isNavigationItemActive ? iconActive : icon} />
                        <NavigationItemTitle
                            style={{ paddingRight: isShow ? 'unset' : '8px', paddingLeft: isShow ? '8px' : '0px' }}
                        >
                            {title}
                        </NavigationItemTitle>
                    </NavigationItemIconWrapper>
                </ButtonTransparent>
                {shouldSubNavigationRender && (
                    <SubNavigationList>
                        {children?.map(({ title: subItemTitle, pageName: subItemPageName, params }) => (
                            <SubNavigationItem
                                key={subItemPageName}
                                isActive={checkSubNavigationActive(subItemPageName, params)}
                            >
                                <Link route={subItemPageName} params={params}>
                                    <SubNavigationTitle>{subItemTitle}</SubNavigationTitle>
                                </Link>
                            </SubNavigationItem>
                        ))}
                    </SubNavigationList>
                )}
            </NavigationItemWrapper>
        </NavigationItem>
    );
};
