import styled from 'styled-components';

import chainMobile from '@shared-asset/dashed-line.svg';
import { ElpasoButton } from '@shared-atom/elpaso-kit/button/style';
import { Typography } from '@shared-atom/typography/typography';
import { Neutral100, Neutral50 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import {
    ChainBlock,
    ChainBlockText,
    ChainBlocksWrapper,
    ChainBlocksRow,
} from '@component/chain-blocks/chain-blocks.styles';

export const HomeSolutionsModalWrapper = styled.div`
    background-color: ${Neutral100};
    overflow-y: scroll;
    height: 100%;
    max-height: 803px;
    border-radius: 0 0 20px 20px;
    width: 710px;
    padding-top: 69px;
    padding-bottom: 30px;
    ${ChainBlocksWrapper} {
        background-size: 55% 55%;
    }
    ${ChainBlock} {
        padding: 36px 16px;
        height: 180px;
        min-height: unset;
        gap: 12px;
    }
    ${ChainBlockText} {
        ${Typography.Paragraph16.Neutral700.Center.Normal.Style};
    }
    ${ElpasoButton} {
        display: none;
    }

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        padding: 48px 16px 30px;
        max-height: unset;
        border-radius: unset;
        ${ChainBlocksWrapper} {
            background-size: auto 100%;
            gap: 32px;
            background-image: unset;
        }
        ${ChainBlocksRow} {
            gap: 32px;
        }
        ${ChainBlock} {
            height: unset;
            min-height: unset;
        }
        ${ElpasoButton} {
            display: block;
            width: 100%;
            margin: 32px auto 0;
            max-width: 557px;
        }
    }
`;
export const HomeSolutionsModalTitle = styled.h2`
    ${Typography.H2Desktop.Bold.Neutral600.Center.Style};

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.H3Mob.Normal.Neutral900.Center.Style};
    }
`;
export const HomeSolutionsModalContent = styled.div`
    max-width: 557px;
    margin: 36px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        margin: 16px auto 0;
        background-image: url(${chainMobile});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
`;
export const HomeServiceModalCustomerInfo = styled.div`
    ${Flex.VerticalCenter.Style};
    width: 372px;
    max-width: 372px;
    padding: 20px;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 32px;
    background: ${Neutral50};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.Column.HorizontalCenter.VerticalCenter.Style};
        width: 100%;
        max-width: 557px;
    }
`;
export const HomeServiceModalCustomerIcon = styled.img`
    width: 86px;
    height: 86px;
    border-radius: 50%;
    overflow: hidden;
`;
export const HomeServiceModalCustomerInfoContent = styled.div`
    ${Flex.Column.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.Column.VerticalCenter.HorizontalCenter.Style};
    }
`;
export const HomeServiceModalCustomerName = styled.div`
    ${Typography.XL.SemiBold.Neutral700.Style};
    line-height: 24px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.H4Mob.Normal.Neutral700.Style};
    }
`;
export const HomeServiceModalCustomerDescription = styled.div`
    ${Typography.Paragraph16.Normal.Neutral500.Style};
`;
