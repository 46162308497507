import { values } from 'mobx';
import { types } from 'mobx-state-tree';

import { FeatureStatus } from '@shared-graphql';
import { FeatureTagModelType } from '@shared-model/feature-tags-model/feature-tags-model.type';

import { featureTagsModelActions } from '@model/feature-tags-model/feature-tags-model-actions/feature-tags-model.actions';

export const FeatureTagsModelType = types
    .model({
        elements: types.array(types.maybe(FeatureTagModelType)),
        loading: types.model({
            isLoading: types.boolean,
        }),
        error: types.optional(types.string, ''),
    })
    .views(self => ({
        get featureTags() {
            return values(self.elements) as unknown as FeatureStatus[];
        },
    }));

export const FeatureTagsModel = FeatureTagsModelType.actions(featureTagsModelActions);

export const getInitialFeatureTagsModel = () =>
    FeatureTagsModel.create({
        elements: [],
        loading: {
            isLoading: false,
        },
    });
