import { useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { EventEmitterEnum } from '@shared-enum/event-emitter.enum';
import {
    Account,
    ConfirmSignPayment,
    CreateCardWithdrawal,
    CreateCardWithdrawalAnswer,
    DefaultAnswer,
    Mutation,
    StartSignPayment,
} from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useSelectedAccountWithStorage } from '@shared-hook/selected-account-with-storage/selected-account-with-storage';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { addDocumentComments } from '@shared-util/add-document-comments';
import { emit } from '@shared-util/event-emitter';
import { isExist, isFalse, isTrue } from '@shared-util/is-data';

import { PAYMENT_TO_CARD_BREADCRUMBS } from '@component/modal/modals/payment/payment-breadcrumbs/payment-breadcrumbs.options';
import { PaymentToCardFormValuesInterface } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form.interface';
import { PaymentUpdateStepsEnum } from '@component/modal/modals/payment/payment-update-steps/payment-update-hook.enum';
import { FilterCountriesEnum, useCountries } from '@hook/countries/countries.hook';
import { addComment } from '@model/actions/add-comment';
import { addFileComment } from '@model/actions/add-file-comment';

import { paymentToCardFormInitials } from './payment-to-card-form.initial';

const usePaymentToCardUpdate = (
    existPaymentId: string | null,
    isSignMode: boolean,
    setPaymentError: Dispatch<SetStateAction<boolean>>
) => {
    const [step, setStep] = useState(PaymentUpdateStepsEnum.ConfirmPayment);
    const [isPayNowActivated, setPayNowActivated] = useState(false);

    const [signPaymentError, setSignPaymentError] = useState('');
    const OtpCodeIncorrectError = useLocalizationText(LocalizationEnum.OtpCodeIncorrectError);

    const [cardWithdrowal, { data: cardWithdrowalData, loading: isLoadingCardWithdrowal }] = useMutation<
        Pick<Mutation, 'createCardWithdrawal'>
    >(CreateCardWithdrawal, {
        onError: error => {
            if (Boolean(error) !== false) {
                setPaymentError(true);
            }
            emit(EventEmitterEnum.TransactionsUpdate);
        },
        onCompleted: result => {
            if (result === null) {
                setPaymentError(true);
            }
            emit(EventEmitterEnum.TransactionsUpdate);
        },
    });

    const [startSignPayment, { data: startSignPaymentData, loading: isStartSignPaymentLoading }] = useMutation<
        Pick<Mutation, 'startSignPayment'>
    >(StartSignPayment, {
        onError: error => {
            if (isTrue(error)) {
                setPaymentError(true);
            }
        },
        onCompleted: result => {
            if (result === null || isFalse((result?.startSignPayment as DefaultAnswer)?.status)) {
                setPaymentError(true);
            }
        },
    });
    const [confirmSignPayment, { data: confirmSignPaymentData, loading: isConfirmSignPaymentLoading }] = useMutation<
        Pick<Mutation, 'confirmSignPayment'>
    >(ConfirmSignPayment, {
        onError: error => {
            if (isTrue(error)) {
                setSignPaymentError(OtpCodeIncorrectError);
            }
        },
        onCompleted: result => {
            if (result === null || isFalse(result?.confirmSignPayment?.status)) {
                setSignPaymentError(OtpCodeIncorrectError);
            }
        },
    });

    const paymentId = (cardWithdrowalData?.createCardWithdrawal as CreateCardWithdrawalAnswer)?.id ?? existPaymentId;
    const isPaymentSigned = (confirmSignPaymentData?.confirmSignPayment as DefaultAnswer)?.status ?? false;

    useEffect(() => {
        if (isExist(cardWithdrowalData)) {
            if (isPayNowActivated) {
                startSignPayment({ variables: { paymentId } });
            } else {
                setStep(PaymentUpdateStepsEnum.PaymentCreated);
            }
        }
    }, [cardWithdrowalData, paymentId]);

    useEffect(() => {
        if ((startSignPaymentData?.startSignPayment as DefaultAnswer)?.status) {
            setStep(PaymentUpdateStepsEnum.Otp);
        }
    }, [startSignPaymentData]);

    useEffect(() => {
        if (isSignMode) {
            if (isPayNowActivated) {
                setStep(PaymentUpdateStepsEnum.Otp);
            }
        }
    }, [isPayNowActivated]);

    useEffect(() => void (isPaymentSigned && setStep(PaymentUpdateStepsEnum.PaymentSigned)), [isPaymentSigned]);

    const onPaymentSign = () => setPayNowActivated(true);
    const onSendOtpCode = () => startSignPayment({ variables: { paymentId } });

    const onOtpSubmit = (otp: string) => {
        if (!isExist(paymentId)) {
            return;
        }

        confirmSignPayment({
            variables: {
                id: paymentId,
                otp,
            },
        })
            .then(() => emit(EventEmitterEnum.TransactionsUpdate))
            .catch(() => setPaymentError(true));
    };

    return {
        paymentUpdateStep: step,
        signPaymentError,
        setSignPaymentError,
        cardWithdrowal,
        isLoadingCardWithdrowal: isLoadingCardWithdrowal || isStartSignPaymentLoading || isConfirmSignPaymentLoading,
        onOtpSubmit,
        onPaymentSign,
        onSendOtpCode,
    };
};

export const usePaymentToCardFormSteps = (
    accounts: Account[],
    isSignMode: boolean,
    initialPayment = paymentToCardFormInitials
) => {
    const [isPaymentError, setPaymentError] = useState(false);
    const { onOpen } = useContext(InformationModalContext);
    const [activeStep, setActiveStep] = useState(0);
    const {
        paymentUpdateStep,
        signPaymentError,
        setSignPaymentError,
        cardWithdrowal,
        isLoadingCardWithdrowal,
        onOtpSubmit,
        onPaymentSign,
        onSendOtpCode,
    } = usePaymentToCardUpdate(initialPayment.id, isSignMode, setPaymentError);

    const [blackListCountries] = useCountries(FilterCountriesEnum.BLACK_LIST);
    const restrictedCountries = useMemo(
        () => blackListCountries?.map(({ alpha2 }) => alpha2) ?? [],
        [blackListCountries]
    );

    const onNextStep = ({ nationality }: { nationality: string }) => {
        if (isTrue(restrictedCountries.includes(nationality ?? ''))) {
            onOpen(InformationModalEnum.FailureInformationModal);
        } else {
            setActiveStep(prev => prev + 1);
        }
    };
    const onPrevStep = () => setActiveStep(prev => prev - 1);

    const onStep = (step: number) => setActiveStep(step);

    const selectedAccount = useSelectedAccountWithStorage({ accounts });

    const defaultInitialAccount = {
        accountId: selectedAccount?.accountId ?? '',
        currency: selectedAccount?.accountId ?? '',
    };

    const initialValues = {
        ...defaultInitialAccount,
        ...initialPayment,
    };

    const onSubmit = ({ accountId: acctId, attachedFiles, reason, ...values }: PaymentToCardFormValuesInterface) => {
        const { id, cardNumber, currency: paymentAccountId, ...formValues } = values;
        const accountId = accounts.find(account => account.id === acctId)?.accountId;
        const paymentCurrency = accounts.find(account => account.id === paymentAccountId)?.currency.code;

        addDocumentComments({
            initialDocuments: initialValues.attachedFiles,
            documents: attachedFiles,
            addComment,
            addFileComment,
        });

        cardWithdrowal({
            variables: {
                cardWithdrawal: {
                    accountId,
                    ...formValues,
                    currency: paymentCurrency,
                    cardNumber: cardNumber?.replaceAll('-', ''),
                    reason,
                    documentIds: attachedFiles.map(file => file.key),
                },
            },
        })
            .then(() => {
                emit(EventEmitterEnum.TransactionsUpdate);
            })
            .catch(() => setPaymentError(true));
    };

    const isLastStepActive = activeStep === PAYMENT_TO_CARD_BREADCRUMBS.length - 1;

    return {
        onNextStep,
        onPrevStep,
        onStep,
        onOtpSubmit,
        onPaymentSign,
        activeStep,
        paymentUpdateStep,
        signPaymentError,
        setSignPaymentError,
        isLoading: isLoadingCardWithdrowal,
        onSubmit: isLastStepActive ? onSubmit : onNextStep,
        initialValues,
        isPaymentError,
        onSendOtpCode,
    };
};
