import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { White, DarkBlue, Dark } from '@shared-style/colors';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const CreatePartnerWrapper = styled.div`
    background-color: ${White};
    border-radius: 8px 0 0 8px;
    width: 952px;
    height: 100%;
    background-color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? DarkBlue : White)};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        border-radius: 0;
    }
`;

export const CreatePartnerTitle = styled.h2`
    ${Typography.XXL.Center.Style}
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    margin-bottom: 24px;
`;

export const CreatePartnerFormWrapper = styled.div`
    padding: 80px 112px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        border-radius: 0;
        padding: 40px 16px;
    }
`;
