import { object } from 'yup';

import { emailValidation, fieldRequiredValidation, moreThanZeroValidation } from '@shared-util/validation';

import { AcquiringRequestFormEnum } from '@component/modal/modals/acquiring-request/acquiring-request-form/acquiring-request-form.enum';

export const acquiringRequestFormValidationSchema = object().shape({
    [AcquiringRequestFormEnum.Name]: fieldRequiredValidation(),
    [AcquiringRequestFormEnum.Email]: emailValidation(),
    [AcquiringRequestFormEnum.MoneyTurnoverCurrency]: fieldRequiredValidation(),
    [AcquiringRequestFormEnum.MoneyTurnoverAmount]: moreThanZeroValidation(),
    [AcquiringRequestFormEnum.Message]: fieldRequiredValidation(),
});
