import React, { useContext, useEffect } from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalHeightEnum } from '@shared-component/modal/modal.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ModalFooter } from '@component/modal/client-modal.styles';
import { UserShortProfileChangePasswordWrapper } from '@component/modal/modals/user-profile/user-short-profile-change-password/user-short-profile-change-password.styles';
import { ChangePasswordForm } from '@page/workspace/settings/section/security/subsections/password/change-password-form/change-password.form';

export const UserShortProfileChangePassword = () => {
    const { setModalHeight } = useContext(ModelContext);

    useEffect(() => {
        setModalHeight(ModalHeightEnum.H485);
    }, []);

    return (
        <UserShortProfileChangePasswordWrapper>
            <ChangePasswordForm>
                <ModalFooter>
                    <Button
                        title={LocalizationEnum.CommonSave}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                        isSubmit
                    />
                </ModalFooter>
            </ChangePasswordForm>
        </UserShortProfileChangePasswordWrapper>
    );
};
