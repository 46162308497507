const cardNumberSymbols = [/\d/, /\d/, /\d/, /\d/];

export const cardMaskRegExp = [
    ...cardNumberSymbols,
    '-',
    ...cardNumberSymbols,
    '-',
    ...cardNumberSymbols,
    '-',
    ...cardNumberSymbols,
];
