import React from 'react';
import ReactGA from 'react-ga4';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ToOnboardingButtonProps } from '@shared-component/to-onboarding-button/to-onboarding-button.interface';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { getOnBoardingActiveType } from '@shared-util/get-onboarding-active-type';

import { GAEventActionsEnum, GAEventCategoryEnum } from '@enum/ga-event.enum';
import { PageEnum } from '@enum/page.enum';

export const ToOnboardingButton = ({ type, size, title, iconBefore, userType, ...props }: ToOnboardingButtonProps) => {
    const navigate = useRouterNavigate();

    const clickHandler = () => {
        ReactGA.event({
            category: GAEventCategoryEnum.User,
            action: GAEventActionsEnum.OpenAccountClickBtn,
        });
        navigate(PageEnum.OnBoarding, {
            activeType: getOnBoardingActiveType(userType),
        });
    };

    return <Button onClick={clickHandler} type={type} size={size} title={title} iconBefore={iconBefore} {...props} />;
};
