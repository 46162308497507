import { object } from 'yup';

import { fieldRequiredValidation } from '@shared-util/validation';

import { RegistrationAdditionalFieldsFormEnum } from '@page/registration/registration-additional-fields/registration-additional-fields.enum';

export const legalRegistrationAdditionalFieldsFormValidationSchema = object().shape({
    [RegistrationAdditionalFieldsFormEnum.CompanyName]: fieldRequiredValidation(),
    [RegistrationAdditionalFieldsFormEnum.LegalCountry]: fieldRequiredValidation(),
    [RegistrationAdditionalFieldsFormEnum.PostalCountry]: fieldRequiredValidation(),
});
