import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral50, Neutral700, Neutral900 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import { SocialIconWrapper } from '@page/main-pages/main-page-common/social-icon/social-icon.styles';

type FooterWrapperType = {
    shouldRenderWhiteBg: boolean;
};

export const FooterWrapper = styled.footer<FooterWrapperType>`
    width: 100%;
    padding: 0 24px 20px;
    background-color: ${({ shouldRenderWhiteBg = false }) => (shouldRenderWhiteBg ? Neutral50 : 'transparent')};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 0 8px 10px;
    }
`;
export const FooterContainer = styled.div`
    width: 100%;
    padding: 120px 88px 40px;
    background: ${Neutral900};
    border-radius: 20px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 40px 24px;
    }
`;
export const FooterInnerContainer = styled.div`
    width: 100%;
    max-width: 1216px;
    margin: 0 auto;
    ${Flex.Column.Style};
    gap: 80px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        gap: 40px;
    }
`;
export const FooterNavRow = styled.div`
    ${Flex.Row.Style};
    width: 100%;
    gap: 48px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.Column.Style};
        gap: 60px;
    }
`;
export const FooterTextsRow = styled.div`
    ${Flex.Column.Style};
    border-top: 1px solid ${Neutral700};
    width: 100%;
    padding-top: 20px;
    gap: 20px;
`;
export const FooterNavLinks = styled.div`
    ${Flex.Column.SpaceBetween.Style};
    gap: 36px;
    width: 50%;
    a {
        &:hover {
            text-decoration: none;
        }
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        &:first-child {
            gap: 32px;
        }
        &:last-child {
            gap: 60px;
        }
    }
`;
export const FooterNavInternalLinks = styled.div`
    ${Flex.Row.SpaceBetween.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.Column.JustifyLeft.Style};
        gap: 60px;
    }
`;
export const FooterNavLinksColumn = styled.div`
    ${Flex.Column.Style};
    gap: 30px;
    width: 50%;
    max-width: 269px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        max-width: unset;
    }
`;
export const FooterSocialLinks = styled.div`
    ${Flex.Row.JustifyLeft.Style};
    gap: 10px;
    ${SocialIconWrapper} {
        margin: 0;
    }
`;
export const MobReverse = styled.div`
    ${Flex.Column.Style};
    gap: 36px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.ColumnReverse.Style};
        gap: 32px;
    }
`;
export const FooterAddressLink = styled.span`
    ${Typography.Paragraph18.Neutral50.Left.Medium.Style};
    max-width: 355px;
    display: inline-block;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.Paragraph16.Neutral50.Left.Medium.Style};
    }
`;
export const FooterText = styled.p`
    ${Typography.Paragraph12.Neutral500.Left.Regular.Style};
`;
export const FooterMobileAppLinks = styled.div`
    ${Flex.Row.Style};
    gap: 24px;
    flex-wrap: wrap;
    a {
        width: unset;
    }
    svg {
        height: 53px;
        width: unset;
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        column-gap: 20px;
        row-gap: 16px;
        svg {
            height: 43px;
        }
    }
`;
