import { useField } from 'formik';

import { CheckBox } from '@shared-component/checkbox/checkbox';
import { CountrySelect } from '@shared-component/country-select/country-select';
import { Input } from '@shared-component/input/input';
import { FormHalfRow, FormRow } from '@shared-form/form-common/form-common.styles';

import { CreatePartnerFormEnum } from '@component/modal/modals/create-partner/create-partner-form/create-partner-form.enum';

import { useCreatePartnerFormAddressLocalization } from './create-partner-form-address.localization-hook';
import {
    CheckBoxContentWrapper,
    CheckboxDescription,
    CheckboxTitle,
    FormOneToTwoRow,
} from './create-partner-form-address.styles';

export const CreatePartnerFormAddress = () => {
    const [{ value }] = useField(CreatePartnerFormEnum.ShouldRenderAddressFields);
    const {
        beneficiaryInfoTitle,
        beneficiaryInfoDescription,
        beneficiaryCountryTitle,
        beneficiaryCityTitle,
        beneficiaryZipTitle,
        beneficiaryAddressTitle,
    } = useCreatePartnerFormAddressLocalization();
    const shouldRenderAddressFields = value === true;

    return (
        <>
            <FormRow>
                <CheckBox name={CreatePartnerFormEnum.ShouldRenderAddressFields}>
                    <CheckBoxContentWrapper>
                        <CheckboxTitle>{beneficiaryInfoTitle}</CheckboxTitle>
                        <CheckboxDescription>{beneficiaryInfoDescription}</CheckboxDescription>
                    </CheckBoxContentWrapper>
                </CheckBox>
            </FormRow>
            {shouldRenderAddressFields && (
                <>
                    <FormHalfRow>
                        <CountrySelect name={CreatePartnerFormEnum.Country} title={beneficiaryCountryTitle} />
                        <Input name={CreatePartnerFormEnum.City} title={beneficiaryCityTitle} />
                    </FormHalfRow>
                    <FormOneToTwoRow>
                        <Input name={CreatePartnerFormEnum.Postcode} title={beneficiaryZipTitle} />
                        <Input name={CreatePartnerFormEnum.Address} title={beneficiaryAddressTitle} />
                    </FormOneToTwoRow>
                </>
            )}
        </>
    );
};
