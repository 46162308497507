import styled from 'styled-components';

import { getAnimationStyle } from '@shared-style/animation';
import { Neutral200 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const CurrencyExchangeFieldsWrapper = styled.div`
    height: 100%;
    ${Flex.Column.SpaceBetween.Style}
`;
export const CurrencyExchangeFormControls = styled.div`
    padding: 16px 24px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 16px 20px;
    }
`;
export const ButtonWrapper = styled.div`
    padding: 16px 24px;
    border-top: 1px solid ${Neutral200};
    ${Flex.JustifyRight.Style}
`;
export const CurrencyExchangeFormRow = styled.div`
    gap: 12px;
    margin-bottom: 24px;
    ${getAnimationStyle(['border'])};
    ${Flex.SpaceBetween.Style}
`;
export const CurrencyExchangeFormControlWrapper = styled.div`
    width: 50%;
`;
