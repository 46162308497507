import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomInputPassword } from '@shared-component/input/input-password/custom-input-password';
import { FormRow, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ResetPasswordEnum } from '@page/auth-pages/reset-password/reset-password.enum';
import { useResetPassword } from '@page/auth-pages/reset-password/reset-password.hook';
import { initialValue } from '@page/auth-pages/reset-password/reset-password.initials';
import { ResetPasswordFormValidation } from '@page/auth-pages/reset-password/reset-password.validation';
import { FormButtonsWrapper } from '@page/registration/registration-additional-fields/registration-additional-fields.styles';

export const ResetPasswordForm = observer(() => {
    const { handleResetPassword, isLoading } = useResetPassword();

    return (
        <Formik
            initialValues={initialValue}
            validateOnChange={false}
            validationSchema={ResetPasswordFormValidation}
            onSubmit={handleResetPassword}
        >
            <Form>
                <FormWrapper>
                    <FormRow>
                        <CustomInputPassword
                            name={ResetPasswordEnum.Password}
                            title={LocalizationEnum.CommonPassword}
                            hasTooltipValidation
                            autofocus
                        />
                    </FormRow>
                    <FormRow>
                        <CustomInputPassword
                            name={ResetPasswordEnum.NewPassword}
                            title={LocalizationEnum.CommonPasswordRepeat}
                        />
                    </FormRow>
                </FormWrapper>

                <FormButtonsWrapper>
                    <Button
                        title={LocalizationEnum.ChangePasswordSubmitButtonTitle}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                        isSubmit
                        isLoading={isLoading}
                    />
                </FormButtonsWrapper>
            </Form>
        </Formik>
    );
});
