import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { PaymentType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { Tile } from '@shared-style/common';

import { ModalHeader, ModalTitle } from '@component/modal/client-modal.styles';
import { AccountDetailsCard } from '@component/modal/modals/account-details/account-details-card/account-details-card';
import { useAccountDetails } from '@component/modal/modals/account-details/account-details.hook';
import {
    AccountDetailsModalContentWrapper,
    AccountDetailsModalWrapper,
    PaymentTypeTilesWrapper,
} from '@component/modal/modals/account-details/account-details.modal.styles';

const paymentTypeToTileTitleMapping: {
    [key: string]: LocalizationEnum;
} = {
    [PaymentType.swift]: LocalizationEnum.CommonSwift,
    [PaymentType.sepa]: LocalizationEnum.CommonSepa,
    [PaymentType.local]: LocalizationEnum.CommonLocalFps,
};

export const AccountDetailsModal = observer(() => {
    const { banks, selectedAccount } = useAccountDetails();
    const [selectedBank, setSelectedBank] = useState(banks[0]);

    return (
        <AccountDetailsModalWrapper>
            <ModalHeader>
                <ModalTitle>
                    <Translate langKey={LocalizationEnum.AccountDetailsModalTitle} />
                </ModalTitle>
            </ModalHeader>
            <AccountDetailsModalContentWrapper>
                <PaymentTypeTilesWrapper>
                    {banks.map(bank => (
                        <Tile
                            key={bank.paymentType}
                            isActive={selectedBank.paymentType === bank.paymentType}
                            onClick={() => setSelectedBank(bank)}
                        >
                            <Translate langKey={paymentTypeToTileTitleMapping[bank.paymentType]} />
                        </Tile>
                    ))}
                </PaymentTypeTilesWrapper>
                <AccountDetailsCard bank={selectedBank} selectedAccount={selectedAccount} />
            </AccountDetailsModalContentWrapper>
        </AccountDetailsModalWrapper>
    );
});
