import React, { ReactNode, useState } from 'react';

import Logo from '../../assets/logo_white.svg?react';
import {
    PageWrapperNavigation,
    PageWrapperNavigationProps,
} from '../page-wrapper/page-wrapper-navigation/page-wrapper-navigation';
import Close from './assets/close.svg?react';
import Hamburger from './assets/hamburger.svg?react';
import {
    CloseMask,
    HamburgerMask,
    SidebarHeader,
    SidebarLogoMask,
    SidebarSwitch,
    SidebarWrapper,
    UserContainer,
} from './sidebar.styles';

interface SidebarInterface<T> extends PageWrapperNavigationProps<T> {
    additionalUserComponent?: ReactNode;
}

export const Sidebar = <T extends string>({
    pageMenuList,
    additionalMenuList,
    additionalUserComponent,
    commissionList,
    optionsList,
}: SidebarInterface<T>) => {
    const [isSidebarOpen, toggleSidebar] = useState(true);
    const closeSidebar = () => {
        toggleSidebar(true);
    };

    return (
        <SidebarWrapper isSidebarOpen={isSidebarOpen}>
            <SidebarHeader>
                <SidebarSwitch onClick={() => toggleSidebar(!isSidebarOpen)}>
                    {isSidebarOpen ? (
                        <HamburgerMask>
                            <Hamburger />
                        </HamburgerMask>
                    ) : (
                        <CloseMask>
                            <Close />
                        </CloseMask>
                    )}
                </SidebarSwitch>
                <SidebarLogoMask href="/">
                    <Logo />
                </SidebarLogoMask>
            </SidebarHeader>
            <PageWrapperNavigation
                closeSidebar={closeSidebar}
                pageMenuList={pageMenuList}
                additionalMenuList={additionalMenuList}
                commissionList={commissionList}
                optionsList={optionsList}
            />
            {additionalUserComponent !== undefined && <UserContainer>{additionalUserComponent}</UserContainer>}
        </SidebarWrapper>
    );
};

Sidebar.defaultProps = {
    additionalUserComponent: null,
};
