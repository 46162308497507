import { types } from 'mobx-state-tree';

import { TransactionModelType } from '@shared-model/transaction-model/transaction-model.type';

import { transactionsActions } from '@model/transactoins-model/transactions-actions/transactions.actions';

export const TransactionsByTypeModelType = types.model('TransactionsByTypeModelType', {
    elements: types.array(types.maybe(TransactionModelType)),
    total: types.number,
});

export const TransactionsLoadingModelType = types.model('LoadingModel', {
    isTransactionsLoading: types.boolean,
    isSingleTransactionLoading: types.boolean,
});

export const TransactionsModelType = types.model('TransactionsModel', {
    transactions: TransactionsByTypeModelType,
    approvalTransactions: TransactionsByTypeModelType,
    holdInstructions: TransactionsByTypeModelType,
    loading: TransactionsLoadingModelType,
    error: types.optional(types.string, ''),
});

export const TransactionsModel = TransactionsModelType.actions(transactionsActions);

export const getInitialTransactionsModel = () =>
    TransactionsModel.create({
        transactions: {
            elements: [],
            total: 0,
        },
        approvalTransactions: {
            elements: [],
            total: 0,
        },
        holdInstructions: {
            elements: [],
            total: 0,
        },
        loading: {
            isTransactionsLoading: false,
            isSingleTransactionLoading: false,
        },
    });
