import { ReactNode, useEffect, useState } from 'react';

import IconLegal from '@shared-asset/vector/suitcase.svg?react';
import IconPrivate from '@shared-asset/vector/user-1.svg?react';
import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { TooltipDataForEnum } from '@shared-atom/tooltip/tooltip-data-for.enum';
import { Translate } from '@shared-atom/translate/translate';
import { UserType } from '@shared-graphql';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { getOnBoardingActiveType } from '@shared-util/get-onboarding-active-type';
import { isEnvVariableTruly, isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { useUserTypeUpdate } from '@hook/user/user-type-update.hook';
import {
    AuthPageContentDescriptionText,
    AuthPageContentDescriptionWrapper,
    AuthPageContentWrapper,
    AuthPageFormTitle,
} from '@page/auth-pages/common.styles';
import { RegistrationOnboardingForm } from '@page/registration/registration-onboarding/registration-onboarding-send-email/registration-onboarding-form/registration-onboarding-form';
import { RegistrationOnboardingType } from '@page/registration/registration-onboarding/registration-onboarding-send-email/registration-onboarding-form/registration-onboarding-form.options';
import {
    PrivateOrLegalButton,
    PrivateOrLegalButtonsWrapper,
    PrivateOrLegalButtonText,
    RegistrationFormWrapper,
} from '@page/registration/registration-onboarding/registration-onboarding-send-email/registration-onboarding-send-email.styles';

interface RegistrationOnboardingSendEmailProps {
    onSendEmail: OnEventType<RegistrationOnboardingType>;
    isSendEmailLoading: boolean;
    recaptchaV2Component: ReactNode | null;
}

export const RegistrationOnboardingSendEmail = ({
    onSendEmail,
    isSendEmailLoading,
    recaptchaV2Component,
}: RegistrationOnboardingSendEmailProps) => {
    const navigate = useRouterNavigate();

    const isLegalOnboardingEnabled = isEnvVariableTruly(
        import.meta.env.VITE_LEGAL_USERS_ONBOARDING_ENABLED_FEATURE_FLAG
    );
    const isPrivateOnboardingEnabled = isEnvVariableTruly(
        import.meta.env.VITE_PRIVATE_USERS_ONBOARDING_ENABLED_FEATURE_FLAG
    );

    const handleUserTypeUpdate = useUserTypeUpdate();
    const {
        searchParams: { activeType },
    } = useCurrentNavigation();
    const [typeBtn, setTypeBtn] = useState<UserType>(UserType.Legal);
    const title = isTrue(typeBtn === UserType.Legal)
        ? LocalizationEnum.RegistrationFormTitle1
        : LocalizationEnum.RegistrationCheckMailTitleIndividual;

    useEffect(() => {
        const userTypeToSet = getOnBoardingActiveType(activeType as UserType);
        navigate(PageEnum.OnBoarding, {
            activeType: getOnBoardingActiveType(userTypeToSet as UserType),
        });
        setTypeBtn(userTypeToSet as UserType);
        handleUserTypeUpdate(userTypeToSet as UserType);
    }, []);

    const handleBtnLegal = () => {
        setTypeBtn(UserType.Legal);
        handleUserTypeUpdate(UserType.Legal);
    };

    const handleBtnPrivate = () => {
        setTypeBtn(UserType.Private);
        handleUserTypeUpdate(UserType.Private);
    };

    return (
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={title} />
            </AuthPageFormTitle>
            <AuthPageContentDescriptionWrapper>
                <AuthPageContentDescriptionText>
                    <Translate langKey={LocalizationEnum.RegistrationFormDescription1} />
                </AuthPageContentDescriptionText>
            </AuthPageContentDescriptionWrapper>
            <PrivateOrLegalButtonsWrapper>
                <Tooltip
                    dataFor={TooltipDataForEnum.LegalAccountIsTemporarilyUnavailable}
                    content={LocalizationEnum.LegalAccountIsTemporarilyUnavailable}
                    isShow={!isLegalOnboardingEnabled}
                    effect="solid"
                >
                    <PrivateOrLegalButton
                        onClick={handleBtnLegal}
                        isActive={typeBtn === UserType.Legal}
                        disabled={!isLegalOnboardingEnabled}
                    >
                        <IconLegal />
                        <PrivateOrLegalButtonText>
                            <Translate langKey={LocalizationEnum.FaqSectionTab1} />
                        </PrivateOrLegalButtonText>
                    </PrivateOrLegalButton>
                </Tooltip>
                <Tooltip
                    dataFor={TooltipDataForEnum.PersonalAccountIsTemporarilyUnavailable}
                    content={LocalizationEnum.PersonalAccountIsTemporarilyUnavailable}
                    isShow={!isPrivateOnboardingEnabled}
                    effect="solid"
                >
                    <PrivateOrLegalButton
                        onClick={handleBtnPrivate}
                        isActive={typeBtn === UserType.Private}
                        disabled={!isPrivateOnboardingEnabled}
                    >
                        <IconPrivate />
                        <PrivateOrLegalButtonText>
                            <Translate langKey={LocalizationEnum.CommonPersonalAccount} />
                        </PrivateOrLegalButtonText>
                    </PrivateOrLegalButton>
                </Tooltip>
            </PrivateOrLegalButtonsWrapper>
            <RegistrationFormWrapper>
                <RegistrationOnboardingForm
                    onSubmit={onSendEmail}
                    isSendEmailLoading={isSendEmailLoading}
                    recaptchaV2Component={recaptchaV2Component}
                />
            </RegistrationFormWrapper>
        </AuthPageContentWrapper>
    );
};
