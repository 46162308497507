import { applySnapshot, getSnapshot, Instance } from 'mobx-state-tree';

import { PersonRelation, UserCompanyActive } from '@shared-graphql';
import { companyModelPersonsMap } from '@shared-model/company-model/company-model.helpers';

import { CompanyModelType } from '@model/company-model/company-model.type';

type CompanyApplyActionsType = Instance<typeof CompanyModelType>;

export const companyApplyActions = (self: CompanyApplyActionsType) => ({
    applyCompany: (company: UserCompanyActive) => {
        const currentSnapshot = getSnapshot(self);
        const newCompanyData = {
            ...currentSnapshot,
            ...company,
        };

        applySnapshot(self, {
            ...newCompanyData,
            persons: companyModelPersonsMap(newCompanyData.persons as PersonRelation[]),
        } as any);
    },
});
