import androidIcon from '@shared-asset/android.svg';
import appleIcon from '@shared-asset/apple.svg';
import chromeIcon from '@shared-asset/chrome.svg';
import defaultIcon from '@shared-asset/default_browser_icon.svg';
import edgeIcon from '@shared-asset/edge.svg';
import firefoxIcon from '@shared-asset/firefox.svg';
import operaIcon from '@shared-asset/opera.svg';
import safariIcon from '@shared-asset/safari.svg';
import { Maybe } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

export const getIconMappingByOs = (source: Maybe<string>) => {
    if (isTrue(source?.includes('safari'))) return safariIcon;
    if (isTrue(source?.includes('edge'))) return edgeIcon;
    if (isTrue(source?.includes('chrome'))) return chromeIcon;
    if (isTrue(source?.includes('opera'))) return operaIcon;
    if (isTrue(source?.includes('firefox'))) return firefoxIcon;
    if (isTrue(source?.includes('ios'))) return appleIcon;
    if (isTrue(source?.includes('android'))) return androidIcon;
    return defaultIcon;
};
