import { observer } from 'mobx-react-lite';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ModalHeader, ModalTitle, ModalWrapper } from '@component/modal/client-modal.styles';
import { useCancelTransaction } from '@component/modal/modals/cancel-transaction/cancel-transaction.hook';
import {
    CancelTransactionModalContentWrapper,
    CancelTransactionModalFooter,
} from '@component/modal/modals/cancel-transaction/cancel-transaction.styles';

export const CancelTransactionModal = observer(() => {
    const { isLoading, rejectPayment, onClose } = useCancelTransaction();

    return (
        <ModalWrapper>
            <ModalHeader>
                <ModalTitle>
                    <Translate langKey={LocalizationEnum.CommonCancelTransaction} />
                </ModalTitle>
            </ModalHeader>
            <CancelTransactionModalContentWrapper>
                <Translate langKey={LocalizationEnum.AreYouSureYouWantToCancelTransaction} />
            </CancelTransactionModalContentWrapper>
            <CancelTransactionModalFooter>
                <Button
                    title={LocalizationEnum.RegistrationBusinessStepFiveFalse}
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.GHOST}
                    onClick={onClose}
                />
                <Button
                    title={LocalizationEnum.RegistrationPersonStepEightRadiobtn2}
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.DANGER}
                    onClick={rejectPayment}
                    isLoading={isLoading}
                />
            </CancelTransactionModalFooter>
        </ModalWrapper>
    );
});
