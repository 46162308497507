import makeInspectable from 'mobx-devtools-mst';
import { types } from 'mobx-state-tree';

import { AccountModel, getInitialAccountModel } from '@model/account-model/account-model';
import { AddressBookModel, getInitialAddressBookModel } from '@model/address-book-model/address-book-model';
import { CompanyModel, getInitialCompanyModel } from '@model/company-model/company-model';
import { CurrenciesModel, getInitialCurrenciesModel } from '@model/currencies-model/currencies-model';
import { FeatureTagsModel, getInitialFeatureTagsModel } from '@model/feature-tags-model/feature-tags-model';
import { TransactionsModel, getInitialTransactionsModel } from '@model/transactoins-model/transactions-model';
import { UserModel, getInitialUserModel } from '@model/user-model/user-model';

import { initializeCompanyAddressReaction } from './reactions/has-company-address.reaction';
import { initializeCompanyDocumentsReaction } from './reactions/has-company-documents.reaction';
import { initializeCompanyPersonsReaction } from './reactions/has-company-persons.reaction';
import { initializeCompanyServiceReaction } from './reactions/has-company-service.reaction';
import { initializeCompanyShareholdersReaction } from './reactions/has-company-shareholders.reaction';
import { initializeCompanyReaction } from './reactions/has-company.reaction';

const RootStore = types.model('RootStore', {
    account: AccountModel,
    addressBook: AddressBookModel,
    company: CompanyModel,
    user: UserModel,
    currencies: CurrenciesModel,
    featureTags: FeatureTagsModel,
    transactions: TransactionsModel,
});

const userStore = getInitialUserModel();
const accountStore = getInitialAccountModel();
const companyStore = getInitialCompanyModel();
const addressBookStore = getInitialAddressBookModel();
const currenciesStore = getInitialCurrenciesModel();
const featureTagsStore = getInitialFeatureTagsModel();
const transactionsStore = getInitialTransactionsModel();

const createStore = () =>
    RootStore.create({
        user: userStore as any,
        account: accountStore as any,
        company: companyStore as any,
        addressBook: addressBookStore as any,
        currencies: currenciesStore as any,
        featureTags: featureTagsStore as any,
        transactions: transactionsStore as any,
    });

initializeCompanyReaction(companyStore, userStore);
initializeCompanyAddressReaction(companyStore);
initializeCompanyPersonsReaction(companyStore);
initializeCompanyShareholdersReaction(companyStore);
initializeCompanyDocumentsReaction(companyStore);
initializeCompanyServiceReaction(companyStore);

export const rootStore = createStore();

makeInspectable(rootStore);
