import { types } from 'mobx-state-tree';

import { CurrencyModelType } from '../../currency-model/currency-model.type';

export const CompanyServiceType = types.model({
    id: types.maybeNull(types.string),
    isEurAccount: types.boolean,
    isSwiftAccount: types.boolean,
    isMulticurrencyAccount: types.boolean,
    currency: CurrencyModelType,
    moneyTurnover: types.maybeNull(types.string),
    annualGross: types.maybeNull(types.number),
    numberOfPersons: types.maybeNull(types.string),
    numberOfCustomers: types.maybeNull(types.string),
    isAccountInAnotherBank: types.maybeNull(types.boolean),
    isSubmitFinancialTaxReport: types.maybeNull(types.boolean),
    spendPlan: types.maybeNull(types.integer),
    preferredAccountCurrency: types.maybeNull(types.string),
});
