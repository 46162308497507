import styled, { keyframes } from 'styled-components';

import { CodGray } from '../../styles/colors';
import { MAX_MOBILE_SIZE } from '../../styles/media-sizes';
import { NavigationWrapper } from '../page-wrapper/page-wrapper-navigation/page-wrapper-navigation.styles';

interface ISidebarWrapperProps {
    isSidebarOpen: boolean;
}

const slideInRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

const slideInLeft = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;

export const SidebarWrapper = styled.aside<ISidebarWrapperProps>`
    position: relative;
    width: 258px;
    height: 100%;
    background: ${CodGray};
    padding: 0 0 94px 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 0 0 0 16px;
        width: 100%;
        height: ${({ isSidebarOpen }) => (isSidebarOpen ? '72px' : '100%')};
        min-height: ${({ isSidebarOpen }) => (isSidebarOpen ? '72px' : '100%')};
        ${NavigationWrapper} {
            display: ${({ isSidebarOpen }) => (isSidebarOpen ? 'none' : 'block')};
        }
        z-index: ${({ isSidebarOpen }) => (isSidebarOpen ? 'unset' : '9')};
    }
`;

export const SidebarHeader = styled.div`
    width: 100%;
    max-width: 220px;
    height: 72px;
    min-height: 72px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding-left: 0;
        min-width: 100%;
        background-color: ${CodGray};
    }
`;

export const UserContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    bottom: 24px;
    left: 24px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        position: absolute;
        top: 14px;
        right: 0;
        bottom: unset;
        left: unset;
    }
`;

export const SidebarSwitch = styled.button`
    display: none;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        :focus {
            border: none;
            outline: none;
        }
    }
`;

export const HamburgerMask = styled.div`
    margin-right: 20px;
    overflow: hidden;
    svg {
        animation: ${slideInRight} 0.3s ease-in-out;
    }
`;
export const CloseMask = styled.div`
    margin-right: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    svg {
        animation: ${slideInLeft} 0.3s ease-in-out;
    }
`;

export const SidebarLogoMask = styled.a`
    width: 100px;
    display: flex;
`;
