import {
    UserLegalStatusModel,
    UserPermissions,
} from '@shared-model/user-model/user-model-legal/user-legal-status-model';

export const UserLegalStatusModelInstance = UserLegalStatusModel.actions(self => ({
    setHasCompany: (hasCompany: boolean) => {
        self.hasCompany = hasCompany;
    },
}));

export const UserPermissionsInstance = UserPermissions.actions(self => ({
    setPrmissions: (permissions: string[]) => {
        (self as any).permissions = permissions;
    },
}));
