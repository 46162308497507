import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral100, Neutral200 } from '@shared-style/colors';

import {
    InformationRowDataWrapper,
    InformationRowWrapper,
} from '@component/modal/modals/common/information-copy-row/information-copy-row.styles';

export const AmountCurrencyFormWrapper = styled.div`
    height: calc(100% - 40px);
    width: 100%;
`;
export const PaymentFormComponentWrapper = styled.div`
    margin-top: 16px;
    padding: 0 24px;
`;
export const PaymentFormInformationWrapper = styled.div`
    padding: 0 24px;

    ${InformationRowDataWrapper} {
        text-align: end;
    }
    ${InformationRowWrapper} {
        :first-child {
            margin-top: 20px;
        }
    }
`;
export const CustomSelectRow = styled.div`
    padding: 4px 16px 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    flex: 1;
    width: 100%;
    min-height: 58px;
    margin: 0;
    border-bottom: 1px solid ${Neutral200};
    text-align: left;
    :hover {
        background-color: ${Neutral100};
    }
`;
export const CustomSelectValue = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex: 1;
    ${Typography.Paragraph14.Neutral900.SemiBold.Style}
`;
