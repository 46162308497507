import styled from 'styled-components';

export const RegistrationOnboardingWrapper = styled.div`
    margin: auto;
    width: 75%;
    height: 100%;

    @media screen and (max-width: 991px) {
        width: 90%;
    }
    @media screen and (max-width: 560px) {
        width: 100%;
    }
`;
