import * as Sentry from '@sentry/react';
import { flow, getParent, Instance } from 'mobx-state-tree';

import { GetClientAccounts, Mutation, Query, UpdateAccountCurrenciesSchema } from '@shared-graphql';
import { getLocalStorageValue } from '@shared-hook/utils/use-local-storage.hook';
import { DEFAULT_CURRENCY } from '@shared-util/constants';
import { isEnvVariableTruly, isTrue } from '@shared-util/is-data';

import { apolloClient } from '@connection/apollo-client';
import { AccountModel } from '@model/account-model/account-model';
import { AccountModelInstanceType, AccountModelType, AccountTypeUnion } from '@model/account-model/account-model.type';

const queryClientAccounts = () =>
    apolloClient
        .query<Pick<Query, 'getAccounts'>>({ query: GetClientAccounts })
        .then(result => result.data?.getAccounts ?? [])
        .catch(() => []);

const setSelectedAccount = (id: any, self: Instance<typeof AccountModelType>) => {
    self.selectedAccount = id;
};

const mutationAccountCurrencies = (currencies: string[]) =>
    apolloClient
        .query<Pick<Mutation, 'updateAccountCurrencies'>>({
            query: UpdateAccountCurrenciesSchema,
            variables: { currencies },
        })
        .then(result => result.data.updateAccountCurrencies);

export const accountModelActions = (self: Instance<typeof AccountModelType>) => ({
    loadClientAccounts: flow(function* loadClientAccounts() {
        const accountInstanceModel = getParent(self) as Instance<typeof AccountModel>;

        if (accountInstanceModel.loading.isLoading) {
            return;
        }
        accountInstanceModel.setAccountLoading(true);

        try {
            const accounts: Instance<typeof AccountTypeUnion>[] = yield queryClientAccounts();
            const selectedAccountFromStorage = getLocalStorageValue('selectedAccount');
            self.elements.clear();
            self.selectedAccount = null;
            accounts.forEach(account => {
                if (
                    isEnvVariableTruly(import.meta.env.VITE_SAVE_ACCOUNT_LOCALLY_FEATURE_FLAG) &&
                    isTrue(selectedAccountFromStorage)
                ) {
                    if (selectedAccountFromStorage === account.currency.code) {
                        setSelectedAccount(account.id, self);
                    }
                } else if (account.currency.code === DEFAULT_CURRENCY) {
                    setSelectedAccount(account.id, self);
                }

                self.elements.set(account.id, account);
            });
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        } finally {
            accountInstanceModel.setAccountLoading(false);
        }
    }),
    updateAccountCurrencies: flow(function* updateAccountCurrencies(currencies: string[]) {
        try {
            const result = yield mutationAccountCurrencies(currencies);

            if (result.status as boolean) {
                (self as Instance<typeof AccountModelInstanceType>).loadClientAccounts();
            }
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        }
    }),
    // TODO TS any https://github.com/mobxjs/mobx-state-tree/issues/1123#issuecomment-491369379
    setSelectedAccount: (id: any) => setSelectedAccount(id, self),
});
