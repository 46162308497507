import styled from 'styled-components';

import ChevronSmallDown from '@shared-asset/vector/chevron-small-down.svg?react';
import { ButtonTransparentStyle } from '@shared-atom/button/button.style';
import { Typography } from '@shared-atom/typography/typography';
import {
    Neutral100,
    Neutral200,
    Neutral50,
    Neutral500,
    Neutral900,
    Primary100,
    Primary500,
} from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

interface LanguageSelectProps {
    isOpen: boolean;
    isDarkMode?: boolean;
}
interface LanguageSelectOptionProps {
    selected: boolean;
}

export const LanguageSelectWrapper = styled.div<LanguageSelectProps>`
    padding: 0 30px;
    cursor: pointer;
    position: relative;
    height: 72px;
    text-transform: uppercase;
    color: ${({ isOpen, isDarkMode = false }) => {
        const closeColor = isDarkMode ? Neutral50 : Neutral900;
        return isOpen ? Primary500 : closeColor;
    }};
    ${Flex.VerticalCenter.Style};
    ${Typography.Paragraph14.Medium.Style};
    transition: all 0.2s ease-out;

    &:hover {
        color: ${Primary500};
        svg,
        path {
            stroke: ${Primary500} !important;
        }
    }
`;
export const SelectedLanguage = styled.div`
    display: block;
    ${Typography.Paragraph14.Medium.Style}
`;
export const LanguageSelectArrow = styled(ChevronSmallDown)`
    transition: all 0.2s ease-out;
    path {
        transition: all 0.2s ease-out;
    }
`;

export const LanguageSelectArrowWrapper = styled.div<LanguageSelectProps>`
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    ${LanguageSelectArrow} {
        transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
        margin-left: 5px;
        width: 10px;
        height: 6px;
        stroke: ${({ isOpen, isDarkMode = false }) => {
        const closeColor = isDarkMode ? Neutral50 : Neutral900;
        return isOpen ? Primary500 : closeColor;
    }};
        path {
            stroke: ${({ isOpen, isDarkMode = false }) => {
        const closeColor = isDarkMode ? Neutral50 : Neutral900;
        return isOpen ? Primary500 : closeColor;
    }};
        }
    }
`;

export const LanguageSelectOptionsList = styled.div<LanguageSelectProps>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 160px;
    box-shadow: 0 20px 40px 20px rgba(16, 24, 40, 0.11);
    border-radius: 16px;
    overflow: hidden;
    transition: all 0.2s ease-out;
    transform: translateY(104%);
`;
export const LanguageSelectOption = styled(ButtonTransparentStyle)<LanguageSelectOptionProps>`
    width: 100%;
    height: 52px;
    padding-left: 12px;
    border-bottom: 1px solid ${Neutral200};
    background-color: ${({ selected }) => (selected ? Primary100 : Neutral50)};
    ${Flex.JustifyLeft.VerticalCenter.Row.Style}
    ${Typography.Paragraph14.SemiBold.Neutral700.Style}
    transition: all .2s ease-out;

    :last-child {
        border-bottom: none;
    }
    :hover {
        background-color: ${Neutral100};
    }
`;
export const FlagIconWrapper = styled.div`
    margin-right: 8px;
    width: 20px;
    height: 20px;
    ${Flex.HorizontalCenter.VerticalCenter.Style}
`;
export const FlagIcon = styled.img`
    width: 100%;
`;
export const LanguageInlineSelectWrapper = styled.div`
    padding: 16px;
    gap: 30px;
    margin-bottom: 16px;
    ${Flex.VerticalCenter.Style};
    flex-wrap: wrap;
`;
export const LanguageInlineSelectOption = styled.div<LanguageSelectOptionProps>`
    cursor: pointer;
    transition: all 0.2s ease-out;
    color: ${({ selected }) => (selected ? Primary500 : Neutral500)};
    ${Flex.VerticalCenter.Style}
`;
