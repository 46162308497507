import { useField } from 'formik';
import { ChangeEvent, useState } from 'react';

import { OnEventType } from '../../../../types/on-event.type';

export const useInputCard = (
    fieldName: string
): [OnEventType<ChangeEvent<HTMLInputElement>>, boolean, (isFocus: boolean) => () => void, boolean] => {
    const [, { error }, { setValue }] = useField(fieldName);
    const [isFocused, setFocused] = useState(false);
    const hasError = error !== undefined;

    const handleChange = ({ target: { value: targetValue } }: ChangeEvent<HTMLInputElement>) => {
        setValue(targetValue);
    };

    const handleFocus = (isFocus: boolean) => () => setFocused(isFocus);

    return [handleChange, isFocused, handleFocus, hasError];
};
