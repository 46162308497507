import { useEffect, useState } from 'react';

import { getAccountIdsOptions } from '@shared-atom/elpaso-kit/select-account/select-account-option/select-account-option.utils';
import { isTrue } from '@shared-util/is-data';

import { StatementFormEnum } from '@component/modal/modals/request-statement/statement-form/statement-form.enum';
import { StatementFormInitial } from '@component/modal/modals/request-statement/statement-form/statement-form.initial';
import { useActiveAccounts } from '@hook/active-accounts/active-accounts.hook';
import { useReportUrlGetting } from '@hook/report-url-getting/report-url-getting';
import { useAccountSelector } from '@selector/account/use-account.selector';

export const useStatementForm = () => {
    const [{ ccAccounts, selectedAccount }, { isLoading: isAccountsLoading }] = useAccountSelector();
    const [initialFormValues, setInitialFormValues] = useState(StatementFormInitial);
    const hasActiveAccounts = useActiveAccounts();

    const accountOptionsSell = getAccountIdsOptions(ccAccounts, true);
    const { isReportUrlLoading, handleSubmit } = useReportUrlGetting();

    useEffect(() => {
        if (isTrue(selectedAccount?.currency.code)) {
            setInitialFormValues({
                ...initialFormValues,
                [StatementFormEnum.AccountId]: selectedAccount?.accountId,
            });
        }
    }, [selectedAccount]);

    return {
        isLoading: isAccountsLoading || isReportUrlLoading,
        handleSubmit,
        accountOptionsSell,
        hasActiveAccounts,
        initialFormValues,
    };
};
