import { createContext } from 'react';

import { InformationModalContextInterface } from '@shared-component/information-modal/information-modal.interface';
import { noop } from '@shared-util/noop';

export const InformationModalContext = createContext<InformationModalContextInterface>({
    selectedInformationModal: null,
    isInformationModalVisible: false,
    onOpen: noop,
    props: {},
});
