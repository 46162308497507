import { FC, SVGProps } from 'react';

export enum BadgeSizeEnum {
    S = 's',
    M = 'm',
    L = 'l',
}
export enum BadgeTypeEnum {
    NEUTRAL_FILLED = 'neutral-filled',
    NEUTRAL_STROKE = 'neutral-stroke',
    NEUTRAL_GHOST = 'neutral-ghost',

    ERROR_FILLED = 'error-filled',
    ERROR_STROKE = 'error-stroke',
    ERROR_GHOST = 'error-ghost',

    SUCCESS_FILLED = 'success-filled',
    SUCCESS_STROKE = 'success-stroke',
    SUCCESS_GHOST = 'success-ghost',

    WARNING_FILLED = 'warning-filled',
    WARNING_STROKE = 'warning-stroke',
    WARNING_GHOST = 'warning-ghost',

    PRIMARY_FILLED = 'primary-filled',
    PRIMARY_STROKE = 'primary-stroke',
    PRIMARY_GHOST = 'primary-ghost',
}

export interface ElpasoBadgeProps {
    title: string;
    size: BadgeSizeEnum;
    type: BadgeTypeEnum;
    iconAfter?: FC<SVGProps<SVGSVGElement>> | null;
    iconBefore?: FC<SVGProps<SVGSVGElement>> | null;
}
