import styled from 'styled-components';

interface AnimationWrapperProps {
    isVisible: boolean;
}

export const AnimationWrapper = styled.div<AnimationWrapperProps>`
    min-width: 1px;
    min-height: 1px;
`;
