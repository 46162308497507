import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PageEnum } from '@enum/page.enum';
import { FooterInterface } from '@page/main-pages/main-page-common/footer/footer.interface';

export const navigationOptions: FooterInterface[] = [
    {
        title: LocalizationEnum.FooterNavProductTitle,
        navOptions: [
            {
                link: PageEnum.OnlineAccount,
                title: LocalizationEnum.FooterNavProductBankingTitle,
            },
            {
                link: PageEnum.Acquiring,
                title: LocalizationEnum.FooterNavProductAcquiringTitle,
            },
            {
                link: PageEnum.Payouts,
                title: LocalizationEnum.FooterNavProductPayoutsTitle,
            },
            {
                link: PageEnum.Prices,
                title: LocalizationEnum.FooterNavProductPriceTitle,
            },
        ],
    },

    {
        title: LocalizationEnum.FooterNavLegalTitle,
        navOptions: [
            {
                link: PageEnum.Smartflow,
                title: LocalizationEnum.FooterNavLegalSmartflowTitle,
            },
            {
                link: PageEnum.LegalDocuments,
                title: LocalizationEnum.CommonLegalDocuments,
            },
        ],
    },
];
