import { useState } from 'react';

import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { PageEnum } from '@enum/page.enum';

import { UseThemeProviderType } from './theme-provider.interface';

const pagesWithTheme = new Set([
    PageEnum.Dashboard,
    // PageEnum.AddressBook,
    PageEnum.PermittedCountries,
    PageEnum.Notification,
    PageEnum.Settings,
]);

export const useThemeProvider = (): UseThemeProviderType => {
    const { name } = useCurrentNavigation();
    const [isDarkTheme, setDarkTheme] = useState(false);

    const handleChangeColorTheme = () => {
        setDarkTheme(!isDarkTheme);
    };

    return [isDarkTheme && pagesWithTheme.has(name), handleChangeColorTheme];
};
