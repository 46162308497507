import { ApolloError, useMutation } from '@apollo/client';

import {
    Maybe,
    Mutation,
    MutationUserConfirmLoginArgs,
    MutationUserLoginArgs,
    UserConfirmLogin,
} from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';

type UserMutationType = Pick<Mutation, 'userConfirmLogin'>;

export const useUserConfirmLogin = (): [
    Maybe<UserMutationType> | undefined,
    ApolloError | undefined,
    boolean,
    OnEventType<MutationUserConfirmLoginArgs>
] => {
    const [userConfirmLogin, { data, error, loading: isLoading }] = useMutation<UserMutationType>(UserConfirmLogin);

    const handleLogin = (variables: MutationUserLoginArgs) => userConfirmLogin({ variables });

    return [data, error, isLoading, handleLogin];
};
