import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { linkMarkdownTag } from '@component/link-markdown-tag/link-markdown-tag';
import { useAccountDetailsCard } from '@component/modal/modals/account-details/account-details-card/account-details-card.hook';
import { AccountDetailsCardInterface } from '@component/modal/modals/account-details/account-details-card/account-details-card.interface';
import {
    AccountDetailsCardText,
    AccountDetailsCardView,
} from '@component/modal/modals/account-details/account-details-card/account-details-card.styles';
import { InformationCopyRow } from '@component/modal/modals/common/information-copy-row/information-copy-row';

export const AccountDetailsCard = ({ bank, selectedAccount }: AccountDetailsCardInterface) => {
    const { fields, beforeListText, afterListText } = useAccountDetailsCard({ bank, selectedAccount });

    return (
        <AccountDetailsCardView>
            <AccountDetailsCardText>
                <Translate langKey={beforeListText} customRender={linkMarkdownTag} />
            </AccountDetailsCardText>
            {fields.map(({ fieldName, data }) => (
                <InformationCopyRow key={fieldName} title={fieldName} data={data ?? ''} />
            ))}
            {afterListText && (
                <AccountDetailsCardText>
                    <Translate langKey={afterListText as LocalizationEnum} customRender={linkMarkdownTag} />
                </AccountDetailsCardText>
            )}
        </AccountDetailsCardView>
    );
};
