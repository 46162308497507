import styled from 'styled-components';

import { FormButtonsRowWrapper } from '@shared-form/form-common/form-common.styles';
import { Primary500 } from '@shared-style/colors';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const FormButtonsWrapper = styled(FormButtonsRowWrapper)`
    svg,
    path {
        fill: ${Primary500};
    }
    gap: 30px;
    button:last-child {
        width: 100%;
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        gap: 10px;
    }
`;
