export enum TooltipDataForEnum {
    AcquiringActivate = 'acquiring-activate',
    AddressBook = 'address-book',
    AddressDelete = 'address-delete',
    AddressEdit = 'address-edit',
    AppStore = 'app-store',
    ChangeEmail = 'change-email',
    ChangePassword = 'change-password',
    GooglePlay = 'google-play',
    PaymentFlowCardWithdrawal = 'payment-flow-card-withdrawal',
    PaymentFlowLocal = 'payment-flow-local',
    PaymentFlowSends = 'payment-flow-sends',
    PaymentFlowSepaZone = 'payment-flow-sepa-zone',
    PaymentFlowSwift = 'payment-flow-swift',
    PaymentNew = 'payment-new',
    PaymentSign = 'payment-sign',
    RequestDemoBusiness = 'request-demo-business',
    RequestDemoHome = 'request-demo-home',
    RequestStatement = 'request-statement',
    TransactionApprove = 'transaction-approve',
    TransactionBlocked = 'transaction-blocked',
    TransactionCancel = 'transaction-cancel',
    TransactionEdit = 'transaction-edit',
    TransactionReceipt = 'transaction-receipt',
    TransactionRepeat = 'transaction-repeat',
    PersonalAccountIsTemporarilyUnavailable = 'personal-account-is-temporarily-unavailable',
    LegalAccountIsTemporarilyUnavailable = 'legal-account-is-temporarily-unavailable',
}
