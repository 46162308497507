import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const UserWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    ${Flex.VerticalCenter.Style}
`;

export const UserName = styled.span`
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    ${Typography.Paragraph14.SemiBold.Neutral50.Left.Style}
`;

export const UserInfo = styled.div`
    width: 165px;
    ${Flex.Column.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        display: none;
    }
`;

export const UserCompany = styled.span`
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    ${Typography.Paragraph12.Medium.Neutral400.Left.Style}
`;

export const AvatarWrapper = styled.div`
    width: 42px;
    height: 42px;
    margin-right: 16px;
`;
