import styled from 'styled-components';

import { Typography } from '../../../typography/typography';
import { CustomInputStyle } from '../custom-input/custom-input.styles';
import { LabelWrapper } from '../input.styles';

export const CustomTextAreaWrapper = styled.div`
    ${LabelWrapper} {
        min-height: 132px;
        padding: 12px 0;
    }

    ${CustomInputStyle} {
        resize: none;
    }
`;

export const LabelMaxLength = styled.p`
    padding-top: 4px;
    ${Typography.Paragraph12.Medium.Neutral500.Style}
`;
