import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral200, Neutral50 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const GoogleAuthActivateModalWrapper = styled.div`
    background-color: ${Neutral50};
    overflow-y: scroll;
    height: 100%;
    width: 560px;
    max-height: 633px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    ${Flex.Column.Style};
    form {
        flex: 1;
        ${Flex.Column.SpaceBetween.Style};
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        max-height: unset;
        border-bottom-right-radius: unset;
        border-bottom-left-radius: unset;
    }
`;
export const GoogleAuthActivateModalTitle = styled.h2`
    width: 100%;
    margin: 0 auto;
    padding: 16px 0;
    border-bottom: 1px solid ${Neutral200};
    ${Typography.H4Desktop.Neutral900.Center.SemiBold.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.H4Mob.Neutral900.Center.SemiBold.Style};
    }
`;
export const GoogleAuthActivateModalContent = styled.div`
    ${Flex.Column.Style};
    padding: 16px 24px;
    gap: 16px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 16px 20px;
    }
`;
export const GoogleAuthActivateModalHint = styled.p`
    ${Typography.Paragraph14.Neutral900.Medium.Style};
`;
export const GoogleAuthActivateModalFooting = styled.div`
    border-top: 1px solid ${Neutral200};
    padding: 16px 20px;
    ${Flex.Row.HorizontalCenter.Style};
    button {
        width: 120px;
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        button {
            width: 100%;
        }
    }
`;

export const QrCodeImage = styled.img`
    width: 270px;
    height: 270px;
    margin: 0 auto;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        margin-bottom: 16px;
    }
`;
