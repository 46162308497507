import styled from 'styled-components';

import { White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const Text = styled.p`
    height: 100%;
    color: ${White};
    ${Flex.VerticalCenter.HorizontalCenter.Style}
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`;
export const ButtonsWrapper = styled.div`
    ${Flex.Row.Style};
    gap: 30px;
`;
