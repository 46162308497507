import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';

import { ModelContext } from '@shared-component/modal/modal.context';
import { EventEmitterEnum } from '@shared-enum/event-emitter.enum';
import { CancelPayment } from '@shared-graphql';
import { emit } from '@shared-util/event-emitter';

export const useCancelTransaction = () => {
    const {
        onClose,
        props: { componentProps },
    } = useContext(ModelContext);

    const [cancelPayment, { loading: isLoading }] = useMutation(CancelPayment, {
        variables: { paymentId: componentProps?.paymentId },
    });

    const rejectPayment = useCallback(() => {
        cancelPayment().finally(() => {
            emit(EventEmitterEnum.TransactionsUpdate);
            emit(EventEmitterEnum.AccountsUpdate);
            onClose();
        });
    }, []);

    return { isLoading, rejectPayment, onClose };
};
