import { useEffect } from 'react';

import { PushNotificationLoginStepInterface } from '@page/auth-pages/login/login-steps/push-notification-login-step/push-notification-login-step.interface';

export const usePushNotificationLoginStep = ({ onSubmit }: Pick<PushNotificationLoginStepInterface, 'onSubmit'>) => {
    useEffect(() => {
        const intervalId: ReturnType<typeof setInterval> = setInterval(() => onSubmit(), 5000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            window.location.reload();
        }, 120000);

        return () => clearTimeout(timeoutId);
    }, []);
};
