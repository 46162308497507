import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { useLocalizationTextWithReplacedValue } from '../../../../hooks/localization/use-localization-text.hook';
import { LocalizationEnum } from '../../../../locale/localization.enum';
import { isExist } from '../../../../utils/is-data';
import { CustomInput, CustomInputProps } from '../custom-input/custom-input';
import { CustomInputTagEnum } from '../input-tag.enum';
import { CustomTextAreaWrapper, LabelMaxLength } from './custom-textarea.styles';

export interface TextAreaProps extends CustomInputProps {
    maxLength?: number;
}

export const CustomTextarea: FC<TextAreaProps> = observer(({ maxLength, name, ...props }) => {
    const [, { error }] = useField(name);

    const shouldRenderMaxLength = isExist(maxLength) && !isExist(error);
    const maxLengthText = useLocalizationTextWithReplacedValue(LocalizationEnum.MaxTextareaLengthText, [
        {
            key: '{length}',
            value: Boolean(maxLength) === true && maxLength !== undefined ? maxLength.toString() : '',
        },
    ]);

    return (
        <CustomTextAreaWrapper>
            <CustomInput {...props} name={name} tag={CustomInputTagEnum.TextArea} />
            {shouldRenderMaxLength && <LabelMaxLength>{maxLengthText}</LabelMaxLength>}
        </CustomTextAreaWrapper>
    );
});
