import { types } from 'mobx-state-tree';

import { AddressType } from '../address-type/address.type';
import { CurrencyModelType } from '../currency-model/currency-model.type';
import { AddressBookBeneficiaryType } from './address-book-beneficiary-model/address-book-beneficiary.type';

export const AddressBookType = types.model({
    id: types.string,
    beneficiary: AddressBookBeneficiaryType,
    currency: CurrencyModelType,
    address: types.maybeNull(AddressType),
});
