import styled from 'styled-components';

import { Typography } from '../../atoms/typography/typography';
import { DarkBlue } from '../../styles/colors';
import { Flex } from '../../styles/flex';
import User from './assets/user.svg?react';

interface AvatarIconProps {
    colorIndex: number;
}

interface UserNameProps {
    isSmall: boolean;
}

export const avatarColors = [
    '#E0ABAB 0%, #CC8B8B 100%',
    '#E0BCAB 0%, #CCA08B 100%',
    '#E0CEAB 0%, #CCB68B 100%',
    '#E0E0AB 0%, #CCCC8B 100%',
    '#CEE0AB 0%, #B6CC8B 100%',
    '#BCE0AB 0%, #A0CC8B 100%',
    '#ABE0AB 0%, #8BCC8B 100%',
    '#ABE0BC 0%, #8BCCA0 100%',
    '#ABE0CE 0%, #8BCCB6 100%',
    '#ABE0E0 0%, #8BCCCC 100%',
    '#ABCEE0 0%, #8BB6CC 100%',
    '#ABBCE0 0%, #8BA0CC 100%',
    '#ABABE0 0%, #8B8BCC 100%',
    '#BCABE0 0%, #A08BCC 100%',
    '#CEABE0 0%, #B68BCC 100%',
    '#E0ABE0 0%, #CC8BCC 100%',
    '#E0ABCE 0%, #CC8BB6 100%',
    '#E0ABBC 0%, #CC8BA0 100%',
];

export const AvatarIcon = styled.div<AvatarIconProps>`
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 48px;
    ${Flex.VerticalCenter.HorizontalCenter.Style}

    ${({ colorIndex }) => (avatarColors[colorIndex] !== undefined ? `background: white` : `background-color: white`)};
`;

export const ImagePreview = styled.img`
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const UserIcon = styled(User)`
    fill: ${DarkBlue};
`;

export const UserName = styled.span<UserNameProps>`
    ${Typography.Medium.White.Style}
    ${({ isSmall }) => (isSmall ? Typography.XXS.Style : Typography.L.Style)}
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-feature-settings: 'salt' on;
    color: #00997a;
`;
