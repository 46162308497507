import { useContext, useEffect } from 'react';

import { ModelContext } from '@shared-component/modal/modal.context';
import { useDidUpdate } from '@shared-hook/utils/use-did-update';
import { isString } from '@shared-util/is-data';

import { useAddressBookActionsSelector } from '@selector/address-book/use-address-book.actions-selector';
import { useAddressBookSelector } from '@selector/address-book/use-address-book.selector';
import { useCurrencyActionsSelector } from '@selector/currencies/use-currencies.actions-selector';
import { useCurrencySelector } from '@selector/currencies/use-currencies.selector';

import { CreatePartnerFormInterface } from './create-partner-form.interface';

export const useCreatePartnerForm = () => {
    const { onClose } = useContext(ModelContext);
    const [{ currenciesActiveSelect }] = useCurrencySelector();
    const [{ selectedItem, totalCount }] = useAddressBookSelector();
    const { loadActiveCurrencies } = useCurrencyActionsSelector();
    const { updateAddressBook, setSelectedItem } = useAddressBookActionsSelector();

    const handleSubmit = ({ shouldRenderAddressFields, id, ...restData }: CreatePartnerFormInterface) =>
        updateAddressBook({
            ...restData,
            id: isString(id) ? id : null,
        });

    useEffect(() => {
        loadActiveCurrencies();
        return () => setSelectedItem(null);
    }, []);

    useDidUpdate(() => onClose(), [totalCount]);

    return {
        initialValues: {
            ...selectedItem,
            currency: selectedItem.currency.code,
            shouldRenderAddressFields:
                isString(selectedItem.beneficiary.beneficiaryAddress) ||
                isString(selectedItem.beneficiary.beneficiaryCity) ||
                isString(selectedItem.beneficiary.beneficiaryCountry) ||
                isString(selectedItem.beneficiary.beneficiaryPostcode),
        },
        currenciesActiveSelect,
        handleSubmit,
    };
};
