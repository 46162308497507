import { UserProfileInterface } from '@shared-form/private-profile-form/private-profile-form.initial';

export const hideFreshdeskWidget = () => {
    const widget = document.getElementById('freshworks-container');

    if (widget) {
        widget.style.display = 'none';
    }
};

export const showFreshdeskWidget = () => {
    const widget = document.getElementById('freshworks-container');

    if (widget) {
        widget.style.display = 'block';
    }
};

export const fillContactForm = (name = '', email = '') => {
    (<any>window).FreshworksWidget('identify', 'ticketForm', {
        name,
        email,
    });
};

export const prefillFreshdeskWidget = (data: { profile: UserProfileInterface; email: string }) => {
    const name = `${data.profile.lastName} ${data.profile.name}`;
    fillContactForm(name, data.email);
};

export const clearFreshdeskContactForm = () => {
    fillContactForm();
};
