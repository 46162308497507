import React, { memo } from 'react';

import InfoIcon from '@shared-asset/info.svg?react';
import {
    ElpasoNotification,
    ElpasoNotificationIcon,
    ElpasoNotificationTitle,
} from '@shared-atom/elpaso-kit/notification/style';
import { ElpasoNotificationProps } from '@shared-atom/elpaso-kit/notification/types';
import { Translate } from '@shared-atom/translate/translate';

import { linkMarkdownTag } from '@component/link-markdown-tag/link-markdown-tag';

export const Notification = memo(({ text, size, type }: ElpasoNotificationProps) => (
    <ElpasoNotification size={size} typeStyle={type}>
        <ElpasoNotificationIcon size={size}>
            <InfoIcon />
        </ElpasoNotificationIcon>
        <ElpasoNotificationTitle size={size}>
            <Translate langKey={text} customRender={linkMarkdownTag} />
        </ElpasoNotificationTitle>
    </ElpasoNotification>
));
