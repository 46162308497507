import styled from 'styled-components';

import { White, DarkBlue } from '@shared-style/colors';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const DeletePartnerWrapper = styled.div`
    background-color: ${White};
    border-radius: 8px 0 0 8px;
    width: 952px;
    height: 100%;
    background-color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? DarkBlue : White)};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        border-radius: 0;
    }
`;

export const ContentWrapper = styled.div`
    height: 100%;
    width: 100%;
    padding: 32px 24px;
`;
