import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { PageLoader } from '@shared-component/page-loader/page-loader';

const PageRoot = memo(() => (
    <PageLoader>
        <Outlet />
    </PageLoader>
));

export default PageRoot;
