import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

import Attachment from '../../../assets/vector/attachment.svg?react';

export const AttachFileWrapper = styled.div`
    gap: 14px;
    ${Flex.Style}
`;

export const ClipIcon = styled(Attachment)`
    height: 20px;
    width: 14px;
`;

export const AttachFileLabelWrapper = styled.p`
    margin-left: 8px;
`;

export const AttachFileLabel = styled.p`
    ${Typography.Paragraph14.SemiBold.Neutral900.Left.Style}
`;

export const AttachFileDescription = styled.p`
    ${Typography.Paragraph12.Regular.Neutral500.Left.Style}
`;
