import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalHeightEnum } from '@shared-component/modal/modal.enum';
import { Mutation, UserConfirmPasswordUpdate, UserStartPasswordUpdate } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { isExist, isTrue } from '@shared-util/is-data';

import { useUserSelector } from '@selector/user/use-user.selector';

export interface HandleSubmitInterface {
    password: string;
    currentPassword: string;
}

export const useUserChangePassword = (): [
    OnEventType<HandleSubmitInterface>,
    boolean,
    OnEventType<string>,
    string,
    OnEventEmptyType,
    string | undefined
] => {
    const { onOpen } = useContext(InformationModalContext);
    const { setModalHeight, onClose } = useContext(ModelContext);
    const [shouldShowOTPForm, setShouldShowOTPForm] = useState(false);
    const [formValues, setFormValues] = useState<HandleSubmitInterface | undefined>();

    const [{ phone }] = useUserSelector();
    const { currentPassword, password } = formValues ?? {};
    const [userStartPasswordUpdate, { data: userStartPasswordUpdateData, error: userStartPasswordUpdateError }] =
        useMutation<Pick<Mutation, 'userStartPasswordUpdate'>>(UserStartPasswordUpdate);

    const [userConfirmPasswordUpdate, { data: userConfirmPasswordUpdateData, error: userConfirmPasswordUpdateError }] =
        useMutation<Pick<Mutation, 'userConfirmPasswordUpdate'>>(UserConfirmPasswordUpdate);

    const handleSubmit = (variables: HandleSubmitInterface) => {
        setFormValues(variables);
        userStartPasswordUpdate({ variables });
    };

    const onResendOtpCode = () => userStartPasswordUpdate({ variables: { currentPassword, password } });

    const handleOtpSubmit = (otp: string) => {
        userConfirmPasswordUpdate({
            variables: { currentPassword, password, otp },
        });
    };

    useEffect(() => {
        if (isExist(userConfirmPasswordUpdateData?.userConfirmPasswordUpdate.status)) {
            onOpen(
                isTrue(userConfirmPasswordUpdateData?.userConfirmPasswordUpdate.status)
                    ? InformationModalEnum.SuccessInformationModal
                    : InformationModalEnum.FailureInformationModal,
                {
                    text: isTrue(userConfirmPasswordUpdateData?.userConfirmPasswordUpdate.status)
                        ? LocalizationEnum.UpdatePasswordSuccessMessage
                        : LocalizationEnum.FailureCurrencyExchangeMessage,
                }
            );
            if (isTrue(userConfirmPasswordUpdateData?.userConfirmPasswordUpdate.status)) {
                onClose();
                setShouldShowOTPForm(false);
            }
        }
    }, [userConfirmPasswordUpdateData]);

    useEffect(() => {
        if (isExist(userStartPasswordUpdateData?.userStartPasswordUpdate.status)) {
            if (isTrue(userStartPasswordUpdateData?.userStartPasswordUpdate.status)) {
                setShouldShowOTPForm(true);
            } else {
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: LocalizationEnum.FailureCurrencyExchangeMessage,
                });
            }
        }
    }, [userStartPasswordUpdateData]);

    useEffect(() => {
        if (isTrue(userConfirmPasswordUpdateError)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: userConfirmPasswordUpdateError?.message ?? LocalizationEnum.ChangePasswordFormError,
            });
        }
    }, [userConfirmPasswordUpdateError]);

    useEffect(() => {
        if (isTrue(userStartPasswordUpdateError)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: userStartPasswordUpdateError?.message ?? LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
    }, [userStartPasswordUpdateError]);

    useEffect(() => {
        if (shouldShowOTPForm) {
            setModalHeight(ModalHeightEnum.H330);
        }
    }, [shouldShowOTPForm]);

    return [
        handleSubmit,
        shouldShowOTPForm,
        handleOtpSubmit,
        phone,
        onResendOtpCode,
        userConfirmPasswordUpdateError?.message,
    ];
};
