import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { OtpSendForm } from '@shared-form/otp-send-form/otp-send-form';
import { FCWithChildrenType } from '@shared-type/common';

import { useUserChangePassword } from '@hook/user/user-change-password.hook';
import { ChangePasswordFormFields } from '@page/workspace/settings/section/security/subsections/password/change-password-form/change-password-form-fields/change-password-form-fields';
import { ChangePasswordInitial } from '@page/workspace/settings/section/security/subsections/password/change-password-form/change-password-form.initial';
import { ChangePasswordSchema } from '@page/workspace/settings/section/security/subsections/password/change-password-form/change-password-form.validation';

export const ChangePasswordForm: FCWithChildrenType = observer(({ children }) => {
    const [handleSubmit, isOtpSent, handleOtpSubmit, phone, onResendOtpCode, error] = useUserChangePassword();

    return isOtpSent ? (
        <OtpSendForm phone={phone} error={error} onSubmit={handleOtpSubmit} onResendOtpCode={onResendOtpCode} />
    ) : (
        <Formik
            initialValues={ChangePasswordInitial}
            validationSchema={ChangePasswordSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
        >
            <Form>
                <ChangePasswordFormFields />
                {children}
            </Form>
        </Formik>
    );
});
