import styled from 'styled-components';

import { ButtonTransparentStyle } from '@shared-atom/button/button.style';
import { LabelError } from '@shared-component/field-error/field-error.styles';
import { CustomInputWrapper, CustomInputWrapperStyle } from '@shared-component/input/input.styles';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import { ModalContentWrapper } from '@component/modal/client-modal.styles';
import { InputWrapper } from '@page/registration/registration-password/registration-password-form/registration-password-form.styles';

export const UserShortProfileChangePasswordWrapper = styled(ModalContentWrapper)`
    form {
        padding-top: 32px;
        height: 100%;

        ${CustomInputWrapper} {
            padding: 0 24px;
            margin-bottom: 24px;
            position: relative;
        }
        ${LabelError} {
            position: absolute;
            bottom: -20px;
        }
        ${CustomInputWrapperStyle} {
            align-items: center;
            padding: 15px 12px;
        }
        ${InputWrapper} {
            margin-top: 0;
        }
        ${ButtonTransparentStyle} {
            height: 20px !important;
        }

        @media (max-width: ${MAX_MOBILE_SIZE}px) {
            padding-top: 16px;

            ${CustomInputWrapper} {
                padding: 0 20px;
            }
        }
    }
`;
