import { types } from 'mobx-state-tree';

import { addressBookModelActions } from './addres-book-actions/address-book-model.actions';
import { initialAddressBookModel } from './address-book-model.initial';
import { AddressBookModelTypeInstance } from './address-book-model.type';

export const AddressBookInstance = types.model('AddressBookModel', {
    data: AddressBookModelTypeInstance,
    loading: types.model('AddressBookLoading', {
        isAddressBookLoading: types.boolean,
    }),
    error: types.optional(types.string, ''),
});

export const AddressBookModel = AddressBookInstance.actions(addressBookModelActions);

export const getInitialAddressBookModel = () =>
    AddressBookModel.create({
        data: initialAddressBookModel,
        loading: {
            isAddressBookLoading: false,
        },
    });
