import styled from 'styled-components';

import { Typography } from '../../../atoms/typography/typography';
import { getAnimationStyle } from '../../../styles/animation';
import { Error500, Neutral200, Neutral50, Primary50, Primary500 } from '../../../styles/colors';
import { Flex } from '../../../styles/flex';
import { resetInputStyle } from '../../../styles/reset-style';
import { MaskedInputStyle } from '../input.styles';

interface InputCodeWrapperProps {
    otpError?: boolean;
}

export const OtpInputStyle = styled(MaskedInputStyle)`
    padding: 0;
    font-variant-numeric: tabular-nums;
    ${Typography.H1Desktop.Left.Style}
    ${resetInputStyle}
    ::placeholder {
        ${Typography.H1Desktop.Left.Neutral200.Style};
        letter-spacing: 2px;
    }
`;

export const InputCodeWrapper = styled.div<InputCodeWrapperProps>`
    width: 285px;
    margin: 20px auto 0;
    position: relative;
    display: inline-block;
    background-color: ${Neutral50};
    height: 72px;
    input {
        padding: 12px;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid ${({ otpError = false }) => (otpError ? Error500 : Neutral200)};
        &:hover {
            background-color: ${Primary50};
        }
        &:focus {
            border: 1px solid ${({ otpError = false }) => (otpError ? Error500 : Primary500)};
        }
        ${getAnimationStyle(['box-shadow'])};
    }
    ${Flex.VerticalCenter.HorizontalCenter.Style};
`;

export const InputOtpComponent = styled.div`
    width: fit-content;
    margin: 0 auto;
`;
