export const sortedEEUCountriesList: Array<Array<string[]>> = [
    [['A'], ['AT']],
    [['B'], ['BE', 'BG']],
    [['C'], ['CY', 'CZ']],
    [['D'], ['DK', 'DE']],
    [['E'], ['EE', 'ES']],
    [['F'], ['FI', 'FR']],
    [['G'], ['GR']],
    [['H'], ['HR', 'HU']],
    [['I'], ['IE', 'IT']],
    [['L'], ['LV', 'LT', 'LU']],
    [['M'], ['MT']],
    [['N'], ['NL']],
    [['P'], ['PL', 'PT']],
    [['R'], ['RO']],
    [['S'], ['SK', 'SI', 'SE']],
];
