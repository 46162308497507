import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { getAnimationStyle } from '@shared-style/animation';
import { Neutral50, Neutral700, Primary50, Primary500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

interface InformationRowDataWrapperProps {
    isCopied: boolean;
}

export const InformationRowWrapper = styled.div`
    ${Flex.SpaceBetween.Style}
`;

export const InformationRowTitle = styled.div`
    padding: 8px;
    width: 50%;
    ${Typography.Paragraph14.Medium.Neutral500.Style}
`;

export const InformationRowDataWrapper = styled.div<InformationRowDataWrapperProps>`
    width: 50%;
    padding: 8px;
    color: ${Neutral700};
    background-color: ${Neutral50};
    ${({ isCopied }: InformationRowDataWrapperProps) =>
        isCopied &&
        `
        :hover{
            cursor: pointer;
            background-color: ${Primary50};
            color: ${Primary500};
            svg{
                width: auto;
                opacity: 1;
            }
        }
    `};
    svg {
        width: 0;
        overflow: hidden;
        opacity: 0;
        float: right;
        margin-left: 4px;
        ${getAnimationStyle(['opacity'])}
    }
    ${getAnimationStyle(['background-color', 'color'])}
`;

export const InformationRowData = styled.span`
    word-break: break-all;
    ${Typography.Paragraph14.SemiBold.Style}
`;
