import { CompanyActivatedStatus, UserActivatedStatus, UserType } from '@shared-graphql';

import { useAccountSelector } from '@selector/account/use-account.selector';
import { useCompanySelector } from '@selector/company/use-company.selector';
import { useUserSelector } from '@selector/user/use-user.selector';

export const useActiveAccounts = () => {
    const [{ status: userStatus, type: userType }] = useUserSelector();
    const [{ status }] = useCompanySelector();
    const [{ ccAccounts }] = useAccountSelector();

    if (userType === UserType.Legal) {
        return status === CompanyActivatedStatus.Activated && ccAccounts.length > 0;
    }
    if (userType === UserType.Private) {
        return userStatus === UserActivatedStatus.Activated && ccAccounts.length > 0;
    }

    return false;
};
