import { DEFAULT_CURRENCY } from '@shared-util/constants';

export const getCurrencyFormat = (
    amount: number,
    currency: string = DEFAULT_CURRENCY,
    lang = navigator.language,
    withSpace = false
) => {
    const numberFormat = new Intl.NumberFormat(lang, { style: 'currency', currency });

    return withSpace
        ? numberFormat
              .format(amount ?? 0)
              .replace(/^(\D+)/, '$1 ')
              .replace(/\s+/, ' ')
        : numberFormat.format(amount ?? 0);
};
