import { combine } from 'flexible-chain';
import styleToCss from 'style-object-to-css-string';
import { CSSObject } from 'styled-components';

import {
    Neutral50,
    Neutral200,
    Neutral900,
    Error100,
    Error500,
    Primary100,
    Primary500,
    Success100,
    Success500,
    Warning100,
    Warning500,
} from '@shared-style/colors';

export const styledType = {
    NeutralFilled: {
        backgroundColor: `${Neutral900}`,
        color: `${Neutral50}`,
    },
    NeutralStroke: {
        backgroundColor: 'transparent',
        color: `${Neutral900}`,
        border: `1px solid ${Neutral900}`,
    },
    NeutralGhost: {
        backgroundColor: `${Neutral200}`,
        color: `${Neutral900}`,
    },
    ErrorFilled: {
        backgroundColor: `${Error500}`,
        color: `${Neutral50}`,
    },
    ErrorStroke: {
        backgroundColor: 'transparent',
        color: `${Error500}`,
        border: `1px solid ${Error500}`,
    },
    ErrorGhost: {
        backgroundColor: `${Error100}`,
        color: `${Error500}`,
    },
    SuccessFilled: {
        backgroundColor: `${Success500}`,
        color: `${Neutral50}`,
    },
    SuccessStroke: {
        backgroundColor: 'transparent',
        color: `${Success500}`,
        border: `1px solid ${Success500}`,
    },
    SuccessGhost: {
        backgroundColor: `${Success100}`,
        color: `${Success500}`,
    },
    WarningFilled: {
        backgroundColor: `${Warning500}`,
        color: `${Neutral50}`,
    },
    WarningStroke: {
        backgroundColor: 'transparent',
        color: `${Warning500}`,
        border: `1px solid ${Warning500}`,
    },
    WarningGhost: {
        backgroundColor: `${Warning100}`,
        color: `${Warning500}`,
    },
    PrimaryFilled: {
        backgroundColor: `${Primary500}`,
        color: `${Neutral50}`,
    },
    PrimaryStroke: {
        backgroundColor: 'transparent',
        color: `${Primary500}`,
        border: `1px solid ${Primary500}`,
    },
    PrimaryGhost: {
        backgroundColor: `${Primary100}`,
        color: `${Primary500}`,
    },
};

const defaultStyles = {
    borderRadius: '24px',
};

const componentResult = {
    Style: (props: CSSObject) => styleToCss({ ...defaultStyles, ...props }),
};

export const ColorStyles = combine(styledType, componentResult);
