import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

import {
    InformationRowWrapper,
    InformationRowDataWrapper,
    InformationRowTitle,
} from '@component/modal/modals/common/information-copy-row/information-copy-row.styles';

export const CurrencyExchangeInformationWrapper = styled.div`
    width: 100%;

    ${InformationRowWrapper} {
        :not(:first-child) {
            margin-top: 8px;
        }
    }
    ${InformationRowDataWrapper} {
        text-align: right;
        padding: 4px;
    }
    ${InformationRowTitle} {
        padding: 4px 0;
    }
`;

export const CurrencyExchangeInformationTitle = styled.h2`
    margin-bottom: 12px;
    ${Typography.Paragraph16.SemiBold.Neutral900.Style}
`;
export const InformationBlockWrapper = styled.div`
    margin-top: 32px;
`;
