import { observer } from 'mobx-react-lite';

import { ModalWrapper } from '@component/modal/client-modal.styles';
import { AcquiringRequestForm } from '@component/modal/modals/acquiring-request/acquiring-request-form/acquiring-request-form';

export const AcquiringRequestModal = observer(() => (
    <ModalWrapper>
        <AcquiringRequestForm />
    </ModalWrapper>
));
