import { FC, memo } from 'react';

import { Link } from '@shared-atom/link/link';
import { Translate } from '@shared-atom/translate/translate';

import { FooterInterface } from '@page/main-pages/main-page-common/footer/footer.interface';

import { FooterNavigationItemTitle, FooterNavigationList, FooterNavigationTitle } from './footer-navigation.styles';

interface FooterNavigationProps {
    item: FooterInterface;
}

export const FooterNavigation: FC<FooterNavigationProps> = memo(({ item }) => (
    <>
        <FooterNavigationTitle>
            <Translate langKey={item.title} />
        </FooterNavigationTitle>
        <FooterNavigationList>
            {item.navOptions.map(child => (
                <FooterNavigationTitle key={child.title}>
                    <Link route={child.link}>
                        <FooterNavigationItemTitle>
                            <Translate langKey={child.title} />
                        </FooterNavigationItemTitle>
                    </Link>
                </FooterNavigationTitle>
            ))}
        </FooterNavigationList>
    </>
));
