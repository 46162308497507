import { ModalEnum } from './modal.enum';
import { ModalPropsInterface } from './modal.interface';

type ModalOptionType = {
    [key in ModalEnum]?: ModalPropsInterface;
};

export const ModalOptionProps: ModalOptionType = {
    [ModalEnum.RegistrationLegalPrivatePersonModal]: {
        isFull: true,
    },
    [ModalEnum.RegistrationLegalPersonLegalModal]: {
        isFull: true,
    },
};
