import { memo } from 'react';

import { Animation } from '@shared-component/animation/animation';
import DefaultLoader from '@shared-component/animation/animation-loading/assets/default-loader.animation.json';
import WhiteLoader from '@shared-component/animation/animation-loading/assets/white-loader.animation.json';

interface AnimationLoadingProps {
    isWhite?: boolean;
}

export const AnimationLoading = memo(({ isWhite = false }: AnimationLoadingProps) => (
    <Animation animationData={isWhite ? WhiteLoader : DefaultLoader} isLoop />
));
