import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { InputDate } from '@shared-atom/elpaso-kit/input/input-date/input-date';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { DayPickerRangeWrapper } from './day-picker-range.styles';
import 'react-day-picker/lib/style.css';

interface DayPickerRangeProps {
    nameFrom: string;
    nameTo: string;
    fromKey?: LocalizationEnum;
    toKey?: LocalizationEnum;
    popperPlacement?:
        | 'auto'
        | 'bottom'
        | 'bottom-end'
        | 'bottom-start'
        | 'left'
        | 'left-end'
        | 'left-start'
        | 'right'
        | 'right-end'
        | 'right-start'
        | 'top'
        | 'top-end'
        | 'top-start';
}

export const DayPickerRange: FC<DayPickerRangeProps> = observer(
    ({
        nameFrom,
        nameTo,
        fromKey = LocalizationEnum.DayPickerRangeFrom,
        toKey = LocalizationEnum.DayPickerRangeTo,
        popperPlacement,
    }) => {
        const [{ value: from }] = useField<Date>(nameFrom);
        const [{ value: to }] = useField<Date>(nameTo);

        return (
            <DayPickerRangeWrapper>
                <InputDate name={nameFrom} isMobile label={fromKey} maxDate={to} popperPlacement={popperPlacement} />
                <InputDate name={nameTo} isMobile label={toKey} minDate={from} popperPlacement={popperPlacement} />
            </DayPickerRangeWrapper>
        );
    }
);
