import { FC } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ClientInformationModalProps } from '@component/informaton-modal/client-information-modal.interface';
import {
    CopykIcon,
    CopyInfoBlockMessageText,
    CopyInfoBlockWrapper,
} from '@component/informaton-modal/modals/copy-information-modal/copy-information-modal.style';

export const CopyInformationModal: FC<ClientInformationModalProps> = ({ isVisible = false }) => (
    <CopyInfoBlockWrapper isVisible={isVisible}>
        <CopykIcon />
        <CopyInfoBlockMessageText>
            <Translate langKey={LocalizationEnum.DataCopiedToTheClipboard} />
        </CopyInfoBlockMessageText>
    </CopyInfoBlockWrapper>
);
