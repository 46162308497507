import { LocalizationEnum } from '@shared-locale/localization.enum';

export const ukraineRegions = [
    { value: 'Crimea', label: LocalizationEnum.CrimeaRegion },
    { value: 'Vinnytska oblast', label: LocalizationEnum.VinnytskaOblast },
    { value: 'Volynska oblast', label: LocalizationEnum.VolynskaOblast },
    { value: 'Dnipropetrovska oblast', label: LocalizationEnum.DnipropetrovskaOblast },
    { value: 'Donetska oblast', label: LocalizationEnum.DonetskaOblast },
    { value: 'Zhytomyrska oblast', label: LocalizationEnum.ZhytomyrskaOblast },
    { value: 'Zakarpatska oblast', label: LocalizationEnum.ZakarpatskaOblast },
    { value: 'Zaporizka oblast', label: LocalizationEnum.ZaporizkaOblast },
    { value: 'Ivano-Frankivska oblast', label: LocalizationEnum.IvanoFrankivskaOblast },
    { value: 'Kyivska oblast', label: LocalizationEnum.KyivskaOblast },
    { value: 'Kirovohradska oblast', label: LocalizationEnum.KirovohradskaOblast },
    { value: 'Luhanska oblast', label: LocalizationEnum.LuhanskaOblast },
    { value: 'Lvivska oblast', label: LocalizationEnum.LvivskaOblast },
    { value: 'Mykolaivska oblast', label: LocalizationEnum.MykolaivskaOblast },
    { value: 'Odeska oblast', label: LocalizationEnum.OdeskaOblast },
    { value: 'Poltavska oblast', label: LocalizationEnum.PoltavskaOblast },
    { value: 'Rivnenska oblast', label: LocalizationEnum.RivnenskaOblast },
    { value: 'Sumska oblast', label: LocalizationEnum.SumskaOblast },
    { value: 'Ternopilska oblast', label: LocalizationEnum.TernopilskaOblast },
    { value: 'Kharkivska oblast', label: LocalizationEnum.KharkivskaOblast },
    { value: 'Khersonska oblast', label: LocalizationEnum.KhersonskaOblast },
    { value: 'Khmelnytska oblast', label: LocalizationEnum.KhmelnytskaOblast },
    { value: 'Cherkaska oblast', label: LocalizationEnum.CherkaskaOblast },
    { value: 'Chernivetska oblast', label: LocalizationEnum.ChernivetskaOblast },
    { value: 'Chernihivska oblast', label: LocalizationEnum.ChernihivskaOblast },
];
