import { memo, useContext } from 'react';

import { Modal } from '@shared-component/modal/modal';
import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalEnum } from '@shared-component/modal/modal.enum';

import { AccountDetailsModal } from '@component/modal/modals/account-details/account-details.modal';
import { AcquiringRequestModal } from '@component/modal/modals/acquiring-request/acquiring-request.modal';
import { ApplyVacancyModal } from '@component/modal/modals/apply-vacancy/apply-vacancy';
import { CancelTransactionModal } from '@component/modal/modals/cancel-transaction/cancel-transaction.modal';
import { CreatePartnerModal } from '@component/modal/modals/create-partner/create-partner.modal';
import { AddCurrencyModal } from '@component/modal/modals/currency-add/currency-add';
import { CurrencyExchangeModal } from '@component/modal/modals/currency-exchange/currency-exchange.modal';
import { DeletePartnerConfirmationModal } from '@component/modal/modals/delete-partner-confirmation/delete-partner-confirmation.modal';
import { GoogleAuthActivateModal } from '@component/modal/modals/google-auth-activate-modal/google-auth-activate-modal';
import { HomeSolutionsModal } from '@component/modal/modals/home-solutions/home-solutions.modal';
import { PaymentModal } from '@component/modal/modals/payment/payment.modal';
import { RegistrationPermittedCountriesModal } from '@component/modal/modals/registration-permitted-countries/registration-permitted-countries.modal';
import { RequestStatementModal } from '@component/modal/modals/request-statement/request-statement.modal';
import { TerminateSessionsModal } from '@component/modal/modals/terminate-sessions/terminate-sessions';
import { UserProfileModal } from '@component/modal/modals/user-profile/user-profile.modal';

export const ClientModal = memo(() => {
    const { selectedModal } = useContext(ModelContext);

    return (
        <Modal>
            {selectedModal === ModalEnum.PaymentModal && <PaymentModal />}
            {selectedModal === ModalEnum.RequestStatementModal && <RequestStatementModal />}
            {selectedModal === ModalEnum.CreatePartnerModal && <CreatePartnerModal />}
            {selectedModal === ModalEnum.UserProfileModal && <UserProfileModal />}
            {selectedModal === ModalEnum.DeletePartnerConfirmationModal && <DeletePartnerConfirmationModal />}
            {selectedModal === ModalEnum.AddCurrency && <AddCurrencyModal />}
            {selectedModal === ModalEnum.AccountDetails && <AccountDetailsModal />}
            {selectedModal === ModalEnum.CurrencyExchangeModal && <CurrencyExchangeModal />}
            {selectedModal === ModalEnum.AcquiringRequestModal && <AcquiringRequestModal />}
            {selectedModal === ModalEnum.RegistrationPermittedCountriesModal && <RegistrationPermittedCountriesModal />}
            {selectedModal === ModalEnum.HomeServiceModal && <HomeSolutionsModal />}
            {selectedModal === ModalEnum.ApplyVacancyModal && <ApplyVacancyModal />}
            {selectedModal === ModalEnum.GoogleAuthActivateModal && <GoogleAuthActivateModal />}
            {selectedModal === ModalEnum.TerminateSessionsModal && <TerminateSessionsModal />}
            {selectedModal === ModalEnum.CancelTransactionModal && <CancelTransactionModal />}
        </Modal>
    );
});
