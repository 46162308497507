import { FC, memo, MutableRefObject, useRef } from 'react';

import { Scroll } from '@shared-component/scroll/scroll';
import { ScrollRefProps } from '@shared-component/scroll/scroll-ref.props';
import { AnchorProvider } from '@shared-hook/navigation/anchor/anchor.provider';
import { isTrue } from '@shared-util/is-data';

import { Footer } from '@page/main-pages/main-page-common/footer/footer';
import { Header } from '@page/main-pages/main-page-common/header/header';
import { MainWrapperInterface } from '@page/main-pages/main-page-common/main-page-common.interface';
import { MainWrapperBlock, MainWrapperView } from '@page/main-pages/main-page-common/main-page-common.styles';

export const MainWrapper: FC<MainWrapperInterface> = memo(({ withHeaderAndFooter = true, children }) => {
    const scrollRef = useRef() as MutableRefObject<ScrollRefProps>;

    return (
        <AnchorProvider scrollRef={scrollRef}>
            <MainWrapperView>
                {isTrue(withHeaderAndFooter) && <Header />}
                <MainWrapperBlock withHeaderAndFooter={withHeaderAndFooter}>
                    <Scroll scrollRef={scrollRef}>
                        {children}
                        {isTrue(withHeaderAndFooter) && <Footer />}
                    </Scroll>
                </MainWrapperBlock>
            </MainWrapperView>
        </AnchorProvider>
    );
});
