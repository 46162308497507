import { useState } from 'react';

import { OnEventEmptyType } from '@shared-type/on-event.type';

export const useApplyVacancyForm = (callback: OnEventEmptyType<void>) => {
    const [isLoading, setLoading] = useState(false);

    const onSubmit = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            callback();
        }, 1500);
    };

    return { isLoading, onSubmit };
};
