import styled from 'styled-components';

import { Flex } from '@shared-style/flex';

import { Typography } from '../../typography/typography';
import { BadgeSizeEnum, BadgeTypeEnum } from './types';
import { getBtnPaddingBySize, getStyleByType } from './utils';

interface ElpasoBadgeProps {
    size: BadgeSizeEnum;
    typeStyle: BadgeTypeEnum;
}

interface ElpasoBadgeTitleProps {
    size: BadgeSizeEnum;
}

interface ElpasoBadgeIconProps {
    isBefore?: boolean;
    size: BadgeSizeEnum;
}

export const ElpasoBadge = styled.div<ElpasoBadgeProps>`
    ${Flex.HorizontalCenter.VerticalCenter.InlineFlex.Style};
    ${({ size }) => (size === 's' ? Typography.Paragraph12.Medium.Style : Typography.Paragraph14.Medium.Style)};
    ${({ typeStyle }) => getStyleByType(typeStyle)}
    padding: ${({ size }) => getBtnPaddingBySize(size)};
    text-align: center;
    svg,
    path {
        ${({ typeStyle }) => getStyleByType(typeStyle)};
    }
`;
export const ElpasoBadgeTitle = styled.div<ElpasoBadgeTitleProps>`
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    ${({ size }) => (size === 's' ? Typography.Paragraph12.Medium.Style : Typography.Paragraph14.Medium.Style)};
`;
export const ElpasoBadgeIcon = styled.div<ElpasoBadgeIconProps>`
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    margin: ${({ isBefore }) => (isBefore === true ? '0 6px 0 0' : '0 0 0 6px')};
`;
