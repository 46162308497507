import { FC } from 'react';

import DownArrowIcon from '../../assets/arrowDown.svg?react';
import UpArrowIcon from '../../assets/arrowUp.svg?react';
import { YearMonthSelect } from '../month-year-view-select/month-year-view-select';
import { MonthYearViewProps } from './month-year-view.interface';
import { Arrow, DateButtonWrapper, DateTitle, DateTitleSelect, MonthYearViewWrapper } from './month-year-view.styles';

export const MonthYearView: FC<MonthYearViewProps> = ({
    value,
    selectedMonth,
    selectedYear,
    list,
    isOpened,
    onOpen,
    onChange,
    isLeftSide,
}) => (
    <MonthYearViewWrapper>
        <DateButtonWrapper onClick={onOpen}>
            <DateTitleSelect>
                <DateTitle>{value}</DateTitle>
                <Arrow as={!isOpened ? DownArrowIcon : UpArrowIcon} />
            </DateTitleSelect>
        </DateButtonWrapper>
        {isOpened && (
            <YearMonthSelect
                list={list}
                value={value}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                onChange={onChange}
                isLeftSide={isLeftSide}
            />
        )}
    </MonthYearViewWrapper>
);
