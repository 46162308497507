import { useField } from 'formik';

import { Input } from '@shared-component/input/input';
import { FormHalfRow } from '@shared-form/form-common/form-common.styles';
import { PaymentEntityType } from '@shared-graphql';

import { CreatePartnerFormEnum } from '@component/modal/modals/create-partner/create-partner-form/create-partner-form.enum';

import { useCreatePartnerFormNameLocalization } from './create-partner-form-name.localization-hook';

export const CreatePartnerFormName = () => {
    const [{ value }] = useField(CreatePartnerFormEnum.EntityType);
    const { firstName, lastName, companyName } = useCreatePartnerFormNameLocalization();

    const inputList =
        value === PaymentEntityType.individual
            ? [
                { title: firstName, name: CreatePartnerFormEnum.FirstName },
                { title: lastName, name: CreatePartnerFormEnum.LastName },
            ]
            : [{ title: companyName, name: CreatePartnerFormEnum.CompanyName }];

    return (
        <FormHalfRow>
            {inputList.map(({ title, name }) => (
                <Input key={title} title={title} name={name} />
            ))}
        </FormHalfRow>
    );
};
