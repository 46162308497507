import styled from 'styled-components';

import { FormRow } from '@shared-form/form-common/form-common.styles';
import { Neutral200 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import { ModalWrapper } from '@component/modal/client-modal.styles';

interface RequestStatementFormWrapperInterface {
    maxHeight: number;
}

export const RequestStatementModalWrapper = styled(ModalWrapper)`
    height: auto;
    max-height: 100%;
    overflow-y: scroll;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        height: 100%;
    }
`;
export const RequestStatementContentWrapper = styled.div`
    width: 100%;
    height: calc(100% - 60px);
    max-height: calc(100% - 60px);
    form {
        ${Flex.Column.SpaceBetween.Style};
    }
`;
export const RequestStatementFormWrapper = styled.div<RequestStatementFormWrapperInterface>`
    width: 100%;
    height: ${({ maxHeight }) => `${maxHeight}px`};

    ${FormRow} {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 12px;
        }
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        height: 100%;
    }
`;
export const RequestStatementFormContent = styled.div`
    padding: 24px;
`;

export const ButtonSubmitWrapper = styled.div`
    padding: 16px 24px;
    width: 100%;
    border-top: 1px solid ${Neutral200};
    margin-top: auto;
    ${Flex.JustifyRight.Style}
`;
