import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { Country, GetAvailableCountries, GetCountriesAnswer, Query } from '@shared-graphql';
import { isExist, isTrue } from '@shared-util/is-data';

export enum FilterCountriesEnum {
    ALLOW_TO_OPEN_ACCOUNT = 'allowToOpenAccount',
    ALLOW_TO_RECEIPT_FUNDS = 'allowToReceiptFunds',
    ALLOW_TO_SEND_FUNDS = 'allowToSendFunds',
    LOCAL = 'local',
    SWIFT = 'swift',
    SEPA = 'sepa',
    BLACK_LIST = 'blacklist',
}

export const useCountries = (filter?: FilterCountriesEnum): [Country[], boolean] => {
    const [loadCountries, { data, loading: isCountriesLoading }] =
        useLazyQuery<Pick<Query, 'getAvailableCountries'>>(GetAvailableCountries);
    const [countries, setCountries] = useState<any>([]);

    useEffect(() => {
        const countryFilter = isTrue(filter) ? { [filter as string]: true } : {};
        loadCountries({
            variables: { filter: countryFilter },
        });
    }, []);

    useEffect(() => {
        if (isExist((data?.getAvailableCountries as GetCountriesAnswer)?.countries)) {
            const normalizedCountriesList = (data?.getAvailableCountries as GetCountriesAnswer)?.countries.map(
                country => ({
                    name: country.name,
                    code: country.code,
                    alpha2: country.alpha2,
                })
            );

            setCountries(normalizedCountriesList);
        }
    }, [data]);

    return [countries, isCountriesLoading];
};
