import { useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { Account, ConversionReservation, CreateConversionReservation, Mutation } from '@shared-graphql';
import {
    useLocalizationText,
    useLocalizationTextWithReplacedValue,
} from '@shared-hook/localization/use-localization-text.hook';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { DEFAULT_CURRENCY, EXCHANGE_DEBOUNCE_DELAY } from '@shared-util/constants';
import { isExist, isTrue } from '@shared-util/is-data';

import {
    minSellAmount,
    REQUIRED_FIELD_NAMES,
} from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.constants';
import { CurrencyExchangeEnum } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.enum';
import { CurrencyExchangeFormInterface } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.interface';
import { CurrencyExchangeInformationInterface } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-information/currency-exchange-information.interface';
import { currencyExchangeInformationInitials } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-information/currency-exchange-information.options';
import { useAccountSelector } from '@selector/account/use-account.selector';

export const useCurrencyExchangeFields = (): [
    CurrencyExchangeInformationInterface,
    boolean,
    boolean,
    OnEventType<string>
] => {
    const { values, errors, setFieldValue, setErrors, setFieldError } =
        useFormikContext<CurrencyExchangeFormInterface>();
    const [{ selectedAccount: defaultAccount, ccAccounts }] = useAccountSelector();
    const { onOpen } = useContext(InformationModalContext);
    const [information, setInformation] = useState(currencyExchangeInformationInitials);

    const debounced = useDebouncedCallback(value => {
        setFieldValue(CurrencyExchangeEnum.Amount, Number(value));
    }, EXCHANGE_DEBOUNCE_DELAY);

    const gotAmountFieldChange = (value: string) => {
        const newValue = (parseFloat(value.replace(/,/g, '.')) / Number(information.rate)).toFixed(2);

        debounced(newValue);
    };

    const [
        createConversionReservation,
        {
            data: createConversionReservationResult,
            loading: isCreateConversionReservationLoading,
            error: createConversionReservationError,
        },
    ] = useMutation<Pick<Mutation, 'createConversionReservation'>>(CreateConversionReservation);

    const debouncedLoadExchangeRate = useDebouncedCallback(({ conversionReservationId, buyAmount, ...data }) => {
        createConversionReservation({
            variables: { conversion: data },
        });
    }, EXCHANGE_DEBOUNCE_DELAY);

    const amountValue = values[CurrencyExchangeEnum.Amount];
    const buyCurrency = values[CurrencyExchangeEnum.Buy];
    const sellCurrency = values[CurrencyExchangeEnum.Sell];

    const insufficientFundsErrorText = useLocalizationText(LocalizationEnum.CommonInsufficientFunds);
    const selectedAccount = useMemo(
        () => ccAccounts.find(({ currency: { code } }) => code === sellCurrency) as Account,
        [ccAccounts, sellCurrency]
    );
    const minAmountSellFormatted = useCurrencyFormat(
        minSellAmount,
        selectedAccount?.currency?.code ?? DEFAULT_CURRENCY
    );
    const minAmountErrorText = useLocalizationTextWithReplacedValue(LocalizationEnum.MinAmountErrorText, [
        {
            key: '{minAmount}',
            value: minAmountSellFormatted,
        },
    ]);
    const conversionReservation =
        createConversionReservationResult?.createConversionReservation as ConversionReservation;
    const totalAmount = useMemo(() => conversionReservation?.fee?.total ?? 0, [conversionReservation]);
    const feeAmount = useMemo(() => conversionReservation?.fee?.amount ?? 0, [conversionReservation]);
    const isDisabledSubmit = useMemo(
        () => !isExist(conversionReservation) || errors[CurrencyExchangeEnum.Sell] !== undefined,
        [conversionReservation, errors]
    );

    const fee = useCurrencyFormat(feeAmount, sellCurrency);
    const total = useCurrencyFormat(totalAmount, sellCurrency);

    useEffect(() => {
        if (totalAmount > (selectedAccount?.balance ?? 0)) {
            setFieldError(CurrencyExchangeEnum.Amount, insufficientFundsErrorText);
        }
    }, [totalAmount, selectedAccount]);

    useEffect(() => {
        const gotAmount = (isTrue(information?.buyAmount) ? information?.buyAmount : 0) as number;
        setFieldValue(CurrencyExchangeEnum.BuyAmount, gotAmount);
    }, [information.buyAmount]);

    useEffect(() => {
        if (defaultAccount !== '') {
            setFieldValue(CurrencyExchangeEnum.Sell, defaultAccount?.currency?.code ?? DEFAULT_CURRENCY);
        }
    }, [defaultAccount]);

    useEffect(() => {
        if (REQUIRED_FIELD_NAMES.every(field => isExist(values[field]))) {
            if (amountValue >= minSellAmount) {
                setErrors({});
                debouncedLoadExchangeRate(values);
            } else {
                setFieldError(CurrencyExchangeEnum.Amount, minAmountErrorText);
            }
        }
    }, [amountValue, buyCurrency, sellCurrency, debouncedLoadExchangeRate]);

    useEffect(() => {
        if (isExist(conversionReservation)) {
            setFieldValue(CurrencyExchangeEnum.ConversionReservationId, conversionReservation?.id);
            setInformation({
                ...currencyExchangeInformationInitials,
                buyAmount: conversionReservation?.resultAmount ?? 0,
                buyCurrency: conversionReservation?.buyCurrency.code ?? '',
                sellAmount: conversionReservation?.amount ?? 0,
                sellCurrency: conversionReservation?.sellCurrency.code ?? '',
                fee,
                total,
                rate: conversionReservation?.rate ?? 0,
            });
        }
    }, [conversionReservation]);

    useEffect(() => {
        if (createConversionReservationError) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: createConversionReservationError?.message ?? LocalizationEnum.FailureCurrencyExchangeMessage,
                timeout: 5000,
            });
        }
    }, [createConversionReservationError]);

    return [information, isDisabledSubmit, isCreateConversionReservationLoading, gotAmountFieldChange];
};
