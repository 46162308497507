import { Translate } from '@shared-atom/translate/translate';
import { ViewPageLoader } from '@shared-component/page-loader/page-loader';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { linkMarkdownTag } from '@component/link-markdown-tag/link-markdown-tag';
import { GoogleAuthActivateForm } from '@component/modal/modals/google-auth-activate-modal/google-auth-activate-form/google-auth-activate-form';
import { useGoogleAuthActivateModal } from '@component/modal/modals/google-auth-activate-modal/google-auth-activate-modal.hook';
import {
    GoogleAuthActivateModalContent,
    GoogleAuthActivateModalHint,
    GoogleAuthActivateModalTitle,
    GoogleAuthActivateModalWrapper,
    QrCodeImage,
} from '@component/modal/modals/google-auth-activate-modal/google-auth-activate-modal.styles';

export const GoogleAuthActivateModal = () => {
    const { qrcode, isLoading } = useGoogleAuthActivateModal();

    return (
        <GoogleAuthActivateModalWrapper>
            {isTrue(isLoading) ? (
                <ViewPageLoader />
            ) : (
                <>
                    <GoogleAuthActivateModalTitle>
                        <Translate langKey={LocalizationEnum.CommonAuthenticatorApp} />
                    </GoogleAuthActivateModalTitle>
                    <GoogleAuthActivateModalContent>
                        <GoogleAuthActivateModalHint>
                            <Translate
                                langKey={LocalizationEnum.DownloadGoogleAuthenticatorApplicationForIosOrAndroid}
                                customRender={linkMarkdownTag}
                            />
                        </GoogleAuthActivateModalHint>
                        {isTrue(qrcode) && <QrCodeImage src={qrcode as string} />}
                        <GoogleAuthActivateModalHint>
                            <Translate langKey={LocalizationEnum.EnterTheCodeForYourAccountFromTheApplication} />
                        </GoogleAuthActivateModalHint>
                    </GoogleAuthActivateModalContent>
                    <GoogleAuthActivateForm />
                </>
            )}
        </GoogleAuthActivateModalWrapper>
    );
};
