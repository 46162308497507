import { PropsWithChildren, ReactNode } from 'react';

import { PageLoader } from '../page-loader/page-loader';
import { Sidebar } from '../sidebar/sidebar';
import { PageWrapperNavigationProps } from './page-wrapper-navigation/page-wrapper-navigation';
import { PageContentWrapper, PageWrapperRoot } from './page-wrapper.styles';

interface PageWrapperInterface<T> extends PropsWithChildren<PageWrapperNavigationProps<T>> {
    additionalUserComponent?: ReactNode;
}

export const PageWrapper = <T extends string>({
    children,
    pageMenuList,
    additionalMenuList,
    commissionList,
    optionsList,
    additionalUserComponent,
}: PageWrapperInterface<T>) => (
    <PageWrapperRoot>
        <Sidebar
            pageMenuList={pageMenuList}
            additionalMenuList={additionalMenuList}
            additionalUserComponent={additionalUserComponent}
            commissionList={commissionList}
            optionsList={optionsList}
        />
        <PageLoader>
            <PageContentWrapper>{children}</PageContentWrapper>
        </PageLoader>
    </PageWrapperRoot>
);
