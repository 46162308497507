import { useLazyQuery } from '@apollo/client';
import { useMemo } from 'react';

import { GetVerificationParams, Query, UserVerificationParams } from '@shared-graphql';

export const useVerification = () => {
    const [getVerificationParams, { data: verificationData, loading: isVerificationDataLoading }] =
        useLazyQuery<Pick<Query, 'getVerificationParams'>>(GetVerificationParams);

    const accessToken = useMemo(
        () => (verificationData?.getVerificationParams as UserVerificationParams)?.token ?? ('' as string),
        [verificationData]
    );

    return {
        accessToken,
        isVerificationDataLoading,
        getVerificationParams,
    };
};
