import styled from 'styled-components';

import { Typography } from '../../../atoms/typography/typography';
import { Neutral200, Neutral50, Neutral900, Primary50, Primary500 } from '../../../styles/colors';
import { Flex } from '../../../styles/flex';
import { ThemeTypeInterface } from '../../../types/theme-props.type';

interface BeneficiaryRadioButtonProps extends ThemeTypeInterface {
    isChecked: boolean;
}

export const BeneficiaryRadioButtonStyle = styled.div<BeneficiaryRadioButtonProps>`
    padding-left: 20px;
    border-radius: 8px;
    cursor: pointer;
    height: 48px;
    background-color: ${({ isChecked }) => (isChecked ? Primary50 : Neutral50)};
    border: ${({ isChecked }) => (isChecked ? `2px solid ${Primary500}` : `1px solid ${Neutral200}`)};
    width: 100%;

    ${Flex.VerticalCenter.JustifyLeft.Style};
    :hover {
        background-color: ${Primary50};
        border: 2px solid ${Primary500};
        padding-left: ${({ isChecked }) => !isChecked && '19px'};
    }
`;

export const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 8px;
    ${Flex.VerticalCenter.HorizontalCenter.Style};
`;

export const LabelWrapper = styled.p<BeneficiaryRadioButtonProps>`
    margin-left: 8px;
    color: ${({ isChecked = false }) => (isChecked ? Primary500 : Neutral900)};
    ${Typography.Paragraph14.SemiBold.Style}
`;
