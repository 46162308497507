import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import { ModalContentWrapper, ModalFooter } from '@component/modal/client-modal.styles';

export const CancelTransactionModalContentWrapper = styled(ModalContentWrapper)`
    height: calc(100% - 144px);
    padding: 0 24px;
    ${Flex.VerticalCenter.Style};
    ${Typography.Paragraph16.Neutral700.SemiBold.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.Paragraph16.Neutral700.Medium.Center.Style};
    }
`;
export const CancelTransactionModalFooter = styled(ModalFooter)`
    justify-content: space-between;
`;
