import { LocalizationEnum } from '../locale/localization.enum';

export enum LangKeysEnum {
    EN = 'en',
    FR = 'fr',
    DE = 'de',
    ES = 'es',
}

export type LocalizationType = {
    [key in LocalizationEnum]: string;
};
