import { Form, Formik } from 'formik';
import { FC } from 'react';

import { OtpSendFormFields } from '@shared-form/otp-send-form/fields/otp-send-form-fields';
import { OtpEnum } from '@shared-form/otp-send-form/otp-send-form.enum';
import { OtpInitial } from '@shared-form/otp-send-form/otp-send-form.initial';
import { OtpSendFormProps } from '@shared-form/otp-send-form/otp-send-form.interface';
import { OtpValidationSchema } from '@shared-form/otp-send-form/otp-send-form.validation';
import { MutationCheckPhoneCodeArgs } from '@shared-graphql';
import { noop } from '@shared-util/noop';
import { getClearText } from '@shared-util/remove-spaces';

export const OtpSendForm: FC<OtpSendFormProps> = ({
    phone,
    error,
    onSubmit,
    onChange = noop,
    children,
    onResendOtpCode,
}) => {
    const handleOtpSubmit = ({ code }: Pick<MutationCheckPhoneCodeArgs, OtpEnum.Code>) => onSubmit(getClearText(code));

    return (
        <Formik
            initialValues={OtpInitial}
            validationSchema={OtpValidationSchema}
            validateOnChange={false}
            onSubmit={handleOtpSubmit}
        >
            <Form>
                <OtpSendFormFields
                    name={OtpEnum.Code}
                    error={error}
                    onResendOtpCode={onResendOtpCode}
                    onChange={onChange}
                    phone={phone}
                />
                {children}
            </Form>
        </Formik>
    );
};
