import { avatarColors } from './avatar.styles';

const MAX_WORD_COUNT = 2;

export const getFirstLetters = (string: string) =>
    string
        .split(/\s+/)
        .splice(0, MAX_WORD_COUNT)
        .map(word => word.charAt(0))
        .join('')
        .toUpperCase();

export const getRandomColor = (value: string) => {
    const colorsLength = avatarColors.length;
    const lastValueCharCode = value.charCodeAt(value.length - 1);

    return lastValueCharCode % colorsLength;
};
