import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import {
    EmptyUpload,
    LoaderWrapper,
    SelectedFile,
    SelectedFileWrapper,
    UploadStatus,
} from '@shared-component/file-upload/document-file-upload/document-file-upload.styles';
import { DocumentCommentWrapper } from '@shared-form/private-document-form/document-comment/document-comment.styles';

export const PaymentDetailsFormWrapper = styled.div`
    height: calc(100% - 40px);
    width: 100%;
`;

export const PaymentDetailsFormComponentWrapper = styled.div`
    padding: 0 24px;
`;

export const PaymentFormComponentWrapper = styled.div`
    margin-top: 16px;
    ${UploadStatus} {
        padding: 0;
        ${EmptyUpload} {
            display: none;
        }
        ${SelectedFile} {
            :before {
                width: 12px;
                left: 0px;
                top: 6px;
            }
        }
        ${LoaderWrapper} {
            padding: 8px;
        }
        ${SelectedFileWrapper} {
            padding: 10px 16px 10px 24px;
        }
    }
    ${DocumentCommentWrapper} {
        display: none;
    }
`;

export const PaymentFormComponentTitle = styled.h3`
    margin-bottom: -12px;
    ${Typography.Paragraph12.SemiBold.Neutral900.Style}
`;
