import { rgba } from 'polished';
import styled from 'styled-components';

import { ButtonTransparentStyle } from '@shared-atom/button/button.style';
import { Typography } from '@shared-atom/typography/typography';
import { FieldErrorWrapper } from '@shared-component/field-error/field-error.styles';
import {
    Primary100,
    Primary50,
    Neutral200,
    Neutral50,
    Neutral100,
    Error500,
    Neutral900,
    Neutral500,
} from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';
import { isTrue } from '@shared-util/is-data';

import globe from '@page/workspace/assets/globe.svg';

export const Wrapper = styled.div`
    min-width: 100px;
`;

export const SelectWrapper = styled.div`
    display: inline-flex;
    position: relative;
    width: 100%;
`;

export const SelectRowItem = styled.div`
    width: 100%;
    min-height: 58px;
    padding: 16px;
    margin: 0;
    border-bottom: 1px solid ${Neutral200};
    text-align: left;
    ${Flex.Row.JustifyLeft.VerticalCenter.Style}

    :hover {
        background-color: ${Neutral100};
    }
    [selected] {
        background-color: ${Primary100};
    }
`;

export const SelectRowWrapperStyle = styled(ButtonTransparentStyle)`
    justify-content: left;
`;

export const containerStyle = (provided: any) => ({
    ...provided,
    width: '100%',
});

export const controlStyle =
    (isMenuOpened: boolean, hasError: boolean, isMobile = false) =>
    (provided: any, state: any) => {
        const wd = window.innerWidth;
        const shouldMobile = isMobile || wd < MAX_MOBILE_SIZE;
        return {
            ...provided,
            border: 'none',
            display: 'flex',
            height: shouldMobile ? 52 : 60,
            paddingLeft: '12px',
            borderRadius: isMenuOpened ? '8px 8px 0 0' : '8px',
            boxShadow: `0 0 0 1px ${hasError ? Error500 : Neutral200}`,
            backgroundColor: Neutral50,
            cursor: 'pointer',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '&:hover': {
                backgroundColor: Boolean(state.isFocused) === true ? Neutral50 : Primary50,
            },
        };
    };

export const indicatorSeparatorStyle = () => (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
});

export const indicatorsContainerStyle = (isDisabled: boolean) => (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    color: Neutral900,
    transform: isTrue(state?.selectProps?.menuIsOpen) && 'rotate(180deg)',
    display: isDisabled ? 'none' : 'flex',
});

export const selectMenuStyle = () => (provided: any) => ({
    ...provided,
    boxShadow: `0 10px 10px ${rgba(Neutral200, 0.12)}`,
    margin: '-1px',
    borderRadius: '0 0 8px 8px',
    width: 'calc(100% + 2px)',
    backgroundColor: Neutral50,
    border: `1px solid ${Neutral200}`,
});

export const singleValueStyle = () => (provided: any) => ({
    ...provided,
    color: Neutral900,
    textTransform: 'uppercase',
});

export const placeholderStyle =
    (forCountrySelect = false, isMobile = false) =>
    (provided: any) => ({
        ...provided,
        position: 'absolute',
        color: Neutral500,
        fontSize: isMobile ? 14 : 16,
        lineHeight: 20,
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        paddingLeft: forCountrySelect ? '40px' : '0',
        ...(forCountrySelect && {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '&:after': {
                content: '"#"',
                textIndent: '-99999px',
                width: '20px',
                height: '20px',
                backgroundImage: `url(${globe})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                position: 'absolute',
                left: 0,
                top: '50%',
                transform: 'translateY(-50%)',
            },
        }),
    });

export const valueContainerStyle =
    (forCountrySelect = false, isMobile = false) =>
    (provided: any) => {
        const wd = window.innerWidth;
        const shouldMobile = isMobile || wd < MAX_MOBILE_SIZE;

        const additionalStyles = {
            fontSize: shouldMobile ? 14 : 16,
            height: shouldMobile ? 52 : 60,
        };
        return {
            ...provided,
            display: 'inline-flex',
            padding: 0,
            flex: 1,
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            ...(forCountrySelect && additionalStyles),
        };
    };

export const inputStyle =
    (forCountrySelect = false) =>
    (provided: any) => {
        const additionalStyles = {
            paddingLeft: '40px',
            position: 'absolute',
        };
        return {
            ...provided,
            color: Neutral900,
            width: 0,
            ...(forCountrySelect && additionalStyles),
        };
    };

export const noOptionsMessageStyle = (provided: any) => ({
    ...provided,
    ...Typography.S.Normal.Object,
});

export const ErrorWrapper = styled(FieldErrorWrapper)``;

export const LabelWrapper = styled.label`
    margin-bottom: 4px;
    display: block;
    ${Typography.Paragraph12.SemiBold.Neutral900.Style};
`;
