import { PaymentEntityType } from '../../graphql';
import { LocalizationEnum } from '../../locale/localization.enum';
import { FormRow } from '../forms/form-common/form-common.styles';
import { PaymentBeneficiaryRadioButton } from './payment-beneficiary-radio-button/payment-beneficiary-radio-button';
import { IndividualIcon, CompanyIcon } from './payment-beneficiary-radio-buttons.styles';

interface PaymentBeneficiaryRadioButtonsProps {
    name: string;
}

export const PaymentBeneficiaryRadioButtons = ({ name }: PaymentBeneficiaryRadioButtonsProps) => (
    <FormRow>
        <PaymentBeneficiaryRadioButton
            name={name}
            value={PaymentEntityType.company}
            label={LocalizationEnum.UserTypeLegacy}
        >
            <CompanyIcon />
        </PaymentBeneficiaryRadioButton>
        <PaymentBeneficiaryRadioButton
            name={name}
            value={PaymentEntityType.individual}
            label={LocalizationEnum.UserTypePrivate}
        >
            <IndividualIcon />
        </PaymentBeneficiaryRadioButton>
    </FormRow>
);
