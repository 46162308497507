import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { GetFee, Query } from '@shared-graphql';
import { DEBOUNCE_DELAY } from '@shared-util/constants';
import { isString, isTrue } from '@shared-util/is-data';

import { PaymentFormValuesInterface } from '@component/modal/modals/payment/payment-form/payment-form.interface';

import { paymentFeeInitials } from './payment-fees.options';

interface PaymentFeeInterface extends PaymentFormValuesInterface {
    bicSwift: string;
    shouldCheckPurpose: boolean;
}

export const usePaymentFee = ({ shouldCheckPurpose, fee, ...values }: PaymentFeeInterface) => {
    const [loadPaymentFee, { data, loading: isLoadingFee }] = useLazyQuery<Pick<Query, 'getFee'>>(GetFee);
    const {
        amount,
        currency,
        purpose,
        beneficiary: { iban, bicSwift, bankCountry, bankName },
    } = useMemo(() => values, [values]);
    const isReadyToProcessing = useMemo(
        () =>
            [currency, bicSwift, iban, bankCountry, bankName, ...(isTrue(shouldCheckPurpose) ? [purpose] : [])].every(
                value => isString(value)
            ) && amount > 0,
        [iban, bankCountry, bankName, currency, bicSwift, amount, purpose, shouldCheckPurpose]
    );

    const debouncedLoadPaymentFee = useDebouncedCallback(vars => {
        loadPaymentFee(vars);
    }, DEBOUNCE_DELAY);

    useEffect(() => {
        if (isReadyToProcessing) {
            debouncedLoadPaymentFee({
                variables: {
                    payment: {
                        ...values,
                    },
                },
            });
        }
    }, [iban, bankCountry, bankName, currency, bicSwift, amount, purpose]);

    return {
        fee: {
            ...paymentFeeInitials,
            ...fee,
            ...data?.getFee,
        },
        isLoadingFee,
    };
};
