import { Form, Formik } from 'formik';

import { GoogleAuthSendFormFields } from '@shared-form/google-auth-send-form/fields/google-auth-send-form-fields';
import { GoogleAuthSendFormEnum } from '@shared-form/google-auth-send-form/google-auth-send-form.enum';
import { GoogleAuthSendFormInitial } from '@shared-form/google-auth-send-form/google-auth-send-form.initial';
import { GoogleAuthSendFormSchema } from '@shared-form/google-auth-send-form/google-auth-send-form.validation';
import { MutationUserGoogleAuthenticatorLoginArgs } from '@shared-graphql';
import { getClearText } from '@shared-util/remove-spaces';

import { GoogleAuthLoginStepInterface } from '@page/auth-pages/login/login-steps/google-auth-login-step/google-auth-login-step.interface';

export const GoogleAuthSendForm = ({ onSubmit, error }: Pick<GoogleAuthLoginStepInterface, 'onSubmit' | 'error'>) => {
    const handelGoogleAuthSendFormSubmit = ({
        totp,
    }: Pick<MutationUserGoogleAuthenticatorLoginArgs, GoogleAuthSendFormEnum.Totp>) => onSubmit(getClearText(totp));

    return (
        <Formik
            initialValues={GoogleAuthSendFormInitial}
            validationSchema={GoogleAuthSendFormSchema}
            validateOnChange={false}
            onSubmit={handelGoogleAuthSendFormSubmit}
        >
            <Form>
                <GoogleAuthSendFormFields name={GoogleAuthSendFormEnum.Totp} error={error} />
            </Form>
        </Formik>
    );
};
