import { Field, useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FC, JSX, ReactNode, useEffect } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { FieldErrorWrapper } from '@shared-component/field-error/field-error.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { OnEventType } from '../../types/on-event.type';
import { isString } from '../../utils/is-data';
import { noop } from '../../utils/noop';
import { FieldError } from '../field-error/field-error';
import { CheckboxEnum } from './checkbox.enum';
import {
    Check,
    CheckBoxWrapper,
    CheckIconWrapper,
    CheckTitle,
    CheckBoxStyle,
    CheckBoxContainer,
} from './checkbox.styles';

export interface CheckBoxProps {
    name: string;
    title?: string | LocalizationEnum;
    disabled?: boolean;
    onChange?: OnEventType<boolean>;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const CheckBox: FC<CheckBoxProps> = observer(
    ({ name, title, disabled = false, onChange = noop, children, ...props }) => {
        const [field] = useField(name);
        const isChecked = Boolean(field.value);
        const shouldRenderTitle = isString(title);

        useEffect(() => void onChange(field.value), [field.value, onChange]);

        return (
            <CheckBoxWrapper>
                <CheckBoxContainer data-testid={CheckboxEnum.CheckBox}>
                    <CheckBoxStyle isDisabled={disabled}>
                        <Field type="checkbox" name={name} disabled={disabled} hidden />
                        <CheckIconWrapper isChecked={isChecked} isDisabled={disabled} {...props}>
                            {isChecked && <Check />}
                        </CheckIconWrapper>
                        {shouldRenderTitle && (
                            <CheckTitle isExist={children !== null}>
                                <Translate langKey={title as LocalizationEnum} />
                            </CheckTitle>
                        )}
                    </CheckBoxStyle>
                    {children}
                </CheckBoxContainer>
                <FieldErrorWrapper>
                    <FieldError name={name} />
                </FieldErrorWrapper>
            </CheckBoxWrapper>
        );
    }
);
