import styled, { css } from 'styled-components';

import { DarkGrey, Mystic, PrimeBlue, White } from '../../styles/colors';
import { Flex } from '../../styles/flex';
import { MAX_MOBILE_SIZE } from '../../styles/media-sizes';

interface RadioButtonProps {
    isChecked: boolean;
}

const RadioButtonChecked = css`
    box-shadow: 0 0 0 2px ${PrimeBlue};
    background-color: ${PrimeBlue};
    border: 4px solid ${White};
`;

const RadioButtonNotChecked = css`
    box-shadow: 0 0 0 2px ${Mystic};
    background-color: inherit;
    border: none;
`;

export const RadioButtonWrapper = styled.label`
    cursor: pointer;
    width: calc(50% - 4px);
    margin-right: 8px;
    ${Flex.InlineFlex.Row.Style};
    :last-child {
        margin-right: 0;
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;
        :last-child {
            margin-bottom: 0;
        }
    }
`;

export const RadioButtonIconWrapper = styled.div<RadioButtonProps>`
    width: 22px;
    height: 22px;
    border-radius: 16px;
    cursor: pointer;
    ${({ isChecked }) => (isChecked ? RadioButtonChecked : RadioButtonNotChecked)};

    ${Flex.VerticalCenter.HorizontalCenter.Style}
    :hover {
        box-shadow: ${({ isChecked }) => !isChecked && `0 0 0 2px ${DarkGrey}`};
    }
`;
