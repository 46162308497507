import { rgba } from 'polished';
import styled from 'styled-components';

import { Neutral100, SelectiveYellow } from '../../../styles/colors';
import { Flex } from '../../../styles/flex';
import { Typography } from '../../typography/typography';

interface CustomCountryItemProps {
    isSelected?: boolean;
}

export const CustomCountryItemWrapper = styled.div<CustomCountryItemProps>`
    height: 52px;
    width: ${({ isSelected = false }) => (isSelected ? 'calc(100% - 40px)' : '100%')};
    ${Flex.Row.JustifyLeft.VerticalCenter.Style}

    :hover {
        background-color: ${({ isSelected = false }) => (isSelected ? 'transparent' : Neutral100)};
    }

    :active {
        background-color: ${rgba(SelectiveYellow, 0.1)};
    }
    ${({ isSelected = false }) =>
        isSelected &&
        `
        img{
            position: relative;
            top: unset;
            left: unset;
            margin-left: 4px;
        }
    `}
`;

export const CustomCountryTitle = styled.span<CustomCountryItemProps>`
    margin-left: ${({ isSelected = false }) => (isSelected ? '16px' : '56px')};
    ${Typography.Paragraph14.SemiBold.Neutral900.Style};
`;
