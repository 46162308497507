import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { DarkGrey } from '../../styles/colors';
import Info from './assets/info.svg?react';

export const Icon = styled(Info)`
    fill: ${DarkGrey};
`;

export const TooltipWrapper = styled(ReactTooltip)`
    white-space: pre-wrap;
    box-shadow: 0 0 8px 0 rgba(67, 86, 100, 0.12);
    font-size: 14px;
`;

export const TooltipText = styled.span``;
