import { useFormikContext } from 'formik';
import React, { FC, JSX, ReactNode } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { LabelError } from '@shared-component/field-error/field-error.styles';
import { Scroll } from '@shared-component/scroll/scroll';
import { Fee } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { InformationCopyRow } from '@component/modal/modals/common/information-copy-row/information-copy-row';
import { PaymentToCardFormValuesInterface } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form.interface';

import { BlockTitle, BlockWrapper, PaymentConfirmationWrapper } from './payment-confirmation.style';

interface PaymentToCardConfirmationProps {
    fee: Fee;
    isInsufficientFunds: boolean;
    balanceAfterTransaction: number;
    currency: string;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const PaymentToCardConfirmation: FC<PaymentToCardConfirmationProps> = ({
    currency,
    isInsufficientFunds,
    fee: { amount: feeAmount },
    balanceAfterTransaction,
    children,
}) => {
    const {
        values: { amount, cardNumber, receiverName, receiverSurname },
    } = useFormikContext<PaymentToCardFormValuesInterface>();

    const beneficiaryName = `${receiverName} ${receiverSurname}`;

    const amountData = useCurrencyFormat(amount, currency);
    const feeAmountData = useCurrencyFormat(feeAmount, currency);
    const balanceAfterTransactionData = useCurrencyFormat(balanceAfterTransaction, currency);

    const insufficientFundsErrorText = useLocalizationText(LocalizationEnum.CommonInsufficientFunds);
    const expectationDepositTimeText = useLocalizationText(LocalizationEnum.CommonInstant);

    return (
        <>
            <PaymentConfirmationWrapper>
                <Scroll>
                    <BlockWrapper>
                        <BlockTitle>
                            <Translate langKey={LocalizationEnum.CommonDetails} />
                        </BlockTitle>
                        <InformationCopyRow
                            title={LocalizationEnum.CommonSendTo}
                            data={beneficiaryName}
                            isCopied={false}
                        />
                        <InformationCopyRow
                            title={LocalizationEnum.CommonCardNumber}
                            data={cardNumber.replaceAll('-', '').replace(/^.{12}/g, '••••••••••• ')}
                            isCopied={false}
                        />
                    </BlockWrapper>
                    <BlockWrapper>
                        <InformationCopyRow title={LocalizationEnum.CommonAmount} data={amountData} isCopied={false} />
                        <InformationCopyRow title={LocalizationEnum.CommonFee} data={feeAmountData} isCopied={false} />
                    </BlockWrapper>
                    <BlockWrapper>
                        <InformationCopyRow
                            title={LocalizationEnum.ExpectationDepositTime}
                            data={expectationDepositTimeText}
                            isCopied={false}
                        />
                        <InformationCopyRow
                            title={LocalizationEnum.BalanceAfterTransaction}
                            data={balanceAfterTransactionData}
                            isCopied={false}
                        />
                        {isInsufficientFunds && <LabelError>{insufficientFundsErrorText}</LabelError>}
                    </BlockWrapper>
                </Scroll>
            </PaymentConfirmationWrapper>
            {children}
        </>
    );
};
