import { Form, useField } from 'formik';
import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Notification } from '@shared-atom/elpaso-kit/notification';
import { NotificationSizeEnum, NotificationTypeEnum } from '@shared-atom/elpaso-kit/notification/types';
import { ModelContext } from '@shared-component/modal/modal.context';
import { Account } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { initialCurrencyModel } from '@shared-model/currency-model/currency-model.initials';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { PaymentBreadcrumbs } from '@component/modal/modals/payment/payment-breadcrumbs/payment-breadcrumbs';
import { PAYMENT_TO_CARD_BREADCRUMBS } from '@component/modal/modals/payment/payment-breadcrumbs/payment-breadcrumbs.options';
import { usePaymentFee } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-fees-hook/payment-fees.hook';
import { PaymentToCardDetailsForm } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-details-form/payment-to-card-details-form';
import { PaymentToCardDetailsFormEnum } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-details-form/payment-to-card-details-form.enum';
import { useCardAvailableHook } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-details-form/payment-to-card-details-form.hook';
import { PaymentUpdateStepsEnum } from '@component/modal/modals/payment/payment-update-steps/payment-update-hook.enum';
import { PaymentUpdateSteps } from '@component/modal/modals/payment/payment-update-steps/payment-update-steps';
import {
    ButtonsWrapper,
    PaymentErrorWrapper,
    PaymentFormStepsWrapper,
} from '@component/modal/modals/payment/payment.modal-style';
import { useActiveAccounts } from '@hook/active-accounts/active-accounts.hook';

interface PaymentToCardFormStepsProps {
    activeStep: number;
    isLoading: boolean;
    isSignMode: boolean;
    accounts: Account[];
    signPaymentError?: string;
    setSignPaymentError: Dispatch<SetStateAction<string>>;
    paymentUpdateStep: PaymentUpdateStepsEnum;
    onPrevStep: OnEventEmptyType;
    onStep: OnEventType<number>;
    onOtpSubmit: OnEventType<string>;
    onPaymentSign: OnEventEmptyType;
    setModalTitle: Dispatch<SetStateAction<LocalizationEnum>>;
    updateValidationSchema: any;
    isPaymentError: boolean;
    onSendOtpCode: OnEventEmptyType;
}

export const PaymentToCardFormSteps = ({
    onStep,
    accounts,
    isSignMode,
    activeStep,
    onPaymentSign,
    signPaymentError,
    setSignPaymentError,
    paymentUpdateStep,
    onPrevStep,
    isLoading,
    onOtpSubmit,
    setModalTitle,
    updateValidationSchema,
    isPaymentError,
    onSendOtpCode,
}: PaymentToCardFormStepsProps) => {
    const [{ value: paymentAccountId }] = useField(PaymentToCardDetailsFormEnum.PaymentCurrency);
    const { setOnBack, onClose } = useContext(ModelContext);
    const canCreate = useActiveAccounts();

    const selectedAccount = useMemo(
        () => accounts.find(account => account.accountId === paymentAccountId),
        [paymentAccountId, accounts]
    );

    const { fee, isLoadingFee } = usePaymentFee();
    const { isNeedToAskRegion, isLoading: isCardAllowedLoading } = useCardAvailableHook(updateValidationSchema);

    const isFirstStepActive = activeStep === 0;
    const isLastStepActive = activeStep === 1;

    useEffect(() => {
        if (isLastStepActive) {
            setOnBack(() => onPrevStep);
        } else {
            setOnBack(() => onClose);
        }
    }, [activeStep]);

    return (
        <Form>
            <PaymentFormStepsWrapper>
                {paymentUpdateStep === PaymentUpdateStepsEnum.ConfirmPayment && (
                    <PaymentBreadcrumbs
                        breadcrumbs={PAYMENT_TO_CARD_BREADCRUMBS}
                        activeStep={activeStep}
                        onStep={onStep}
                    />
                )}
                {isFirstStepActive && (
                    <PaymentToCardDetailsForm
                        fee={fee}
                        balance={selectedAccount?.balance !== undefined ? selectedAccount.balance : 0}
                        currency={selectedAccount?.currency || initialCurrencyModel}
                        isNeedToAskRegion={isNeedToAskRegion}
                    />
                )}
                {isLastStepActive && (
                    <PaymentUpdateSteps
                        currency={selectedAccount?.currency.code as string}
                        fee={fee}
                        selectedAccount={selectedAccount}
                        signPaymentError={signPaymentError}
                        setSignPaymentError={setSignPaymentError}
                        step={paymentUpdateStep}
                        onPrevStep={onPrevStep}
                        onOtpSubmit={onOtpSubmit}
                        onPaymentSign={onPaymentSign}
                        isSignMode={isSignMode}
                        isLoading={isLoading || isLoadingFee}
                        canSign={canCreate}
                        setModalTitle={setModalTitle}
                        isPaymentError={isPaymentError}
                        onSendOtpCode={onSendOtpCode}
                    />
                )}
                {!canCreate && (
                    <PaymentErrorWrapper>
                        <Notification
                            text={LocalizationEnum.PaymentFormError}
                            size={NotificationSizeEnum.L}
                            type={NotificationTypeEnum.ACCENT}
                        />
                    </PaymentErrorWrapper>
                )}
            </PaymentFormStepsWrapper>
            <ButtonsWrapper isDisable={isLastStepActive || isPaymentError}>
                {!isLastStepActive && (
                    <Button
                        type={ButtonTypeEnum.COLOR}
                        size={ButtonSizeEnum.L}
                        title={LocalizationEnum.CommonNext}
                        isSubmit
                        isDisabled={!canCreate}
                        isLoading={isLoading || isLoadingFee || isCardAllowedLoading}
                    />
                )}
            </ButtonsWrapper>
        </Form>
    );
};
