import { applySnapshot, types } from 'mobx-state-tree';

import { CompanyBiggestClient, Maybe } from '@shared-graphql';

export const CompanyBiggestClientType = types
    .model('CompanyBiggestClientType', {
        id: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        website: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        subjectOfBusiness: types.maybeNull(types.string),
    })
    .actions(self => ({
        applyCompanyBiggestClient: (biggestClient: Maybe<CompanyBiggestClient>) => {
            applySnapshot(self, biggestClient ?? {});
        },
    }));
