import { useContext } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { PageTitle } from '@shared-atom/page-title/page-title';
import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { TooltipDataForEnum } from '@shared-atom/tooltip/tooltip-data-for.enum';
import { Translate } from '@shared-atom/translate/translate';
import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { HeaderWrapper, Title } from '@page/workspace/common/common.styles';
import { useUserSelector } from '@selector/user/use-user.selector';

export const IsPermissions = (funcName: string) => {
    const [{ companyPermissions }] = useUserSelector();
    const arrayPermissions = [...companyPermissions.permissions];
    return !arrayPermissions.includes(funcName);
};

export const AddressBookHeader = () => {
    const { onOpen } = useContext(ModelContext);

    const handleOpenModalAddPartner = () => onOpen(ModalEnum.CreatePartnerModal);
    const isDisabledBtn = IsPermissions('addressBookUpdate');

    return (
        <HeaderWrapper>
            <PageTitle title={LocalizationEnum.AddressBookTitle} />
            <Title>
                <Translate langKey={LocalizationEnum.AddressBookTitle} />
            </Title>
            <Tooltip
                dataFor={TooltipDataForEnum.AddressBook}
                content={LocalizationEnum.YouDoNotHavePermissionPleaseContactOurSupportTeam}
                isShow={isDisabledBtn}
            >
                <ButtonPrimary isLarge onClick={handleOpenModalAddPartner} isDisabled={isDisabledBtn}>
                    {/* true for role C */}
                    <Translate langKey={LocalizationEnum.AddressBookPageButtonTitle} />
                </ButtonPrimary>
            </Tooltip>
        </HeaderWrapper>
    );
};
