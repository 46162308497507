import { FC, useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import CopyIcon from '@shared-asset/vector/copy.svg?react';
import { Translate } from '@shared-atom/translate/translate';
import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isString } from '@shared-util/is-data';

import {
    InformationRowData,
    InformationRowTitle,
    InformationRowDataWrapper,
    InformationRowWrapper,
} from '@component/modal/modals/common/information-copy-row/information-copy-row.styles';

interface InformationRowProps {
    data?: string;
    isCopied?: boolean;
    title: LocalizationEnum | string;
}

export const InformationCopyRow: FC<InformationRowProps> = ({ title, data = '', isCopied = true }) => {
    const shouldRenderData = isString(data);
    const { onOpen } = useContext(InformationModalContext);

    const onCopy = () => {
        onOpen(InformationModalEnum.CopyInformationModal);
    };

    return (
        <InformationRowWrapper>
            <InformationRowTitle>
                <Translate langKey={title} />
            </InformationRowTitle>
            {isCopied && shouldRenderData ? (
                <CopyToClipboard text={data} onCopy={onCopy}>
                    <InformationRowDataWrapper isCopied={isCopied}>
                        <CopyIcon />
                        <InformationRowData>{data}</InformationRowData>
                    </InformationRowDataWrapper>
                </CopyToClipboard>
            ) : (
                shouldRenderData && (
                    <InformationRowDataWrapper isCopied={isCopied}>
                        {isCopied && <CopyIcon />}
                        <InformationRowData>{data}</InformationRowData>
                    </InformationRowDataWrapper>
                )
            )}
        </InformationRowWrapper>
    );
};
