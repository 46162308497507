import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { CommissionType, GetFee, Query } from '@shared-graphql';
import { DEBOUNCE_DELAY } from '@shared-util/constants';

import { PaymentToCardFormValuesInterface } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form.interface';

import { paymentFeeInitials } from './payment-fees.options';

export const usePaymentFee = () => {
    const { values } = useFormikContext<PaymentToCardFormValuesInterface>();

    const { amount = 0, currency } = values;
    const [loadPaymentFee, { data: loadPaymentFeeData, loading: isLoadingFee }] =
        useLazyQuery<Pick<Query, 'getFee'>>(GetFee);
    const hasPaymentFee = amount > 0;

    const debouncedLoadPaymentFee = useDebouncedCallback(data => {
        loadPaymentFee(data);
    }, DEBOUNCE_DELAY);

    useEffect(
        () =>
            void (
                hasPaymentFee &&
                debouncedLoadPaymentFee({
                    variables: {
                        payment: {
                            amount,
                            type: CommissionType.CardWithdrawal,
                            // operation: OperationType.Debit,
                            currency,
                        },
                    },
                })
            ),
        [amount, currency, hasPaymentFee, debouncedLoadPaymentFee]
    );

    return {
        fee: {
            ...paymentFeeInitials,
            ...loadPaymentFeeData?.getFee,
        },
        isLoadingFee,
    };
};
