import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useCreatePartnerFormLocalization = () => {
    const beneficiaryTitle = useLocalizationText(LocalizationEnum.PaymentFormBeneficiaryStepBeneficiaryTypeBlockTitle);
    const currencyTitle = useLocalizationText(LocalizationEnum.PaymentFormCurrencyAmountStepCurrency);
    const swiftTitle = useLocalizationText(LocalizationEnum.CreatePartnerFormBic);
    const submitTitle = useLocalizationText(LocalizationEnum.CommonConfirm);
    const bankNameTitle = useLocalizationText(LocalizationEnum.PaymentFormCurrencyAmountStepBankName);

    return {
        beneficiaryTitle,
        currencyTitle,
        swiftTitle,
        submitTitle,
        bankNameTitle,
    };
};
