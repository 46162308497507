import { object } from 'yup';

import { emailValidation, fieldRequiredValidation } from '@shared-util/validation';

import { ApplyVacancyFormEnum } from '@component/modal/modals/apply-vacancy/apply-vacancy.enum';

export const applyVacancyFormValidationSchema = object().shape({
    [ApplyVacancyFormEnum.Name]: fieldRequiredValidation(),
    [ApplyVacancyFormEnum.Email]: emailValidation(),
});
