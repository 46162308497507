import { object } from 'yup';

import { phoneValidation } from '@shared-util/validation';

export enum RegistrationPhoneFormEnum {
    Phone = 'phone',
    Token = 'token',
}

export type RegistrationPhoneType = {
    [RegistrationPhoneFormEnum.Phone]: string;
    [RegistrationPhoneFormEnum.Token]: string;
};

export const RegistrationPhoneSchema = object().shape({
    [RegistrationPhoneFormEnum.Phone]: phoneValidation(),
});
