import useEvent from '@react-hook/event';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, createSearchParams, useSearchParams } from 'react-router-dom';

import { PageEnum } from '@enum/page.enum';

import { OnEventEmptyType } from '../../types/on-event.type';

export const useCurrentNavigation = (): {
    name: PageEnum;
    params: Record<string, string>;
    searchParams: Record<string, string>;
} => {
    const { pathname } = useLocation();
    const params = useParams();

    const [urlSearchParams] = useSearchParams();

    const searchParams = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const entry of urlSearchParams.entries()) {
        searchParams.push(entry);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return { name: pathname, params, searchParams: Object.fromEntries(searchParams) };
};

export const useRouterNavigate = () => {
    const navigate = useNavigate();

    return (name: PageEnum, options: any = {}) => {
        navigate({
            pathname: name,
            search: createSearchParams(options).toString(),
        });
    };
};

const getWindowHash = () => window.location.hash.replace('#', '');

export const useHashNavigation = <T extends string | null>(): [T, OnEventEmptyType] => {
    const [hash, setHash] = useState<string | null>(null);

    useEvent(window, 'hashchange', () => setHash(getWindowHash()));

    useEffect(() => setHash(getWindowHash()), []);

    const handleClearHash = () => {
        window.history.pushState('', document.title, window.location.pathname);
        setHash(null);
    };

    return [hash as T, handleClearHash];
};
