import { Account, Currency } from '@shared-graphql';
import { isExist } from '@shared-util/is-data';

import { PaymentToSendsFormEnum } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.enum';
import { PaymentToSendsFormInitial } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.initial';
import { PaymentToSendsFormInterface } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.interface';

export const mapPaymentDataToInitialValues = (data: PaymentToSendsFormInterface, selectedAccount: Account) => {
    const baseInitialValues = {
        ...PaymentToSendsFormInitial,
        [PaymentToSendsFormEnum.Currency]:
            (data?.currency as unknown as Currency)?.code ?? selectedAccount.currency.code,
        [PaymentToSendsFormEnum.AccountId]: data?.accountId ?? selectedAccount.id,
    };

    if (isExist(data)) {
        return {
            ...baseInitialValues,
            [PaymentToSendsFormEnum.Amount]: data.amount,
            [PaymentToSendsFormEnum.AttachedFiles]: data.attachedFiles,
            [PaymentToSendsFormEnum.Beneficiary]: data.beneficiary,
            [PaymentToSendsFormEnum.EmailOrIban]: data.beneficiary?.iban ?? '',
            [PaymentToSendsFormEnum.CallbackUrl]: data.callbackUrl,
            [PaymentToSendsFormEnum.DocumentIds]: data.documentIds,
            [PaymentToSendsFormEnum.Purpose]: data.purpose,
            [PaymentToSendsFormEnum.Reason]: data.reason,
            [PaymentToSendsFormEnum.Type]: data.type,
            [PaymentToSendsFormEnum.Id]: data.id,
        };
    }
    return baseInitialValues;
};
