import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { InformationModalContextProvider } from '@shared-component/information-modal/information-modal.provider';
import { ModelContextProvider } from '@shared-component/modal/modal.provider';

import { ApplicationContextProvider } from '@application/application.provider';
import { ClientInformationModal } from '@component/informaton-modal/client-information-modal';
import { ClientModal } from '@component/modal/client-modal';
import { ThemeProvider } from '@component/theme-provider/theme-provider';
import CookieConsent from '@page/main-pages/main-page-common/cookie-info/CookieConsent';
import { PageEvent } from '@page/page-event';

const WithProviders = memo(() => (
    <ApplicationContextProvider>
        <ThemeProvider>
            <InformationModalContextProvider>
                <ModelContextProvider>
                    <Outlet />
                    <ClientModal />
                    <ClientInformationModal />
                    <PageEvent />
                    <CookieConsent />
                </ModelContextProvider>
            </InformationModalContextProvider>
        </ThemeProvider>
    </ApplicationContextProvider>
));

export default WithProviders;
