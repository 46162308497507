import React from 'react';
import './formrow.css';

// eslint-disable-next-line arrow-body-style
const FormRow = props => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, react/destructuring-assignment
    if (props.splited)
        return (
            // eslint-disable-next-line @typescript-eslint/indent, react/jsx-indent, react/destructuring-assignment
            <div className="elform-row splited">{props.children}</div>
            // eslint-disable-next-line @typescript-eslint/indent
        );
    return (
        // eslint-disable-next-line @typescript-eslint/indent, react/jsx-indent, react/destructuring-assignment
        <div className="elform-row">{props.children}</div>
        // eslint-disable-next-line @typescript-eslint/indent
    );
};
export default FormRow;
