import styled from 'styled-components';

import { Typography } from '../../../atoms/typography/typography';
import { NileBlue, White } from '../../../styles/colors';
import { Flex } from '../../../styles/flex';
import { ThemeTypeInterface } from '../../../types/theme-props.type';

export const FormWrapper = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const FormRow = styled.div`
    width: 100%;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : NileBlue)};
    ${Flex.Row.JustifyLeft.Style};
    gap: 16px;
    :not(:first-child) {
        margin-top: 16px;
    }
    & > *:not(button) {
        flex: 1;
    }
`;

export const FormRowTitle = styled.p`
    width: 100%;
    padding-top: 24px;
    ${Typography.Paragraph16.SemiBold.Neutral900.Style};
`;

export const FormHalfRow = styled(FormRow)`
    @media (max-width: 768px) {
        grid-template-columns: unset;
    }
`;

export const FormButtonWrapper = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const FormButtonsRowWrapper = styled(FormButtonWrapper)`
    ${Flex.SpaceBetween.VerticalCenter.Style}
`;
