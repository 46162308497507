import { useFormikContext } from 'formik';
import React from 'react';

import { CustomInputPassword } from '@shared-component/input/input-password/custom-input-password';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { RegistrationPasswordFormEnum } from '@page/registration/registration-password/registration-password-form/registration-password-form.options';
import { InputWrapper } from '@page/registration/registration-password/registration-password-form/registration-password-form.styles';
import { ChangePasswordFormEnum } from '@page/workspace/settings/section/security/subsections/password/change-password-form/change-password-form.enum';

export const ChangePasswordFormFields = () => {
    const { setErrors } = useFormikContext();

    return (
        <>
            <CustomInputPassword
                id={ChangePasswordFormEnum.CurrentPassword}
                title={LocalizationEnum.CommonCurrentPassword}
                name={ChangePasswordFormEnum.CurrentPassword}
                onFocus={() => setErrors({})}
            />
            <CustomInputPassword
                name={ChangePasswordFormEnum.Password}
                title={LocalizationEnum.ChangePasswordFormNewPassword}
                hasTooltipValidation
            />
            <InputWrapper>
                <CustomInputPassword
                    name={RegistrationPasswordFormEnum.RepeatPassword}
                    title={LocalizationEnum.CommonConfirmPassword}
                />
            </InputWrapper>
        </>
    );
};
