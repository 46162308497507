/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Translate } from '../../../atoms/translate/translate';
import { File as FileUploadType } from '../../../graphql';
import { LocalizationEnum } from '../../../locale/localization.enum';
import { OnEventType } from '../../../types/on-event.type';
import { FileUpload } from '../file-upload';
import { DocumentFileUploadEnum } from './document-file-upload.enum';
import {
    DocumentUploadInfo,
    DocumentUploadTitle,
    GreenText,
    DocumentUploadWrapper,
    UploadIcon,
    UploadStatus,
    EmptyUpload,
    SelectedFile,
    DeleteIcon,
    ReplaceFileIcon,
    SelectedFileWrapper,
    ErrorWrapper,
    ErrorText,
    LoaderWrapper,
    LoaderText,
    CancelIcon,
} from './document-file-upload.styles';
import { Loader } from './loader';

export interface DocumentFileUploadProps {
    commentId?: string;
    onUploaded: OnEventType<FileUploadType>;
    uploadedFileInfo?: any;
    onDeleted?: any;
}

const uploadWrapper =
    (hasError: any, uploadedFileInfo = { key: null, filename: null }, onDeleted?: any) =>
        (isDrop: boolean, isLoading: boolean, selectedFile: FileUploadType | undefined, error: any, onCancel: any) => {
            const isShowError = Boolean(hasError) || Boolean(error);
            const isCanceled = Boolean(error) === true && error.message.includes('aborted');
            const [isFileUploaded, setValue] = useState(false);
            useEffect(() => {
                if (selectedFile !== undefined || Boolean(uploadedFileInfo?.key) === true) {
                    setValue(true);
                }
            }, [selectedFile, uploadedFileInfo]);
            const handleRemoveFiel = (event: any) => {
                event.preventDefault();
                if (onDeleted) {
                    onDeleted();
                }

                setValue(false);
            };
            const handleCancel = (event: any) => {
                event.preventDefault();
                setValue(false);
                onCancel();
            };
            const titleLocale =
            (isFileUploaded && LocalizationEnum.UploadDocumentSelectUploaded) ||
            (isLoading ? LocalizationEnum.UploadDocumentSelectUploading : LocalizationEnum.UploadDocumentSelectTitle);
            const dragAndDropLocale = LocalizationEnum.DragAndDropFile;
            return (
                <>
                    {!isFileUploaded && (
                        <DocumentUploadWrapper isDrop={isDrop} data-testId={DocumentFileUploadEnum.UploadFileForm}>
                            <UploadIcon />
                            <DocumentUploadTitle>
                                <GreenText>
                                    <Translate langKey={titleLocale} />
                                </GreenText>
                                {!isLoading && <Translate langKey={dragAndDropLocale} />}
                            </DocumentUploadTitle>
                            <DocumentUploadInfo>
                                <Translate langKey={LocalizationEnum.UploadDocumentSelectInfo} />
                            </DocumentUploadInfo>
                        </DocumentUploadWrapper>
                    )}
                    <UploadStatus>
                        {isLoading ? (
                            <LoaderWrapper>
                                <div style={{ display: 'flex' }}>
                                    <Loader />
                                    <LoaderText>
                                        <Translate langKey={LocalizationEnum.RegistrationCompanyStepSevenWait} />
                                    </LoaderText>
                                </div>
                                <CancelIcon onClick={handleCancel} />
                            </LoaderWrapper>
                        ) : isFileUploaded ? (
                            <SelectedFileWrapper>
                                <SelectedFile>{selectedFile?.filename || uploadedFileInfo?.filename}</SelectedFile>
                                <DeleteIcon onClick={handleRemoveFiel} />
                            </SelectedFileWrapper>
                        ) : Boolean(isShowError) === true && Boolean(isCanceled) === false ? (
                            <ErrorWrapper>
                                <ErrorText>Error uploading</ErrorText>
                                <ReplaceFileIcon />
                            </ErrorWrapper>
                        ) : (
                            <EmptyUpload>
                                <Translate langKey={LocalizationEnum.RegistrationCompanyStepSevenEmptyUpload} />
                            </EmptyUpload>
                        )}
                    </UploadStatus>
                </>
            );
        };

export const DocumentFileUpload = ({ onUploaded, commentId, uploadedFileInfo, onDeleted }: DocumentFileUploadProps) => {
    const [hasError, setError] = useState(false);

    const handleError = (isError: boolean) => setError(isError);

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <FileUpload onUploaded={onUploaded} commentId={commentId} onError={handleError}>
                    {uploadWrapper(hasError, uploadedFileInfo, onDeleted)}
                </FileUpload>
            </DndProvider>
            {/* hasError && (
                <DocumentErrorText>
                    <Translate langKey={LocalizationEnum.CommonFileUploadError} />
                </DocumentErrorText>
            ) */}
        </>
    );
};
