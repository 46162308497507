import { routes } from '@router/routes';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { RouterProvider } from 'react-router-dom';

import { RootWrapper } from '@application/application.styles';

export const MainApplication = observer(() => (
    <RootWrapper>
        <RouterProvider router={routes} />
    </RootWrapper>
));
