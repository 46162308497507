import styled from 'styled-components';

import { Dark } from '@shared-style/colors';

import { FieldErrorWrapper } from '../../field-error/field-error.styles';
import { MaskedInputStyle } from '../input.styles';

export interface CustomPhoneInputWrapperProps {
    isFocused: boolean;
}
export const PhoneMaskedStyle = styled(MaskedInputStyle)`
    border: none;
    flex: 1;
    font-size: 16px;
    color: ${Dark};
    padding: 0 0 0 56px;
    margin-top: 2px;
    background-color: transparent;
`;

export const PhoneWrapper = styled.div<CustomPhoneInputWrapperProps>`
    position: relative;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    :hover {
        background: #f1fffc;
    }
    ${({ isFocused }) => isFocused === true && `border: 1px solid #00997A`}
`;

export const ErrorWrapper = styled(FieldErrorWrapper)`
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
`;
export const LabelPhoneWrapper = styled.label`
    display: inline-flex;
    position: relative;
    width: 100%;
    background-color: inherit;
    border-radius: 8px;
    height: 56px;
`;
