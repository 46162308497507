import { ApolloError, useMutation } from '@apollo/client';

import { Maybe, Mutation, MutationUserLoginArgs, LoginUser } from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';

type UserMutationType = Pick<Mutation, 'userLogin'>;

export const useUserLogin = (): [
    Maybe<UserMutationType> | undefined,
    ApolloError | undefined,
    boolean,
    OnEventType<MutationUserLoginArgs>
] => {
    const [loginUser, { data, error, loading: isLoading }] = useMutation<UserMutationType>(LoginUser);

    const handleLogin = (variables: MutationUserLoginArgs) => loginUser({ variables });

    return [data, error, isLoading, handleLogin];
};
