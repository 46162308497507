import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const AccountDetailsCardView = styled.div`
    width: 100%;
    flex: 1;
`;
export const AccountDetailsCardText = styled.p`
    ${Typography.Paragraph14.Medium.Neutral900.Style};
    padding: 16px 0;
`;
