import { ApolloError, useMutation } from '@apollo/client';

import {
    Maybe,
    Mutation,
    UserGoogleAuthenticatorLogin,
    UserGoogleAuthenticatorLoginMutationVariables,
} from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';

type UserGoogleAuthenticatorLoginMutationType = Pick<Mutation, 'userGoogleAuthenticatorLogin'>;

export const useUserGoogleAuthLogin = (): [
    Maybe<UserGoogleAuthenticatorLoginMutationType> | undefined,
    ApolloError | undefined,
    boolean,
    OnEventType<UserGoogleAuthenticatorLoginMutationVariables>
] => {
    const [userGoogleAuthenticatorLogin, { data, loading: isLoading, error }] =
        useMutation<UserGoogleAuthenticatorLoginMutationType>(UserGoogleAuthenticatorLogin);

    const handleGoogleAuthenticatorLogin = (variables: UserGoogleAuthenticatorLoginMutationVariables) =>
        userGoogleAuthenticatorLogin({ variables });

    return [data, error, isLoading, handleGoogleAuthenticatorLogin];
};
