import { LocalizationEnum } from '@shared-locale/localization.enum';

export enum PaymentStepsEnum {
    AmountCurrency = 'AmountCurrency',
    PaymentDetails = 'PaymentDetails',
    PaymentConfirmation = 'PaymentConfirmation',
}

export interface PaymentBreadcrumbInterface {
    title: LocalizationEnum;
    value: PaymentStepsEnum;
}

export const PAYMENT_BREADCRUMBS: PaymentBreadcrumbInterface[] = [
    { title: LocalizationEnum.PaymentFormDetailsStepTitle, value: PaymentStepsEnum.AmountCurrency },
    { title: LocalizationEnum.CommonRecipientInfo, value: PaymentStepsEnum.PaymentDetails },
];

export const PAYMENT_TO_CARD_BREADCRUMBS: PaymentBreadcrumbInterface[] = [
    { title: LocalizationEnum.PaymentFormCurrencyAmountStepTitle, value: PaymentStepsEnum.PaymentDetails },
    { title: LocalizationEnum.PaymentFormDetailsStepTitle, value: PaymentStepsEnum.PaymentConfirmation },
];
