import { observer } from 'mobx-react-lite';
import React from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import { UserSession } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import {
    ModalContentWrapper,
    ModalSubtitle,
    ModalHeader,
    ModalTitle,
    ModalWrapper,
} from '@component/modal/client-modal.styles';
import { useTerminateSessionsModal } from '@component/modal/modals/terminate-sessions/terminate-sessions.hook';
import {
    ButtonWrapper,
    ModalContent,
    SessionsListScrolled,
} from '@component/modal/modals/terminate-sessions/terminate-sessions.style';
import { Session } from '@page/workspace/settings/section/security/subsections/sessions/session/session';

export const TerminateSessionsModal = observer(() => {
    const { onTerminateSessions, isLoading, sessions } = useTerminateSessionsModal();

    return (
        <ModalWrapper>
            <ModalHeader>
                <ModalTitle>
                    <Translate langKey={LocalizationEnum.SessionsTermination} />
                </ModalTitle>
            </ModalHeader>
            <ModalContentWrapper>
                <ModalContent>
                    <ModalSubtitle>
                        <Translate langKey={LocalizationEnum.DoYouWantToTerminateAllOtherSessionsExceptTheActiveOne} />
                    </ModalSubtitle>
                    <SessionsListScrolled>
                        {(sessions as UserSession[]).map(session => (
                            <Session key={session.id} {...session} />
                        ))}
                    </SessionsListScrolled>
                    <ButtonWrapper>
                        <Button
                            title={LocalizationEnum.TerminateAllOtherSessions}
                            size={ButtonSizeEnum.M}
                            type={ButtonTypeEnum.DANGER}
                            onClick={onTerminateSessions}
                            isLoading={isLoading}
                        />
                    </ButtonWrapper>
                </ModalContent>
            </ModalContentWrapper>
        </ModalWrapper>
    );
});
