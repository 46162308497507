import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { Account } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PAYMENT_TO_CARD_BREADCRUMBS } from '@component/modal/modals/payment/payment-breadcrumbs/payment-breadcrumbs.options';
import { PaymentToCardFormSteps } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-form-steps';
import { usePaymentToCardFormSteps } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form.hook';
import { PaymentToCardFormValuesInterface } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form.interface';
import { useFormValidation } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form.validation';

interface PaymentFormStepsProps {
    data?: PaymentToCardFormValuesInterface;
    accounts: Account[];
    isSignMode: boolean;
    setModalTitle: Dispatch<SetStateAction<LocalizationEnum>>;
}

export const PaymentToCardForm = observer(({ data, accounts, isSignMode, setModalTitle }: PaymentFormStepsProps) => {
    const {
        initialValues,
        activeStep,
        paymentUpdateStep,
        signPaymentError,
        setSignPaymentError,
        onPrevStep,
        onStep,
        onSubmit,
        onOtpSubmit,
        onPaymentSign,
        isLoading,
        isPaymentError,
        onSendOtpCode,
    } = usePaymentToCardFormSteps(accounts, isSignMode, data);
    const [validationSchema, updateValidationSchema] = useFormValidation();
    useEffect(() => void (isSignMode && onStep(PAYMENT_TO_CARD_BREADCRUMBS.length - 1)), []);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
        >
            <PaymentToCardFormSteps
                onStep={onStep}
                accounts={accounts}
                isSignMode={isSignMode}
                activeStep={activeStep}
                onPaymentSign={onPaymentSign}
                signPaymentError={signPaymentError}
                setSignPaymentError={setSignPaymentError}
                paymentUpdateStep={paymentUpdateStep}
                onPrevStep={onPrevStep}
                isLoading={isLoading}
                onOtpSubmit={onOtpSubmit}
                setModalTitle={setModalTitle}
                updateValidationSchema={updateValidationSchema}
                isPaymentError={isPaymentError}
                onSendOtpCode={onSendOtpCode}
            />
        </Formik>
    );
});
