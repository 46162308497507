import { useContext, useEffect } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonSecondary } from '@shared-atom/button/button-secondary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { ModelContext } from '@shared-component/modal/modal.context';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { useAddressBookActionsSelector } from '@selector/address-book/use-address-book.actions-selector';

import {
    ConfirmationWrapper,
    ContentWrapper,
    DescriptionWrapper,
    ButtonsContainer,
    ButtonWrapper,
} from './delete-partner-confirmation.styles';

export const DeletePartnerConfirmation = () => {
    const { onClose } = useContext(ModelContext);
    const { deleteAddressBook, setSelectedItem } = useAddressBookActionsSelector();

    useEffect(() => () => setSelectedItem(null), []);

    const handleDelete = () => {
        deleteAddressBook();
        onClose();
    };

    return (
        <ConfirmationWrapper>
            <ContentWrapper>
                <DescriptionWrapper>Are you sure you want to delete the partner?</DescriptionWrapper>
                <ButtonsContainer>
                    <ButtonPrimary type={ButtonTypeEnum.Button} onClick={handleDelete}>
                        <Translate langKey={LocalizationEnum.CommonConfirm} />
                    </ButtonPrimary>
                    <ButtonWrapper>
                        <ButtonSecondary type={ButtonTypeEnum.Button} onClick={onClose}>
                            <Translate langKey={LocalizationEnum.CommonCancel} />
                        </ButtonSecondary>
                    </ButtonWrapper>
                </ButtonsContainer>
            </ContentWrapper>
        </ConfirmationWrapper>
    );
};
