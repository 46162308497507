import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

import { SubsectionInfoWrapper } from '@page/workspace/settings/settings.styles';

export const SessionSubsectionInfoWrapper = styled(SubsectionInfoWrapper)`
    gap: 36px;
`;
export const SessionTypeWrapper = styled.div`
    ${Flex.Column.Style};
`;
export const SessionTypeTitle = styled.h4`
    ${Typography.Paragraph14.Neutral900.SemiBold.Style};
    margin-bottom: 16px;
`;
export const SessionsList = styled.div`
    ${Flex.Column.Style};
    gap: 24px;
`;
export const TerminateButtonWrapper = styled.div`
    margin-bottom: 20px;
`;
