import { countries, ICountry } from 'countries-list';
import { sort } from 'fast-sort';

import { CustomCountryCodeType } from './custom-country-select.type';

type CustomCountryListType = [CustomCountryCodeType | string, ICountry];

const countryListMap = ([code, country]: CustomCountryListType) => ({
    value: code,
    label: country.name,
});

export const customCountryList = sort(Object.entries(countries).map(countryListMap)).asc(item => item.label);
