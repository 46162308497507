import { Form, Formik } from 'formik';
import React from 'react';

import { SelectAccount } from '@shared-atom/elpaso-kit/select-account/select-account';
import { Scroll } from '@shared-component/scroll/scroll';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { noop } from '@shared-util/noop';

import { PaymentFlowSelectorOptions } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector-options/payment-flow-selector-options';
import { PaymentFlowSelectorFormEnum } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector.enum';
import { usePaymentFlowSelector } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector.hook';
import { PaymentFlowSelectorProps } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector.interface';
import { PaymentFlowSelectorContainer } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector.styles';

export const PaymentFlowSelector = ({
    accounts,
    setCurrentPaymentFlow,
    setSelectedAccount,
}: PaymentFlowSelectorProps) => {
    const { paymentFlowSelectorFormInitial, accountOptions } = usePaymentFlowSelector({ accounts });

    return (
        <PaymentFlowSelectorContainer>
            <Scroll>
                <Formik onSubmit={noop} initialValues={paymentFlowSelectorFormInitial} enableReinitialize>
                    <Form>
                        <SelectAccount
                            title={LocalizationEnum.CommonSelectAccount}
                            label={LocalizationEnum.AccountYouPayFrom}
                            name={PaymentFlowSelectorFormEnum.SelectedAccount}
                            options={accountOptions}
                            rowCountView={3}
                            isMobile
                        />
                        <PaymentFlowSelectorOptions
                            accounts={accounts}
                            setCurrentPaymentFlow={setCurrentPaymentFlow}
                            setSelectedAccount={setSelectedAccount}
                        />
                    </Form>
                </Formik>
            </Scroll>
        </PaymentFlowSelectorContainer>
    );
};
