import { memo } from 'react';

import { CurrencyIcons } from './currency-icon.options';
import { Icon } from './currency-icon.styles';

interface CurrencyIconProps {
    code: string;
}

export const CurrencyIcon = memo(({ code }: CurrencyIconProps) => (
    <Icon as={CurrencyIcons[code] ?? CurrencyIcons.WORLD} />
));
