export const isExist = <T>(value?: T): boolean => value !== undefined && value !== null;

export const isTrue = <T>(value?: T): boolean => Boolean(value) === true;

export const isFalse = <T>(value?: T): boolean => Boolean(value) === false;

export const isString = <T>(value?: T): boolean => typeof value === 'string' && value.length > 0;

export const isNumber = <T>(value?: T): boolean => typeof value === 'number' && !Number.isNaN(value);

export const isEnvVariableTruly = (variable: string): boolean => isTrue(variable === 'true');

export const isEnvVariableFalsy = (variable: string): boolean => isTrue(variable === 'false');
