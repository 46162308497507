import { reaction } from 'mobx';
import { Instance } from 'mobx-state-tree';

import { UserCompanyRelationType } from '@shared-graphql';

import { CompanyModel } from '@model/company-model/company-model';

const MAX_TOTAL_PERCENT = 10_000; // 100.00%

const relationOwnerships = new Set([UserCompanyRelationType.Ownership]);

export const initializeCompanyShareholdersReaction = (companyStore: Instance<typeof CompanyModel>) =>
    reaction(
        () =>
            companyStore.company
                .getRelationPersons(relationOwnerships)
                .reduce(
                    (acc, { relation: { ownershipPercent, companyRelation } }) =>
                        companyRelation.includes(UserCompanyRelationType.Ownership)
                            ? acc + (ownershipPercent ?? 0)
                            : acc,
                    0
                ),
        percent => companyStore.company.companyStatus.setHasCompanyShareholders(percent === MAX_TOTAL_PERCENT)
    );
