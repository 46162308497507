import { FC, JSX, ReactNode } from 'react';

import { ExternalLinkStyles } from '@shared-atom/external-link/external-link.styles';

export interface ExternalLinkProps {
    href: string;
    children?: ReactNode | ReactNode[] | JSX.Element;
    animated?: boolean;
}

export const ExternalLink: FC<ExternalLinkProps> = ({ href, children, animated = false, ...props }) => (
    <ExternalLinkStyles href={href} target="_blank" rel="external nofollow noopener" animated={animated} {...props}>
        {children}
    </ExternalLinkStyles>
);
