export const typographyPositions = {
    Center: {
        textAlign: 'center',
    },
    Left: {
        textAlign: 'left',
    },
    Right: {
        textAlign: 'right',
    },
};
