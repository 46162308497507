import { useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect } from 'react';

import { ModelContext } from '@shared-component/modal/modal.context';
import { Mutation, UserTerminateSessions } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

export const useTerminateSessionsModal = () => {
    const {
        props: { componentProps },
    } = useContext(ModelContext);
    const [userTerminateSessions, { data: userTerminateSessionsData, loading: isLoading }] =
        useMutation<Pick<Mutation, 'userTerminateSessions'>>(UserTerminateSessions);

    const onTerminateSessions = useCallback(() => {
        userTerminateSessions();
    }, []);

    useEffect(() => {
        if (isTrue(userTerminateSessionsData?.userTerminateSessions)) {
            componentProps?.getActiveSessions();
        }
    }, [userTerminateSessionsData]);

    return { onTerminateSessions, isLoading, sessions: componentProps?.sessions };
};
