import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { InputAmountView } from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount.styles';
import { getAmount } from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount.util';
import { ErrorWrapper, InputWrapper, Label, LabelWrapper } from '@shared-atom/elpaso-kit/input/input.styles';
import { Translate } from '@shared-atom/translate/translate';
import { FieldError } from '@shared-component/field-error/field-error';
import { OnEventType } from '@shared-type/on-event.type';
import { isNumber, isString } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

export interface CustomInputAmountProps extends Partial<Pick<HTMLInputElement, 'type' | 'tabIndex' | 'disabled'>> {
    name: string;
    title: string;
    placeholder?: string;
    onChange?: OnEventType<string>;
    isMobile?: boolean;
    isDisabled?: boolean;
}

export const CustomInputAmount = observer(
    ({
        name,
        title,
        disabled,
        placeholder = '',
        onChange = noop,
        isMobile = false,
        isDisabled = false,
    }: CustomInputAmountProps) => {
        const [{ value: amount }, { error }, { setError, setValue }] = useField(name);
        const [isFocused, setFocused] = useState(false);
        const [amountValue, setAmountValue] = useState(amount);

        const value = isNumber(amount) && amount !== 0 ? amount.toFixed(2) : undefined;

        const hasValue = value !== undefined;
        const hasError = error !== undefined;
        const shouldRenderLabel = isString(title);

        const toggleFocus = (isFocus: boolean) => () => setFocused(isFocus);

        const handleChange = (newValue = '0') => {
            onChange(newValue);
            setValue(isString(newValue) ? getAmount(newValue) : undefined);
            setError(undefined);
            setAmountValue(newValue);
        };

        useEffect(() => {
            const amountValueNum = getAmount(`${amountValue ?? 0}`);

            if (amountValueNum !== amount && hasValue) {
                setAmountValue(value);
            }
        }, [amountValue, amount]);

        return (
            <InputWrapper>
                {shouldRenderLabel && (
                    <Label hasError={hasError}>
                        <Translate langKey={title} />
                    </Label>
                )}
                <LabelWrapper hasError={hasError} isFocused={isFocused} isDisabled={disabled} isMobile={isMobile}>
                    <InputAmountView
                        name={name}
                        title={title}
                        placeholder={placeholder}
                        value={amountValue === 0 || amountValue === '0' ? undefined : amountValue}
                        decimalsLimit={2}
                        onFocus={toggleFocus(true)}
                        onBlur={toggleFocus(false)}
                        onValueChange={handleChange}
                        isMobile={isMobile}
                        disabled={isDisabled}
                    />
                </LabelWrapper>
                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
            </InputWrapper>
        );
    }
);
