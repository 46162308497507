import { isValidBIC } from 'ibantools';
import parsePhoneNumber from 'libphonenumber-js';
import { number, ref, string } from 'yup';

import { PrivateProfileFormEnum } from '@shared-form/private-profile-form/private-profile-form.enum';
import { REQUIRED_FIELD_ERROR } from '@shared-util/constants';
import { isExist, isString } from '@shared-util/is-data';

const countryException = ['UA', 'RU', 'KZ', 'BY'];

const latinNameValidation = () =>
    string()
        .trim()
        .max(100)
        .matches(/(^[a-z]{1,}[a-z.,'\-()\s]{0,}[a-z.\s]$)|(^.{0}$)/i, 'fieldName must contain text only ');
export const minPasswordLength = 8;
export const maxPasswordLength = 255;

export const nameValidation = () => latinNameValidation().required();

export const fieldRequiredValidation = () => string().nullable().required();

export const thirdNameValidation = () =>
    string().when(PrivateProfileFormEnum.Nationality, {
        is: (nationality: string) => isExist(nationality) && countryException.includes(nationality?.toUpperCase()),
        then: latinNameValidation().required(),
        otherwise: latinNameValidation().nullable(),
    });

export const passwordValidation = () =>
    string()
        .trim()
        .min(minPasswordLength, 'Password must be at least 8')
        .max(maxPasswordLength, 'Password must be at less than 255')
        .matches(/[!@#$%^&*()_=+\-.,]/, 'Password must be have one of special symbols (!@#$%^&*()_=+\\-.,)')
        .matches(/[a-z]/, 'Password must be have lowercase symbols')
        .matches(/[A-Z]/, 'Password must be have uppercase symbols')
        .matches(/[0-9]/, 'Password must be have numbers')
        .matches(/^[a-zA-Z!@#$%^&*()_=+\-.,\d]+$/, 'Password has no valid symbols')
        .required('Password is required field');

export const repeatPasswordValidation = (passwordFieldName: string) =>
    string()
        .trim()
        .required('Passwords must match')
        .oneOf([ref(passwordFieldName), ''], 'Passwords must match');

export const emailValidation = () =>
    string()
        .required('Email is required field')
        .trim()
        .matches(
            /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Invalid email'
        );

export const phoneValidation = (isPhoneRequired = true) =>
    string()
        [isPhoneRequired ? 'required' : 'notRequired']('Phone number is required')
        .test('phone', 'Phone number is incorrect', value => {
            if (!isPhoneRequired && (typeof value !== 'string' || value.trim().length === 0)) {
                return true;
            }

            if (typeof value !== 'string') {
                return false;
            }

            const phone = parsePhoneNumber(value);

            return phone !== undefined && phone.isValid();
        });

export const percentValidation = () =>
    string()
        .required()
        .test('is-min', 'Value must be more then 0', value => parseInt(value ?? '', 10) >= 0)
        .test('is-max', 'Value must be less then 100', value => parseInt(value ?? '', 10) <= 10000);

export const bicValidation = string()
    .default('')
    .test({
        message: 'Bic is incorrect',
        test: bic => (isString(bic) ? isValidBIC(bic) : true),
    });

export const maxAmountLengthValidation = (amountName: string) =>
    number()
        .required(REQUIRED_FIELD_ERROR)
        .test(amountName, 'Max length 13 numbers', value => /^\d{1,13}(\.\d*)?$/.test(`${value ?? ''}`))
        .test(amountName, 'Must be greater than 0', value => Number(value) > 0);

export const moreThanZeroValidation = () =>
    number()
        .required('Field is required')
        .test('is-min', 'Value must be more then 0', value => value !== undefined && value > 0);
