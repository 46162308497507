import { object, date, string } from 'yup';

import { REQUIRED_FIELD_ERROR } from '@shared-util/constants';

import { StatementFormEnum } from './statement-form.enum';

export const StatementFormValidation = object().shape({
    [StatementFormEnum.From]: date().required(REQUIRED_FIELD_ERROR),
    [StatementFormEnum.To]: date().required(REQUIRED_FIELD_ERROR),
    [StatementFormEnum.AccountId]: string().required(REQUIRED_FIELD_ERROR),
});
