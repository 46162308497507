import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { FormEvent, FC, SVGProps, useState, RefObject, ReactNode, JSX } from 'react';

import { InputTypeEnum } from '@shared-atom/elpaso-kit/input/custom-input/custom-input-type.enum';
import { IconWrapper, CustomInputStyle } from '@shared-atom/elpaso-kit/input/custom-input/custom-input.styles';
import { CustomInputTagEnum } from '@shared-atom/elpaso-kit/input/input-tag.enum';
import { ErrorWrapper, InputWrapper, Label, LabelWrapper } from '@shared-atom/elpaso-kit/input/input.styles';
import { Translate } from '@shared-atom/translate/translate';
import { FieldError } from '@shared-component/field-error/field-error';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist, isString } from '@shared-util/is-data';

export interface CustomInputProps extends Partial<Pick<HTMLInputElement, 'type' | 'tabIndex' | 'disabled'>> {
    label: string;
    name: string;
    onFocus?: OnEventType<boolean>;
    icon?: FC<SVGProps<SVGSVGElement>>;
    inputRef?: RefObject<HTMLInputElement>;
    tag?: CustomInputTagEnum;
    placeholder?: string;
    isMobile?: boolean;
    isOnlyDigitsAndLetters?: boolean;
    isSpacecDisabled?: boolean;
    isLineBreaksDisabled?: boolean;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const CustomInput: FC<CustomInputProps> = observer(
    ({
        label,
        placeholder = '',
        name,
        type = InputTypeEnum.Text,
        isMobile = false,
        isOnlyDigitsAndLetters = false,
        isSpacecDisabled = false,
        isLineBreaksDisabled = false,
        icon,
        tag,
        inputRef,
        onFocus,
        disabled,
        children,
        ...props
    }) => {
        const [field, { error }, { setError, setValue }] = useField(name);
        const [isFocused, setFocused] = useState(false);

        const hasValue = typeof field.value === 'string' ? isString(field.value) : isExist(field.value);
        const hasIcon = icon !== undefined;
        const hasError = error !== undefined;
        const normalizedPlaceholder = useLocalizationText(placeholder);
        const toggleFocus = (isFocus: boolean) => () => {
            setFocused(isFocus);
            if (onFocus instanceof Function) {
                onFocus(isFocus);
            }
        };

        const handleBlur = () => {
            toggleFocus(false)();
            if (type === InputTypeEnum.Text) {
                setValue(field.value?.trim() ?? null);
            }
        };

        const handleChange = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
            setValue(
                type === InputTypeEnum.Number && value !== ''
                    ? parseFloat(value)
                    : name === 'email'
                    ? value.toLowerCase()
                    : isOnlyDigitsAndLetters
                    ? value?.replace(/[^A-Z0-9]+/gi, '')
                    : isSpacecDisabled
                    ? value?.replace(/\s+/g, '')
                    : isLineBreaksDisabled
                    ? value?.replace(/\r?\n|\r/g, '')
                    : value
            );

            if (hasError) {
                setError(undefined);
            }
        };

        const Icon = icon !== undefined && IconWrapper(icon);
        const taggedInput = { as: tag } as unknown as object;
        const shouldRenderLabel = isString(label);

        return (
            <InputWrapper>
                {shouldRenderLabel && (
                    <Label hasError={hasError}>
                        <Translate langKey={label} />
                    </Label>
                )}
                <LabelWrapper hasError={hasError} isFocused={isFocused} isDisabled={disabled} isMobile={isMobile}>
                    {Icon !== false && <Icon hasValue={hasValue} />}
                    <CustomInputStyle
                        {...props}
                        {...field}
                        {...taggedInput}
                        ref={inputRef}
                        type={type}
                        value={field.value ?? ''}
                        disabled={disabled}
                        hasIcon={hasIcon}
                        placeholder={!hasIcon ? normalizedPlaceholder : ''}
                        onFocus={toggleFocus(true)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isMobile={isMobile}
                    />
                    {children}
                </LabelWrapper>

                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
            </InputWrapper>
        );
    }
);
