import styled from 'styled-components';

import { Typography } from '../../atoms/typography/typography';

export const LabelError = styled.p`
    ${Typography.Paragraph12.Error500.Left.Style}
`;

export const FieldErrorWrapper = styled.div`
    margin-top: 4px;
`;
