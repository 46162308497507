import styled from 'styled-components';

export const PermittedCountriesPageForEEUContent = styled.div`
    overflow-y: scroll;
    height: calc(100% - 24px);
`;

export const CountriesBlock = styled.div`
    padding-top: 30px;
`;

export const CountriesBlockTitle = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #090909;
    padding: 0 24px;
`;
export const CountriesBlockCountry = styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #090909;
    padding-top: 18px;
    padding-left: 8px;
    position: relative;
    margin-top: 12px;
`;
export const CountriesBlockCountryName = styled.div`
    padding-left: 64px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #1f1f1f;
`;
