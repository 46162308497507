import { ChangeEvent, ReactNode, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import { File as FileUploadType } from '../../graphql';
import { OnEventType } from '../../types/on-event.type';
import { noop } from '../../utils/noop';
import { useFileUpload } from './file-upload.hook';
import { Label } from './file-upload.styles';

interface DropInterface {
    files: FileList;
}

export interface FileUploadProps {
    onUploaded: OnEventType<FileUploadType>;
    onError?: OnEventType<boolean>;
    commentId?: string;
    children?: (
        isDrop: boolean,
        isLoading: boolean,
        selectedFile: FileUploadType | undefined,
        error: any,
        onCancel?: any
    ) => ReactNode;
    accepts?: string[];
}

const DEFAULT_ACCEPT = ['application/pdf', 'image/png', 'image/jpeg'];
const MAX_UPLOAD_SIZE = 50 * 1024 * 1024;

export const FileUpload = ({
    commentId,
    children,
    onUploaded,
    accepts = DEFAULT_ACCEPT,
    onError = noop,
}: FileUploadProps) => {
    const [uploadFile, isLoading, fileData, onCancel, error] = useFileUpload(commentId);
    const handleUploadFile = (file: File) => {
        if (Boolean(file) && accepts.includes(file.type) && file.size <= MAX_UPLOAD_SIZE) {
            return uploadFile(file);
        }
        return onError(true);
    };

    const handleChangeUploadFile = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
        onError(false);

        if (files !== null) {
            handleUploadFile(files[0]);
        }
    };

    const [isDrop, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: item => handleUploadFile((item as DropInterface).files[0]),
        collect: monitor => monitor.canDrop() && monitor.isOver(),
    });

    useEffect(() => void (fileData !== undefined && onUploaded(fileData)), [fileData]);

    return (
        <Label ref={drop}>
            <input type="file" hidden accept={accepts.join(',')} onChange={handleChangeUploadFile} />
            {children instanceof Function && children(isDrop, isLoading, fileData, error, onCancel)}
        </Label>
    );
};
