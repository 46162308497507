import { memo } from 'react';

import { customCountryList } from '@shared-atom/elpaso-kit/custom-country-select/custom-country-select.options';
import {
    CustomCountryItemWrapper,
    CustomCountryTitle,
} from '@shared-atom/elpaso-kit/custom-country-select/custom-country-select.styles';
import { CustomSelect } from '@shared-atom/elpaso-kit/custom-select/custom-select';
import { Flag } from '@shared-atom/flag/flag';
import { BaseOptionInterface } from '@shared-component/select/select.props';

export interface CustomCountrySelectProps {
    name: string;
    label: string;
    placeholder: string;
    countries?: BaseOptionInterface[];
    isMobile?: boolean;
    isDisabled?: boolean;
}

const countryRow = (item: BaseOptionInterface, isSelected = false) => (
    <CustomCountryItemWrapper isSelected={isSelected}>
        <Flag countryCode={item.value.toLowerCase()} />
        <CustomCountryTitle isSelected={isSelected}>{item.label}</CustomCountryTitle>
    </CustomCountryItemWrapper>
);

export const CustomCountrySelect = memo(
    ({
        name,
        label,
        placeholder,
        countries = customCountryList,
        isMobile = false,
        isDisabled = false,
    }: CustomCountrySelectProps) => (
        <CustomSelect
            name={name}
            placeholder={placeholder}
            label={label}
            options={countries}
            isSearchable
            rowRender={item => countryRow(item, false)}
            valueRender={props => {
                const item = props.getValue()[0] ?? {};

                return countryRow(item, true);
            }}
            rowHeight={56}
            isMobile={isMobile}
            forCountrySelect
            isDisabled={isDisabled}
        />
    )
);
