import { applySnapshot, Instance } from 'mobx-state-tree';

import { Maybe, Address, UserDeclaration, UserDocument, UserDocumentType, UserService } from '@shared-graphql';
import { isExist } from '@shared-util/is-data';

import { UserModelInstance } from '@model/user-model/user-model';

type UserModelPrivateActionsType = Instance<typeof UserModelInstance>;

const REQUIRED_DOCUMENT_TYPES = new Set([
    UserDocumentType.Selfie,
    UserDocumentType.Passport,
    UserDocumentType.Location,
]);

export const userModelPrivateActions = (self: UserModelPrivateActionsType) => ({
    setUserAddress: (address: Maybe<Address>) => {
        self.user.address = address as any;
        self.user.privateStatus.hasAddress = isExist(address);
    },
    setUserService: (service: Maybe<UserService>) => {
        self.user.service = service;
        self.user.privateStatus.hasService = isExist(service);
    },
    setUserDeclaration: (declaration: Maybe<UserDeclaration>) => {
        self.user.declaration = declaration;
        self.user.privateStatus.hasDeclaration = isExist(declaration);
    },
    setUserHasDocuments: (hasDocument: boolean) => {
        self.user.privateStatus.hasDocument = hasDocument;
    },
    setUserDocuments: (documents: Array<UserDocument>) => {
        applySnapshot(self.user.documents, documents);

        const hasAllDocuments =
            documents.filter(doc => REQUIRED_DOCUMENT_TYPES.has(doc.type)).length === REQUIRED_DOCUMENT_TYPES.size;

        (self as unknown as ReturnType<typeof userModelPrivateActions>).setUserHasDocuments(hasAllDocuments);
    },
    setUserAccept: () => {
        self.user.privateStatus.hasAccept = true;
    },
    setUserAcceptAgreement: (isAcceptAgreement: boolean) => {
        self.user.isAcceptAgreement = isAcceptAgreement;
    },
});
