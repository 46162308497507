import { MenuProps } from 'react-select';
import { ListRowProps } from 'react-virtualized/dist/es/List';

import { SelectRowWrapperStyle } from '../../custom-select/custom-select.styles';
import { NodeOptionInterface, RowRenderInterface } from '../select-account.props';

export const selectAccountRowWrapper =
    (
        { options, setValue }: MenuProps<NodeOptionInterface, boolean>,
        rowRender: RowRenderInterface<NodeOptionInterface>
    ) =>
    ({ style, key, index }: ListRowProps) => {
        const currentItem = options[index];
        const handleChoose = () => setValue(currentItem, 'select-option');

        return (
            <SelectRowWrapperStyle key={key} style={style} onClick={handleChoose}>
                {rowRender(currentItem, index)}
            </SelectRowWrapperStyle>
        );
    };
