import { FC, useCallback } from 'react';

import { Scroll } from '../../../../../../components/scroll/scroll';
import { YearMonthSelectProps } from './month-year-view-select.interface';
import { DateList, DateListOption, DateListWrapper, ListOptionTitle } from './month-year-view-select.styles';

export const YearMonthSelect: FC<YearMonthSelectProps> = ({
    list,
    selectedMonth,
    selectedYear,
    value,
    onChange,
    isLeftSide,
}) => {
    const handlerValue = useCallback(
        (listValue: string | number, listIdx: number) => () =>
            onChange(selectedMonth ?? listIdx, (selectedYear as number) ?? listValue),
        [selectedMonth, selectedYear]
    );

    return (
        <DateListWrapper>
            <Scroll>
                <DateList isLeftSide={isLeftSide}>
                    {list.map((listValue, i) => (
                        <DateListOption
                            isChecked={value === listValue}
                            key={listValue}
                            onClick={handlerValue(listValue, i)}
                        >
                            <ListOptionTitle>{listValue}</ListOptionTitle>
                        </DateListOption>
                    ))}
                </DateList>
            </Scroll>
        </DateListWrapper>
    );
};
