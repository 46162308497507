import { useFormikContext } from 'formik';
import React, { FC, JSX, ReactNode } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { LabelError } from '@shared-component/field-error/field-error.styles';
import { Scroll } from '@shared-component/scroll/scroll';
import { Fee, PaymentEntityType, PaymentType } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { InformationCopyRow } from '@component/modal/modals/common/information-copy-row/information-copy-row';
import { PaymentFormValuesInterface } from '@component/modal/modals/payment/payment-form/payment-form.interface';

import { BlockTitle, BlockWrapper, PaymentConfirmationWrapper } from './payment-confirmation.style';

interface PaymentConfirmationProps {
    fee: Fee;
    isInsufficientFunds: boolean;
    currency: string;
    children?: ReactNode | ReactNode[] | JSX.Element;
    balanceAfterTransaction: number;
}

export const PaymentConfirmation: FC<PaymentConfirmationProps> = ({
    currency,
    isInsufficientFunds,
    fee: { amount: feeAmount },
    children,
    balanceAfterTransaction,
}) => {
    const {
        values: {
            amount,
            type,
            beneficiary: {
                beneficiaryEntityType,
                beneficiaryFirstName,
                beneficiaryLastName,
                beneficiaryCompanyName,
                iban,
                bankName,
            },
        },
    } = useFormikContext<PaymentFormValuesInterface>();

    const beneficiaryName =
        beneficiaryEntityType === PaymentEntityType.individual
            ? `${beneficiaryFirstName} ${beneficiaryLastName}`
            : (beneficiaryCompanyName as string);

    const amountData = useCurrencyFormat(amount, currency);

    const feeAmountData = useCurrencyFormat(feeAmount, currency);
    const balanceAfterTransactionData = useCurrencyFormat(balanceAfterTransaction, currency);

    const insufficientFundsErrorText = useLocalizationText(LocalizationEnum.CommonInsufficientFunds);
    const instantText = useLocalizationText(LocalizationEnum.CommonInstant);

    return (
        <>
            <PaymentConfirmationWrapper>
                <Scroll>
                    <BlockWrapper>
                        <BlockTitle>
                            <Translate langKey={LocalizationEnum.CommonDetails} />
                        </BlockTitle>
                        <InformationCopyRow
                            title={LocalizationEnum.CommonSendTo}
                            data={beneficiaryName}
                            isCopied={false}
                        />
                        <InformationCopyRow
                            title={LocalizationEnum.PaymentFormCurrencyAmountStepIban}
                            data={iban?.replaceAll('-', '').replace(/^.{18}/g, '•••••••••••••••• ')}
                            isCopied={false}
                        />
                        {type !== PaymentType.internal && (
                            <InformationCopyRow
                                title={LocalizationEnum.PaymentFormCurrencyAmountStepBankName}
                                data={bankName as string}
                                isCopied={false}
                            />
                        )}
                    </BlockWrapper>
                    <BlockWrapper>
                        <InformationCopyRow
                            title={LocalizationEnum.PaymentFormConfirmationStepAmount}
                            data={amountData}
                            isCopied={false}
                        />
                        <InformationCopyRow
                            title={LocalizationEnum.CommonPaymentFee}
                            data={feeAmountData}
                            isCopied={false}
                        />
                    </BlockWrapper>
                    <BlockWrapper>
                        {type === PaymentType.internal && (
                            <InformationCopyRow
                                title={LocalizationEnum.ExpectationDepositTime}
                                data={instantText}
                                isCopied={false}
                            />
                        )}
                        <InformationCopyRow
                            title={LocalizationEnum.BalanceAfterTransaction}
                            data={balanceAfterTransactionData}
                            isCopied={false}
                        />
                        {isInsufficientFunds && <LabelError>{insufficientFundsErrorText}</LabelError>}
                    </BlockWrapper>
                </Scroll>
            </PaymentConfirmationWrapper>

            {children}
        </>
    );
};
