import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Dark, White } from '@shared-style/colors';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const BeneficiaryTitle = styled.h3`
    margin-top: 16px;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.L.Medium.Style}
`;

export const ButtonSubmitWrapper = styled.div`
    margin-top: 40px;
`;
