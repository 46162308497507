import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC, RefObject, useEffect, useRef, useState } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { Translate } from '@shared-atom/translate/translate';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isString } from '@shared-util/is-data';

import { Input } from '../../../input/input';
import Comment from './assets/comment.svg?react';
import { DocumentCommentWrapper, DocumentCommentShow } from './document-comment.styles';

interface DocumentCommentProps {
    path: string;
}

export const DocumentComment: FC<DocumentCommentProps> = observer(({ path }) => {
    const commentPath = `${path}.comment`;
    const [{ value }] = useField(commentPath);
    const [isShowComment, setShowComment] = useState(isString(value));
    const commentTitle = useLocalizationText(LocalizationEnum.PrivateDocumentCommentTitle);
    const inputRef = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;

    const handleShowComment = () => setShowComment(true);

    useEffect(() => inputRef.current?.focus(), [inputRef, isShowComment]);

    return (
        <DocumentCommentWrapper>
            {isShowComment && <Input title={commentTitle} name={commentPath} inputRef={inputRef} />}
            {!isShowComment && (
                <ButtonTransparent onClick={handleShowComment}>
                    <Comment />
                    <DocumentCommentShow>
                        <Translate langKey={LocalizationEnum.PrivateDocumentCommentShow} />
                    </DocumentCommentShow>
                </ButtonTransparent>
            )}
        </DocumentCommentWrapper>
    );
});
