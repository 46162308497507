import styled from 'styled-components';

import { Typography } from '../../../../atoms/typography/typography';

export const DocumentCommentWrapper = styled.div`
    margin-top: 16px;
    height: 56px;
`;

export const DocumentCommentShow = styled.span`
    ${Typography.Dark.Style}
`;
