import * as Sentry from '@sentry/react';
import { Instance, getParent, flow } from 'mobx-state-tree';

import { GraphqlErrorCodesEnum } from '@shared-enum/graphql-error-codes.enum';
import {
    AddressBook,
    Query,
    Mutation,
    AddressBookInput,
    AddressBookFilters,
    DeleteAddressBook,
    GetAddressBooks,
    AddressBookUpdate,
} from '@shared-graphql';
import { getGraphQLErrorCode } from '@shared-util/get-graphql-error-code';

import { apolloClient } from '@connection/apollo-client';
import { AddressBookModel } from '@model/address-book-model/address-book-model';
import { AddressBookModelType } from '@model/address-book-model/address-book-model.type';

const queryAddressBooks = (filters?: AddressBookFilters) =>
    apolloClient
        .query<Pick<Query, 'getAddressBooks'>>({ query: GetAddressBooks, variables: { filters } })
        .then(result => result.data.getAddressBooks);

const mutationAddressBook = (addressBook: AddressBookInput) =>
    apolloClient.mutate<Pick<Mutation, 'addressBookUpdate'>>({
        mutation: AddressBookUpdate,
        variables: { addressBook },
    });

const removeAddressBook = (id: string) =>
    apolloClient
        .mutate<Pick<Mutation, 'deleteAddressBook'>>({ mutation: DeleteAddressBook, variables: { id } })
        .then(result => result.data?.deleteAddressBook);

export const addressBookMainActions = (self: Instance<typeof AddressBookModelType>) => ({
    setSelectedItem: (id: string | null) => {
        self.selectedId = id;
    },

    loadAddressBooks: flow(function* loadAddressBooks(filters?: AddressBookFilters) {
        const addressBookInstanceModel = getParent(self) as Instance<typeof AddressBookModel>;
        if (addressBookInstanceModel.loading.isAddressBookLoading) {
            return;
        }
        addressBookInstanceModel.setAddressBookLoading(true);

        try {
            const addressBooks = yield queryAddressBooks(filters);

            self.elements.clear();

            self.totalCount = addressBooks.totalCount;
            addressBooks.elements.forEach((addressBook: AddressBook) => {
                self.elements.set(addressBook.id, addressBook);
            });
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        } finally {
            addressBookInstanceModel.setAddressBookLoading(false);
        }
    }),

    updateAddressBook: flow(function* updateAddressBook(addressBook: AddressBookInput) {
        const addressBookInstanceModel = getParent(self) as Instance<typeof AddressBookModel>;
        if (addressBookInstanceModel.loading.isAddressBookLoading) {
            return;
        }
        addressBookInstanceModel.setAddressBookLoading(true);
        addressBookInstanceModel.setAddressBookError('');

        try {
            const { data, errors } = yield mutationAddressBook(addressBook);
            const errorCode = getGraphQLErrorCode(errors);

            if (errorCode === GraphqlErrorCodesEnum.UserInputError) {
                addressBookInstanceModel.setAddressBookError(errorCode);
            } else {
                const newAddressBook = data.addressBookUpdate;
                self.elements.set(newAddressBook.id, newAddressBook);
                self.totalCount += 1;
            }
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        } finally {
            addressBookInstanceModel.setAddressBookLoading(false);
        }
    }),

    deleteAddressBook: flow(function* deleteAddressBook() {
        const addressBookInstanceModel = getParent(self) as Instance<typeof AddressBookModel>;
        if (addressBookInstanceModel.loading.isAddressBookLoading) {
            return;
        }
        addressBookInstanceModel.setAddressBookLoading(true);

        try {
            const id = self.selectedId as string;
            const result = yield removeAddressBook(id);

            if (result?.status === true) {
                self.elements.delete(id);
            }
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        } finally {
            addressBookInstanceModel.setAddressBookLoading(false);
        }
    }),
});
