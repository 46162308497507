import { useStore } from '@store/store-context.hook';

export const useAccountActionsSelector = () => {
    const {
        setAccountLoading,
        account: { loadClientAccounts, updateAccountCurrencies, setSelectedAccount },
    } = useStore().account;

    return {
        setAccountLoading,
        setSelectedAccount,
        loadClientAccounts,
        updateAccountCurrencies,
    };
};
