import { types } from 'mobx-state-tree';

import { initialAccountModel } from '@shared-model/account-model/account-model.initials';

import { accountActions } from './account-model-actions/account-actions';
import { AccountModelInstanceType } from './account-model.type';

export const AccountModelInstance = types.model('AccountModel', {
    account: AccountModelInstanceType,
    loading: types.model('AccountLoading', {
        isLoading: types.boolean,
    }),
    error: types.optional(types.string, ''),
});

export const AccountModel = AccountModelInstance.actions(accountActions);

export const getInitialAccountModel = () =>
    AccountModel.create({
        account: initialAccountModel,
        loading: {
            isLoading: false,
        },
    });
