import { Form, Formik } from 'formik';
import React from 'react';

import { CheckBox } from '@shared-component/checkbox/checkbox';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { noop } from '@shared-util/noop';

import { PaymentAgreementFormEnum } from '@component/modal/modals/payment/payment-update-steps/payment-agreement-form/payment-agreement-form.enum';
import { PaymentAgreementFormInitial } from '@component/modal/modals/payment/payment-update-steps/payment-agreement-form/payment-agreement-form.initials';
import {
    PaymentAgreementFormInterface,
    PaymentAgreementFormValuesInterface,
} from '@component/modal/modals/payment/payment-update-steps/payment-agreement-form/payment-agreement-form.interface';
import { PaymentAgreementFormWrapper } from '@component/modal/modals/payment/payment-update-steps/payment-agreement-form/payment-agreement-form.styles';

export const PaymentAgreementForm = ({ setAgreementConfirmed }: PaymentAgreementFormInterface) => {
    const onValidForm = (values: PaymentAgreementFormValuesInterface) => {
        setAgreementConfirmed(Object.values(values).every(Boolean));
    };

    return (
        <PaymentAgreementFormWrapper>
            <Formik initialValues={PaymentAgreementFormInitial} onSubmit={noop} validate={onValidForm}>
                <Form>
                    <FormRow>
                        <CheckBox
                            name={PaymentAgreementFormEnum.DetailsIncluding}
                            title={LocalizationEnum.IConfirmThatThePaymentDetailsIncluding}
                        />
                    </FormRow>
                    <FormRow>
                        <CheckBox
                            name={PaymentAgreementFormEnum.PurposeAwareness}
                            title={LocalizationEnum.IConfirmThatIAmAwareOfThePurposeOfThisTransaction}
                        />
                    </FormRow>
                    <FormRow>
                        <CheckBox
                            name={PaymentAgreementFormEnum.FraudRiskUnderstanding}
                            title={LocalizationEnum.IUnderstandTheRisksOfFraudAndConfirmThatThisPaymentIsLegitimate}
                        />
                    </FormRow>
                </Form>
            </Formik>
        </PaymentAgreementFormWrapper>
    );
};
