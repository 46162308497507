export enum ModalEnum {
    RegistrationLegalPrivatePersonModal,
    RegistrationLegalPersonLegalModal,
    PaymentModal,
    RequestStatementModal,
    CreatePartnerModal,
    UserProfileModal,
    CurrencyExchangeModal,
    DeletePartnerConfirmationModal,
    AddCurrency,
    AccountDetails,
    AcquiringRequestModal,
    RegistrationPermittedCountriesModal,
    HomeServiceModal,
    ApplyVacancyModal,
    GoogleAuthActivateModal,
    TerminateSessionsModal,
    CancelTransactionModal,
}

export enum ModalHeightEnum {
    Quarter = 'quarter',
    Half = 'half',
    Full = 'full',
    H280 = 'h280',
    H330 = 'h330',
    H485 = 'h485',
}
