import { Scroll } from '@shared-component/scroll/scroll';

import { AddCurrency } from '@component/currency-add/currency-add';

import { AddCurrencyWrapper, ContentWrapper } from './currency-add.styles';

export const AddCurrencyModal = () => (
    <AddCurrencyWrapper>
        <Scroll>
            <ContentWrapper>
                <AddCurrency />
            </ContentWrapper>
        </Scroll>
    </AddCurrencyWrapper>
);
