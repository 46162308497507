import { reaction } from 'mobx';
import { Instance } from 'mobx-state-tree';

import { initialCompanyModel } from '@shared-model/company-model/company-model.initial';

import { CompanyModel } from '@model/company-model/company-model';

export const initializeCompanyAddressReaction = (companyStore: Instance<typeof CompanyModel>) =>
    reaction(
        () => companyStore.company.legalAddress?.city,
        companyAddressCity =>
            companyStore.company.companyStatus.setHasCompanyAddress(
                companyAddressCity !== initialCompanyModel.legalAddress.city
            )
    );
