import { getMonth, getYear } from 'date-fns';
import { FC, useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';

import { MIN_START_YEAR } from '../../../../../utils/constants';
import { getRangeBetweenYears, useMonthLocalization } from './date-picker-caption-element.helper';
import { YearMonthProps } from './date-picker-caption-element.interface';
import { DatePickerCaptionWrapper, MonthYearSelectWrapper } from './date-picker-caption-element.styles';
import { MonthYearView } from './month-year-view/month-year-view';

const YEAR_LIST = getRangeBetweenYears(MIN_START_YEAR, getYear(new Date()));

export const DatePickerCaptionElement: FC<YearMonthProps> = ({ selectedDate, changeYear, changeMonth }) => {
    const monthLocale = useMonthLocalization();
    const [isMonthOpened, setMonthOpened] = useState(false);
    const [isYearOpened, setYearOpened] = useState(false);
    const [month, setMonth] = useState(getMonth(new Date()));
    const [year, setYear] = useState(getYear(new Date()));

    const handleOpenMonth = useCallback(() => {
        setMonthOpened(!isMonthOpened);
        setYearOpened(false);
    }, [isMonthOpened]);
    const handleOpenYear = useCallback(() => {
        setYearOpened(!isYearOpened);
        setMonthOpened(false);
    }, [isYearOpened]);

    const handleChange =
        (closeSelect: Dispatch<SetStateAction<boolean>>) => (selectedMonth: number, selectedYear: number) => {
            closeSelect(false);
            setMonth(selectedMonth);
            setYear(selectedYear);
            changeYear(selectedYear);
            changeMonth(selectedMonth);
        };

    useEffect(() => {
        if (selectedDate instanceof Date || Boolean(selectedDate) === true) {
            setMonth(getMonth(selectedDate));
            setYear(getYear(selectedDate));
        }
    }, [selectedDate]);

    return (
        <DatePickerCaptionWrapper>
            <MonthYearSelectWrapper>
                <MonthYearView
                    value={monthLocale[month]}
                    selectedYear={year}
                    list={monthLocale}
                    onOpen={handleOpenMonth}
                    isOpened={isMonthOpened}
                    onChange={handleChange(setMonthOpened)}
                    isLeftSide
                />
            </MonthYearSelectWrapper>
            <MonthYearSelectWrapper>
                <MonthYearView
                    value={year}
                    selectedMonth={month}
                    list={YEAR_LIST}
                    onOpen={handleOpenYear}
                    isOpened={isYearOpened}
                    onChange={handleChange(setYearOpened)}
                    isLeftSide={false}
                />
            </MonthYearSelectWrapper>
        </DatePickerCaptionWrapper>
    );
};
