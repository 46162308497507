import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import { PageWrapper } from '@shared-component/page-wrapper/page-wrapper';

import { WorkspaceUser } from '@page/workspace/workspace-user/workspace-user';
import { useNavigationListOptions } from '@page/workspace/workspace.hook';

const WorkspacePage = observer(() => {
    const { pageMenuList, commissionList, additionalMenuList, optionsMenuList } = useNavigationListOptions();

    return (
        <PageWrapper
            pageMenuList={pageMenuList}
            additionalMenuList={additionalMenuList}
            additionalUserComponent={<WorkspaceUser />}
            commissionList={commissionList}
            optionsList={optionsMenuList}
        >
            <Outlet />
        </PageWrapper>
    );
});

export default WorkspacePage;
