import styled from 'styled-components';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { Typography } from '@shared-atom/typography/typography';
import { Neutral900, Neutral400, Primary500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

interface CrumbWrapperProps extends ThemeTypeInterface {
    isActive: boolean;
    isDisabled: boolean;
}

const getColor = ({ isActive, isDisabled }: CrumbWrapperProps) => {
    const themeColor = isDisabled ? Neutral400 : Primary500;
    return isActive ? Neutral900 : themeColor;
};

export const CrumbIcon = styled.div<CrumbWrapperProps>`
    ${Flex.VerticalCenter.HorizontalCenter.Style};
    path,
    svg {
        stroke: ${getColor};
    }
`;

export const CrumbWrapper = styled(ButtonTransparent)<CrumbWrapperProps>`
    margin: 0 8px;
    color: ${getColor};
    ${Typography.Paragraph12.Medium.Style};
    @media (max-width: 560px) {
        font-size: 14px;
    }
`;
