import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { FormHalfRow } from '@shared-form/form-common/form-common.styles';
import { Dark, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const CheckBoxContentWrapper = styled.div`
    flex: 1;
    margin-left: 8px;
    ${Flex.Column.Style}
`;

export const CheckboxTitle = styled.span`
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.S.Normal.Style}
`;

export const CheckboxDescription = styled.span`
    margin-top: 8px;
    ${Typography.S.Normal.Raven.Style}
`;

export const FormOneToTwoRow = styled(FormHalfRow)`
    grid-template-columns: 170px 1fr;
`;
