/* eslint-disable */
// @ts-ignore
import parser, { Tag } from 'bbcode-to-react';
import { ExternalLink } from '@shared-atom/external-link/external-link';
import { LinkAnchor } from '@shared-atom/link/link-anchor';

class LinkMarkdownTag extends Tag {
    toReact() {
        // @ts-ignore
        let url = this.params.url;

        // @ts-ignore
        if (this.params.type === 'email') {
            url = `mailto:${url}`;
        }

        // @ts-ignore
        if (this.params.type === 'tel') {
            url = `tel:${url}`;
        }

        if (url.includes('http')) {
            return (
                <ExternalLink href={url}>
                    {
                        // @ts-ignore
                        this.getComponents()
                    }
                </ExternalLink>
            );
        }

        return (
            <LinkAnchor href={url} isSecondary>
                {
                    // @ts-ignore
                    this.getComponents()
                }
            </LinkAnchor>
        );
    }
}

parser.registerTag('link', LinkMarkdownTag);

export const linkMarkdownTag = (text: string) => parser.toReact(text);
