import { Form, Formik } from 'formik';
import { memo } from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomInputAmountWithSelect } from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount-with-select';
import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { CustomTextarea } from '@shared-atom/elpaso-kit/input/custom-textarea/custom-textarea';
import { Translate } from '@shared-atom/translate/translate';
import { Scroll } from '@shared-component/scroll/scroll';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ModalFooter, ModalFormRowWrapper } from '@component/modal/client-modal.styles';
import { useAcquiringRequestFormHook } from '@component/modal/modals/acquiring-request/acquiring-request-form/acquiring-request-form.hook';
import { acquiringRequestFormInitials } from '@component/modal/modals/acquiring-request/acquiring-request-form/acquiring-request-form.initials';
import {
    AcquiringRequestModalTitle,
    AcquiringRequestModalContent,
} from '@component/modal/modals/acquiring-request/acquiring-request-form/acquiring-request-form.styles';
import { acquiringRequestFormValidationSchema } from '@component/modal/modals/acquiring-request/acquiring-request-form/acquiring-request-form.validations';

import { AcquiringRequestFormEnum } from './acquiring-request-form.enum';

export const AcquiringRequestForm = memo(() => {
    const { onSubmit, isLoading, availableCurrencies } = useAcquiringRequestFormHook();
    return (
        <Formik
            initialValues={acquiringRequestFormInitials}
            validationSchema={acquiringRequestFormValidationSchema}
            onSubmit={onSubmit}
            validateOnChange={false}
        >
            <Form>
                <AcquiringRequestModalTitle>
                    <Translate langKey={LocalizationEnum.AcquiringRequestModalTitle} />
                </AcquiringRequestModalTitle>
                <AcquiringRequestModalContent>
                    <Scroll>
                        <ModalFormRowWrapper>
                            <CustomInput
                                label={LocalizationEnum.HowCanWeContactYou}
                                name={AcquiringRequestFormEnum.Name}
                                placeholder={LocalizationEnum.CommonYourName}
                                isMobile
                            />
                        </ModalFormRowWrapper>
                        <ModalFormRowWrapper>
                            <CustomInput
                                label={LocalizationEnum.CommonEmailUkFull}
                                name={AcquiringRequestFormEnum.Email}
                                placeholder="yourmail@example.com"
                                isMobile
                            />
                        </ModalFormRowWrapper>
                        <ModalFormRowWrapper>
                            <CustomInputAmountWithSelect
                                title={LocalizationEnum.WhatIsYourCompanysAnnualTurnover}
                                name={AcquiringRequestFormEnum.MoneyTurnoverAmount}
                                placeholder="100,000"
                                isMobile
                                selectFieldName={AcquiringRequestFormEnum.MoneyTurnoverCurrency}
                                selectOptions={availableCurrencies}
                            />
                        </ModalFormRowWrapper>
                        <ModalFormRowWrapper>
                            <CustomTextarea
                                label={LocalizationEnum.LeaveYourQuestion}
                                name={AcquiringRequestFormEnum.Message}
                                placeholder={LocalizationEnum.CommonYourQuestion}
                                isMobile
                            />
                        </ModalFormRowWrapper>
                    </Scroll>
                </AcquiringRequestModalContent>
                <ModalFooter>
                    <Button
                        isSubmit
                        isLoading={isLoading}
                        title={LocalizationEnum.CommonLeaveARequest}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                    />
                </ModalFooter>
            </Form>
        </Formik>
    );
});
