import React, { FC, useContext } from 'react';

import { ExternalLink } from '@shared-atom/external-link/external-link';
import { Link } from '@shared-atom/link/link';
import { Translate } from '@shared-atom/translate/translate';
import { AnchorContext } from '@shared-hook/navigation/anchor/anchor.context';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { HeaderLinksInterface } from '@page/main-pages/main-page-common/header/header-right-side/header.options';

import { Item, TextHeaderItem } from './header-item.styles';

interface HeaderItemProps extends HeaderLinksInterface {
    onClick: OnEventEmptyType;
    isDark: boolean;
}

export const HeaderItem: FC<HeaderItemProps> = ({ title, link, externalLink, onClick, isDark }) => {
    const { scrollTop } = useContext(AnchorContext);

    if (Boolean(link) === true) {
        return (
            <Item onClick={scrollTop}>
                <Link route={link} onClick={onClick}>
                    <TextHeaderItem isDark={isDark}>
                        <Translate langKey={title} />
                    </TextHeaderItem>
                </Link>
            </Item>
        );
    }
    if (Boolean(externalLink) === true) {
        return (
            <Item onClick={scrollTop}>
                <ExternalLink href={externalLink as string}>
                    <TextHeaderItem isDark={isDark}>
                        <Translate langKey={title} />
                    </TextHeaderItem>
                </ExternalLink>
            </Item>
        );
    }

    return null;
};
