import { autorun } from 'mobx';
import { Instance } from 'mobx-state-tree';

import { CompanyModel } from '@model/company-model/company-model';

export const initializeCompanyDocumentsReaction = (companyStore: Instance<typeof CompanyModel>) =>
    autorun(() =>
        companyStore.company.companyStatus.setHasCompanyDocuments(
            companyStore.company.isDocumentsDone &&
                companyStore.company.isOwnershipDocumentsDone &&
                companyStore.company.isBeneficiaryDocumentsDone &&
                companyStore.company.isDirectorDocumentsDone &&
                companyStore.company.isUserDocumentsDone
        )
    );
