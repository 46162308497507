import { Instance } from 'mobx-state-tree';

import { UserModelType } from '@model/user-model/user-model.type';
import { useStore } from '@store/store-context.hook';

interface LoadingInterface {
    isUserLoading: boolean;
    isProfileLoading: boolean;
}

export const useUserSelector = (): [Instance<typeof UserModelType>, LoadingInterface, string] => {
    const { user, loading, error } = useStore().user;
    return [user, loading, error];
};
