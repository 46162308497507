import { MenuProps } from 'react-select';
import { List } from 'react-virtualized';

import { selectAccountRowWrapper } from '../select-account-row-wrapper/select-account-row-wrapper';
import { NodeOptionInterface, RowRenderInterface } from '../select-account.props';

interface MenuListProps<T> {
    menuHeight: number;
    rowHeight: number;
    rowCount: number;
    rowRender: RowRenderInterface<T>;
    menuWidth: number;
}

export const SelectAccountMenuList =
    ({ menuWidth, menuHeight, rowHeight, rowCount, rowRender }: MenuListProps<NodeOptionInterface>) =>
    (props: MenuProps<NodeOptionInterface, boolean>) =>
        (
            <List
                width={menuWidth}
                height={menuHeight}
                rowHeight={rowHeight}
                rowCount={rowCount}
                rowRenderer={selectAccountRowWrapper(props, rowRender)}
            />
        );
