import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import {
    ElcoinPaymentStatus,
    ExchangeType,
    OperationType,
    PaymentStatus,
    PaymentType,
    TransactionType,
} from '@shared-graphql';
import { BeneficiaryModelType } from '@shared-model/beneficiary-model/beneficiary-model';
import { ClientModel } from '@shared-model/client-model/client-model';
import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';
import { FeeModel } from '@shared-model/fee-model/fee-model';
import { SenderModel } from '@shared-model/sender-model/sender-model';

const typeValues = $enum(TransactionType).getValues();
const statusValues = $enum(PaymentStatus).getValues();
const paymentTypeValues = $enum(PaymentType).getValues();
const operationTypeValues = $enum(OperationType).getValues();
const exchangeTypeValues = $enum(ExchangeType).getValues();
const elcoinPaymentStatusValues = $enum(ElcoinPaymentStatus).getValues();

export const TransactionModelType = types.model('TransactionModel', {
    amount: types.maybeNull(types.number),
    balance: types.maybeNull(types.number),
    beneficiary: types.maybeNull(BeneficiaryModelType),
    buyAmount: types.maybeNull(types.number),
    buyBalance: types.maybeNull(types.number),
    buyCurrency: types.maybeNull(CurrencyModelType),
    client: types.maybeNull(ClientModel),
    createdAt: types.maybeNull(types.Date),
    currency: types.maybeNull(CurrencyModelType),
    details: types.maybeNull(types.string),
    elcoinStatus: types.maybeNull(types.enumeration(elcoinPaymentStatusValues)),
    exchangeType: types.maybeNull(types.enumeration(exchangeTypeValues)),
    fee: types.maybeNull(FeeModel),
    id: types.maybe(types.maybeNull(types.string)),
    operation: types.maybeNull(types.enumeration(operationTypeValues)),
    paymentType: types.maybeNull(types.enumeration(paymentTypeValues)),
    rate: types.maybeNull(types.number),
    reason: types.maybeNull(types.string),
    sellAmount: types.maybeNull(types.number),
    sellBalance: types.maybeNull(types.number),
    sellCurrency: types.maybeNull(CurrencyModelType),
    sender: types.maybeNull(SenderModel),
    status: types.maybeNull(types.maybe(types.enumeration(statusValues))),
    total: types.maybeNull(types.number),
    type: types.maybeNull(types.enumeration(typeValues)),
    updatedAt: types.maybeNull(types.Date),
});
