import { useContext, useMemo } from 'react';
import ReactModal from 'react-modal';

import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalHeightEnum } from '@shared-component/modal/modal.enum';
import { useModal } from '@shared-component/modal/modal.hook';
import { ModalOptionProps } from '@shared-component/modal/modal.options';
import { BackIcon, ButtonBackWrapper, ButtonCloseWrapper, CloseIcon } from '@shared-component/modal/modal.styles';
import { HalfSecond } from '@shared-style/animation';
import { FCWithChildrenType } from '@shared-type/common';
import { noop } from '@shared-util/noop';
import '@shared-component/modal/styles.css';

ReactModal.setAppElement(document.getElementById('modal') as HTMLElement);

const CLOSE_TIMEOUT = 1000 * HalfSecond;

export const Modal: FCWithChildrenType = ({ children }) => {
    const { onClose, removeModalProps, selectedModal, modalHeight, onBack } = useContext(ModelContext);

    const { onAfterOpen, onAfterClose, savedSelectedModal, isVisible } = useModal({
        selectedModal,
        onModalAfterClose: removeModalProps,
    });
    const isModalFull = useMemo(
        () => savedSelectedModal !== null && ModalOptionProps[savedSelectedModal]?.isFull === true,
        [savedSelectedModal]
    );

    const shouldRenderCloseButton = !isModalFull && isVisible;

    return (
        <ReactModal
            closeTimeoutMS={isModalFull ? 0 : CLOSE_TIMEOUT}
            onRequestClose={isModalFull ? noop : onClose}
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
            isOpen={selectedModal !== null}
            className={`modal-content ${isVisible && 'visible'} ${modalHeight}`}
            overlayClassName={`modal-overlay ${isVisible && 'visible'}`}
        >
            {shouldRenderCloseButton && (
                <>
                    <ButtonCloseWrapper onClick={onClose}>
                        <CloseIcon />
                    </ButtonCloseWrapper>
                    <ButtonBackWrapper onClick={onBack} isVisible={modalHeight === ModalHeightEnum.Full}>
                        <BackIcon />
                    </ButtonBackWrapper>
                </>
            )}
            {children}
        </ReactModal>
    );
};
