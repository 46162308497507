import { Translate } from '@shared-atom/translate/translate';

import { ModalHeader, ModalTitle, ModalWrapper } from '@component/modal/client-modal.styles';
import { useUserProfile } from '@component/modal/modals/user-profile/user-profile.hook';
import { UserProfileContentWrapper } from '@component/modal/modals/user-profile/user-profile.styles';
import { UserShortProfileChangeEmail } from '@component/modal/modals/user-profile/user-short-profile-change-email/user-short-profile-change-email';
import { UserShortProfileChangePassword } from '@component/modal/modals/user-profile/user-short-profile-change-password/user-short-profile-change-password';
import { UserShortProfileInformation } from '@component/modal/modals/user-profile/user-short-profile-information/user-short-profile-information';

export const UserProfileModal = () => {
    const { modalTitle, isChangePasswordOpen, isChangeEmailOpen, setChangePasswordOpen, setChangeEmailOpen } =
        useUserProfile();

    return (
        <ModalWrapper>
            <ModalHeader>
                <ModalTitle>
                    <Translate langKey={modalTitle} />
                </ModalTitle>
            </ModalHeader>
            {!isChangePasswordOpen && !isChangeEmailOpen ? (
                <UserProfileContentWrapper>
                    <UserShortProfileInformation
                        setChangeEmailOpen={setChangeEmailOpen}
                        setChangePasswordOpen={setChangePasswordOpen}
                    />
                </UserProfileContentWrapper>
            ) : (
                <>
                    {isChangePasswordOpen && <UserShortProfileChangePassword />}
                    {isChangeEmailOpen && <UserShortProfileChangeEmail />}
                </>
            )}
        </ModalWrapper>
    );
};
