import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal.context';
import { ActivateGoogleAuthenticator, Mutation, UserActivateGoogleAuthenticator } from '@shared-graphql';
import { isExist } from '@shared-util/is-data';

export const useGoogleAuthActivateModal = () => {
    const { onOpen: onInformationModalOpen } = useContext(InformationModalContext);
    const { onClose } = useContext(ModelContext);

    const [qrcode, setQrcode] = useState<string | null>(null);

    const [
        userActivateGoogleAuthenticator,
        { data: dataActivateGoogleAuthenticator, loading: isLoading, error: activateGoogleAuthenticatorError },
    ] = useMutation<Pick<Mutation, 'userActivateGoogleAuthenticator'>>(UserActivateGoogleAuthenticator);

    useEffect(() => {
        userActivateGoogleAuthenticator();
    }, []);

    useEffect(() => {
        if (
            isExist(
                (dataActivateGoogleAuthenticator?.userActivateGoogleAuthenticator as ActivateGoogleAuthenticator)
                    ?.qrcode
            )
        ) {
            setQrcode(
                (dataActivateGoogleAuthenticator?.userActivateGoogleAuthenticator as ActivateGoogleAuthenticator)
                    ?.qrcode
            );
        }
    }, [dataActivateGoogleAuthenticator]);

    useEffect(() => {
        if (isExist(activateGoogleAuthenticatorError?.message)) {
            onClose();
            onInformationModalOpen(InformationModalEnum.FailureInformationModal, {
                text: activateGoogleAuthenticatorError?.message,
            });
        }
    }, [activateGoogleAuthenticatorError]);

    return { qrcode, isLoading };
};
