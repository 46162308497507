import { MenuProps } from 'react-select';
import { List } from 'react-virtualized';

import { BaseOptionInterface } from '@shared-component/select/select.props';

import { customSelectRowWrapper } from '../custom-select-row-wrapper/custom-select-row-wrapper';
import { RowRenderInterface } from '../custom-select.props';

interface MenuListProps<T> {
    menuHeight: number;
    rowHeight: number;
    rowCount: number;
    rowRender: RowRenderInterface<T>;
    menuWidth: number;
}

export const CustomSelectMenuList =
    ({ menuWidth, menuHeight, rowHeight, rowCount, rowRender }: MenuListProps<BaseOptionInterface>) =>
    (props: MenuProps<BaseOptionInterface, boolean>) =>
        (
            <List
                width={menuWidth}
                height={menuHeight}
                rowHeight={rowHeight}
                rowCount={rowCount}
                rowRenderer={customSelectRowWrapper(props, rowRender)}
            />
        );
