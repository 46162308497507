import ChevronIcon from '@shared-asset/vector/arrow-small-right.svg?react';
import SuccessIcon from '@shared-asset/vector/check-circle.svg?react';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { CrumbIcon, CrumbWrapper } from './payment-crumbs.style';

interface CrumbProps {
    title: string;
    onClick: OnEventEmptyType;
    isActive: boolean;
    isDisabled: boolean;
}

export const Crumb = ({ title, onClick, isActive, isDisabled }: CrumbProps) => (
    <>
        <CrumbIcon isDisabled={isDisabled} isActive={isActive}>
            {!isDisabled && !isActive ? <SuccessIcon /> : <ChevronIcon />}
        </CrumbIcon>
        <CrumbWrapper type={ButtonTypeEnum.Button} onClick={onClick} isDisabled={isDisabled} isActive={isActive}>
            <Translate langKey={title} />
        </CrumbWrapper>
    </>
);
