import { useContext, useEffect } from 'react';

import { ModelContext } from '@shared-component/modal/modal.context';

import { useAccountActionsSelector } from '@selector/account/use-account.actions-selector';
import { useAccountSelector } from '@selector/account/use-account.selector';
import { useCurrencyActionsSelector } from '@selector/currencies/use-currencies.actions-selector';
import { useCurrencySelector } from '@selector/currencies/use-currencies.selector';

import { AddCurrencyInterface } from './currency-add-button/currency-add-button.interface';

export const useAddCurrency = () => {
    const { onClose } = useContext(ModelContext);

    const [{ currencies: availableCurrencies }] = useCurrencySelector();
    const [{ accounts }] = useAccountSelector();
    const { loadActiveCurrencies } = useCurrencyActionsSelector();
    const { updateAccountCurrencies } = useAccountActionsSelector();

    useEffect(() => void loadActiveCurrencies(), []);

    const activeCurrencies: AddCurrencyInterface = {};

    const chosenCurrencies = accounts.map(({ currency }) => {
        activeCurrencies[currency.code] = true;
        return {
            ...currency,
            isDisabled: true,
        };
    });

    const restCurrencies = availableCurrencies.filter(({ code }) => !activeCurrencies[code]);

    const currencies = [...chosenCurrencies, ...restCurrencies];

    const onSubmit = (values: AddCurrencyInterface) => {
        const selectedCurrencies = Object.keys(values).filter(currencyCode => values[currencyCode]);
        updateAccountCurrencies(selectedCurrencies);
        onClose();
    };

    return { currencies, activeCurrencies, onSubmit };
};
