import styled from 'styled-components';

import FailureIcon from '@shared-asset/vector/failure-close-roundmark.svg?react';
import FinallyIcon from '@shared-asset/vector/success-checkmark.svg?react';
import { ElpasoButton } from '@shared-atom/elpaso-kit/button/style';
import { Typography } from '@shared-atom/typography/typography';
import { Error50, Neutral200, Primary50 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

interface IconWrapperProps {
    isErrorVisible: boolean;
}

export const FinallyActionContentWrapper = styled.div`
    height: 100%;

    ${Flex.Column.VerticalCenter.Style}
`;
export const FinallyActionContent = styled.div`
    height: 100%;
    ${Flex.Column.VerticalCenter.HorizontalCenter.Style};
`;
export const FinallyActionFooter = styled.div`
    height: 84px;
    min-height: 84px;
    width: 100%;
    border-top: 1px solid ${Neutral200};
    ${Flex.VerticalCenter.HorizontalCenter.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${ElpasoButton} {
            width: calc(100% - 40px);
        }
    }
`;
export const FinallyActionIconWrapper = styled.div<IconWrapperProps>`
    border-radius: 50%;
    width: 80px;
    height: 80px;
    background-color: ${({ isErrorVisible }) => (isErrorVisible ? Error50 : Primary50)};
    margin-bottom: 24px;
    ${Flex.VerticalCenter.HorizontalCenter.Style}
`;
export const FinallyActionIcon = styled(FinallyIcon)``;
export const FailureActionIcon = styled(FailureIcon)``;
export const FinallyActionMessage = styled.h2`
    width: 320px;
    ${Typography.Paragraph16.Medium.Neutral700.Center.Style}
`;
