import { types } from 'mobx-state-tree';

import { userModelLoadActions } from '@model/user-model/user-model-actions/user-model-load.actions';
import { userModelMainActions } from '@model/user-model/user-model-actions/user-model-main.actions';
import { userModelPrivateActions } from '@model/user-model/user-model-actions/user-model-private.actions';

import { userModelExitActions } from './user-model-actions/user-model-exit.actions';
import { initialUserModel } from './user-model-initials/user-model.initial';
import { UserModelType } from './user-model.type';

export const UserModelInstance = types.model('UserModel', {
    user: UserModelType,
    loading: types.model('UserLoading', {
        isUserLoading: types.boolean,
        isProfileLoading: types.boolean,
    }),
    error: types.optional(types.string, ''),
});

export const UserModel = UserModelInstance.actions(userModelMainActions)
    .actions(userModelPrivateActions)
    .actions(userModelLoadActions)
    .actions(userModelExitActions);

export const getInitialUserModel = () =>
    UserModel.create({
        user: initialUserModel as any,
        loading: {
            isUserLoading: false,
            isProfileLoading: false,
        },
    });
