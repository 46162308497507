import { FC } from 'react';

import { OtpSendForm } from '@shared-form/otp-send-form/otp-send-form';
import { OtpSendFormProps } from '@shared-form/otp-send-form/otp-send-form.interface';
import { noop } from '@shared-util/noop';

import {
    OtpComponentWrapper,
    OtpWrapper,
} from '@component/modal/modals/payment/payment-update-steps/payment-otp-form/payment-otp-form.styles';

export const PaymentOtpForm: FC<OtpSendFormProps> = ({
    phone,
    error,
    onSubmit,
    isLoading,
    onChange = noop,
    onResendOtpCode,
}) => (
    <OtpWrapper>
        <OtpComponentWrapper isLoading={isLoading}>
            <OtpSendForm
                onChange={onChange}
                error={error}
                phone={phone}
                onSubmit={onSubmit}
                onResendOtpCode={onResendOtpCode}
            />
        </OtpComponentWrapper>
    </OtpWrapper>
);
