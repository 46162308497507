import { $enum } from 'ts-enum-util';
import { object, string } from 'yup';

import { BeneficiaryDetails } from '@shared-graphql';
import { REQUIRED_FIELD_ERROR } from '@shared-util/constants';
import { isLikeIban } from '@shared-util/is-like-iban';

import { PaymentDetailsFormEnum } from '@component/modal/modals/payment/payment-form/payment-form-steps/payment-details-form/payment-details-form.enum';
import {
    BENEFICIARY_PREFIX,
    REASON_REQUIRED_FIELD_REGEXP,
} from '@component/modal/modals/payment/payment-form/payment-form-steps/payment-form-steps.options';

const getYupValidation = (name: string, validation: string, onlyRequiredFields: string[]) => {
    const validator = string().required(REQUIRED_FIELD_ERROR);

    if (onlyRequiredFields.includes(name)) {
        return { [name]: validator };
    }

    return { [name]: validator.matches(new RegExp(validation)) };
};

const paymentBeneficiaryFormFields = $enum(PaymentDetailsFormEnum).getValues();

export const getBeneficiaryValidation = (paramFields: BeneficiaryDetails) => {
    const validationSchema = Object.entries(paramFields).reduce((schema, [name, validation]) => {
        if (
            validation !== null &&
            paymentBeneficiaryFormFields.includes(`${BENEFICIARY_PREFIX}.${name}` as PaymentDetailsFormEnum)
        ) {
            return {
                ...schema,
                ...getYupValidation(name, validation, [PaymentDetailsFormEnum.BeneficiaryEntityType]),
            };
        }

        return schema;
    }, {});

    return object().shape({
        beneficiary: object().shape(validationSchema),
        reason: string()
            .required(REQUIRED_FIELD_ERROR)
            .matches(
                new RegExp(REASON_REQUIRED_FIELD_REGEXP),
                'Payment reference contains invalid characters. Acceptable characters are numbers, letters, space and \\/ - ? : ( ) . , \u0027 +'
            ),
    });
};

export const getBeneficiaryDetails = (
    { iban, ...beneficiary }: Partial<BeneficiaryDetails>,
    bankCountry: string
    // hasSortCode: boolean
) => ({
    ...beneficiary,
    bankCountry,
    [isLikeIban(iban ?? '') ? 'iban' : 'acctNumber']: iban,
    // [hasSortCode ? 'sortCode' : 'bicSwift']: bicSwift,
});
