import styled from 'styled-components';

import { Flex } from '@shared-style/flex';

import { ModalContentWrapper, ModalFormRowWrapper } from '@component/modal/client-modal.styles';

export const UserShortProfileChangeEmailWrapper = styled(ModalContentWrapper)`
    form {
        ${Flex.Column.SpaceBetween.Style};
    }
    ${ModalFormRowWrapper} {
        margin-top: 32px;
    }
`;
