import ArrowCircleDown from '@shared-asset/vector/arrow-circle-down.svg?react';
import CreditCard from '@shared-asset/vector/credit-card.svg?react';
import Smartphone from '@shared-asset/vector/smartphone.svg?react';
import Wallet from '@shared-asset/vector/wallet.svg?react';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PageEnum } from '@enum/page.enum';
import { ProductsEnum } from '@page/main-pages/main-page-common/header/components/product-select/product-select.enum';

export const productOptions = [
    {
        value: ProductsEnum.PersonalIBAN,
        langKey: LocalizationEnum.PersonalIban,
        page: PageEnum.OnlineAccount,
        icon: <Wallet />,
    },
    {
        value: ProductsEnum.PaymentsAcceptance,
        langKey: LocalizationEnum.PaymentsAcceptance,
        page: PageEnum.Acquiring,
        icon: <ArrowCircleDown />,
    },
    {
        value: ProductsEnum.PayoutsToCards,
        langKey: LocalizationEnum.PayoutsToCards,
        page: PageEnum.Payouts,
        icon: <CreditCard />,
    },
    {
        value: ProductsEnum.MobileApp,
        langKey: LocalizationEnum.CommonApplication,
        page: PageEnum.MobileApp,
        icon: <Smartphone />,
    },
];
