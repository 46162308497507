import { useMemo } from 'react';
import { v4 } from 'uuid';

import { LocalizationTextType, useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';

export interface TranslateProps {
    langKey: LocalizationTextType;
    separator?: string;
    customRender?: any;
}

const NEW_LINE_SEPARATOR = /\n/gm;
const NEW_LINE_BREAKER = (key: string) => <br key={key} />;

export const Translate = ({ langKey, separator = ' ', customRender }: TranslateProps) => {
    const text = useLocalizationText(langKey);

    if (import.meta.env.MODE !== 'production') {
        if (text === undefined) {
            console.error('langKey', langKey, text);
        }
    }

    const result = useMemo(
        () =>
            ((Array.isArray(text) ? text.join(separator) : text) ?? '')
                .split(NEW_LINE_SEPARATOR)
                .reduce((acc, currentText) => {
                    const newText = customRender instanceof Function ? customRender(currentText) : currentText;
                    return acc.length > 0 ? [...acc, NEW_LINE_BREAKER(v4()), newText] : [newText];
                }, [] as any[]),
        [customRender, separator, text]
    );

    return (
        <>
            {Boolean(result) === true && result}
            {Boolean(result) === false && null}
        </>
    );
};
