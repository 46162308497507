import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral200, Neutral50, Primary50, Primary500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const PaymentFlowSelectorOptionsWrapper = styled.div`
    ${Flex.Column.Style};
    margin-top: 32px;
    gap: 8px;
`;
export const PaymentFlowSelectorSubtitle = styled.h4`
    ${Typography.Paragraph14.Medium.Neutral700.Style};
    margin-bottom: 4px;
`;
export const PaymentFlowButtonWrapper = styled.div`
    ${Flex.Row.VerticalCenter.Style};
    gap: 10px;
`;

export const PaymentFlowButton = styled.div`
    border-radius: 8px;
    padding: 16px;
    flex: 1;
    min-width: 250px;
    cursor: pointer;
    border: 1px solid ${Neutral200};
    background: ${Neutral50};
    ${Typography.Paragraph14.SemiBold.Left.Style};
    ${Flex.Row.SpaceBetween.VerticalCenter.Style};
    &:hover {
        border: 1px solid ${Primary500};
        background: ${Primary50};
    }
`;
export const PaymentFlowButtonIcon = styled.img``;
