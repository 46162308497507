import { FC, SVGProps } from 'react';

import AED from './assets/aed.svg?react';
import AUD from './assets/aud.svg?react';
import CAD from './assets/cad.svg?react';
import CHF from './assets/chf.svg?react';
import CZK from './assets/czk.svg?react';
import DKK from './assets/dkk.svg?react';
import EUR from './assets/eur.svg?react';
import GBP from './assets/gbp.svg?react';
import HKD from './assets/hkd.svg?react';
import HRK from './assets/hrk.svg?react';
import HUF from './assets/huf.svg?react';
import JPY from './assets/jpy.svg?react';
import KES from './assets/kes.svg?react';
import MXN from './assets/mxn.svg?react';
import NOK from './assets/nok.svg?react';
import NZD from './assets/nzd.svg?react';
import PLN from './assets/pln.svg?react';
import RON from './assets/ron.svg?react';
import SAR from './assets/sar.svg?react';
import SEK from './assets/sek.svg?react';
import SGD from './assets/sgd.svg?react';
import TRY from './assets/try.svg?react';
import USD from './assets/usd.svg?react';
import WORLD from './assets/world.svg?react';
import ZAR from './assets/zar.svg?react';

interface CurrencyIconsInterface {
    [key: string]: FC<SVGProps<SVGSVGElement>>;
}

export const CurrencyIcons: CurrencyIconsInterface = {
    EUR,
    USD,
    AED,
    AUD,
    CAD,
    CHF,
    CZK,
    DKK,
    GBP,
    HKD,
    HRK,
    HUF,
    JPY,
    KES,
    MXN,
    NOK,
    NZD,
    PLN,
    RON,
    SAR,
    SEK,
    SGD,
    TRY,
    ZAR,
    WORLD,
};
