import { applySnapshot, types } from 'mobx-state-tree';

import { Maybe, Address } from '../../graphql';
import { initialAddress } from './address.initial';

export const AddressType = types
    .model('AddressType', {
        id: types.maybeNull(types.string),
        street: types.maybeNull(types.string),
        zip: types.maybeNull(types.string),
        city: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        building: types.maybeNull(types.string),
    })
    .actions(self => ({
        applyAddress: (address: Maybe<Address>) => {
            applySnapshot(self, address ?? initialAddress);
        },
    }));
