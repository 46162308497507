export enum CreatePartnerFormEnum {
    EntityType = 'beneficiary.beneficiaryEntityType',
    FirstName = 'beneficiary.beneficiaryFirstName',
    LastName = 'beneficiary.beneficiaryLastName',
    CompanyName = 'beneficiary.beneficiaryCompanyName',
    AcctNumber = 'beneficiary.acctNumber',
    BicSwift = 'beneficiary.bicSwift',
    BankName = 'beneficiary.bankName',
    Currency = 'currency',
    ShouldRenderAddressFields = 'shouldRenderAddressFields',
    Country = 'beneficiary.beneficiaryCountry',
    City = 'beneficiary.beneficiaryCity',
    Postcode = 'beneficiary.beneficiaryPostcode',
    Address = 'beneficiary.beneficiaryAddress',
    BeneficiaryInitial = 'beneficiary',
}

export enum BeneficiaryFormEnum {
    FirstName = 'beneficiaryFirstName',
    LastName = 'beneficiaryLastName',
    CompanyName = 'beneficiaryCompanyName',
    AcctNumber = 'acctNumber',
    BicSwift = 'bicSwift',
    Country = 'beneficiaryCountry',
    City = 'beneficiaryCity',
    Postcode = 'beneficiaryPostcode',
    Address = 'beneficiaryAddress',
}
