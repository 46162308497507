import { FC, JSX, ReactNode, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { TooltipDataForEnum } from '@shared-atom/tooltip/tooltip-data-for.enum';
import { Icon, TooltipText, TooltipWrapper } from '@shared-atom/tooltip/tooltip.styles';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { Fiord, White } from '@shared-style/colors';
import { ThemePropsInterface } from '@shared-type/theme-props.type';

import { linkMarkdownTag } from '@component/link-markdown-tag/link-markdown-tag';

export interface TooltipProps {
    content: string | LocalizationEnum | ReactNode | ReactNode[] | JSX.Element;
    dataFor: TooltipDataForEnum;
    effect?: 'float' | 'solid';
    place?: 'top' | 'right' | 'bottom' | 'left';
    isShow?: boolean;
    backgroundColor?: string;
    textColor?: string;
    children?: ReactNode | ReactNode[] | JSX.Element;
    clickable?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({
    content,
    children,
    dataFor,
    place = 'bottom',
    effect = 'float',
    backgroundColor = White,
    textColor = Fiord,
    isShow = true,
    clickable = false,
}) => {
    const normalizedContent =
        typeof content !== 'string' ? content : <Translate langKey={content} customRender={linkMarkdownTag} />;
    const {
        size: { isMaxMobile },
    } = useContext<ThemePropsInterface>(ThemeContext) as ThemePropsInterface;

    return isShow ? (
        <>
            <TooltipWrapper
                id={dataFor}
                getContent={() => normalizedContent}
                backgroundColor={backgroundColor}
                textColor={textColor}
                place={place}
                effect={effect}
                event={!isMaxMobile ? 'click' : undefined}
                delayHide={100}
                clickable={clickable}
                scrollHide
                globalEventOff="click"
            />
            <TooltipText data-tip="tooltip" data-for={dataFor}>
                {children ?? <Icon />}
            </TooltipText>
        </>
    ) : (
        <div>{children}</div>
    );
};
