export enum AccountCurrencyFormEnum {
    AccountId = 'accountId',
    PaymentCurrency = 'currency',
    Amount = 'amount',
    AddressBook = 'addressBook',
    Iban = 'beneficiary.iban',
    BicSwift = 'beneficiary.bicSwift',
    SortCode = 'beneficiary.sortCode',
    BankName = 'beneficiary.bankName',
    BankCountry = 'beneficiary.bankCountry',
    Purpose = 'purpose',
    InvoiceNumber = 'beneficiary.invoiceNumber',
    InvoiceDate = 'beneficiary.invoiceDate',
    CharityNumber = 'beneficiary.charityNumber',
}
