import styled from 'styled-components';

import { CheckBoxStyle, CheckBoxWrapper } from '@shared-component/checkbox/checkbox.styles';
import { Geyser, Mystic, WhiteDark } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const InputWrapper = styled.div`
    padding: 16px 8px 16px;
    box-shadow: 0 1px 0 0 ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? WhiteDark : Mystic)};

    ${CheckBoxStyle} {
        min-height: inherit;
    }

    ${CheckBoxWrapper} {
        ${Flex.InlineFlex.VerticalCenter.Row.Style}
    }
`;

export const InputTitleWrapper = styled.p`
    margin-left: 16px;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? Geyser : Mystic)};
`;

export const CurrencyIconWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50px;
    overflow: hidden;
    margin-left: 16px;
    ${Flex.HorizontalCenter.VerticalCenter.Style}
`;
