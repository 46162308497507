import { Neutral500, Neutral700, Primary500, Primary800 } from '../../../styles/colors';
import { LinkTypeEnum } from './types';

export const getStyleByType = (type: LinkTypeEnum, disabled: boolean) => {
    switch (type) {
        case LinkTypeEnum.PRIMARY:
            return `
                color: ${Primary500};
                :active{
                    color: ${Primary800};
                }
                :visited{
                    color: ${Primary800};
                }
                :hover{
                    color: ${Primary500};
                }
                ${disabled && 'pointer-events: none; opacity: 0.5'}
            `;
        case LinkTypeEnum.SECONDARY:
            return `
                color: ${Neutral700};
                :active{
                    color: ${Primary800};
                }
                :visited{
                    color: ${Primary800};
                }
                :hover{
                    color: ${Primary500};
                }
                ${disabled && 'pointer-events: none; opacity: 0.5'}
            `;
        case LinkTypeEnum.GREY:
            return `
                color: ${Neutral500};
                :active{
                    color: ${Primary800};
                }
                :visited{
                    color: ${Primary800};
                }
                :hover{
                    color: ${Primary500};
                }
                ${disabled && 'pointer-events: none; opacity: 0.6'}
            `;
        default:
            return `
                color: ${Primary500};
                :active{
                    color: ${Primary800};
                }
                :visited{
                    color: ${Primary800};
                }
                :hover{
                    color: ${Primary500};
                }
                ${disabled && 'pointer-events: none; opacity: 0.5'}
            `;
    }
};
