import { Form, Formik } from 'formik';
import React, { useContext } from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { Translate } from '@shared-atom/translate/translate';
import { ModelContext } from '@shared-component/modal/modal.context';
import { Scroll } from '@shared-component/scroll/scroll';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ModalFooter, ModalFormRowWrapper } from '@component/modal/client-modal.styles';
import { ApplyVacancyFormEnum } from '@component/modal/modals/apply-vacancy/apply-vacancy.enum';
import { useApplyVacancyForm } from '@component/modal/modals/apply-vacancy/apply-vacancy.hook';
import { applyVacancyFormInitials } from '@component/modal/modals/apply-vacancy/apply-vacancy.initial';
import {
    ApplyVacancyModalTitle,
    ApplyVacancyModalWrapper,
} from '@component/modal/modals/apply-vacancy/apply-vacancy.styles';
import { applyVacancyFormValidationSchema } from '@component/modal/modals/apply-vacancy/apply-vacancy.validation';

export const ApplyVacancyModal = () => {
    const { onClose } = useContext(ModelContext);
    const { isLoading, onSubmit } = useApplyVacancyForm(onClose);

    return (
        <ApplyVacancyModalWrapper>
            <ApplyVacancyModalTitle>
                <Translate langKey={LocalizationEnum.JoinOurTeam} />
            </ApplyVacancyModalTitle>
            <Formik
                initialValues={applyVacancyFormInitials}
                validationSchema={applyVacancyFormValidationSchema}
                onSubmit={onSubmit}
                validateOnChange={false}
            >
                <Form>
                    <Scroll>
                        <ModalFormRowWrapper>
                            <CustomInput
                                label={LocalizationEnum.CommonName}
                                name={ApplyVacancyFormEnum.Name}
                                placeholder={LocalizationEnum.CommonYourName}
                                isMobile
                            />
                        </ModalFormRowWrapper>
                        <ModalFormRowWrapper>
                            <CustomInput
                                label={LocalizationEnum.CommonEmailUkFull}
                                name={ApplyVacancyFormEnum.Email}
                                placeholder="yourmail@example.com"
                                isMobile
                            />
                        </ModalFormRowWrapper>
                        <ModalFormRowWrapper>
                            <CustomInput
                                label={LocalizationEnum.CommonLinkedin}
                                name={ApplyVacancyFormEnum.LinkedinProfile}
                                placeholder={LocalizationEnum.LinkedinProfileLink}
                                isMobile
                            />
                        </ModalFormRowWrapper>
                    </Scroll>
                    <ModalFooter>
                        <Button
                            isSubmit
                            isLoading={isLoading}
                            title={LocalizationEnum.CommonApply}
                            size={ButtonSizeEnum.L}
                            type={ButtonTypeEnum.COLOR}
                        />
                    </ModalFooter>
                </Form>
            </Formik>
        </ApplyVacancyModalWrapper>
    );
};
