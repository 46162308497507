import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import soon from '@component/modal/modals/request-statement/statement-form/empty-accounts/assets/soon.png';
import {
    EmptyAccountsDescription,
    EmptyAccountsPreview,
    EmptyAccountsTitle,
    EmptyAccountsWrapper,
} from '@component/modal/modals/request-statement/statement-form/empty-accounts/empty-accounts.styles';

export const EmptyAccounts = () => (
    <EmptyAccountsWrapper>
        <EmptyAccountsPreview src={soon} />
        <EmptyAccountsTitle>
            <Translate langKey={LocalizationEnum.YourAccountWillBeAvailableHere} />
        </EmptyAccountsTitle>
        <EmptyAccountsDescription>
            <Translate langKey={LocalizationEnum.ItUsuallyTakes24HoursToCompleteOurStandardChecksAndAssessments} />
        </EmptyAccountsDescription>
    </EmptyAccountsWrapper>
);
