import { Instance, flow, applySnapshot, getSnapshot } from 'mobx-state-tree';

import { GetFeatureTags, Mutation, Query, UserAddFeatureTag, UserFeatureTag } from '@shared-graphql';
import { isFalse } from '@shared-util/is-data';

import { apolloClient } from '@connection/apollo-client';
import { FeatureTagsModel, FeatureTagsModelType } from '@model/feature-tags-model/feature-tags-model';

const queryGetFeatureTags = () =>
    apolloClient
        .query<Pick<Query, 'getFeatureTags'>>({ query: GetFeatureTags })
        .then(result => result?.data?.getFeatureTags);

const mutationAddFeatureTag = (userFeatureTag: UserFeatureTag) =>
    apolloClient
        .mutate<Pick<Mutation, 'userAddFeatureTag'>>({
            mutation: UserAddFeatureTag,
            variables: { userFeatureTag },
        })
        .then(result => result?.data?.userAddFeatureTag);

export const featureTagsModelActions = (self: Instance<typeof FeatureTagsModelType>) => ({
    setLoading: (loading: boolean) => {
        self.loading.isLoading = loading;
    },
    clearFeatureTags: () => {
        self.elements = [] as any;
    },
    loadFeatureTags: flow(function* loadFeatureTags() {
        (self as Instance<typeof FeatureTagsModel>).setLoading(true);

        try {
            const featureTags = yield queryGetFeatureTags();

            const currentSnapshot = getSnapshot(self);

            applySnapshot(self, {
                ...currentSnapshot,
                elements: featureTags ?? [],
            });
        } catch (error: any) {
            throw new Error(error);
        } finally {
            (self as Instance<typeof FeatureTagsModel>).setLoading(false);
        }
    }),
    userAddFeatureTag: flow(function* userAddFeatureTag(userFeatureTag: UserFeatureTag) {
        (self as Instance<typeof FeatureTagsModel>).setLoading(true);

        try {
            const { status } = yield mutationAddFeatureTag(userFeatureTag);

            if (isFalse(status)) {
                throw new Error("Feature tag hasn't been added");
            }
        } catch (error: any) {
            throw new Error(error);
        } finally {
            (self as Instance<typeof FeatureTagsModel>).setLoading(false);
            (self as Instance<typeof FeatureTagsModel>).loadFeatureTags();
        }
    }),
});
