import { Field, useField } from 'formik';
import { useEffect, FC, ReactNode, JSX } from 'react';

import { OnEventType } from '../../types/on-event.type';
import { noop } from '../../utils/noop';
import { RadioButtonWrapper, RadioButtonIconWrapper } from './radio-button.style';

export interface RadioButtonProps {
    name: string;
    value: string | number;
    onChange?: OnEventType<boolean>;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const RadioButton: FC<RadioButtonProps> = ({ name, value, onChange = noop, children }) => {
    const [field] = useField(name);
    const isChecked = field.value === value;

    useEffect(() => void onChange(field.value), [field.value]);

    return (
        <RadioButtonWrapper>
            <Field type="radio" name={name} value={value} hidden />
            {children ?? <RadioButtonIconWrapper isChecked={isChecked} />}
        </RadioButtonWrapper>
    );
};
