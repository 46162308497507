import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { CurrencyIcon } from '@shared-atom/currency-icon/currency-icon';
import { CheckBox } from '@shared-component/checkbox/checkbox';
import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';

import { CurrencyIconWrapper, InputTitleWrapper, InputWrapper } from './currency-checkbox.styles';

interface CurrencyCheckBoxProps extends Instance<typeof CurrencyModelType> {
    isDisabled?: boolean;
}

export const CurrencyCheckBox: FC<CurrencyCheckBoxProps> = ({ code, currency, isDisabled = false }) => (
    <InputWrapper key={code}>
        <CheckBox name={code} disabled={isDisabled}>
            <CurrencyIconWrapper>
                <CurrencyIcon code={code} />
            </CurrencyIconWrapper>
            <InputTitleWrapper>
                {currency}({code})
            </InputTitleWrapper>
        </CheckBox>
    </InputWrapper>
);
