import { Instance } from 'mobx-state-tree';

import { AddressBookModelType } from '@model/address-book-model/address-book-model.type';
import { useStore } from '@store/store-context.hook';

export const useAddressBookSelector = (): [Instance<typeof AddressBookModelType>, boolean, string] => {
    const { data, loading, error } = useStore().addressBook;

    return [data, loading.isAddressBookLoading, error];
};
