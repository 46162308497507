import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';
import { IsDisabledType } from '@shared-type/common';

export const UserProfileInformationWrapper = styled.div`
    width: 100%;
    gap: 24px;
    ${Flex.Column.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        gap: 24px;
    }
`;
export const UserProfileInformationItemWrapper = styled.div`
    width: 100%;
    ${Flex.Row.SpaceBetween.Style};
`;
export const UserProfileInformationItemTitle = styled.span`
    ${Typography.Paragraph14.Medium.Neutral500.Style};
`;
export const UserProfileInformationItemData = styled.span`
    ${Typography.Paragraph14.Medium.Neutral700.Style};
    ${Flex.Row.Style};
    gap: 16px;
`;
export const UserProfileInformationItemEdit = styled.img<IsDisabledType>`
    width: 14.5px;
    height: 14.5px;
    cursor: pointer;
    ${({ isDisabled }) =>
        isDisabled &&
        `
        cursor: default;
        pointer-events: none;
        opacity: 0.6;
    `}}
`;
