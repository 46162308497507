import styled from 'styled-components';

import { ElpasoButton } from '@shared-atom/elpaso-kit/button/style';
import { CustomInputStyle } from '@shared-atom/elpaso-kit/input/custom-input/custom-input.styles';
import { InputWrapper, Label, LabelWrapper } from '@shared-atom/elpaso-kit/input/input.styles';
import { Typography } from '@shared-atom/typography/typography';
import { FieldErrorWrapper } from '@shared-component/field-error/field-error.styles';
import { Neutral500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const FooterFollowFormWrapper = styled.div`
    form {
        ${Flex.Row.FlexEnd.Style};
        gap: 16px;
        ${InputWrapper} {
            width: 100%;
            max-width: 354px;
            ${Label} {
                ${Typography.Paragraph14.Regular.Neutral500.Style};
            }
            ${FieldErrorWrapper} {
                position: absolute;
            }
            ${LabelWrapper} {
                box-shadow: none;
                background: transparent;
                ${CustomInputStyle} {
                    border-radius: 8px;
                    border: 1px solid ${Neutral500};
                    padding: 16px 12px;
                    ${Typography.Paragraph14.SemiBold.Neutral500.Style}
                    &::placeholder {
                        ${Typography.Paragraph14.SemiBold.Neutral500.Style}
                    }
                }
            }
        }
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        form {
            ${Flex.Column.FlexStart.Style};
            gap: 12px;
            ${InputWrapper} {
                max-width: unset;
                ${Label} {
                    margin-bottom: 12px;
                }
                ${FieldErrorWrapper} {
                    position: relative;
                }
            }
            ${ElpasoButton} {
                width: 100%;
                margin-top: -4px;
            }
        }
    }
`;
