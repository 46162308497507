import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

import { MAX_MOBILE_SIZE } from '../../../../styles/media-sizes';
import { resetInputStyle } from '../../../../styles/reset-style';
import { Typography } from '../../../typography/typography';
import { InputWrapper } from '../input.styles';

interface InputAmountViewProps {
    isMobile?: boolean;
}

export const InputAmountView = styled(CurrencyInput)<InputAmountViewProps>`
    border: none;
    flex: 1;
    padding: 0 12px;
    width: 100%;
    ${resetInputStyle};
    ${({ isMobile = false }) =>
        isMobile ? Typography.Paragraph14.SemiBold.Neutral900.Style : Typography.Paragraph16.SemiBold.Neutral900.Style};
    background-color: transparent;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.Paragraph14.SemiBold.Neutral900.Style};
    }
`;
export const InputAmountWithSelect = styled(InputWrapper)`
    position: relative;
`;
