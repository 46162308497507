import styled from 'styled-components';

import { getAnimationStyle } from '../../styles/animation';
import { LinkWater } from '../../styles/colors';
import { Flex } from '../../styles/flex';
import { MAX_MOBILE_SIZE } from '../../styles/media-sizes';

export const PageWrapperRoot = styled.section`
    height: 100%;
    background-color: ${LinkWater};
    ${Flex.Row.Style}
    ${getAnimationStyle(['background-color'])}
    flex-direction: row;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        flex-direction: column;
    }
`;

export const PageContentWrapper = styled.div`
    width: 100%;
    flex: 1;
    padding: 0 20px;
    ${Flex.Column.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 16px 10px 0px 10px;
    }
`;
