import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { FieldErrorWrapper } from '@shared-component/field-error/field-error.styles';
import { getAnimationStyle } from '@shared-style/animation';
import { Error500, Neutral200, Neutral50, Primary50, Primary500 } from '@shared-style/colors';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';
import { isTrue } from '@shared-util/is-data';

interface WrapperProps extends ThemeTypeInterface {
    isFocused: boolean;
    hasError: boolean;
    isDisabled?: boolean;
    isMobile?: boolean;
    isOverHide?: boolean;
}

export interface LabelProps {
    hasError?: boolean;
}

const getLabelWrapperBoxShadowColor = ({ hasError, isFocused, isDisabled }: WrapperProps) => {
    if (hasError) {
        return Error500;
    }
    if (isTrue(isDisabled)) {
        return Neutral200;
    }
    if (isFocused) {
        return Primary500;
    }
    return Neutral200;
};

export const Label = styled.span<LabelProps>`
    pointer-events: none;
    margin-bottom: 4px;
    display: block;
    ${Typography.Paragraph12.SemiBold.Neutral900.Left.Style};
    ${({ hasError = false }) => hasError && `color: ${Error500}`};
`;

export const LabelWrapper = styled.label<WrapperProps>`
    display: inline-flex;
    position: relative;
    width: 100%;
    background-color: ${Neutral50};
    border-radius: 8px;
    box-shadow: 0 0 0 1px ${getLabelWrapperBoxShadowColor};
    height: ${({ isMobile = false }) => (isMobile ? '52px' : '60px')};
    overflow: ${({ isOverHide = true }) => (isOverHide ? 'hidden' : 'visible')};
    &:hover {
        background-color: ${({ isDisabled = false }) => !isDisabled && Primary50};
    }
    ${getAnimationStyle(['box-shadow'])};
    & ${Label} {
        ${({ isDisabled = false }) => isDisabled && `opacity: 0.5`};
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        height: 52px;
    }
`;

export const InputWrapper = styled.div``;
export const ErrorWrapper = styled(FieldErrorWrapper)``;
