import { useMemo } from 'react';

import { Account } from '@shared-graphql';
import { getLocalStorageValue } from '@shared-hook/utils/use-local-storage.hook';
import { DEFAULT_CURRENCY } from '@shared-util/constants';
import { isExist } from '@shared-util/is-data';

export const useSelectedAccountWithStorage = ({ accounts }: { accounts: Account[] }) => {
    const selectedAccountFromStorage = getLocalStorageValue('selectedAccount');
    const [firstAccount] = accounts;

    const defaultAccount = useMemo(
        () => accounts.find(({ currency: { code } }) => code === DEFAULT_CURRENCY) ?? firstAccount,
        [accounts, firstAccount]
    );

    return useMemo(
        () =>
            isExist(selectedAccountFromStorage)
                ? accounts.find(({ currency: { code } }) => code === selectedAccountFromStorage) ?? firstAccount
                : defaultAccount,
        [accounts, selectedAccountFromStorage]
    ) as Account;
};
