import { MenuProps } from 'react-select';
import { ListRowProps } from 'react-virtualized/dist/es/List';

import { BaseOptionInterface, RowRenderInterface } from '../select.props';
import { SelectRowWrapperStyle } from '../select.styles';

export const selectRowWrapper =
    (
        { options, setValue }: MenuProps<BaseOptionInterface, boolean>,
        rowRender: RowRenderInterface<BaseOptionInterface>
    ) =>
    ({ style, key, index }: ListRowProps) => {
        const currentItem = options[index];

        const handleChoose = () => setValue(currentItem, 'select-option');

        return (
            <SelectRowWrapperStyle key={key} style={style} onClick={handleChoose}>
                {rowRender(currentItem, index)}
            </SelectRowWrapperStyle>
        );
    };
