import { useField } from 'formik';
import { useEffect } from 'react';

import { Input } from '@shared-component/input/input';
import { isString } from '@shared-util/is-data';

import { CreatePartnerFormEnum } from '@component/modal/modals/create-partner/create-partner-form/create-partner-form.enum';
import { useAddressBookSelector } from '@selector/address-book/use-address-book.selector';

import { useCreatePartnerFormAcctNumberLocalization } from './create-partner-form-acct-number.localization-hook';

export const CreatePartnerFormAcctNumber = () => {
    const [, , { setError }] = useField(CreatePartnerFormEnum.AcctNumber);
    const [, , error] = useAddressBookSelector();
    const { accountTitle, statusTitle } = useCreatePartnerFormAcctNumberLocalization();

    useEffect(() => void (isString(error) && setError(statusTitle)), [error]);

    return <Input name={CreatePartnerFormEnum.AcctNumber} title={accountTitle} />;
};
