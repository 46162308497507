import { format } from 'date-fns';
import { FC } from 'react';

import { DateFormatWithTimeSplitComma } from '@shared-atom/elpaso-kit/input/input-date/input-date-format.helper';
import { Translate } from '@shared-atom/translate/translate';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { InformationCopyRow } from '@component/modal/modals/common/information-copy-row/information-copy-row';
import { CurrencyExchangeInformationInterface } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-information/currency-exchange-information.interface';
import {
    CurrencyExchangeInformationTitle,
    CurrencyExchangeInformationWrapper,
    InformationBlockWrapper,
} from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-information/currency-exchange-information.styles';

interface CurrencyExchangeInformationProps {
    information: CurrencyExchangeInformationInterface;
}

export const CurrencyExchangeInformation: FC<CurrencyExchangeInformationProps> = ({
    information: { buyAmount, buyCurrency, sellAmount, sellCurrency, fee, rate, total, settlementTime },
}) => {
    const amountBuy = useCurrencyFormat(buyAmount ?? 0, buyCurrency);
    const amountSell = useCurrencyFormat(sellAmount ?? 0, sellCurrency);

    const { lang } = useLocalizationStore();
    const time = format(
        settlementTime instanceof Date ? settlementTime : new Date(settlementTime),
        DateFormatWithTimeSplitComma[lang] ?? ''
    );

    return (
        <CurrencyExchangeInformationWrapper>
            <CurrencyExchangeInformationTitle>
                <Translate langKey={LocalizationEnum.ExchangeFormQuoteTitle} />
            </CurrencyExchangeInformationTitle>
            <InformationCopyRow
                title={LocalizationEnum.ExchangeModalInformationSelling}
                data={amountSell}
                isCopied={false}
            />
            <InformationCopyRow
                title={LocalizationEnum.ExchangeModalInformationBuying}
                data={amountBuy}
                isCopied={false}
            />
            <InformationBlockWrapper>
                <InformationCopyRow
                    title={LocalizationEnum.ExchangeModalInformationExchangeRate}
                    data={rate?.toString()}
                    isCopied={false}
                />
                <InformationCopyRow
                    title={LocalizationEnum.ExchangeModalInformationExchangeFee}
                    data={fee}
                    isCopied={false}
                />
            </InformationBlockWrapper>
            <InformationBlockWrapper>
                <InformationCopyRow
                    title={LocalizationEnum.ExchangeModalInformationTotalAmount}
                    data={total}
                    isCopied={false}
                />
                <InformationCopyRow
                    title={LocalizationEnum.ExchangeModalInformationConversionDate}
                    data={time}
                    isCopied={false}
                />
            </InformationBlockWrapper>
        </CurrencyExchangeInformationWrapper>
    );
};
