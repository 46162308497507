import React from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import { Timer } from '@shared-component/timer/timer';
import { GoogleAuthSendForm } from '@shared-form/google-auth-send-form/google-auth-send-form';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OTHER_METHODS_BUTTON_VISIBILITY_DELAY } from '@shared-util/constants';
import { noop } from '@shared-util/noop';

import {
    AuthPageButtonWithTimerWrapper,
    AuthPageButtonWrapper,
    AuthPageContentDescriptionText,
    AuthPageContentDescriptionWrapper,
    AuthPageContentWrapper,
    AuthPageFormTitle,
    AuthPageFormWrapper,
} from '@page/auth-pages/common.styles';
import { GoogleAuthLoginStepInterface } from '@page/auth-pages/login/login-steps/google-auth-login-step/google-auth-login-step.interface';
import { useLoginMethodSelector } from '@page/auth-pages/login/login-steps/login-method-selector/login-method-selector.hook';

export const GoogleAuthLoginStep = ({
    onSubmit,
    setLoginSelectorVisible,
    isLoading,
    error,
    shouldShowOtherMethodsWithoutTimer = false,
}: GoogleAuthLoginStepInterface) => {
    const { isOtherLoginMethodsAvailable } = useLoginMethodSelector();

    return (
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={LocalizationEnum.TwoFactorAuthentication} />
            </AuthPageFormTitle>
            <AuthPageContentDescriptionWrapper>
                <AuthPageContentDescriptionText>
                    <Translate
                        langKey={LocalizationEnum.EnterTheCodeToYourAccountGeneratedByGoogleAuthenticatorApplication}
                    />
                </AuthPageContentDescriptionText>
            </AuthPageContentDescriptionWrapper>
            <AuthPageFormWrapper isLoading={isLoading}>
                <GoogleAuthSendForm onSubmit={onSubmit} error={error} />
            </AuthPageFormWrapper>
            {isOtherLoginMethodsAvailable || shouldShowOtherMethodsWithoutTimer ? (
                <AuthPageButtonWrapper>
                    <Button
                        onClick={() => setLoginSelectorVisible(true)}
                        type={ButtonTypeEnum.GHOST}
                        size={ButtonSizeEnum.L}
                        title={LocalizationEnum.CommonOtherMethods}
                    />
                </AuthPageButtonWrapper>
            ) : (
                <AuthPageButtonWithTimerWrapper>
                    <Translate langKey={LocalizationEnum.OtherMethodsAreAvailableVia} />{' '}
                    <Timer time={OTHER_METHODS_BUTTON_VISIBILITY_DELAY} onComplete={noop} />
                </AuthPageButtonWithTimerWrapper>
            )}
        </AuthPageContentWrapper>
    );
};
