import { CompanyActivatedStatus, Maybe, PersonRelation, Address, UserCompanyActive } from '../../graphql';
import { initialAddress } from '../address-type/address.initial';

export interface ModelCompanyInterface
    extends Omit<UserCompanyActive, 'registrationDate' | 'legalAddress' | 'postalAddress' | 'persons'> {
    registrationDate: Maybe<Date>;
    legalAddress: Address;
    postalAddress: Address;
    persons: Map<string, PersonRelation>;
}

export const initialCompanyModel: ModelCompanyInterface = {
    id: '',
    companyName: '',
    registrationCountry: '',
    companyLegalForm: null,
    companyNumber: '',
    companyTaxNumber: '',
    companySite: '',
    registrationDate: null,
    isConductBusiness: false,
    isGroupPart: false,
    industryDescription: '',
    status: CompanyActivatedStatus.Created,
    owner: {
        email: '',
        phone: '',
        documents: [],
        address: initialAddress,
        profile: null,
        isVerified: null,
    },
    companyStatus: {
        consentToPrivacyPolicyAccepted: null,
        hasAddress: false,
        hasIndustry: false,
        hasPersons: false,
        hasShareholders: false,
        hasDocuments: false,
        hasService: false,
        hasAccept: false,
        termsAccepted: null,
    },

    isCompanyAddressSame: true,
    legalAddress: initialAddress,
    postalAddress: initialAddress,
    companyService: null,
    biggestClient: null,
    statuses: [],

    relation: null,
    elcoinId: null,
    persons: {} as Map<string, PersonRelation>,
    documents: [],
};
