import { FC, SVGProps } from 'react';
import styled, { css } from 'styled-components';

import { DarkGrey, NileBlue } from '@shared-style/colors';
import { resetInputStyle } from '@shared-style/reset-style';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

import { Typography } from '../../../typography/typography';

export interface IconWrapperProps extends ThemeTypeInterface {
    hasValue: boolean;
}

export interface InputStyleProps {
    hasIcon?: boolean;
    isMobile?: boolean;
}

export const getInputStyles = () => css`
    border: none;
    flex: 1;
    font-size: 16px;
    padding: 0 12px;
    background-color: transparent;
    width: 100%;
`;

export const CustomInputStyle = styled.input<InputStyleProps>`
    ${getInputStyles}
    ${resetInputStyle}
    ${({ isMobile = false }) =>
        isMobile ? Typography.Paragraph14.SemiBold.Neutral900.Style : Typography.Paragraph16.SemiBold.Neutral900.Style}
    ${({ hasIcon = false }) =>
        hasIcon &&
        css`
            padding: 0 0 0 56px;
        `}
`;

export const IconWrapper = (icon: FC<SVGProps<SVGSVGElement>>) => styled(icon)<IconWrapperProps>`
    position: absolute;
    width: 24px;
    height: 24px;
    left: 16px;
    top: 16px;

    fill: ${({ hasValue }: IconWrapperProps) => (hasValue ? NileBlue : DarkGrey)};
`;
