import { useMemo } from 'react';

import { UserActivatedStatus, UserType } from '@shared-graphql';

import { useCompanySelector } from '@selector/company/use-company.selector';
import { useUserSelector } from '@selector/user/use-user.selector';

export const useUserStatus = () => {
    const [{ type: userType, status: userStatus }] = useUserSelector();
    const [{ status: companyStatus }] = useCompanySelector();

    return useMemo(() => {
        if (userType === UserType.Legal) {
            return companyStatus;
        }
        if (userType === UserType.Private) {
            return userStatus;
        }
        return null;
    }, [userStatus, companyStatus, userType]) as UserActivatedStatus;
};
