import { useField, useFormikContext } from 'formik';
import { useCallback, useEffect } from 'react';

import { InputOtp } from '@shared-component/input/input-otp/input-otp';
import { GoogleAuthSendFormFieldsInterface } from '@shared-form/google-auth-send-form/fields/google-auth-send-form-fields.interface';

const OTP_VALUE_LENGTH = 11;

export const GoogleAuthSendFormFields = ({ name, error }: GoogleAuthSendFormFieldsInterface) => {
    const [{ value }] = useField(name);

    const { handleSubmit, setFieldError } = useFormikContext();

    useEffect(() => {
        if (value?.length === OTP_VALUE_LENGTH) {
            handleSubmit();
        }
    }, [value]);

    useEffect(() => {
        setFieldError(name, error);
    }, [error, name]);

    const onChange = useCallback(() => setFieldError(name, undefined), [name]);

    return <InputOtp name={name} onChange={onChange} />;
};
