import { DEFAULT_CURRENCY } from '@shared-util/constants';

import { AcquiringRequestFormEnum } from '@component/modal/modals/acquiring-request/acquiring-request-form/acquiring-request-form.enum';

export const acquiringRequestFormInitials = {
    [AcquiringRequestFormEnum.Name]: '',
    [AcquiringRequestFormEnum.Email]: '',
    [AcquiringRequestFormEnum.MoneyTurnoverCurrency]: DEFAULT_CURRENCY,
    [AcquiringRequestFormEnum.MoneyTurnoverAmount]: 0,
    [AcquiringRequestFormEnum.Message]: '',
};
