import {
    AppPreferredLoginMethod,
    Maybe,
    UserActivatedStatus,
    UserCompanyPermissions,
    UserLegalStatus,
    WebPreferredLoginMethod,
} from '@shared-graphql';
import { ModelCompanyInterface } from '@shared-model/company-model/company-model.initial';

import { initialUserDocumentModels } from '@model/user-model/user-model-initials/user-documents-model.initial';
import { UserInterface } from '@model/user-model/user-model.interfaces';

import { InitialUserProfileInterface, initialUserProfileModel } from './user-profile-model.initial';

export interface ModelUserInterface
    extends Omit<UserInterface, 'profile' | 'companies' | 'legalStatus' | 'companyStatus' | 'createdAt' | 'updatedAt'> {
    profile: InitialUserProfileInterface;
    companies: Map<string, ModelCompanyInterface>;
    legalStatus: UserLegalStatus;
    fullName: string | null;
    onboardingStep: string;
    isLegal: boolean;
    updatedAt: Maybe<Date>;
    companyPermissions: UserCompanyPermissions;
    firstName: string;
    lastName: string;
    companyName: string;
    nationality: string;
    residenceCountry: string;
    legalCountry: string;
    postalCountry: string;
}

export const initialUserPrivateStatus = {
    consentToPrivacyPolicyAccepted: null,
    hasProfile: false,
    hasAddress: false,
    hasService: false,
    hasDeclaration: false,
    hasDocument: false,
    hasAccept: false,
    termsAccepted: null,
};

export const initialUserLegalStatus = {
    hasCompany: false,
};
export const initialPermissions = {
    permissions: [],
};

type ExcludeListType =
    | 'fullName'
    | 'isLegal'
    | 'statuses'
    | 'chatId'
    | 'gaUserId'
    | 'jumioAccountId'
    | 'jumioIds'
    | 'tokenForChat';

interface InitialUserModelInterface extends Omit<ModelUserInterface, ExcludeListType> {
    otp: string;
    companyRole: string;
    webAvailableLoginMethods: Array<Maybe<WebPreferredLoginMethod>>;
    isUserActivated: boolean;
}

export const initialUserModel: InitialUserModelInterface = {
    id: '',
    email: '',
    password: '',
    phone: '',
    otp: '',
    companyRole: '',
    onboardingStep: '',
    telegramLink: '',
    companyPermissions: initialPermissions,
    type: null,
    status: UserActivatedStatus.Created,
    privateStatus: initialUserPrivateStatus,
    legalStatus: initialUserLegalStatus,
    profile: initialUserProfileModel,
    address: null,
    service: null,
    updatedAt: null,
    declaration: null,
    documents: initialUserDocumentModels,
    isAcceptAgreement: false,
    companies: {} as Map<string, ModelCompanyInterface>,
    firstName: '',
    lastName: '',
    companyName: '',
    nationality: '',
    residenceCountry: '',
    legalCountry: '',
    postalCountry: '',
    elcoinId: '',
    webPreferredLoginMethod: WebPreferredLoginMethod.Sms,
    webAvailableLoginMethods: [WebPreferredLoginMethod.Sms],
    appPreferredLoginMethod: AppPreferredLoginMethod.Sms,
    isUserActivated: false,
};
