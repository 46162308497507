import { useEffect, useState } from 'react';

import { getAccountIdsOptions } from '@shared-atom/elpaso-kit/select-account/select-account-option/select-account-option.utils';
import { useSelectedAccountWithStorage } from '@shared-hook/selected-account-with-storage/selected-account-with-storage';
import { isTrue } from '@shared-util/is-data';

import { PaymentFlowSelectorFormEnum } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector.enum';
import { PaymentFlowSelectorFormInitial } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector.initial';
import { PaymentFlowSelectorProps } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector.interface';

export const usePaymentFlowSelector = ({ accounts }: Pick<PaymentFlowSelectorProps, 'accounts'>) => {
    const selectedAccountFromStorage = useSelectedAccountWithStorage({ accounts });

    const [paymentFlowSelectorFormInitial, setPaymentFlowSelectorFormInitial] =
        useState(PaymentFlowSelectorFormInitial);
    const accountOptions = getAccountIdsOptions(accounts, true);

    useEffect(() => {
        if (isTrue(selectedAccountFromStorage)) {
            setPaymentFlowSelectorFormInitial({
                [PaymentFlowSelectorFormEnum.SelectedAccount]: selectedAccountFromStorage?.accountId as string,
            });
        }
    }, [selectedAccountFromStorage]);

    return { paymentFlowSelectorFormInitial, accountOptions };
};
