import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { FeatureTagStatus, UserFeatureTag } from '@shared-graphql';

const featureTagsStatusValues = $enum(FeatureTagStatus).getValues();
const featureTagsValues = $enum(UserFeatureTag).getValues();

export const FeatureTagModelType = types.model({
    status: types.enumeration(featureTagsStatusValues),
    featureTag: types.enumeration(featureTagsValues),
    message: types.maybeNull(types.string),
});
