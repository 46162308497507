import { useMemo } from 'react';

import logo from '@shared-asset/logo_black.svg';
import { Link } from '@shared-atom/link/link';
import { Translate } from '@shared-atom/translate/translate';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { registrationPages } from '@page/registration/registration.constants';
import classes from '@page/sign-in/styles/header.module.css';
import cl from '@page/sign-in/styles/signWrapper.module.css';
import { ToOnboardingButton } from '@shared-component/to-onboarding-button/to-onboarding-button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { UserType } from '@shared-graphql';

export const Header = () => {
    const { name } = useCurrentNavigation();
    const isPagesForShowingSignInFlow = useMemo(() => registrationPages.has(name), [name]);

    return (
        <div className={classes.mainWrapper}>
            <div className={cl.container}>
                <div className={classes.headerWrapper}>
                    <div className={classes.logoSection}>
                        <Link route={PageEnum.Home}>
                            <img alt="logo" src={logo} />
                        </Link>
                    </div>
                    {name !== PageEnum.Verification && (
                        <div className={classes.registrationPart}>
                            {isTrue(isPagesForShowingSignInFlow) ? (
                                <p>
                                    <Translate langKey={LocalizationEnum.HeaderRightTextSignin} />
                                </p>
                            ) : (
                                <p>
                                    <Translate langKey={LocalizationEnum.HeaderRightTextRegistration} />
                                </p>
                            )}

                            <div className={classes.headerWrapper}>
                                {isTrue(isPagesForShowingSignInFlow) ? (
                                    <Link route={PageEnum.Login}>
                                        <Translate langKey={LocalizationEnum.RegistrationFormTitleSignin} />
                                    </Link>
                                ) : (
                                    <ToOnboardingButton
                                        title={LocalizationEnum.CommonOpenAccount}
                                        size={ButtonSizeEnum.M}
                                        type={ButtonTypeEnum.GHOST}
                                        userType={UserType.Private}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
