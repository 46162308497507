import { object } from 'yup';

import { emailValidation, fieldRequiredValidation } from '@shared-util/validation';

export enum RegistrationOnboardingFormEnum {
    Email = 'email',
    FirstName = 'firstName',
    LastName = 'lastName',
    Token = 'token',
}

export type RegistrationOnboardingType = {
    [RegistrationOnboardingFormEnum.Email]: string;
    [RegistrationOnboardingFormEnum.FirstName]: string;
    [RegistrationOnboardingFormEnum.LastName]: string;
    [RegistrationOnboardingFormEnum.Token]: string;
};

export const registrationOnboardingFormSchema = object().shape({
    [RegistrationOnboardingFormEnum.Email]: emailValidation(),
    [RegistrationOnboardingFormEnum.FirstName]: fieldRequiredValidation(),
    [RegistrationOnboardingFormEnum.LastName]: fieldRequiredValidation(),
});
