import { Translate } from '@shared-atom/translate/translate';
import { Scroll } from '@shared-component/scroll/scroll';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ModalContentWrapper, ModalHeader, ModalTitle, ModalWrapper } from '@component/modal/client-modal.styles';
import { CurrencyExchangeForm } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form';

export const CurrencyExchangeModal = () => (
    <ModalWrapper>
        <ModalHeader>
            <ModalTitle>
                <Translate langKey={LocalizationEnum.ExchangeModalFormTitle} />
            </ModalTitle>
        </ModalHeader>
        <ModalContentWrapper>
            <Scroll>
                <CurrencyExchangeForm />
            </Scroll>
        </ModalContentWrapper>
    </ModalWrapper>
);
