import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { CustomCountrySelect } from '@shared-atom/elpaso-kit/custom-country-select/custom-country-select';
import { CustomSelect } from '@shared-atom/elpaso-kit/custom-select/custom-select';
import { CustomInputAmount } from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount';
import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { InputDate } from '@shared-atom/elpaso-kit/input/input-date/input-date';
import { SelectAccount } from '@shared-atom/elpaso-kit/select-account/select-account';
import { getAccountIdsOptions } from '@shared-atom/elpaso-kit/select-account/select-account-option/select-account-option.utils';
import { Scroll } from '@shared-component/scroll/scroll';
import { BaseOptionInterface } from '@shared-component/select/select.props';
import { FormHalfRow, FormRow } from '@shared-form/form-common/form-common.styles';
import { Account, Fee, PaymentType, PurposeAdditionalField, PurposeCode } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { getCurrencyFormat } from '@shared-util/get-currency-format';
import { isString, isTrue } from '@shared-util/is-data';

import { InformationCopyRow } from '@component/modal/modals/common/information-copy-row/information-copy-row';
import { AccountCurrencyFormEnum } from '@component/modal/modals/payment/payment-form/payment-form-steps/amount-currency-form/amount-currency-form.enum';
import { useAmountCurrencyFormAccount } from '@component/modal/modals/payment/payment-form/payment-form-steps/amount-currency-form/amount-currency-form.hook';
import {
    customSelectRowRender,
    customSelectValueRender,
} from '@component/modal/modals/payment/payment-form/payment-form-steps/amount-currency-form/amount-currency-form.options';
import {
    AmountCurrencyFormWrapper,
    PaymentFormComponentWrapper,
    PaymentFormInformationWrapper,
} from '@component/modal/modals/payment/payment-form/payment-form-steps/amount-currency-form/amount-currency-form.styles';
import { PaymentFormValuesInterface } from '@component/modal/modals/payment/payment-form/payment-form.interface';

interface AccountCurrencyFormProps {
    fee: Fee;
    accounts: Account[];
    purposeCodes: PurposeCode[];
    currency: string;
    availableListCountries: BaseOptionInterface[];
}

const additionalFieldsNameToInputLabelMapping: { [key: string]: LocalizationEnum } = {
    invoiceNumber: LocalizationEnum.CommonInvoiceNumber,
    invoiceDate: LocalizationEnum.CommonInvoiceDate,
    charityNumber: LocalizationEnum.CommonCharitynumber,
};

const additionalFieldsNameToInputPlaceholderMapping: { [key: string]: LocalizationEnum } = {
    invoiceNumber: LocalizationEnum.EnterInvoiceNumber,
    charityNumber: LocalizationEnum.EnterCharityNumber,
};

const additionalFieldsNameToInputNameMapping: { [key: string]: AccountCurrencyFormEnum } = {
    invoiceNumber: AccountCurrencyFormEnum.InvoiceNumber,
    invoiceDate: AccountCurrencyFormEnum.InvoiceDate,
    charityNumber: AccountCurrencyFormEnum.CharityNumber,
};

export const AmountCurrencyForm = observer(
    ({ accounts, fee, purposeCodes, currency, availableListCountries }: AccountCurrencyFormProps) => {
        const { totalAmount, paymentFee } = useAmountCurrencyFormAccount(fee, currency);
        const accountOptionsSell = getAccountIdsOptions(accounts, true);
        const {
            values: {
                purpose,
                type: paymentType,
                beneficiary: { bankCountry },
            },
        } = useFormikContext<PaymentFormValuesInterface>();

        const purposeCodesOptions: BaseOptionInterface<string>[] = useMemo(
            () =>
                purposeCodes.map(({ code, description }) => ({
                    value: code,
                    label: description ?? '',
                })),
            [purposeCodes]
        );

        const additionalFields = useMemo(() => {
            if (isTrue(purpose)) {
                return purposeCodes.find(({ code }) => code === purpose)?.additionalFields;
            }
            return [];
        }, [purpose, purposeCodes]);

        return (
            <AmountCurrencyFormWrapper>
                <Scroll>
                    <PaymentFormComponentWrapper>
                        <SelectAccount
                            label={LocalizationEnum.PaymentFormAccountTitle}
                            title={LocalizationEnum.PaymentFormAccountTitle}
                            name={AccountCurrencyFormEnum.PaymentCurrency}
                            options={accountOptionsSell}
                            rowCountView={3}
                            isMobile
                            isDisabled
                        />
                    </PaymentFormComponentWrapper>
                    <PaymentFormComponentWrapper>
                        <FormRow>
                            <CustomInputAmount
                                title={LocalizationEnum.CommonAmount}
                                name={AccountCurrencyFormEnum.Amount}
                                placeholder={getCurrencyFormat(100, currency)}
                                isMobile
                            />
                            {paymentType === PaymentType.local && (
                                <CustomInput
                                    label={LocalizationEnum.PaymentFormCurrencyAmountStepSortCode}
                                    name={AccountCurrencyFormEnum.SortCode}
                                    placeholder="000000"
                                    isMobile
                                    isOnlyDigitsAndLetters
                                />
                            )}
                        </FormRow>
                    </PaymentFormComponentWrapper>
                    <PaymentFormComponentWrapper>
                        <FormHalfRow>
                            <CustomInput
                                label={
                                    paymentType === PaymentType.local
                                        ? LocalizationEnum.IbanAccountNumber
                                        : LocalizationEnum.PaymentFormCurrencyAmountStepIban
                                }
                                name={AccountCurrencyFormEnum.Iban}
                                placeholder="0000000000"
                                isMobile
                                isOnlyDigitsAndLetters
                            />
                            <CustomInput
                                label={LocalizationEnum.CreatePartnerFormBic}
                                name={AccountCurrencyFormEnum.BicSwift}
                                placeholder="0000000000"
                                isMobile
                                isOnlyDigitsAndLetters
                            />
                        </FormHalfRow>
                        <FormRow>
                            <CustomInput
                                label={LocalizationEnum.RecipientBankName}
                                name={AccountCurrencyFormEnum.BankName}
                                placeholder={LocalizationEnum.AccountDetailsCardBankName}
                                isMobile
                            />
                        </FormRow>
                        {purposeCodesOptions.length === 0 ? (
                            <FormRow>
                                <CustomCountrySelect
                                    label={LocalizationEnum.RecipientBankCountry}
                                    placeholder={LocalizationEnum.AccountDetailsCardBankCountry}
                                    name={AccountCurrencyFormEnum.BankCountry}
                                    isMobile
                                    isDisabled={isString(bankCountry)}
                                    countries={availableListCountries}
                                />
                            </FormRow>
                        ) : (
                            <>
                                <FormRow>
                                    <CustomCountrySelect
                                        label={LocalizationEnum.RecipientBankCountry}
                                        placeholder={LocalizationEnum.AccountDetailsCardBankCountry}
                                        name={AccountCurrencyFormEnum.BankCountry}
                                        isMobile
                                        isDisabled={isString(bankCountry)}
                                        countries={availableListCountries}
                                    />
                                    <CustomSelect
                                        name={AccountCurrencyFormEnum.Purpose}
                                        placeholder={LocalizationEnum.CommonSelectPurposeCode}
                                        label={LocalizationEnum.CommonPurposeCode}
                                        options={purposeCodesOptions}
                                        isSearchable={false}
                                        rowRender={customSelectRowRender}
                                        rowHeight={58}
                                        valueRender={customSelectValueRender}
                                        isMobile
                                    />
                                </FormRow>
                                {(additionalFields as PurposeAdditionalField[])?.length > 0 && (
                                    <FormRow>
                                        {(additionalFields as PurposeAdditionalField[]).map(({ name }) =>
                                            name.includes('Date') ? (
                                                <InputDate
                                                    key={name}
                                                    name={additionalFieldsNameToInputNameMapping[name]}
                                                    label={additionalFieldsNameToInputLabelMapping[name]}
                                                    isMobile
                                                />
                                            ) : (
                                                <CustomInput
                                                    key={name}
                                                    label={additionalFieldsNameToInputLabelMapping[name]}
                                                    name={additionalFieldsNameToInputNameMapping[name]}
                                                    placeholder={additionalFieldsNameToInputPlaceholderMapping[name]}
                                                    isMobile
                                                />
                                            )
                                        )}
                                    </FormRow>
                                )}
                            </>
                        )}
                    </PaymentFormComponentWrapper>
                    <PaymentFormInformationWrapper>
                        <InformationCopyRow
                            title={LocalizationEnum.CommonPaymentFee}
                            data={paymentFee}
                            isCopied={false}
                        />
                        <InformationCopyRow
                            title={LocalizationEnum.PaymentFormCurrencyAmountStepPayIn}
                            data={totalAmount}
                            isCopied={false}
                        />
                    </PaymentFormInformationWrapper>
                </Scroll>
            </AmountCurrencyFormWrapper>
        );
    }
);
