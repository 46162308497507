import { isValidIBAN } from 'ibantools';
import { date, object, string } from 'yup';

import { REQUIRED_FIELD_ERROR } from '@shared-util/constants';
import { isString, isTrue } from '@shared-util/is-data';
import { isLikeIban } from '@shared-util/is-like-iban';
import { bicValidation, maxAmountLengthValidation } from '@shared-util/validation';

import { AccountCurrencyFormEnum } from './amount-currency-form.enum';

export const getAmountCurrencyFormValidation = (
    accountNumberRegExp?: string | null,
    sortCodeRegExp?: string | null,
    purposeRegexp?: string | null,
    invoiceNumberRegexp?: string | null,
    invoiceDateRegexp?: string | null,
    charityNumberRegexp?: string | null
) =>
    object().shape({
        amount: maxAmountLengthValidation(AccountCurrencyFormEnum.Amount),
        currency: string().required(REQUIRED_FIELD_ERROR),
        accountId: string().required(REQUIRED_FIELD_ERROR),
        beneficiary: object().shape({
            bankName: string().required(REQUIRED_FIELD_ERROR),
            bankCountry: string().required(REQUIRED_FIELD_ERROR),
            iban: string()
                .required(REQUIRED_FIELD_ERROR)
                .default('')
                .test('iban', 'Account is incorrect', account => {
                    if (isLikeIban(account)) {
                        return isValidIBAN(account);
                    }
                    if (isString(accountNumberRegExp)) {
                        return new RegExp(accountNumberRegExp as string).test(account);
                    }

                    return true;
                }),
            bicSwift: bicValidation.required(REQUIRED_FIELD_ERROR),
            sortCode: isString(sortCodeRegExp)
                ? string()
                      .matches(new RegExp(sortCodeRegExp as string), 'Sort code is incorrect')
                      .required(REQUIRED_FIELD_ERROR)
                : string().default(''),
            ...(isString(charityNumberRegexp)
                ? {
                      charityNumber: string().test('charityNumber', REQUIRED_FIELD_ERROR, charityNumber =>
                          isString(charityNumberRegexp) ? isTrue(charityNumber) : true
                      ),
                  }
                : {}),
            ...(isString(invoiceNumberRegexp)
                ? {
                      invoiceNumber: string().test('invoiceNumber', REQUIRED_FIELD_ERROR, invoiceNumber =>
                          isString(invoiceNumberRegexp) ? isTrue(invoiceNumber) : true
                      ),
                  }
                : {}),
            ...(isString(invoiceDateRegexp)
                ? {
                      invoiceDate: date().test('invoiceDate', REQUIRED_FIELD_ERROR, invoiceDate =>
                          isString(invoiceDateRegexp) ? isTrue(invoiceDate) : true
                      ),
                  }
                : {}),
        }),
        purpose: isString(purposeRegexp) ? string().required(REQUIRED_FIELD_ERROR) : string().default(''),
    });
