import { useEffect, useRef } from 'react';

export const usePrevious = <T>(value: T, hasUpdate = true) => {
    const ref = useRef<T>();

    useEffect(() => {
        if (hasUpdate) {
            ref.current = value;
        }
    }, [value, hasUpdate]);

    return ref.current;
};
