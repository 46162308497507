import { AddressBook } from '@shared-graphql';
import { initialAddressBook } from '@shared-model/address-book-model/address-book-model.initial';

export interface ModelAddressBookInterface {
    elements: Record<string, AddressBook>;
    totalCount: number;
    selectedId: string | null;
    selectedAddressBook: AddressBook;
}

export const initialAddressBookModel: ModelAddressBookInterface = {
    elements: {},
    totalCount: 0,
    selectedId: null,
    selectedAddressBook: initialAddressBook,
};
