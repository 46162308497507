import { LocalizationTextType } from '../../../hooks/localization/use-localization-text.hook';

export enum LinkSizeEnum {
    S = 's',
    L = 'l',
    XL = 'xl',
}
export enum LinkTypeEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    GREY = 'grey',
}

export interface ElpasoLinkProps {
    title: LocalizationTextType;
    size: LinkSizeEnum;
    type: LinkTypeEnum;
    href: string;
    isDisabled?: boolean;
}
