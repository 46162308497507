import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalHeightEnum } from '@shared-component/modal/modal.enum';
import { DefaultAnswer, Mutation, MutationUserEmailUpdateArgs, UserEmailUpdate } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';

export const useUserShortProfileChangeEmail = () => {
    const { setUserEmail } = useUserActionsSelector();
    const { onClose, setModalHeight } = useContext(ModelContext);
    const { onOpen } = useContext(InformationModalContext);
    const [currentEmail, setCurrentEmail] = useState('');

    const [userEmailUpdate, { data, error, loading: isLoading }] =
        useMutation<Pick<Mutation, 'userEmailUpdate'>>(UserEmailUpdate);

    const onSubmit = ({ email }: MutationUserEmailUpdateArgs) => {
        userEmailUpdate({ variables: { email } }).then(() => setCurrentEmail(email));
    };

    useEffect(() => {
        setModalHeight(ModalHeightEnum.H280);
    }, []);

    useEffect(() => {
        if (isTrue((data?.userEmailUpdate as DefaultAnswer)?.status) && isTrue(currentEmail)) {
            setUserEmail(currentEmail);
            onOpen(InformationModalEnum.SuccessInformationModal, {
                text: LocalizationEnum.YourEmailHasBeenSuccessfullyChanged,
            });
            onClose();
        }
    }, [data, currentEmail]);

    useEffect(() => {
        if (error) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: error?.message ?? LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
    }, [error]);

    return { onSubmit, isLoading };
};
