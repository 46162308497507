import { useEffect, useMemo, useState } from 'react';

import { getLocalStorageValue, setLocalStorageValue } from '@shared-hook/utils/use-local-storage.hook';
import DeKeys from '@shared-locale/de.json';
import EnKeys from '@shared-locale/en.json';
import EsKeys from '@shared-locale/es.json';
import FrKeys from '@shared-locale/fr.json';
import { LocalizationStoreContext } from '@shared-store/store-localization.context';
import { LangKeysEnum, LocalizationType } from '@shared-store/store-localization.interface';
import { FCWithChildrenType } from '@shared-type/common';
import { isObjectEmpty } from '@shared-util/is-object';

const browserLang = navigator.language.split('-')[0] as LangKeysEnum;

const langToKeysMapping = {
    [LangKeysEnum.EN]: EnKeys,
    [LangKeysEnum.ES]: EsKeys,
    [LangKeysEnum.DE]: DeKeys,
    [LangKeysEnum.FR]: FrKeys,
};

function isHasLang(value: LangKeysEnum) {
    const arr: string[] = Object.values(LangKeysEnum);
    if (arr.includes(value)) {
        return value;
    }
    return LangKeysEnum.EN;
}

function defaultLanguage() {
    const storageLang: LangKeysEnum | null = getLocalStorageValue('defaultLanguage');

    if (Boolean(storageLang) === true && storageLang !== null) {
        return isHasLang(storageLang);
    }

    return isHasLang(browserLang);
}

export const LocalizationStoreProvider: FCWithChildrenType = ({ children }) => {
    const [keys, setKeys] = useState<LocalizationType>(EnKeys);
    const [lang, setLangKey] = useState(defaultLanguage());
    const [isLoading, setLoading] = useState(false);

    const setLangKeys = (newKeys: LocalizationType) => {
        setKeys({ ...keys, ...newKeys });
    };

    const loadingLanguage = () => {
        setLoading(true);
        const langKeys = langToKeysMapping[lang];

        setLangKeys(langKeys);
        setLoading(false);
    };

    useEffect(() => {
        loadingLanguage();
    }, []);

    const setLanguage = (language: LangKeysEnum) => {
        setLangKey(language);
        loadingLanguage();
        setLocalStorageValue('defaultLanguage', language);
    };

    const isKeysEmpty = useMemo(() => isObjectEmpty(keys), [keys]);

    const values = useMemo(
        () => ({
            lang,
            keys,
            loading: isLoading,
            setLangKey,
            setLanguage,
            isKeysEmpty,
        }),
        [lang, keys, isLoading, setLangKey, setLanguage, isKeysEmpty]
    );

    return <LocalizationStoreContext.Provider value={values}>{children}</LocalizationStoreContext.Provider>;
};
