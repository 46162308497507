import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral500, Neutral900 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const PrivacyContainer = styled.div`
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 20px 60px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        max-width: 1200px;
    }
`;
export const PrivacyTitle = styled.h1`
    ${Typography.H2Desktop.Bold.Neutral900.Style};
    padding: 20px 0;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.H3Desktop.Bold.Neutral900.Style};
        padding: 20px 0 10px;
    }
`;

export const PrivacyInnerContainer = styled.div`
    width: 100%;
    max-width: 780px;
    margin: 0 auto;
`;

export const PrivacyContent = styled.div`
    ${Flex.Column.Style}
    table {
        border-collapse: collapse;
        color: ${Neutral900};
    }

    table,
    th,
    td {
        border: 1px solid black;
        padding: 8px;
        text-align: center;
        word-break: normal;
    }
`;

export const PrivacySubTitle = styled.h2`
    ${Typography.H4Desktop.Medium.Neutral900.Style};
    padding: 20px 0 10px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.Paragraph18.Medium.Neutral900.Style};
        padding: 15px 0 8px;
    }
`;

export const PrivacyCenteredSubTitle = styled(PrivacySubTitle)`
    text-align: center;
`;
export const PrivacyBoldTitle = styled.span`
    ${Typography.Paragraph18.SemiBold.Neutral900.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.Paragraph16.SemiBold.Neutral900.Style};
    }
`;
export const PrivacyBoldText = styled.span`
    font-weight: 600;
    color: ${Neutral900};
`;
export const PrivacyParagraph = styled.p`
    ${Typography.Paragraph14.Medium.Neutral500.Style};
    padding-bottom: 20px;
    word-break: break-word;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding-bottom: 15px;
    }
    &:first-child {
        padding-top: 20px;
        @media (max-width: ${MAX_MOBILE_SIZE}px) {
            padding-top: 10px;
        }
    }
    a,
    tr,
    td {
        color: ${Neutral500};
    }
    a {
        text-decoration: underline;
    }
`;
export const PrivacyCenteredParagraph = styled(PrivacyParagraph)`
    text-align: center;
`;
export const PrivacyParagraphMinify = styled(PrivacyParagraph)`
    max-width: 225px;
`;
export const PrivacyParagraphBottomIndent = styled.div`
    margin-bottom: 6px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        margin-bottom: 4px;
    }
`;
export const PrivacyParagraphLeftIndent = styled.div`
    padding-left: 30px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        margin-bottom: 20px;
    }
`;
export const ExhibitContainer = styled.div`
    ${Flex.Row.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.Column.Style};
    }
`;
export const ExhibitContainerColumn = styled.div`
    ${Flex.Column.FlexStart.Style};
    width: 50%;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
    }
`;
export const ImageWrapper = styled.div`
    width: 200px;
    height: 64px;
    margin-top: 24px;
`;
export const TableWrapper = styled.div`
    width: 100%;
    overflow-x: scroll;
`;
