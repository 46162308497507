import { Fee } from '@shared-graphql';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';

interface AccountCurrencyFormInterface {
    totalAmount: string;
    paymentFee: string;
}

export const useAmountCurrencyFormAccount = (
    { total, amount }: Fee,
    currency: string
): AccountCurrencyFormInterface => {
    const totalAmount = useCurrencyFormat(total, currency);
    const paymentFee = useCurrencyFormat(amount, currency);

    return {
        totalAmount,
        paymentFee,
    };
};
