import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { Avatar } from '@shared-component/avatar/avatar';
import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalEnum } from '@shared-component/modal/modal.enum';

import {
    AvatarWrapper,
    UserName,
    UserWrapper,
    UserCompany,
    UserInfo,
} from '@page/workspace/workspace-user/workspace-user.style';
import { useCompanySelector } from '@selector/company/use-company.selector';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';

export const WorkspaceUser = observer(() => {
    const [{ fullName, email }] = useUserSelector();
    const [{ companyName }] = useCompanySelector();
    const { onOpen } = useContext(ModelContext);
    const { loadUserProfile } = useUserActionsSelector();
    const handleOpenModalAddPartner = () => onOpen(ModalEnum.UserProfileModal);
    useEffect(() => {
        void loadUserProfile();
    }, []);

    return (
        <ButtonTransparent onClick={handleOpenModalAddPartner} isFull>
            <UserWrapper>
                <AvatarWrapper>
                    <Avatar name={fullName ?? email} />
                </AvatarWrapper>
                <UserInfo>
                    <UserName>{fullName}</UserName>
                    <UserCompany>{companyName}</UserCompany>
                </UserInfo>
            </UserWrapper>
        </ButtonTransparent>
    );
});
