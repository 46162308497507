import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral200, Neutral50 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const ModalWrapper = styled.div`
    background-color: ${Neutral50};
    border-radius: 16px;
    width: 560px;
    height: 100%;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        height: 100%;
        width: 100%;
        border-radius: 0;
    }
    form {
        height: 100%;
    }
`;

export const ModalHeader = styled.div`
    height: 60px;
    border-bottom: 1px solid ${Neutral200};
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        height: 56px;
    }
`;
export const ModalTitle = styled.h2`
    ${Typography.H4Desktop.SemiBold.Neutral900.Center.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.H4Mob.SemiBold.Neutral900.Center.Style}
    }
`;
export const ModalSubtitle = styled.h3`
    ${Typography.Paragraph16.SemiBold.Neutral900.Style};
    margin-bottom: 16px;
`;
export const ModalContentWrapper = styled.div`
    height: calc(100% - 60px);
    width: 100%;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        height: calc(100% - 56px);
    }
`;
export const ModalFormRowWrapper = styled.div`
    margin-top: 16px;
    padding: 0 24px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 0 20px;
    }
`;
export const ModalFooter = styled.div`
    margin-top: auto;
    padding: 16px 24px;
    border-top: 1px solid ${Neutral200};
    ${Flex.JustifyRight.Style}
`;
