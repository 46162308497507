import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalEnum, ModalHeightEnum } from '@shared-component/modal/modal.enum';
import { ModelContextInterface } from '@shared-component/modal/modal.interface';
import { useDevices } from '@shared-hook/utils/use-deivices.hook';
import { FCWithChildrenType } from '@shared-type/common';
import { hideFreshdeskWidget, showFreshdeskWidget } from '@shared-util/freshdesk-widget';
import { isFalse } from '@shared-util/is-data';

export const ModelContextProvider: FCWithChildrenType = ({ children }) => {
    const [selectedModal, setModal] = useState<ModalEnum | null>(null);
    const [modalHeight, setModalHeight] = useState<ModalHeightEnum>(ModalHeightEnum.Full);
    const [props, setProps] = useState<Record<string, any>>({});
    const { isMobileS, isMobileL, isMobileM, isTablet, isMaxMobile } = useDevices();
    const location = useLocation();

    useEffect(() => {
        setModal(null);
        setModalHeight(ModalHeightEnum.Full);
        showFreshdeskWidget();
    }, [location]);

    const handleOpen = (modal: ModalEnum, newModalProps?: Record<string, any>) => {
        if (isFalse(isMobileS && isMobileL && isMobileM && isTablet && isMaxMobile)) {
            hideFreshdeskWidget();
        }
        setModal(modal);
        if (newModalProps !== undefined) {
            setProps({ ...props, ...newModalProps });
        }
    };

    const handleClose = () => {
        setModal(null);
        setModalHeight(ModalHeightEnum.Full);
        showFreshdeskWidget();
    };
    const [onBack, setOnBack] = useState(handleClose);

    const removeModalProps = () => setProps({});

    const contextValue: ModelContextInterface = useMemo(
        () => ({
            selectedModal,
            props,
            removeModalProps,
            onClose: handleClose,
            onBack,
            setOnBack,
            onOpen: handleOpen,
            modalHeight,
            setModalHeight,
        }),
        [
            selectedModal,
            props,
            removeModalProps,
            handleClose,
            onBack,
            setOnBack,
            handleOpen,
            modalHeight,
            setModalHeight,
        ]
    );

    return <ModelContext.Provider value={contextValue}>{children}</ModelContext.Provider>;
};
