import { PropsWithChildren } from 'react';

import { OnEventEmptyType } from '../../types/on-event.type';
import { ItemLink, LinkDisable, SecondaryItemLink } from './link.styles';

export interface LinkProps<T> {
    route: T;
    params?: Record<string, unknown>;
    isDisable?: boolean;
    isSecondary?: boolean;
    onClick?: OnEventEmptyType;
}

export const Link = ({
    route,
    params,
    children,
    isDisable = false,
    isSecondary = false,
    ...props
}: PropsWithChildren<LinkProps<any>>) => {
    const ItemLinkComponent = isSecondary ? SecondaryItemLink : ItemLink;

    return (
        <>
            {isDisable && (
                <LinkDisable isSecondary={isSecondary} {...props}>
                    {children}
                </LinkDisable>
            )}
            {!isDisable && (
                <ItemLinkComponent to={route} state={{ params }} {...props}>
                    {children}
                </ItemLinkComponent>
            )}
        </>
    );
};

Link.defaultProps = {
    params: {},
};
