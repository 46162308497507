import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useCreatePartnerFormNameLocalization = () => {
    const firstName = useLocalizationText(LocalizationEnum.CreatePartnerFormFirstName);
    const lastName = useLocalizationText(LocalizationEnum.CreatePartnerFormLastName);
    const companyName = useLocalizationText(LocalizationEnum.CreatePartnerFormCompanyName);

    return {
        firstName,
        lastName,
        companyName,
    };
};
