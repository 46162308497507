import { useMemo } from 'react';

import { Maybe } from '@shared-graphql';
import { DEFAULT_CURRENCY } from '@shared-util/constants';

import { getCurrencyFormat } from '../../utils/get-currency-format';
import { isExist, isString } from '../../utils/is-data';
import { useLocalizationStore } from '../localization/use-localization-store.hook';

const hasCurrency = (currency?: string): boolean => isExist(currency) && isString(currency);

export const useCurrencyFormat = (amount: number, currencyCode?: Maybe<string>, withSpace = false) => {
    const { lang } = useLocalizationStore();

    const currency = (hasCurrency(currencyCode as string) ? currencyCode : DEFAULT_CURRENCY) as string;

    return useMemo(() => getCurrencyFormat(amount, currency, lang, withSpace), [amount, currency, lang]);
};
