import * as Sentry from '@sentry/react';
import { Instance, flow, getParent } from 'mobx-state-tree';

import { OnEventEmptyType } from '@shared-type/on-event.type';

import { CompanyModel } from '@model/company-model/company-model';
import { CompanyModelType } from '@model/company-model/company-model.type';

import { companyApplyActions } from './company-apply.actions ';

interface CompanyIndustriesActionsInterface
    extends Instance<typeof CompanyModelType>,
        ReturnType<typeof companyApplyActions> {}

export const companyIndustriesActions = (self: CompanyIndustriesActionsInterface) => ({
    applyCompanyIndustriesMutation: flow(function* loadCompanyAddress<T>(mutation: OnEventEmptyType<Promise<T>>) {
        const companyModel = getParent(self) as Instance<typeof CompanyModel>;

        if (companyModel.loading.isCompanyIndustriesLoading) {
            return;
        }

        companyModel.setCompanyIndustriesLoading(true);

        try {
            const { industryDescription } = yield mutation();

            self.industryDescription = industryDescription;
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        } finally {
            companyModel.setCompanyIndustriesLoading(false);
        }
    }),
});
