import styled from 'styled-components';

import { Neutral100, Neutral200, Neutral50 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const SelectedAccountComponent = styled.div`
    width: 100%;
    height: 52px;
    padding: 16px;
    background: ${Neutral50};
    border: 1px solid ${Neutral200};
    border-radius: 8px;
    ${Flex.JustifyLeft.VerticalCenter.Style}
`;

export const RecipientComponent = styled.div`
    background: ${Neutral100};
    border: 1px solid ${Neutral200};
    border-radius: 8px;
`;
