import styled from 'styled-components';

import { OtpFormWrapper } from '@shared-style/common';
import { Flex } from '@shared-style/flex';

export const OtpWrapper = styled.div`
    margin-top: 32px;
    ${Flex.HorizontalCenter.Style}
`;

export const OtpComponentWrapper = styled(OtpFormWrapper)`
    width: 285px;
`;
