import { values } from 'mobx';
import { Instance, types } from 'mobx-state-tree';

import { Account } from '@shared-graphql';
import { AccountCcTypeSchema } from '@shared-model/account-model/account-model.type';

import { AccountCardType } from './account-card-model/account-card-model.type';
import { accountModelActions } from './account-model-actions/account-model-actions';

export const AccountCcType = types.model(AccountCcTypeSchema);
export const AccountTypeUnion = types.union(AccountCcType);

export const AccountModelType = types
    .model('Account', {
        elements: types.map(AccountTypeUnion),
        selectedAccount: types.maybeNull(types.reference(AccountTypeUnion)),
        maxInitialShow: 3,
    })
    .views(self => ({
        get accounts() {
            return values(self.elements) as unknown as Account[];
        },
        get countAccounts() {
            return self.elements.size;
        },
        get ccAccounts() {
            return values(self.elements) as unknown as Account[];
        },
    }))
    .views(self => ({
        get hasAccountMore() {
            return self.countAccounts > self.maxInitialShow;
        },
        get limitAccounts() {
            return self.accounts.slice(0, self.maxInitialShow);
        },
        get limitCcAccounts() {
            return self.ccAccounts.slice(0, self.maxInitialShow);
        },
        get cards() {
            return [] as Instance<typeof AccountCardType>[];
        },
    }));

export const AccountModelInstanceType = AccountModelType.actions(accountModelActions);
