import styled, { keyframes } from 'styled-components';

import LoaderIcon from '@shared-asset/vector/loader.svg?react';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import {
    getBtnPaddingBySize,
    getStyleByType,
    getLoaderStyleByType,
    getBtnRadiusBySize,
} from '@shared-atom/elpaso-kit/button/utils';
import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

interface ElpasoButtonProps {
    size: ButtonSizeEnum;
    typeStyle: ButtonTypeEnum;
    animated: boolean;
}

interface ElpasoButtonTitleProps {
    size: ButtonSizeEnum;
    isLoading: boolean;
}

interface ElpasoButtonIconProps {
    isBefore?: boolean;
    isLoading: boolean;
    size: ButtonSizeEnum;
}
interface LoaderProps {
    typeStyle: ButtonTypeEnum;
}

const rotate = keyframes`
    0% {
       transform: translate(-50%, -50%)  rotate(0);
    }
    100% {
        transform: translate(-50%, -50%)  rotate(360deg);
    }
`;

export const ElpasoButton = styled.button<ElpasoButtonProps>`
    border: 0;
    position: relative;
    outline: none;
    cursor: pointer;
    ${Flex.HorizontalCenter.VerticalCenter.InlineFlex.Style};
    padding: ${({ size }) => getBtnPaddingBySize(size)};
    border-radius: ${({ size }) => getBtnRadiusBySize(size)};
    ${({ size }) => (size === 'xl' ? Typography.Paragraph16.SemiBold.Style : Typography.Paragraph14.SemiBold.Style)};
    ${({ typeStyle }) => getStyleByType(typeStyle)}
    transition: all .2s ease-out;
    &:hover {
        ${({ animated }) => animated && 'transform: scale(1.01) translateY(-5px);'}
    }
    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    :focus {
        outline: 0;
    }
    svg,
    path {
        ${({ typeStyle }) => getStyleByType(typeStyle, true)};
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        &:hover {
            transform: unset;
        }
    }
`;
export const ElpasoButtonTitle = styled.span<ElpasoButtonTitleProps>`
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
    white-space: nowrap;
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    ${({ size }) => (size === 'xl' ? Typography.Paragraph16.SemiBold.Style : Typography.Paragraph14.SemiBold.Style)};
`;
export const ElpasoButtonIcon = styled.span<ElpasoButtonIconProps>`
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    margin: ${({ isBefore }) => (isBefore === true ? '0 6px 0 0' : '0 0 0 6px')};
`;

export const Loader = styled(LoaderIcon)<LoaderProps>`
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    animation: ${rotate} 3s linear infinite;
    position: absolute;
    background-color: transparent !important;
    svg,
    path {
        ${({ typeStyle }) => getLoaderStyleByType(typeStyle)};
    }
`;
