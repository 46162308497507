import { ColorStyles } from './styles-combine';
import { NotificationSizeEnum, NotificationTypeEnum } from './types';

export const getNotificationPaddingBySize = (size: NotificationSizeEnum) => {
    switch (size) {
        case NotificationSizeEnum.M:
            return '8px 8px 8px 10px';
        case NotificationSizeEnum.L:
            return '16px 16px 16px 18px';
        default:
            return '8px 8px 8px 10px';
    }
};

export const getStyleByType = (type: NotificationTypeEnum) => {
    switch (type) {
        case NotificationTypeEnum.INFO:
            return `
                ${ColorStyles.Info.Style}
            `;
        case NotificationTypeEnum.ACCENT:
            return `
                ${ColorStyles.Accent.Style}
            `;
        case NotificationTypeEnum.ERROR:
            return `
                ${ColorStyles.Error.Style}
            `;
        case NotificationTypeEnum.WARNING:
            return `
                ${ColorStyles.Warning.Style}
            `;
        case NotificationTypeEnum.WARNING_ACCENT:
            return `
                ${ColorStyles.WarningAccept.Style}
            `;
        default:
            return `
                ${ColorStyles.Info.Style}
            `;
    }
};
