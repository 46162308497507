import { ColorStyles } from './styles-combine';
import { BadgeSizeEnum, BadgeTypeEnum } from './types';

export const getBtnPaddingBySize = (size: BadgeSizeEnum) => {
    switch (size) {
        case BadgeSizeEnum.S:
            return '0 12px';
        case BadgeSizeEnum.M:
            return '2px 14px';
        case BadgeSizeEnum.L:
            return '4px 16px';
        default:
            return '18px';
    }
};

export const getStyleByType = (type: BadgeTypeEnum) => {
    switch (type) {
        case BadgeTypeEnum.NEUTRAL_FILLED:
            return `
                ${ColorStyles.NeutralFilled.Style}
            `;
        case BadgeTypeEnum.NEUTRAL_STROKE:
            return `
                ${ColorStyles.NeutralStroke.Style}
            `;
        case BadgeTypeEnum.NEUTRAL_GHOST:
            return `
                ${ColorStyles.NeutralGhost.Style}
            `;
        case BadgeTypeEnum.ERROR_FILLED:
            return `
                ${ColorStyles.ErrorFilled.Style}
            `;
        case BadgeTypeEnum.ERROR_STROKE:
            return `
                ${ColorStyles.ErrorStroke.Style}
            `;
        case BadgeTypeEnum.ERROR_GHOST:
            return `
                ${ColorStyles.ErrorGhost.Style}
            `;
        case BadgeTypeEnum.SUCCESS_FILLED:
            return `
                ${ColorStyles.SuccessFilled.Style}
            `;
        case BadgeTypeEnum.SUCCESS_STROKE:
            return `
                ${ColorStyles.SuccessStroke.Style}
            `;
        case BadgeTypeEnum.SUCCESS_GHOST:
            return `
                ${ColorStyles.SuccessGhost.Style}
            `;
        case BadgeTypeEnum.WARNING_FILLED:
            return `
                ${ColorStyles.WarningFilled.Style}
            `;
        case BadgeTypeEnum.WARNING_STROKE:
            return `
                ${ColorStyles.WarningStroke.Style}
            `;
        case BadgeTypeEnum.WARNING_GHOST:
            return `
                ${ColorStyles.WarningGhost.Style}
            `;
        case BadgeTypeEnum.PRIMARY_FILLED:
            return `
                ${ColorStyles.PrimaryFilled.Style}
            `;
        case BadgeTypeEnum.PRIMARY_STROKE:
            return `
                ${ColorStyles.PrimaryStroke.Style}
            `;
        case BadgeTypeEnum.PRIMARY_GHOST:
            return `
                ${ColorStyles.PrimaryGhost.Style}
            `;
        default:
            return `
                ${ColorStyles.NeutralFilled.Style}
            `;
    }
};
