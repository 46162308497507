import { object } from 'yup';

import { passwordValidation, repeatPasswordValidation } from '@shared-util/validation';

export enum RegistrationPasswordFormEnum {
    Password = 'password',
    RepeatPassword = 'repeatPassword',
}

export type RegistrationPasswordType = {
    [RegistrationPasswordFormEnum.Password]: string;
    [RegistrationPasswordFormEnum.RepeatPassword]: string;
};

export const RegistrationPasswordSchema = object().shape({
    [RegistrationPasswordFormEnum.Password]: passwordValidation(),
    [RegistrationPasswordFormEnum.RepeatPassword]: repeatPasswordValidation(RegistrationPasswordFormEnum.Password),
});
