import styled from 'styled-components';

import { FieldErrorWrapper } from '@shared-component/field-error/field-error.styles';
import { MaskedInputStyle } from '@shared-component/input/input.styles';
import { Primary500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

import CalendarIcon from '../../../../assets/vector/calendar.svg?react';
import { Typography } from '../../../typography/typography';
import { InputStyleProps } from '../custom-input/custom-input.styles';

interface ArrowProps {
    disabled?: boolean;
}

export const DateIcon = styled(CalendarIcon)`
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
`;

export const InputDateWrapper = styled.div`
    position: relative;
`;

export const DateMaskedStyle = styled(MaskedInputStyle)<InputStyleProps>`
    border: none;
    flex: 1;
    background-color: transparent;
    margin: auto 0;
    ${({ isMobile = false }) =>
        isMobile
            ? `
            padding: 16px;
            ${Typography.Paragraph14.SemiBold.Neutral900.Style}
        `
            : `
            padding: 14px;
            ${Typography.Paragraph16.SemiBold.Neutral900.Style}
        `}
`;

export const CustomHeaderWrapper = styled.div`
    gap: 8px;
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    & > svg path {
        fill: ${Primary500};
    }
`;

export const ErrorWrapper = styled(FieldErrorWrapper)`
    padding: 0;
`;

export const Arrow = styled.svg<ArrowProps>`
    cursor: pointer;
    ${({ disabled = false }) =>
        disabled &&
        `
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    `}
`;
