import styled from 'styled-components';

import { Flex } from '../../../../styles/flex';
import { MAX_MOBILE_SIZE } from '../../../../styles/media-sizes';
import { Typography } from '../../../typography/typography';

interface AccountBalanceProps {
    isMobile?: boolean;
}

export const AccountCurrencyLogo = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    ${Flex.VerticalCenter.HorizontalCenter.Style}
    svg {
        width: 15px;
    }
`;
export const AccountBalance = styled.div<AccountBalanceProps>`
    white-space: nowrap;
    ${({ isMobile = false }) =>
        isMobile ? Typography.Paragraph14.SemiBold.Neutral900.Style : Typography.Paragraph16.SemiBold.Neutral900.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.Paragraph14.SemiBold.Neutral900.Style}
    }
`;
export const AccountOptionWrapper = styled.div`
    ${Flex.VerticalCenter.Style}
`;
