import { FC, SVGProps } from 'react';

import { BadgeSizeEnum, BadgeTypeEnum } from '@shared-atom/elpaso-kit/badge/types';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { ClickEventType } from '@shared-type/click-event.type';

import { PageEnum } from '@enum/page.enum';
import AcquiringActive from '@page/workspace/assets/arrow-narrow-circle-broken-down-left-white.svg?react';
import AcquiringIcon from '@page/workspace/assets/arrow-narrow-circle-broken-down-left.svg?react';
import PermittedCountriesIconActive from '@page/workspace/assets/globe.svg?react';
import CommissionProfileIconActive from '@page/workspace/assets/percent-white.svg?react';
import CommissionProfileIcon from '@page/workspace/assets/percent.svg?react';
import PermittedCountriesIcon from '@page/workspace/assets/permitted-countries.svg?react';
import SettingsIconActive from '@page/workspace/assets/settings-white.svg?react';
import SettingsIcon from '@page/workspace/assets/settings.svg?react';
import EmptyNotificationIcon from '@page/workspace/assets/telegram.svg?react';
import DashboardIconActive from '@page/workspace/assets/user-white.svg?react';
import DashboardIcon from '@page/workspace/assets/user.svg?react';

export interface PageMenuListInterface {
    title: LocalizationEnum;
    icon: FC<SVGProps<SVGSVGElement>>;
    iconActive?: FC<SVGProps<SVGSVGElement>>;
    pageName?: PageEnum;
    modalName?: ModalEnum;
    onClick?: ClickEventType;
    children?: SubMenuListInterface[];
    temporaryLink?: string;
    stroked?: boolean;
    badge?: {
        text: LocalizationEnum;
        size: BadgeSizeEnum;
        type: BadgeTypeEnum;
    };
}

export interface SubMenuListInterface {
    title: string;
    pageName: PageEnum;
    onClick?: ClickEventType;
}

export const DASHBOARD_NAV_LINK_OPTION = {
    title: LocalizationEnum.DashboardTitle,
    icon: DashboardIcon,
    iconActive: DashboardIconActive,
    pageName: PageEnum.Dashboard,
};
// export const ADDRESS_BOOK_NAV_LINK_OPTION = {
//     title: LocalizationEnum.AddressBookTitle,
//     icon: AddressBookIcon,
//     iconActive: AddressBookIcon,
//     pageName: PageEnum.AddressBook,
// };
export const COMMISSION_PROFILE_NAV_LINK_OPTION = {
    title: LocalizationEnum.HeaderLinkPricing,
    icon: CommissionProfileIcon,
    iconActive: CommissionProfileIconActive,
    pageName: PageEnum.CommissionProfile,
};
export const PERMITTED_COUNTRIES_NAV_LINK_OPTION = {
    title: LocalizationEnum.PermittedCountriesTitle,
    icon: PermittedCountriesIcon,
    iconActive: PermittedCountriesIconActive,
    pageName: PageEnum.PermittedCountries,
};
export const CABINET_ACQUIRING_NAV_LINK_OPTION = {
    title: LocalizationEnum.FooterNavProductAcquiringTitle,
    icon: AcquiringIcon,
    iconActive: AcquiringActive,
    pageName: PageEnum.CabinetAcquiring,
    stroked: true,
    badge: {
        text: LocalizationEnum.CommonNew,
        size: BadgeSizeEnum.S,
        type: BadgeTypeEnum.PRIMARY_GHOST,
    },
};
export const NOTIFICATION_NAV_LINK_OPTION = {
    title: LocalizationEnum.NotificationTitle,
    icon: EmptyNotificationIcon,
    iconActive: EmptyNotificationIcon,
    pageName: PageEnum.Notification,
};
// export const SUPPORT_NAV_LINK_OPTION = {
//     title: LocalizationEnum.CommonSubmitATicket,
//     icon: SupportIcon,
//     iconActive: SupportIcon,
//     pageName: PageEnum.Dashboard,
//     temporaryLink: FRESHDESK_SUPPORT_NEW_TICKET_LINK,
//     stroked: true,
// };
export const SETTINGS_NAV_LINK_OPTION = {
    title: LocalizationEnum.SettingsTitle,
    icon: SettingsIcon,
    iconActive: SettingsIconActive,
    pageName: PageEnum.Settings,
};
