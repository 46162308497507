import { memo } from 'react';

import { Flag } from '../../atoms/flag/flag';
import { Select } from '../select/select';
import { BaseOptionInterface } from '../select/select.props';
import { countryList } from './country-select.options';
import { CountryItemWrapper, CountryTitle } from './country-select.styles';

export interface CountrySelectProps {
    name?: string;
    title?: string;
    countries?: BaseOptionInterface[];
}

const countryRow = (item: BaseOptionInterface) => (
    <CountryItemWrapper>
        <Flag countryCode={item.value.toLowerCase()} />
        <CountryTitle>{item.label}</CountryTitle>
    </CountryItemWrapper>
);

export const CountrySelect = memo(
    ({ name = 'country', title = 'Select country...', countries = countryList, ...rest }: CountrySelectProps) => (
        <Select name={name} title={title} options={countries} rowRender={countryRow} rowHeight={56} {...rest} />
    )
);
