import { reaction } from 'mobx';
import { Instance } from 'mobx-state-tree';

import { CompanyModel } from '@model/company-model/company-model';

export const initializeCompanyServiceReaction = (companyStore: Instance<typeof CompanyModel>) =>
    reaction(
        () => companyStore.company.companyService,
        service => companyStore.company.companyStatus.setHasCompanyService(service !== null)
    );
