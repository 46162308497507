import { enUS, es, de, fr } from 'date-fns/locale';
import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import NextArrow from '@shared-atom/elpaso-kit/input/input-date/assets/nextMonth.svg?react';
import PrevArrow from '@shared-atom/elpaso-kit/input/input-date/assets/prevMonth.svg?react';
import { DatePickerCaptionElement } from '@shared-atom/elpaso-kit/input/input-date/date-picker-caption-element/date-picker-caption-element';
import {
    DateFormatMap,
    DateLocaleMaskFormatMap,
} from '@shared-atom/elpaso-kit/input/input-date/input-date-format.helper';
import {
    Arrow,
    CustomHeaderWrapper,
    DateIcon,
    DateMaskedStyle,
    ErrorWrapper,
    InputDateWrapper,
} from '@shared-atom/elpaso-kit/input/input-date/input-date.styles';
import { Label, LabelWrapper } from '@shared-atom/elpaso-kit/input/input.styles';
import { Translate } from '@shared-atom/translate/translate';
import { FieldError } from '@shared-component/field-error/field-error';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { LangKeysEnum } from '@shared-store/store-localization.interface';
import { isExist, isString, isTrue } from '@shared-util/is-data';
import 'react-datepicker/dist/react-datepicker.css';

export interface InputDateProps {
    name: string;
    label?: string;
    noIcon?: boolean;
    isDisabled?: boolean;
    isMobile?: boolean;
    minDate?: Date;
    maxDate?: Date;
    popperPlacement?:
        | 'auto'
        | 'bottom'
        | 'bottom-end'
        | 'bottom-start'
        | 'left'
        | 'left-end'
        | 'left-start'
        | 'right'
        | 'right-end'
        | 'right-start'
        | 'top'
        | 'top-end'
        | 'top-start';
}

registerLocale(LangKeysEnum.EN, enUS);
registerLocale(LangKeysEnum.ES, es);
registerLocale(LangKeysEnum.DE, de);
registerLocale(LangKeysEnum.FR, fr);

export const InputDate: FC<InputDateProps> = observer(
    ({
        label = '',
        name,
        noIcon = false,
        isDisabled = false,
        isMobile = false,
        minDate = new Date(1900, 1, 1),
        maxDate = new Date(),
        popperPlacement,
        ...props
    }) => {
        const { lang } = useLocalizationStore();
        const [fieldDate, { error }, { setValue, setError }] = useField<Date | ''>(name);
        const [isCalendarOpened, setCalendarOpened] = useState(false);

        const hasError = isExist(error);
        const handleFocus = (isFocused: boolean) => () => isFocused && !isCalendarOpened && setCalendarOpened(true);
        const shouldRenderLabel = isString(label);
        const dateInputPlaceholder = useLocalizationText(LocalizationEnum.RegistrationPrivateDatePlaceholder);

        const onChange = (date: Date) => {
            setError(undefined);
            setValue(date ?? '');
        };

        return (
            <InputDateWrapper>
                {shouldRenderLabel && (
                    <Label hasError={hasError}>
                        <Translate langKey={label} />
                    </Label>
                )}
                <LabelWrapper
                    hasError={hasError}
                    isFocused={isCalendarOpened}
                    isDisabled={isDisabled}
                    isMobile={isMobile}
                    isOverHide={false}
                >
                    <DatePicker
                        selected={typeof fieldDate.value === 'string' ? null : fieldDate.value}
                        onChange={onChange}
                        maxDate={maxDate}
                        minDate={minDate}
                        dateFormat={DateFormatMap[lang]}
                        locale={lang}
                        onFocus={handleFocus(true)}
                        onBlur={handleFocus(false)}
                        placeholderText={dateInputPlaceholder}
                        closeOnScroll
                        disabledKeyboardNavigation
                        popperPlacement={isTrue(popperPlacement) ? popperPlacement : undefined}
                        popperModifiers={[{ name: 'arrow', options: { padding: 24 } }]}
                        customInput={
                            <DateMaskedStyle
                                type={InputTypeEnum.Text}
                                mask={DateLocaleMaskFormatMap[lang]}
                                keepCharPositions
                                guide={false}
                                showMask={false}
                                placeholder={DateFormatMap[lang]}
                                autoComplete="off"
                                isMobile={isMobile}
                                {...props}
                            />
                        }
                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <CustomHeaderWrapper>
                                <Arrow
                                    as={PrevArrow}
                                    onClick={() => decreaseMonth()}
                                    disabled={prevMonthButtonDisabled}
                                />
                                <DatePickerCaptionElement
                                    selectedDate={date}
                                    changeYear={changeYear}
                                    changeMonth={changeMonth}
                                />
                                <Arrow
                                    as={NextArrow}
                                    onClick={() => increaseMonth()}
                                    disabled={nextMonthButtonDisabled}
                                />
                            </CustomHeaderWrapper>
                        )}
                    />
                    {!noIcon && <DateIcon />}
                </LabelWrapper>
                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
            </InputDateWrapper>
        );
    }
);
