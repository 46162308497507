import { FC } from 'react';

import { ExternalLink } from '@shared-atom/external-link/external-link';

import { SocialOptionInterface } from '@page/main-pages/main-page-common/main-page-common.constants';
import { SocialIconWrapper } from '@page/main-pages/main-page-common/social-icon/social-icon.styles';

export const SocialIcon: FC<SocialOptionInterface> = ({ link, Icon }) => (
    <SocialIconWrapper>
        <ExternalLink href={link}>
            <Icon />
        </ExternalLink>
    </SocialIconWrapper>
);
