import { useFormikContext } from 'formik';
import React from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomInputAmount } from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount';
import { SelectAccount } from '@shared-atom/elpaso-kit/select-account/select-account';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isExist } from '@shared-util/is-data';

import { useCurrencyExchangeFields } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-fields/currency-exchange-fields.hook';
import {
    CurrencyExchangeFieldsWrapper,
    ButtonWrapper,
    CurrencyExchangeFormControls,
    CurrencyExchangeFormRow,
    CurrencyExchangeFormControlWrapper,
} from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-fields/currency-exchange-fields.styles';
import { useCurrencyExchangeLabels } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-fields/currency-exchange-labels.hook';
import { REQUIRED_FIELD_NAMES } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.constants';
import { CurrencyExchangeEnum } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.enum';
import { CurrencyExchangeFormInterface } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.interface';
import { CurrencyExchangeInformation } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-information/currency-exchange-information';
import { currencyExchangeInformationInitials } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-information/currency-exchange-information.options';

export const CurrencyExchangeFields = ({ isConversionResultLoading }: { isConversionResultLoading: boolean }) => {
    const { values, errors } = useFormikContext<CurrencyExchangeFormInterface>();
    const { amountLabel, buyLabel, sellLabel, accountOptionsSell, accountOptionsBuy } = useCurrencyExchangeLabels();
    const [information, isDisabledSubmit, isCreateConversionReservationLoading, gotAmountFieldChange] =
        useCurrencyExchangeFields();

    return (
        <CurrencyExchangeFieldsWrapper>
            <CurrencyExchangeFormControls>
                <CurrencyExchangeFormRow>
                    <CurrencyExchangeFormControlWrapper>
                        <CustomInputAmount
                            title={amountLabel}
                            name={CurrencyExchangeEnum.Amount}
                            isDisabled={isCreateConversionReservationLoading || isConversionResultLoading}
                        />
                    </CurrencyExchangeFormControlWrapper>
                    <CurrencyExchangeFormControlWrapper>
                        <SelectAccount
                            label={sellLabel}
                            title={sellLabel}
                            name={CurrencyExchangeEnum.Sell}
                            options={accountOptionsSell}
                            rowCountView={3}
                            isDisabled={isCreateConversionReservationLoading || isConversionResultLoading}
                        />
                    </CurrencyExchangeFormControlWrapper>
                </CurrencyExchangeFormRow>
                <CurrencyExchangeFormRow>
                    <CurrencyExchangeFormControlWrapper>
                        <CustomInputAmount
                            title={LocalizationEnum.ExchangeModalInformationBuying}
                            name={CurrencyExchangeEnum.BuyAmount}
                            isDisabled={
                                isCreateConversionReservationLoading ||
                                isConversionResultLoading ||
                                !REQUIRED_FIELD_NAMES.every(field => isExist(values[field])) ||
                                information.rate === currencyExchangeInformationInitials.rate
                            }
                            onChange={gotAmountFieldChange}
                        />
                    </CurrencyExchangeFormControlWrapper>
                    <CurrencyExchangeFormControlWrapper>
                        <SelectAccount
                            label={buyLabel}
                            title={buyLabel}
                            name={CurrencyExchangeEnum.Buy}
                            options={accountOptionsBuy}
                            rowCountView={3}
                            isDisabled={isCreateConversionReservationLoading || isConversionResultLoading}
                        />
                    </CurrencyExchangeFormControlWrapper>
                </CurrencyExchangeFormRow>
                <CurrencyExchangeInformation information={information} />
            </CurrencyExchangeFormControls>
            <ButtonWrapper>
                <Button
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.COLOR}
                    title={LocalizationEnum.CommonConfirm}
                    isDisabled={
                        isDisabledSubmit ||
                        isCreateConversionReservationLoading ||
                        isConversionResultLoading ||
                        Object.keys(errors).length > 0
                    }
                    isSubmit
                    isLoading={isConversionResultLoading || isCreateConversionReservationLoading}
                />
            </ButtonWrapper>
        </CurrencyExchangeFieldsWrapper>
    );
};
