import { useMemo } from 'react';
import { ThemeProvider as StyledThemProvider } from 'styled-components';

import { useDevices } from '@shared-hook/utils/use-deivices.hook';
import { FCWithChildrenType } from '@shared-type/common';

import { ThemeContext } from '@component/theme-provider/theme-provider.context';
import { ThemeContextType } from '@component/theme-provider/theme-provider.interface';
import { useThemeProvider } from '@component/theme-provider/use-theme-provider.hook';

export const ThemeProvider: FCWithChildrenType = ({ children }) => {
    const size = useDevices();
    const [isDarkTheme, setColorTheme] = useThemeProvider();

    const contentProps: ThemeContextType = useMemo(() => [isDarkTheme, setColorTheme], [isDarkTheme, setColorTheme]);

    return (
        <ThemeContext.Provider value={contentProps}>
            <StyledThemProvider theme={{ size, isDarkTheme }}>{children}</StyledThemProvider>
        </ThemeContext.Provider>
    );
};
