import React from 'react';

import { AccountSelectOption } from '@shared-atom/elpaso-kit/select-account/select-account-option/select-account-option';
import { NodeOptionInterface } from '@shared-atom/elpaso-kit/select-account/select-account.props';
import { Account } from '@shared-graphql';

export const getAccountIdsOptions = (accounts: Account[], isMobile = false): NodeOptionInterface[] =>
    accounts.map(({ currency, balance, accountId }) => ({
        value: accountId,
        label: <AccountSelectOption currency={currency} balance={balance} isMobile={isMobile} />,
    }));

export const getAccountCurrencyCodesOptions = (accounts: Account[], isMobile = false): NodeOptionInterface[] =>
    accounts.map(({ currency, balance }) => ({
        value: currency.code,
        label: <AccountSelectOption currency={currency} balance={balance} isMobile={isMobile} />,
    }));
