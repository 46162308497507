import { PageEnum } from '@enum/page.enum';

export const workspacePages = new Set([
    PageEnum.Dashboard,
    PageEnum.CommissionProfile,
    PageEnum.CabinetAcquiring,
    PageEnum.PermittedCountries,
    PageEnum.Notification,
    PageEnum.Settings,
]);
