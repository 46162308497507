import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { SelectAccount } from '@shared-atom/elpaso-kit/select-account/select-account';
import { Translate } from '@shared-atom/translate/translate';
import { DayPickerRange } from '@shared-component/day-picker/day-picker-range/day-picker-range';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ModalSubtitle } from '@component/modal/client-modal.styles';
import {
    ButtonSubmitWrapper,
    RequestStatementFormContent,
    RequestStatementFormWrapper,
} from '@component/modal/modals/request-statement/request-statement.styles';
import { EmptyAccounts } from '@component/modal/modals/request-statement/statement-form/empty-accounts/empty-accounts';
import { StatementFormEnum } from '@component/modal/modals/request-statement/statement-form/statement-form.enum';
import { useStatementForm } from '@component/modal/modals/request-statement/statement-form/statement-form.hook';
import { StatementFormValidation } from '@component/modal/modals/request-statement/statement-form/statement-form.validation';

export const StatementForm = observer(() => {
    const { handleSubmit, accountOptionsSell, hasActiveAccounts, isLoading, initialFormValues } = useStatementForm();

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={StatementFormValidation}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
        >
            <Form>
                <RequestStatementFormWrapper maxHeight={hasActiveAccounts ? 300 : 570}>
                    <RequestStatementFormContent>
                        {hasActiveAccounts ? (
                            <FormRow>
                                <SelectAccount
                                    label={LocalizationEnum.AccountForStatement}
                                    title={LocalizationEnum.AccountForStatement}
                                    name={StatementFormEnum.AccountId}
                                    options={accountOptionsSell}
                                    rowCountView={3}
                                    isMobile
                                />
                            </FormRow>
                        ) : (
                            <EmptyAccounts />
                        )}
                        <ModalSubtitle>
                            <Translate langKey={LocalizationEnum.StatementFormDateRange} />
                        </ModalSubtitle>
                        <FormRow>
                            <DayPickerRange
                                nameFrom={StatementFormEnum.From}
                                nameTo={StatementFormEnum.To}
                                popperPlacement="top-start"
                            />
                        </FormRow>
                    </RequestStatementFormContent>
                </RequestStatementFormWrapper>
                <ButtonSubmitWrapper>
                    <Button
                        title={LocalizationEnum.RequestStatementModalSubmitButtonTitle}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                        isSubmit
                        isLoading={isLoading}
                        isDisabled={!hasActiveAccounts}
                    />
                </ButtonSubmitWrapper>
            </Form>
        </Formik>
    );
});
