export enum NotificationSizeEnum {
    M = 'm',
    L = 'l',
}
export enum NotificationTypeEnum {
    INFO = 'info',
    ACCENT = 'accent',
    ERROR = 'error',
    WARNING = 'warning',
    WARNING_ACCENT = 'warning-accent',
}

export interface ElpasoNotificationProps {
    text: string;
    size: NotificationSizeEnum;
    type: NotificationTypeEnum;
}
