import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { getTagManagerPageLayer } from '@util/gtm.util';

export const PageEvent = observer(() => {
    const { name } = useCurrentNavigation();

    useEffect(() => {
        TagManager.dataLayer(getTagManagerPageLayer(name));
    }, [name]);

    return null;
});
