import { object } from 'yup';

import { passwordValidation, repeatPasswordValidation } from '@shared-util/validation';

import { ResetPasswordEnum } from './reset-password.enum';

export const ResetPasswordFormValidation = object().shape({
    [ResetPasswordEnum.Password]: passwordValidation(),
    [ResetPasswordEnum.NewPassword]: repeatPasswordValidation(ResetPasswordEnum.Password),
});
