import { useContext, useEffect, useMemo, useState } from 'react';

import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalHeightEnum } from '@shared-component/modal/modal.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useUserProfile = () => {
    const { setModalHeight } = useContext(ModelContext);

    const [isChangePasswordOpen, setChangePasswordOpen] = useState(false);
    const [isChangeEmailOpen, setChangeEmailOpen] = useState(false);

    const modalTitle = useMemo(
        () =>
            isChangePasswordOpen
                ? LocalizationEnum.UserShortProfileButtonChangePasswordTitle
                : isChangeEmailOpen
                ? LocalizationEnum.CommonEditEmail
                : LocalizationEnum.CommonProfileInformation,
        [isChangeEmailOpen, isChangePasswordOpen]
    );

    useEffect(() => {
        setModalHeight(ModalHeightEnum.H330);
    }, []);

    return { modalTitle, isChangePasswordOpen, isChangeEmailOpen, setChangePasswordOpen, setChangeEmailOpen };
};
