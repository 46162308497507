import { DEFAULT_CURRENCY } from '@shared-util/constants';

export const initialProps = {
    currency: DEFAULT_CURRENCY,
    date: {
        from: null,
        to: null,
    },
    operation: null,
    paymentId: null,
    operationType: null,
};
