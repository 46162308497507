import { AddressBook } from '../../graphql';
import { initialCurrencyModel } from '../currency-model/currency-model.initials';
import { initialAddressBookBeneficiary } from './address-book-beneficiary-model/address-book-beneficiary.initial';

export const initialAddressBook: AddressBook = {
    id: '',
    beneficiary: initialAddressBookBeneficiary,
    currency: initialCurrencyModel,
    address: null,
};
