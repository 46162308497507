import { types } from 'mobx-state-tree';

const CompanyStatusModelType = types.model('CompanyStatus', {
    hasAddress: types.boolean,
    hasIndustry: types.boolean,
    hasPersons: types.boolean,
    hasShareholders: types.optional(types.boolean, false),
    hasDocuments: types.boolean,
    hasService: types.boolean,
    hasAccept: types.boolean,
});

export const CompanyStatusModelInstance = CompanyStatusModelType.actions(self => ({
    setHasCompanyAddress: (hasAddress: boolean) => {
        self.hasAddress = hasAddress;
    },
    setHasCompanyIndustries: (hasIndustry: boolean) => {
        self.hasIndustry = hasIndustry;
    },
    setHasCompanyPersons: (hasPersons: boolean) => {
        self.hasPersons = hasPersons;
    },
    setHasCompanyShareholders: (hasShareholders: boolean) => {
        self.hasShareholders = hasShareholders;
    },
    setHasCompanyDocuments: (hasDocuments: boolean) => {
        self.hasDocuments = hasDocuments;
    },
    setHasCompanyService: (hasService: boolean) => {
        self.hasService = hasService;
    },
    setHasCompanyAccept: (hasAccept: boolean) => {
        self.hasAccept = hasAccept;
    },
}));
