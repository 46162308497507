import styled from 'styled-components';

import { Flex } from '@shared-style/flex';

import { MainWrapperInterface } from '@page/main-pages/main-page-common/main-page-common.interface';

import '@ui/commonStyle.css';

export const MainWrapperView = styled.div`
    flex: 1;
    background-color: #f7f7f7;
    ${Flex.Column.Style}
`;

export const MainWrapperBlock = styled.div<MainWrapperInterface>`
    flex: 1;
    ${({ withHeaderAndFooter = true }) => withHeaderAndFooter && 'padding-top: 72px;'}
`;

export const AlignContainer = styled.div.attrs({
    className: 'container',
})``;
