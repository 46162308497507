import { $enum } from 'ts-enum-util';

import { GtmEventEnum } from '@enum/gtm-event.enum';
import { PageEnum } from '@enum/page.enum';

const pageMap = $enum(GtmEventEnum)
    .getEntries()
    .reduce(
        (prev, [key, value]) => ({
            ...prev,
            [key]: value,
        }),
        {}
    );

export const tagManagerData = {
    gtmId: import.meta.env.VITE_GTM_ID,
    events: pageMap,
    dataLayerName: 'dataLayer',
};

export const getTagManagerPageLayer = (page: PageEnum) => ({
    gtmId: import.meta.env.VITE_GTM_ID,
    dataLayerName: 'dataLayer',
    dataLayer: { page },
});

export const getTagManagerEvent = (event: GtmEventEnum) => ({
    gtmId: import.meta.env.VITE_GTM_ID,
    dataLayerName: 'dataLayer',
    dataLayer: { event },
});
