import { useContext, useEffect } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const AuthPartnersSuccess = () => {
    const { onOpen } = useContext(InformationModalContext);

    useEffect(() => {
        onOpen(InformationModalEnum.SuccessInformationModal, {
            text: LocalizationEnum.YourRequestHasBeenSuccessfullySent,
        });
    }, []);

    return null;
};

export default AuthPartnersSuccess;
