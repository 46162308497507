import SumsubWebSdk from '@sumsub/websdk-react';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import { PageLoader, ViewPageLoader } from '@shared-component/page-loader/page-loader';
import { UserType } from '@shared-graphql';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { useVerification } from '@page/auth-pages/verification/verification.hook';
import { SignWrapper } from '@page/sign-in/SignWrapper';
import { useCompanyActionsSelector } from '@selector/company/use-company.actions-selector';
import { useFeatureTagsActionsSelector } from '@selector/feature-tags/use-feature-tags.actions-selector';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';

const VerificationPage = observer(() => {
    const navigate = useRouterNavigate();

    const [{ id, type: userType }] = useUserSelector();
    const { accessToken, getVerificationParams } = useVerification();
    const { lang } = useLocalizationStore();
    const [isLoading, setLoading] = useState(false);
    const { loadCurrentUser } = useUserActionsSelector();
    const { loadCompany } = useCompanyActionsSelector();
    const { loadFeatureTags } = useFeatureTagsActionsSelector();

    const hasAccessToken = isTrue(accessToken);
    const config = { lang };
    const options = { addViewportTag: false, adaptIframeHeight: true };

    useEffect(() => {
        if (isTrue(id)) {
            getVerificationParams();
        } else {
            navigate(PageEnum.Login);
        }
    }, []);

    useEffect(() => {
        let timerId: any = null;

        if (isTrue(isLoading)) {
            timerId = setTimeout(() => {
                if (userType === UserType.Legal) {
                    loadCurrentUser().finally(() => {
                        loadCompany().finally(() => {
                            loadFeatureTags().finally(() => navigate(PageEnum.Dashboard));
                        });
                    });
                }
                if (userType === UserType.Private) {
                    loadCurrentUser().finally(() => {
                        loadFeatureTags().finally(() => navigate(PageEnum.Dashboard));
                    });
                }
            }, 3000);
        }

        return () => clearTimeout(timerId);
    }, [isLoading]);

    const expirationHandler = useCallback(() => {
        getVerificationParams();
        const waitForToken = (): Promise<string> | ReturnType<typeof setTimeout> => {
            if (isTrue(accessToken)) {
                return Promise.resolve(accessToken);
            }
            return setTimeout(() => waitForToken(), 250);
        };

        return waitForToken();
    }, []);

    if (isLoading) {
        return <ViewPageLoader />;
    }

    return (
        <SignWrapper>
            <PageLoader>
                {hasAccessToken && (
                    <SumsubWebSdk
                        accessToken={accessToken as string}
                        expirationHandler={expirationHandler}
                        options={options}
                        config={config}
                        onMessage={(type: string) => {
                            if (type === 'idCheck.onApplicantSubmitted') {
                                setLoading(true);
                            }
                        }}
                    />
                )}
            </PageLoader>
        </SignWrapper>
    );
});

export default VerificationPage;
