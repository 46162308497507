import { UserType } from '@shared-graphql';
import { isEnvVariableTruly } from '@shared-util/is-data';

export const getOnBoardingActiveType = (type: UserType) => {
    const isLegalOnboardingEnabled = isEnvVariableTruly(
        import.meta.env.VITE_LEGAL_USERS_ONBOARDING_ENABLED_FEATURE_FLAG
    );
    const isPrivateOnboardingEnabled = isEnvVariableTruly(
        import.meta.env.VITE_PRIVATE_USERS_ONBOARDING_ENABLED_FEATURE_FLAG
    );

    if (isLegalOnboardingEnabled && isPrivateOnboardingEnabled) {
        return type;
    }
    if (!isPrivateOnboardingEnabled) {
        return UserType.Legal;
    }
    return UserType.Private;
};
