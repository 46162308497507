import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useOutletContext } from 'react-router-dom';

import ArrowLeft from '@shared-asset/vector/arrow-left.svg?react';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import { OtpSendForm } from '@shared-form/otp-send-form/otp-send-form';
import { UserType } from '@shared-graphql';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { isFalse, isString, isTrue } from '@shared-util/is-data';

import { GAEventActionsEnum, GAEventCategoryEnum } from '@enum/ga-event.enum';
import { PageEnum } from '@enum/page.enum';
import { AuthPageContentWrapper, AuthPageFormTitle, AuthPageFormWrapper } from '@page/auth-pages/common.styles';
import { FormButtonsWrapper } from '@page/registration/registration-additional-fields/registration-additional-fields.styles';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';

export interface RegistrationPhoneProps {
    error?: string;
    handleRegisterUser: OnEventType<string>;
    isLoading: boolean;
    isRegistrationConfirmed: boolean;
    onStartRegistration: OnEventType<string>;
}

const RegistrationOtp = observer(() => {
    const {
        error,
        onStartRegistration,
        isLoading,
        handleRegisterUser,
        isRegistrationConfirmed,
    }: RegistrationPhoneProps = useOutletContext();
    const navigate = useRouterNavigate();
    const [{ phone, otp, type }] = useUserSelector();
    const { setUserData } = useUserActionsSelector();
    const handleOtpSubmit = (otpCode: any) => {
        setUserData({ otp: otpCode } as any);
        handleRegisterUser(otpCode);
    };

    useEffect(() => {
        if (isString(otp) && !isLoading && isFalse(error) && isTrue(isRegistrationConfirmed)) {
            ReactGA.event({
                category: GAEventCategoryEnum.User,
                action:
                    type === UserType.Private
                        ? GAEventActionsEnum.AccountSuccessfullyCreatedPrivate
                        : GAEventActionsEnum.AccountSuccessfullyCreatedLegal,
            });
            navigate(PageEnum.Dashboard);
        }
    }, [type, isLoading, error, otp, isRegistrationConfirmed]);

    return (
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={LocalizationEnum.RegistrationOtpTitle} />
            </AuthPageFormTitle>
            <AuthPageFormWrapper>
                <AuthPageFormWrapper isLoading={isLoading}>
                    <OtpSendForm
                        error={error}
                        phone={phone}
                        onSubmit={handleOtpSubmit}
                        onResendOtpCode={() => onStartRegistration('')}
                    />
                    <FormButtonsWrapper>
                        <Button
                            onClick={() => navigate(PageEnum.RegistrationPhone)}
                            type={ButtonTypeEnum.GHOST}
                            size={ButtonSizeEnum.L}
                            title={LocalizationEnum.CommonBack}
                            iconBefore={ArrowLeft}
                        />
                    </FormButtonsWrapper>
                </AuthPageFormWrapper>
            </AuthPageFormWrapper>
        </AuthPageContentWrapper>
    );
});

export default RegistrationOtp;
