import { useLocalizationText } from '../../../../../hooks/localization/use-localization-text.hook';
import { LocalizationEnum } from '../../../../../locale/localization.enum';

export const getRangeBetweenYears = (startYear: number, endYear: number): number[] =>
    Array(endYear - startYear + 1)
        .fill(startYear)
        .map((_, idx) => endYear - idx);

export const useMonthLocalization = () => [
    useLocalizationText(LocalizationEnum.CommonJanuary),
    useLocalizationText(LocalizationEnum.CommonFebruary),
    useLocalizationText(LocalizationEnum.CommonMarch),
    useLocalizationText(LocalizationEnum.CommonApril),
    useLocalizationText(LocalizationEnum.CommonMay),
    useLocalizationText(LocalizationEnum.CommonJune),
    useLocalizationText(LocalizationEnum.CommonJuly),
    useLocalizationText(LocalizationEnum.CommonAugust),
    useLocalizationText(LocalizationEnum.CommonSeptember),
    useLocalizationText(LocalizationEnum.CommonOctober),
    useLocalizationText(LocalizationEnum.CommonNovember),
    useLocalizationText(LocalizationEnum.CommonDecember),
];
