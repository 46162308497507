import React, { memo } from 'react';

import { Translate } from '../../translate/translate';
import { ElpasoLink } from './style';
import { ElpasoLinkProps } from './types';

export const CustomLink = memo(({ title, href, size, type, isDisabled = false }: ElpasoLinkProps) => (
    <ElpasoLink href={href} disabled={isDisabled} size={size} typeStyle={type}>
        <Translate langKey={title} />
    </ElpasoLink>
));
