import styled, { css } from 'styled-components';

import { Link } from '@shared-atom/link/link';
import { Typography } from '@shared-atom/typography/typography';
import { getAnimationStyle } from '@shared-style/animation';
import { Black, Neutral900, Tarawera, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';
import { ThemePropsInterface, ThemeTypeInterface } from '@shared-type/theme-props.type';

interface ThemeMobileProps {
    theme: ThemePropsInterface;
    isOpened: boolean;
}
interface HamburgerProps {
    isDark: boolean;
}

const flexWrapperStyles = css`
    height: 72px;
    ${Flex.VerticalCenter.Style}
`;

export const FlexWrapper = styled.div`
    display: block;

    ${({ theme }: ThemeTypeInterface) => theme.size.isMobileL && flexWrapperStyles}
`;

export const HamburgerWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 23px;
`;

export const Hamburger = styled.div<HamburgerProps>`
    width: 25px;
    height: 3px;
    background-color: ${({ isDark }) => (isDark ? Neutral900 : White)};
    margin: 4px 0;
`;

const mobileMenuStyles = css`
    position: relative;
    width: initial;
    height: initial;
    background-color: transparent;
    ${Flex.VerticalCenter.Style}
`;

const mobileMenuOpenStyles = (isOpened: boolean) => css`
    opacity: ${isOpened ? 1 : 0};
    visibility: ${isOpened ? 'visible' : 'hidden'};
    transform: translateX(${isOpened ? 0 : 400}px);
`;

export const MobileMenuWrapper = styled.div<ThemeMobileProps>`
    background-color: ${White};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;

    ${getAnimationStyle(['opacity', 'visibility', 'transform'])}

    ${({ theme }: ThemeTypeInterface) => theme.size.isMaxMobile && mobileMenuStyles}
    ${({ theme, isOpened }: ThemeMobileProps) => !theme.size.isMaxMobile && mobileMenuOpenStyles(isOpened)}
`;

export const CloseBtn = styled.div`
    position: absolute;
    top: 20px;
    right: 24px;
    width: 32px;
    height: 32px;
`;

export const CloseLine = styled.div`
    position: absolute;
    top: 16px;
    right: 0;
    width: 20px;
    height: 1px;
    background-color: ${Black};
    transform: rotate(45deg);

    &:last-child {
        transform: rotate(-45deg);
    }
`;

export const LogoWrapper = styled.div`
    height: 56px;
    width: 168px;
    border-bottom-right-radius: 8px;
    background-color: ${Tarawera};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 55px;
        margin-left: 16px;
    }
`;

export const FlexLink = styled(Link)`
    ${Flex.VerticalCenter.HorizontalCenter.Style}
`;

export const Navigation = styled.nav`
    margin-top: ${({ theme }: ThemeTypeInterface) => (theme.size.isMaxMobile ? 0 : 40)}px;
`;

const listStyles = css`
    height: 72px;
    max-width: initial;
    margin: initial;

    ${Flex.Row.VerticalCenter.HorizontalCenter.Style}
`;

export const List = styled.ul`
    list-style: none;
    height: initial;
    margin: 0 24px;
    max-width: 200px;
    ${Flex.Column.JustifyLeft.FlexStart.Style}

    ${({ theme }: ThemeTypeInterface) => theme.size.isMaxMobile && listStyles}
`;

export const DesktopMenuNavigation = styled.div`
    ${Flex.Row.VerticalCenter.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        display: none;
    }
`;

export const MobileMenuNavigation = styled.div`
    display: none;

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.Column.Style};
        height: calc(100% - 72px);
        overflow-y: scroll;
    }
`;
export const MobileMenuNavigationBtnWrapper = styled.div`
    ${Flex.Column.Style};
    width: 95%;
    row-gap: 16px;
    margin: 0 auto 16px;
    button {
        width: 100%;
    }
`;
export const MobileMenuHeading = styled.div`
    display: none;

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        height: 72px;
        ${Flex.Row.VerticalCenter.Style};
    }
`;
export const MobileMenuHeadingLogo = styled.img`
    width: 55px;
`;

export const MobileMenu = styled.div`
    display: none;

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.Row.VerticalCenter.Style};
        gap: 56px;
    }
`;
export const MobileNavigationList = styled.ul`
    list-style: none;
    margin-bottom: 68px;

    li {
        padding-left: 16px;
        text-align: left;
    }
    li:not(:last-child) {
        margin-bottom: 32px;
    }
    li p {
        ${Typography.Paragraph18.SemiBold.Neutral900.Left.Style};
    }
`;
