import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const RegistrationFormWrapper = styled.div`
    margin: 24px 0;
`;

export const Title = styled.h3`
    ${Typography.XL.Normal.Dark.Style}

    ${({ theme }: ThemeTypeInterface) => theme.size.isMobileM && Typography.XXL.Style}
`;
