import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { AddCurrencyButtonProps, AddCurrencyFormContextInterface } from './currency-add-button.interface';
import { ButtonSubmitWrapper } from './currency-add-button.styles';

export const AddCurrencyButton = ({ activeCurrencies }: AddCurrencyButtonProps) => {
    const { values }: AddCurrencyFormContextInterface = useFormikContext();

    const isDisabled = useMemo(() => {
        const selectedCurrencies = Object.keys(values).filter(currencyCode => values[currencyCode]);
        return Object.keys(activeCurrencies).length === selectedCurrencies.length;
    }, [values]);

    return (
        <ButtonSubmitWrapper>
            <ButtonPrimary type={ButtonTypeEnum.Submit} isDisabled={isDisabled} isFull>
                <Translate langKey={LocalizationEnum.CommonConfirm} />
            </ButtonPrimary>
        </ButtonSubmitWrapper>
    );
};
