import { Lottie, ReactLottieEvent } from '@crello/react-lottie';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-hook-inview';

import { AnimationWrapper } from '@shared-component/animation/animation.styles';
import { OnEventEmptyType } from '@shared-type/on-event.type';

interface AnimationProps<T> {
    path?: T;
    animationData?: T;
    isLoop?: boolean;
    onAnimateCanceled?: OnEventEmptyType;
    speed?: number;
}

export const Animation = ({
    path,
    animationData,
    isLoop = false,
    speed = 1,
    onAnimateCanceled,
}: AnimationProps<any>) => {
    const [ref, isVisible] = useInView({ threshold: 0 });
    const [isLoaded, setLoaded] = useState(false);

    const handleEvent = useCallback(
        ({ currentTime, totalTime }: { currentTime: number; totalTime: number }) => {
            if (onAnimateCanceled !== undefined && currentTime === totalTime - 1) {
                setTimeout(onAnimateCanceled, 0);
            }
        },
        [onAnimateCanceled]
    );

    const lottieEventListeners = useMemo(
        () =>
            isLoop
                ? undefined
                : [
                    {
                        name: 'enterFrame',
                        callback: handleEvent,
                    },
                ],
        [isLoop, handleEvent]
    ) as ReactLottieEvent[];

    useEffect(() => void (isVisible && !isLoaded && setLoaded(true)), [isVisible]);

    return (
        <AnimationWrapper ref={ref} isVisible={isLoaded}>
            <Lottie
                playingState={isVisible ? 'playing' : 'stopped'}
                speed={speed}
                lottieEventListeners={lottieEventListeners}
                config={{
                    animationData,
                    path: isLoaded ? (path as unknown as string) : undefined,
                    autoplay: isVisible,
                    loop: isLoop,
                }}
            />
        </AnimationWrapper>
    );
};
