import { Form, Formik } from 'formik';

import { ExchangeType } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { FinallyAction } from '@component/modal/modals/common/finally-action/finally-action';
import { CurrencyExchangeFields } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-fields/currency-exchange-fields';
import { useCurrencyExchangeForm } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.hook';
import { CurrencyExchangeFormInterface } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.interface';
import { currencyExchangeValidationSchema } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.validation';

export const CurrencyExchangeForm = () => {
    const { isFormSubmitted, isConversionResultLoading, conversionError, onSubmit, successMessageNormalized } =
        useCurrencyExchangeForm();

    const errorMessage = useLocalizationText(LocalizationEnum.FailureCurrencyExchangeMessage);
    const successBtnText = useLocalizationText(LocalizationEnum.CommonConfirm);
    const errorBtnText = useLocalizationText(LocalizationEnum.CommonClose);

    return (
        <Formik
            initialValues={
                {
                    isAgreement: true,
                    type: ExchangeType.Sell,
                } as CurrencyExchangeFormInterface
            }
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={currencyExchangeValidationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            <Form>
                {!isFormSubmitted ? (
                    <CurrencyExchangeFields isConversionResultLoading={isConversionResultLoading} />
                ) : (
                    <FinallyAction
                        error={conversionError}
                        successMessage={successMessageNormalized}
                        errorMessage={errorMessage}
                        successBtnText={successBtnText}
                        errorBtnText={errorBtnText}
                    />
                )}
            </Form>
        </Formik>
    );
};
