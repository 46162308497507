import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

export const FooterNavigationTitle = styled.p`
    ${Typography.Paragraph14.Medium.Neutral50.Left.Style}
`;

export const FooterNavigationList = styled.ul`
    ${Flex.Column.Style};
    gap: 20px;
`;

export const FooterNavigationItemTitle = styled.span`
    ${Typography.Paragraph14.Medium.Neutral400.Left.Style}
`;
