import { object } from 'yup';

import { fieldRequiredValidation } from '@shared-util/validation';

import { RegistrationAdditionalFieldsFormEnum } from '@page/registration/registration-additional-fields/registration-additional-fields.enum';

export const privateRegistrationAdditionalFieldsFormValidationSchema = object().shape({
    [RegistrationAdditionalFieldsFormEnum.Nationality]: fieldRequiredValidation(),
    [RegistrationAdditionalFieldsFormEnum.ResidenceCountry]: fieldRequiredValidation(),
});
