import { Badge } from '@shared-atom/elpaso-kit/badge';
import { ExternalLink } from '@shared-atom/external-link/external-link';
import { Link } from '@shared-atom/link/link';
import { Translate } from '@shared-atom/translate/translate';
import {
    NavigationListItem,
    NavigationListItemButton,
    NavigationListItemIcon,
} from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/navigation-item-custom.styles';
import { useNavigationItem } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.hook';
import { PageWrapperNavigationItemProps } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.interface';
import {
    NavigationItemIcon,
    SubNavigationItem,
    SubNavigationList,
    SubNavigationTitle,
} from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.styles';
import { isExist } from '@shared-util/is-data';

export const NavigationItemCustom = <T extends string>({
    icon,
    iconActive,
    title,
    children,
    pageName,
    modalName,
    onClick,
    temporaryLink = '',
    stroked = false,
    badge,
}: PageWrapperNavigationItemProps<T>) => {
    const [isNavigationItemActive, isMenuOpen, onMenuClick, checkSubNavigationActive] = useNavigationItem({
        pageName,
        modalName,
        children,
    });

    const shouldSubNavigationRender = isMenuOpen && isExist(children) && isNavigationItemActive;
    const handleMenuClick = (e: any) => {
        if (temporaryLink === '') {
            onMenuClick(e.currentTarget);
            if (onClick !== undefined) {
                onClick({} as any);
            }
        }
    };
    return (
        <NavigationListItem>
            <NavigationListItemButton onClick={handleMenuClick} active={isNavigationItemActive} stroked={stroked}>
                {temporaryLink !== '' ? (
                    <ExternalLink href={temporaryLink}>
                        <NavigationListItemIcon>
                            <NavigationItemIcon as={isNavigationItemActive ? iconActive : icon} />
                        </NavigationListItemIcon>
                        <span>
                            <Translate langKey={title} />
                        </span>
                    </ExternalLink>
                ) : (
                    <>
                        <NavigationListItemIcon>
                            <NavigationItemIcon as={isNavigationItemActive ? iconActive : icon} />
                        </NavigationListItemIcon>
                        <Translate langKey={title} />
                        {badge && <Badge title={badge.text} size={badge.size} type={badge.type} />}
                    </>
                )}
            </NavigationListItemButton>
            {shouldSubNavigationRender && (
                <SubNavigationList>
                    {children?.map(({ title: subItemTitle, pageName: subItemPageName, params }) => (
                        <SubNavigationItem
                            key={subItemPageName}
                            isActive={checkSubNavigationActive(subItemPageName, params)}
                        >
                            <Link route={subItemPageName} params={params}>
                                <SubNavigationTitle>{subItemTitle}</SubNavigationTitle>
                            </Link>
                        </SubNavigationItem>
                    ))}
                </SubNavigationList>
            )}
        </NavigationListItem>
    );
};
