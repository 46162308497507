import { Translate } from '@shared-atom/translate/translate';
import { Scroll } from '@shared-component/scroll/scroll';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isExist } from '@shared-util/is-data';

import { useAddressBookSelector } from '@selector/address-book/use-address-book.selector';

import { CreatePartnerForm } from './create-partner-form/create-partner-form';
import { CreatePartnerFormWrapper, CreatePartnerTitle, CreatePartnerWrapper } from './create-partner.styles';

export const CreatePartnerModal = () => {
    const [{ selectedId }] = useAddressBookSelector();
    const title = isExist(selectedId)
        ? LocalizationEnum.AddressBookModalEditTitle
        : LocalizationEnum.AddressBookModalCreateTitle;

    return (
        <CreatePartnerWrapper>
            <Scroll>
                <CreatePartnerFormWrapper>
                    <CreatePartnerTitle>
                        <Translate langKey={title} />
                    </CreatePartnerTitle>
                    <CreatePartnerForm />
                </CreatePartnerFormWrapper>
            </Scroll>
        </CreatePartnerWrapper>
    );
};
