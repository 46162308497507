import styled from 'styled-components';

import { ElpasoButton } from '@shared-atom/elpaso-kit/button/style';
import { Typography } from '@shared-atom/typography/typography';
import { Neutral100 } from '@shared-style/colors';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const ApplyVacancyModalWrapper = styled.div`
    background-color: ${Neutral100};
    overflow-y: scroll;
    height: 100%;
    width: 560px;
    padding-top: 40px;
    max-height: 450px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    ${ElpasoButton} {
        text-transform: capitalize;
    }
    form {
        height: calc(100% - 114px);
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        max-height: unset;
    }
`;
export const ApplyVacancyModalTitle = styled.h2`
    ${Typography.H4Desktop.SemiBold.Neutral900.Center.Style};
    letter-spacing: -0.4px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.H4Mob.SemiBold.Neutral900.Center.Style};
        letter-spacing: -0.36px;
    }
`;
