import styled from 'styled-components';

import { InputDateWrapper } from '../../../atoms/elpaso-kit/input/input-date/input-date.styles';
import { Flex } from '../../../styles/flex';
import { MAX_MOBILE_SIZE } from '../../../styles/media-sizes';

export const DayPickerRangeWrapper = styled.div`
    width: 100%;
    gap: 16px;
    ${Flex.FlexStart.SpaceBetween.Style};
    ${InputDateWrapper} {
        flex: 1;
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        align-items: stretch;
        ${Flex.Column.Style};
    }
`;
