import { Account, BeneficiaryDetails, Fee, Payment } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

import { PaymentToCardFormValuesInterface } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form.interface';

import { PaymentFormValuesInterface } from './payment-form/payment-form.interface';

export const getPaymentMap = (
    payment: Payment,
    accounts: Account[],
    isRepeat: boolean
): PaymentFormValuesInterface => ({
    id: isRepeat ? null : payment.id,
    amount: payment.amount,
    currency: payment.currency.code,
    accountId: accounts.find(account => account.currency.code === payment.currency.code)?.accountId as string,
    beneficiary: {
        ...(payment.beneficiary as Partial<BeneficiaryDetails>),
        iban: payment.beneficiary?.iban ?? payment.beneficiary?.acctNumber,
        invoiceDate: isTrue(payment?.beneficiary?.invoiceDate)
            ? (new Date(payment?.beneficiary?.invoiceDate as string) as unknown as string) ?? null
            : null,
    },
    fee: payment.fee as Fee,
    reason: payment.reason,
    type: payment.type,
    purpose: payment.purpose,
    attachedFiles: payment.documents,
});

export const getPaymentToCardMap = (
    payment: Payment,
    accounts: Account[],
    isRepeat: boolean
): PaymentToCardFormValuesInterface => ({
    id: isRepeat ? null : payment.id,
    amount: payment.amount,
    currency: payment.currency.code,
    accountId: accounts.find(account => account.currency.code === payment.currency.code)?.id as string,
    reason: payment.reason,
    attachedFiles: payment.documents,
    cardNumber: payment?.beneficiary?.acctNumber as string,
    receiverName: payment?.beneficiary?.beneficiaryFirstName as string,
    receiverSurname: payment?.beneficiary?.beneficiaryLastName as string,
    region: payment?.beneficiary?.beneficiaryStateOrProvince as string,
    nationality: payment?.beneficiary?.beneficiaryCountry ?? '',
    fee: payment.fee as Fee,
});

export const initialComponentProps = {
    id: null,
    isSignMode: false,
    isRepeatMode: false,
};
export const PAYMENT_DOCUMENT_SIZE = parseInt(import.meta.env.VITE_PAYMENT_DOCUMENT_SIZE, 10);
