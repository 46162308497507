import { useMemo, useState } from 'react';

import { FCWithChildrenType } from '@shared-type/common';

import { ApplicationContext } from '@application//application.context';
import { initialProps } from '@application/application.initials';

export const ApplicationContextProvider: FCWithChildrenType = ({ children }) => {
    const [props, setProps] = useState(initialProps);

    const applicationProviderProps = useMemo(() => ({ props, setProps }), [props, setProps]);

    return <ApplicationContext.Provider value={applicationProviderProps}>{children}</ApplicationContext.Provider>;
};
