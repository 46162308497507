import { Footer } from './Footer';
import { Header } from './Header';
import classes from './styles/signWrapper.module.css';

export const SignWrapper = ({ children }) => (
    <div className={classes.wrapper}>
        <Header />
        <div className={classes.main}>
            <div className={classes.container}>{children}</div>
        </div>
        <Footer />
    </div>
);
