import styled from 'styled-components';

import { ElpasoButton, ElpasoButtonTitle } from '@shared-atom/elpaso-kit/button/style';
import { Typography } from '@shared-atom/typography/typography';
import { Neutral200 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import { PageWrapper } from '@page/workspace/common/common.styles';

export const SettingsWrapper = styled(PageWrapper)`
    height: 100%;
    padding: 32px 60px 40px;
    ${Flex.Column.Style};
    select {
        margin-left: 0;
        max-width: unset;
        ${Typography.Paragraph14.SemiBold.Neutral900.Style}
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 0;
    }
`;
export const PageContent = styled.div`
    height: 100%;
    margin-top: 32px;
    ${Flex.Row.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        margin-top: 16px;
        ${Flex.Column.Style};
    }
`;
export const Subsection = styled.section`
    column-gap: 50px;
    padding-bottom: 20px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${Neutral200};
    ${Flex.Wrap.SpaceBetween.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding-bottom: 16px;
        margin-bottom: 32px;
        ${Flex.Column.Style}
    }
`;

export const SubsectionTextContent = styled.div`
    margin-bottom: 20px;
    width: 230px;
    ${Flex.Column.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
    }
`;

export const SubsectionTitle = styled.h4`
    margin-bottom: 20px;
    width: 230px;
    ${Typography.Paragraph16.SemiBold.Neutral900.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
    }
`;
export const SubsectionDescription = styled.p`
    margin-bottom: 20px;
    width: 270px;
    ${Typography.Paragraph12.Regular.Neutral500.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
    }
`;

export const SubsectionTextContentTitle = styled(SubsectionTitle)`
    margin-bottom: 4px;
    width: 100%;
`;

export const SubsectionInfoWrapper = styled.div`
    width: 414px;
    max-width: 100%;
    padding: 0 1px;
    ${Flex.Column.Style};
    gap: 20px;
    ${ElpasoButton} {
        align-self: end;
        margin-top: 16px;
        @media (max-width: ${MAX_MOBILE_SIZE}px) {
            width: 100%;
        }
        :disabled {
            display: none;
        }
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 100%;
        gap: 16px;
    }
    form {
        ${Flex.Column.Style};
        gap: 16px;
    }
`;

export const SupportInfoWrapper = styled.div`
    width: 100%;
    padding: 24px 0 16px;
    ${Flex.Row.VerticalCenter.Style};
    gap: 20px;
    border-top: 1px solid ${Neutral200};
    ${ElpasoButtonTitle} {
        display: block;
        &:first-letter {
            text-transform: capitalize;
        }
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Flex.Column.FlexStart.Style};
        gap: 16px;
        padding: 0;
        border-top: none;
        ${ElpasoButton} {
            width: 100%;
        }
    }
`;
export const SupportInfo = styled.p`
    ${Typography.Paragraph16.SemiBold.Neutral900.Left.Style};
    letter-spacing: 0;

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.Paragraph14.SemiBold.Neutral900.Left.Style};
    }
`;
