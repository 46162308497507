import { createContext } from 'react';

import EsKeys from '@shared-locale/es.json';
import { LangKeysEnum, LocalizationType } from '@shared-store/store-localization.interface';
import { noop } from '@shared-util/noop';

export const LocalizationStoreContext = createContext({
    lang: LangKeysEnum.EN,
    keys: EsKeys as LocalizationType,
    loading: false,
    setLangKey: noop,
    setLanguage: noop,
    isKeysEmpty: false,
});
