import { useMemo } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { UserActivatedStatus } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { linkMarkdownTag } from '@component/link-markdown-tag/link-markdown-tag';
import { useUserStatus } from '@hook/user/user-status.hook';

export const useUserBlockedNotify = () => {
    const userStatus = useUserStatus();
    return useMemo(
        () =>
            userStatus === UserActivatedStatus.Blocked ? (
                <Translate
                    langKey={LocalizationEnum.YourAccountIsCurrentlyBlockedOurSupportTeamIsReadyToAssistYouWith}
                    customRender={linkMarkdownTag}
                />
            ) : null,
        [userStatus]
    );
};
