import styled, { css } from 'styled-components';

import { Link } from '@shared-atom/link/link';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

const logoWrapperStyles = css`
    height: 72px;
`;

export const LogoWrapper = styled.div`
    height: 56px;
    max-width: 69px;

    border-bottom-right-radius: 8px;

    ${({ theme }: ThemeTypeInterface) => theme.size.isMobileL && logoWrapperStyles};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        max-width: 56px;
    }
`;

export const FlexLink = styled(Link)`
    ${Flex.VerticalCenter.HorizontalCenter.Style}
    justify-content: flex-start;
`;

export const FlexWrapper = styled.div`
    height: ${({ theme }: ThemeTypeInterface) => (theme.size.isMobileL ? 72 : 56)}px;

    ${Flex.VerticalCenter.Style}
`;
