import { useField } from 'formik';
import { useMemo } from 'react';

import { getAccountCurrencyCodesOptions } from '@shared-atom/elpaso-kit/select-account/select-account-option/select-account-option.utils';
import { CurrenciesEnum } from '@shared-enum/currencies.enum';
import { Account } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { CurrencyExchangeEnum } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-form.enum';
import { useAccountSelector } from '@selector/account/use-account.selector';

export interface CurrencyExchangeHookInterface {
    accountOptionsSell: { label: JSX.Element; value: string }[];
    accountOptionsBuy: { label: JSX.Element; value: string }[];
    amountLabel: string;
    buyLabel: string;
    sellLabel: string;
}

const buyAvailableCurrencies: CurrenciesEnum[] = [CurrenciesEnum.GBP, CurrenciesEnum.EUR, CurrenciesEnum.USD];

export const useCurrencyExchangeLabels = (): CurrencyExchangeHookInterface => {
    const amountLabel = useLocalizationText(LocalizationEnum.ExchangeFormAmountTitle);
    const buyLabel = useLocalizationText(LocalizationEnum.ExchangeFormBuyTitle);
    const sellLabel = useLocalizationText(LocalizationEnum.ExchangeFormSellTitle);
    const [{ value: valueSellCurrency }] = useField(CurrencyExchangeEnum.Sell);
    const [{ ccAccounts }] = useAccountSelector();
    const accountOptionsSell = getAccountCurrencyCodesOptions(ccAccounts);
    const accountOptionsBuy = useMemo(
        () =>
            getAccountCurrencyCodesOptions(
                buyAvailableCurrencies.map(availableCurrencyCode => {
                    const existingAccount = ccAccounts.find(({ currency: { code } }) => code === availableCurrencyCode);
                    if (existingAccount) {
                        return existingAccount;
                    }
                    return { currency: { code: availableCurrencyCode }, balance: 0 } as Account;
                })
            ).filter(({ value }) => value !== valueSellCurrency),
        [ccAccounts, valueSellCurrency]
    );

    return {
        amountLabel,
        buyLabel,
        sellLabel,
        accountOptionsSell,
        accountOptionsBuy,
    };
};
