import React, { FC, useContext, useMemo } from 'react';

import { Notification } from '@shared-atom/elpaso-kit/notification';
import { NotificationSizeEnum, NotificationTypeEnum } from '@shared-atom/elpaso-kit/notification/types';
import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { ClientInformationModalProps } from '@component/informaton-modal/client-information-modal.interface';
import { ClientInformationModalWrapper } from '@component/informaton-modal/client-information-modal.styles';

export const SuccessInformationModal: FC<ClientInformationModalProps> = ({ isVisible = false }) => {
    const { props } = useContext(InformationModalContext);
    const notificationText = useMemo(
        () => (isTrue(props?.text) ? props.text : LocalizationEnum.YourRequestHasBeenSuccessfullySent),
        [props.text]
    ) as LocalizationEnum;
    const notificationSize = useMemo(
        () => (isTrue(props?.size) ? props.size : NotificationSizeEnum.M),
        [props.size]
    ) as NotificationSizeEnum;
    const notificationType = useMemo(
        () => (isTrue(props?.type) ? props.type : NotificationTypeEnum.ACCENT),
        [props.type]
    ) as NotificationTypeEnum;

    return (
        <ClientInformationModalWrapper isVisible={isVisible} isDashboardModal={props.isDashboardModal}>
            <Notification text={notificationText} size={notificationSize} type={notificationType} />
        </ClientInformationModalWrapper>
    );
};
