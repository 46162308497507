export enum DevicesEnum {
    MobileS = 320,
    MobileM = 375,
    MobileL = 480,
    Tablet = 768,
    MaxMobile = 993,
    Laptop = 1024,
    Desktop = 1366,
    DesktopL = 1600,
    FullHD = 1920,
}
