import { Instance } from 'mobx-state-tree';

import { FeatureTagsModelType } from '@model/feature-tags-model/feature-tags-model';
import { useStore } from '@store/store-context.hook';

export const useFeatureTagsSelector = (): [Instance<typeof FeatureTagsModelType>, boolean, null | string] => {
    const { featureTags } = useStore();

    return [featureTags, featureTags.loading.isLoading, featureTags.error];
};
