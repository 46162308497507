import { useEffect, useState } from 'react';

import { ModalEnum } from './modal.enum';
import { UseModalInterface } from './modal.interface';

export const useModal = ({ selectedModal, onModalAfterClose }: UseModalInterface) => {
    const [savedSelectedModal, setSavedSelectedModal] = useState<ModalEnum | null>(null);
    const [isVisible, setVisible] = useState(false);

    const isModalOpen = selectedModal !== null;

    const onAfterOpen = () => {
        setSavedSelectedModal(selectedModal);
        setVisible(true);
    };

    const onAfterClose = () => {
        setSavedSelectedModal(null);
        setVisible(false);
        if (onModalAfterClose instanceof Function) {
            onModalAfterClose();
        }
    };

    useEffect(() => void (!isModalOpen && setVisible(isModalOpen)), [isModalOpen]);

    return { savedSelectedModal, isVisible, onAfterOpen, onAfterClose };
};
