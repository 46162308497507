import React from 'react';
import './index.css';

export const CheckboxWrapper = ({
    label,
    subLabel,
    forId,
    isDisabled = false,
    isChecked = false,
    onChange = e => {
        console.log('e: ', e);
    },
    style = {},
    className = '',
}) => (
    <label htmlFor={forId} className={`checkboxWrapper ${className}`} style={style}>
        <input type="checkbox" checked={isChecked} onChange={onChange} disabled={isDisabled} id={forId} />
        {label !== '' && <p className="mainSpan">{label}</p>}
        {subLabel !== '' && <p className="subSpan">{subLabel}</p>}
    </label>
);
