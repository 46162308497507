import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { PageEnum } from '@enum/page.enum';
import { HeaderLeftSide } from '@page/main-pages/main-page-common/header/header-left-side/header-left-side';
import { HeaderRightSide } from '@page/main-pages/main-page-common/header/header-right-side/header-right-side';
import { Wrapper } from '@page/main-pages/main-page-common/header/header.styles';
import { AlignContainer } from '@page/main-pages/main-page-common/main-page-common.styles';

export const Header = () => {
    const { name } = useCurrentNavigation();

    return (
        <Wrapper isDark={name === PageEnum.BusinessHome} shouldShowHeaderNotification={false}>
            {/* {name === PageEnum.Home && ( */}
            {/*    <PricingNotification route={PageEnum.Prices}> */}
            {/*        <Translate langKey={LocalizationEnum.IncomingPaymentsPricingUpdateForIndividualsAndLegalEntities} /> */}
            {/*    </PricingNotification> */}
            {/* )} */}
            <AlignContainer>
                <HeaderLeftSide isBusinessPage={name === PageEnum.BusinessHome} />
                <HeaderRightSide isBusinessPage={name === PageEnum.BusinessHome} />
            </AlignContainer>
        </Wrapper>
    );
};
