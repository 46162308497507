import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import FailureIcon from './assets/failure.svg?react';
import SuccessIcon from './assets/success.svg?react';
import { useInputPasswordTooltipValidationOptions } from './input-password-tooltip-validation.options';
import { PasswordDropdownWrapper, DropdownItem, IconWrapper, Label } from './input-password-tooltip-validation.styles';

export interface TooltipValidationProps {
    value: string;
}

export const TooltipValidation: FC<TooltipValidationProps> = observer(({ value }) => {
    const validationOptions = useInputPasswordTooltipValidationOptions();

    return (
        <PasswordDropdownWrapper>
            {validationOptions.map(option => (
                <DropdownItem key={option.label}>
                    <Label>{option.label}</Label>
                    <IconWrapper>{option.isValid(value) ? <SuccessIcon /> : <FailureIcon />}</IconWrapper>
                </DropdownItem>
            ))}
        </PasswordDropdownWrapper>
    );
});
