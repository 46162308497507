import { useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal.context';
import {
    DefaultAnswer,
    Mutation,
    UserSetWebPreferredLoginMethod,
    UserVerifyCodeGoogleAuthenticator,
    UserVerifyCodeGoogleAuthenticatorMutationVariables,
    WebPreferredLoginMethod,
} from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isExist, isTrue } from '@shared-util/is-data';

import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';

export const useGoogleAuthActivateForm = () => {
    const { onOpen: onInformationModalOpen } = useContext(InformationModalContext);
    const { onClose } = useContext(ModelContext);

    const { loadCurrentUser } = useUserActionsSelector();

    const [
        userSetWebPreferredLoginMethod,
        {
            data: dataSetWebPreferredLoginMethod,
            loading: isLoadingSetWebPreferredLoginMethod,
            error: setWebPreferredLoginMethodError,
        },
    ] = useMutation<Pick<Mutation, 'userSetWebPreferredLoginMethod'>>(UserSetWebPreferredLoginMethod);

    const [
        userVerifyCodeGoogleAuthenticator,
        {
            data: dataVerifyCodeGoogleAuthenticator,
            loading: isLoadingVerifyCodeGoogleAuthenticator,
            error: verifyCodeGoogleAuthenticatorError,
        },
    ] = useMutation<Pick<Mutation, 'userVerifyCodeGoogleAuthenticator'>>(UserVerifyCodeGoogleAuthenticator);

    const showFailureInformModal = useCallback(
        () =>
            onInformationModalOpen(InformationModalEnum.FailureInformationModal, {
                text: LocalizationEnum.ChangesHaveNotBeenChangedPleaseTryAgainLater,
            }),
        [onInformationModalOpen]
    );

    useEffect(() => {
        if (isTrue((dataVerifyCodeGoogleAuthenticator?.userVerifyCodeGoogleAuthenticator as DefaultAnswer)?.status)) {
            userSetWebPreferredLoginMethod({
                variables: { preferredLoginMethod: WebPreferredLoginMethod.GoogleAuthenticator },
            });
        }
    }, [dataVerifyCodeGoogleAuthenticator]);

    useEffect(() => {
        if (isTrue((dataSetWebPreferredLoginMethod?.userSetWebPreferredLoginMethod as DefaultAnswer)?.status)) {
            loadCurrentUser().finally(onClose);
        }
    }, [dataSetWebPreferredLoginMethod]);

    useEffect(() => {
        if (isExist(verifyCodeGoogleAuthenticatorError?.message)) {
            onInformationModalOpen(InformationModalEnum.FailureInformationModal, {
                text: verifyCodeGoogleAuthenticatorError?.message,
            });
        }
    }, [verifyCodeGoogleAuthenticatorError]);

    useEffect(() => {
        if (isExist(setWebPreferredLoginMethodError?.message)) {
            onClose();
            showFailureInformModal();
        }
    }, [setWebPreferredLoginMethodError]);

    const onSubmit = useCallback((values: UserVerifyCodeGoogleAuthenticatorMutationVariables) => {
        userVerifyCodeGoogleAuthenticator({ variables: { code: values.code } });
    }, []);

    return { isLoading: isLoadingSetWebPreferredLoginMethod || isLoadingVerifyCodeGoogleAuthenticator, onSubmit };
};
