import { ExternalLink } from '@shared-atom/external-link/external-link';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { GoogleRecaptchaInfoComponent } from '@component/google-recaptcha-info/google-recaptcha-info.styles';

export const GoogleRecaptchaInfo = () => (
    <GoogleRecaptchaInfoComponent>
        <Translate langKey={LocalizationEnum.ThisSiteIsProtectedByRecaptchaAndTheGoogle} />
        <ExternalLink href="https://policies.google.com/privacy">
            <Translate langKey={LocalizationEnum.PrivacyPolicyItemsTitle} />
        </ExternalLink>
        <Translate langKey={LocalizationEnum.CommonAnd} />
        <ExternalLink href="https://policies.google.com/terms">
            <Translate langKey={LocalizationEnum.TermsOfService} />
        </ExternalLink>
        <Translate langKey={LocalizationEnum.CommonApply} />
    </GoogleRecaptchaInfoComponent>
);
