import { FC, JSX, MutableRefObject, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { ScrollRefProps } from '../../../components/scroll/scroll-ref.props';
import { isString } from '../../../utils/is-data';
import { usePrevious } from '../../utils/use-previous';
import { useCurrentNavigation, useHashNavigation } from '../use-current-navigation.hook';
import { AnchorContext } from './anchor.context';
import { AnchorEnum } from './anchor.enum';

interface AnchorProviderProps {
    scrollRef: MutableRefObject<ScrollRefProps>;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

const OFFSET_POSITION = 88 + 16; // header size + offset

export const AnchorProvider: FC<AnchorProviderProps> = ({ children, scrollRef }) => {
    const { name } = useCurrentNavigation();
    const [hash, handleClearHash] = useHashNavigation<AnchorEnum>();
    const [anchorRefs, setAnchorRef] = useState({} as Record<AnchorEnum, HTMLElement>);
    const prevPageName = usePrevious(name);

    const setAnchor = (hashName: AnchorEnum, ref: HTMLElement) =>
        setAnchorRef(currentRefs => ({
            ...currentRefs,
            [hashName]: ref,
        }));

    const scrollTop = useCallback(() => scrollRef.current?.setScrollPosition(0), [scrollRef]);

    useEffect(() => {
        if (!isString(hash)) {
            if (prevPageName !== name) {
                setTimeout(() => scrollRef.current?.setScrollPosition(0), 50);
            }

            return;
        }

        const elementToScroll = anchorRefs[hash];

        if (elementToScroll !== undefined && elementToScroll !== null) {
            const { top } = elementToScroll.getBoundingClientRect();

            scrollRef.current.setScrollPosition(top - OFFSET_POSITION + scrollRef.current.scrollPosition);
            return handleClearHash();
        }
    }, [hash, anchorRefs, name]);

    const props = useMemo(() => ({ setAnchor, scrollTop, scrollRef }), [setAnchor, scrollTop, scrollRef]);

    return <AnchorContext.Provider value={props}>{children}</AnchorContext.Provider>;
};
