import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { ClickEventType } from '@shared-type/click-event.type';

import KeyIcon from '../assets/key.svg?react';
import { CustomInput } from '../customInput';
import { InputProps } from '../input'; // Input,
import { InputTypeEnum } from '../input-type.enum';
import { TooltipValidation } from './input-password-tooltip-validation/input-password-tooltip-validation';
import { EyeClose, EyeOpen, PasswordWrapper } from './input-password.styles';

export interface InputPasswordProps extends Omit<InputProps, 'type' | 'name'> {
    id?: string;
    name?: string;
    isNew?: boolean;
    hasTooltipValidation?: boolean;
    autofocus?: boolean | undefined;
}

export const CustomInputPassword = observer(
    ({
        id = 'password',
        title,
        name = 'password',
        isNew = true,
        hasTooltipValidation = false,
        autofocus,
        ...props
    }: InputPasswordProps) => {
        const normalizedTitle = useLocalizationText(title);
        const [isShowPassword, setShowPassword] = useState(false);
        const [isShowTooltipValidation, setShowTooltipValidation] = useState(false);
        const [{ value }] = useField(name);

        const handleShowPassword: ClickEventType = event => {
            event.preventDefault();
            setShowPassword(!isShowPassword);
        };

        const handleShowTooltipValidation = (isFocus: boolean) => {
            setShowTooltipValidation(isFocus);
        };

        const autocompleteProps = { autocomplete: isNew ? 'new-password' : 'current-password' };
        const shouldRenderTooltipValidation = isShowTooltipValidation && hasTooltipValidation;

        return (
            <CustomInput
                {...autocompleteProps}
                id={id}
                type={isShowPassword ? InputTypeEnum.Text : InputTypeEnum.Password}
                title={normalizedTitle}
                name={name}
                icon={KeyIcon}
                onFocus={handleShowTooltipValidation}
                {...props}
                autofocus={autofocus}
            >
                <ButtonTransparent
                    tabIndex={-1}
                    type={ButtonTypeEnum.Button}
                    onClick={handleShowPassword}
                    style={{ height: '25px' }}
                >
                    <PasswordWrapper>{isShowPassword ? <EyeClose /> : <EyeOpen />}</PasswordWrapper>
                </ButtonTransparent>
                {shouldRenderTooltipValidation && <TooltipValidation value={value} />}
            </CustomInput>
        );
    }
);
