import { Form, Formik } from 'formik';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomInput } from '@shared-component/input/customInput';
import { FormWrapper } from '@shared-form/form-common/form-common.styles';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ForgotPasswordPropsType } from '@page/auth-pages/forgot-password/forgot-password.type';
import { forgotPasswordFormSchema } from '@page/auth-pages/forgot-password/forgot-password.validation';

import { ForgotPasswordFormEnum } from './forgot-password.enum';
import { initialValue } from './forgot-password.initial';

export const ForgotPasswordForm = ({ handleForgotPassword, isLoading }: ForgotPasswordPropsType) => {
    const forgotPassword = useLocalizationText(LocalizationEnum.LoginFormInputTitle);
    const enterYourMailTitle = useLocalizationText(LocalizationEnum.EnterYourMail);

    return (
        <Formik
            initialValues={initialValue}
            onSubmit={handleForgotPassword}
            validationSchema={forgotPasswordFormSchema}
            validateOnChange={false}
        >
            <Form>
                <FormWrapper>
                    <CustomInput
                        title={forgotPassword}
                        name={ForgotPasswordFormEnum.Email}
                        placeholder={enterYourMailTitle}
                        autofocus
                    />
                    <Button
                        title={LocalizationEnum.ForgotPasswordFormTitle}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                        isSubmit
                        style={{ marginTop: '24px', width: '100%' }}
                        isLoading={isLoading}
                    />
                </FormWrapper>
            </Form>
        </Formik>
    );
};
