import { Maybe, UserFinancialStatus, UserGender, UserProfile } from '@shared-graphql';

export interface InitialUserProfileInterface extends Omit<UserProfile, 'dateBirthday' | 'gender' | 'financialStatus'> {
    dateBirthday: Maybe<Date>;
    gender: Maybe<UserGender>;
    financialStatus: Maybe<UserFinancialStatus>;
}

export const initialUserProfileModel: InitialUserProfileInterface = {
    id: '',
    name: '',
    lastName: '',
    thirdName: null,
    dateBirthday: null,
    gender: null,
    financialStatus: null,
    nationality: '',
};
