import { useCallback, useState } from 'react';
import { AnyObjectSchema, number, object, string } from 'yup';

import {
    useLocalizationText,
    useLocalizationTextWithReplacedValue,
} from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PaymentToCardDetailsFormEnum } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-details-form/payment-to-card-details-form.enum';

export const useFormValidation = () => {
    const requiredFieldErrorText = useLocalizationText(LocalizationEnum.RequiredFieldErrorMessage);
    // const onlyMastercardErrorText = useLocalizationText(LocalizationEnum.OnlyMastercardCardsAreAvailable);
    const mustBeGreaterFieldErrorText = useLocalizationTextWithReplacedValue(LocalizationEnum.MustBeGreaterThanX, [
        { key: '{number}', value: '0' },
    ]);
    const maxLengthFieldErrorText = useLocalizationTextWithReplacedValue(LocalizationEnum.MaxLengthXNumbers, [
        { key: '{number}', value: '13' },
    ]);

    const defaultSchema = object().shape({
        [PaymentToCardDetailsFormEnum.PaymentCurrency]: string().required(requiredFieldErrorText),
        [PaymentToCardDetailsFormEnum.Amount]: number()
            .required(requiredFieldErrorText)
            .test(PaymentToCardDetailsFormEnum.Amount, maxLengthFieldErrorText, value =>
                /^\d{1,13}(\.\d*)?$/.test(`${value ?? ''}`)
            )
            .test(PaymentToCardDetailsFormEnum.Amount, mustBeGreaterFieldErrorText, value => Number(value) > 0),
        [PaymentToCardDetailsFormEnum.CardNumber]: string().required(requiredFieldErrorText),
        // .test(PaymentToCardDetailsFormEnum.CardNumber, onlyMastercardErrorText, value =>
        //     /(?<!\d)\d{16}(?!\d)/.test(`${value?.replaceAll('-', '') ?? ''}`)
        // )
        [PaymentToCardDetailsFormEnum.ReceiverFirstName]: string().required(requiredFieldErrorText),
        [PaymentToCardDetailsFormEnum.ReceiverSurname]: string().required(requiredFieldErrorText),
        [PaymentToCardDetailsFormEnum.Nationality]: string().required(requiredFieldErrorText),
    });
    const [schema, setSchema] = useState<AnyObjectSchema>(defaultSchema);

    const updateSchema = useCallback(
        (addedSchema: any) => {
            const newSchema = schema.shape(addedSchema);
            setSchema(newSchema);
        },
        [schema, setSchema]
    );

    return [schema, updateSchema];
};
