import { useEffect } from "react";

import {useLocalizationText} from "@shared-hook/localization/use-localization-text.hook";
import {useCurrentNavigation} from "@shared-hook/navigation/use-current-navigation.hook";
import {LocalizationEnum} from "@shared-locale/localization.enum";

import {PageEnum} from "@enum/page.enum";
import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './CookieConsent.css'


const cookieConsentDisabledPages = new Set([
    PageEnum.PrivacyPolicy,
    PageEnum.MobileAppComplaints,
    PageEnum.MobileAppTermsAndConditions,
    PageEnum.MobileAppPrivacyPolicy,
    PageEnum.MobileAppHRDataPrivacyNotice,
    PageEnum.MobileAppAcquiringServicesAgreement,
    PageEnum.MobileAppModernSlaveryStatement,
    PageEnum.MobileAppValueMissionStatement,
]);

/* eslint @typescript-eslint/naming-convention: 0 */
const CookieConsent = () => {
    const { name } = useCurrentNavigation();
    // TODO make sure name is vwlid and check passed
    
    const cookieInfoTitle = useLocalizationText(LocalizationEnum.CookieInfoTitle);
    const cookieInfoDescription = useLocalizationText(LocalizationEnum.CookieInfoDescription);
    const acceptAllBtnText = useLocalizationText(LocalizationEnum.CommonAcceptAll);
    const preferencesBtnText = useLocalizationText(LocalizationEnum.CommonPreferences);
    const closeBtnText = useLocalizationText(LocalizationEnum.CommonClose);
    const saveCurrentSelectionBtnText = useLocalizationText(LocalizationEnum.SaveCurrentSelection);
    const rejectAllBtnText = useLocalizationText(LocalizationEnum.CommonRejectAll);
    const cookieSettingsModalTitle = useLocalizationText(LocalizationEnum.CookieSettingsModalTitle);
    const cookieSettingsModalDescription = useLocalizationText(LocalizationEnum.CookieSettingsModalDescription);
    const cookieSettingsModalNecessaryTitle = useLocalizationText(LocalizationEnum.CookieSettingsModalNecessaryTitle);
    const cookieSettingsModalAnalyticsTitle = useLocalizationText(LocalizationEnum.CookieSettingsModalAnalyticsTitle);
    const cookieSettingsModalAdvertisingTitle = useLocalizationText(LocalizationEnum.CookieSettingsModalAdvertisingTitle);
    const cookieSettingsModalNecessaryDescription = useLocalizationText(LocalizationEnum.CookieSettingsModalNecessaryDescription);
    const cookieSettingsModalAnalyticsDescription = useLocalizationText(LocalizationEnum.CookieSettingsModalAnalyticsDescription);
    const cookieSettingsModalAdvertisingDescription = useLocalizationText(LocalizationEnum.CookieSettingsModalAdvertisingDescription);

    const allTextsReady = [
        cookieInfoTitle,
        cookieInfoDescription,
        acceptAllBtnText,
        preferencesBtnText,
        closeBtnText,
        saveCurrentSelectionBtnText,
        rejectAllBtnText,
        cookieSettingsModalTitle,
        cookieSettingsModalDescription,
        cookieSettingsModalNecessaryTitle,
        cookieSettingsModalAnalyticsTitle,
        cookieSettingsModalAdvertisingTitle,
        cookieSettingsModalNecessaryDescription,
        cookieSettingsModalAnalyticsDescription,
        cookieSettingsModalAdvertisingDescription,
    ].every(text => Boolean(text) === true);


    useEffect(() => {
        // TODO make sure name is vwlid and check passed

        if (!document.getElementById('cc--main') && allTextsReady && !cookieConsentDisabledPages.has(name)) {
            window.CookieConsentApi = window.initCookieConsent();
            window.CookieConsentApi.run({
                current_lang: 'en',
                autoclear_cookies: true,
                cookie_name: 'allowUsingCookie',
                cookie_expiration: 365,
                page_scripts: true,
                force_consent: true,
                gui_options: {
                    consent_modal: {
                        layout: 'cloud',
                        position: 'bottom center',
                        transition: 'slide'
                    },
                    settings_modal: {
                        layout: 'box',
                        position: 'left',
                        transition: 'zoom'
                    }
                },
                languages: {
                    'en': {
                        consent_modal: {
                            title: `${cookieInfoTitle}`,
                            description: `${cookieInfoDescription} <a href="/privacy-policy" class="cc-link">Privacy policy</a>`,
                            primary_btn: {
                                text: acceptAllBtnText,
                                role: 'accept_all',
                            },
                            secondary_btn: {
                                text: preferencesBtnText,
                                role: 'settings',
                            },
                        },
                        settings_modal: {
                            title: cookieSettingsModalTitle,
                            save_settings_btn: saveCurrentSelectionBtnText,
                            accept_all_btn: acceptAllBtnText,
                            reject_all_btn: rejectAllBtnText,
                            close_btn_label: closeBtnText,
                            blocks: [
                                {
                                    description: `${cookieSettingsModalDescription} <a href="/privacy-policy" class="cc-link">Privacy policy</a>`
                                }, {
                                    title: cookieSettingsModalNecessaryTitle,
                                    description: cookieSettingsModalNecessaryDescription,
                                    toggle: {
                                        value: 'necessary',
                                        enabled: true,
                                        readonly: true,
                                    }
                                }, {
                                    title: cookieSettingsModalAnalyticsTitle,
                                    description: cookieSettingsModalAnalyticsDescription,
                                    toggle: {
                                        value: 'analytics',
                                        enabled: false,
                                        readonly: false,
                                        reload: 'on_disable'
                                    },
                                }, {
                                    title: cookieSettingsModalAdvertisingTitle,
                                    description: cookieSettingsModalAdvertisingDescription,
                                    toggle: {
                                        value: 'advertising',
                                        enabled: false,
                                        readonly: false,
                                        reload: 'on_disable'
                                    },
                                }
                            ]
                        }
                    }
                }
            });
        }

    }, [allTextsReady]);

    return null;
};
/* eslint @typescript-eslint/naming-convention: 1 */

export default CookieConsent;
