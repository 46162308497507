import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Dark, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const PageWrapper = styled.div`
    max-width: 1094px;
    width: 100%;
    margin: 0 auto;
`;

export const PageContainerWrapper = styled(PageWrapper)`
    display: flex;
    flex-direction: column;
    padding: 32px 0 40px 0;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 0 0 40px;
    }
`;

export const HeaderWrapper = styled.div`
    max-width: 1094px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 16px;

    ${Flex.SpaceBetween.VerticalCenter.Style}
    gap:15px;
`;

export const Title = styled.h1`
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.XXL.Normal.Style};
    @media (max-width: 768px) {
        font-size: 32px;
    }
`;

export const PageTitle = styled.h1`
    ${Typography.H3Desktop.SemiBold.Neutral900.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${Typography.H4Desktop.SemiBold.Neutral900.Style};
    }
`;
