import { useField } from 'formik';

import { PaymentEntityType } from '@shared-graphql';

import { PaymentDetailsFormEnum } from '@component/modal/modals/payment/payment-form/payment-form-steps/payment-details-form/payment-details-form.enum';

export const usePaymentDetailsForm = () => {
    const [{ value: beneficiaryEntityType }] = useField(PaymentDetailsFormEnum.BeneficiaryEntityType);

    const shouldRenderLegalFields = beneficiaryEntityType === PaymentEntityType.company;

    return { shouldRenderLegalFields };
};
