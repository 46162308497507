import { BeneficiaryDetails, PaymentEntityType, PaymentType } from '@shared-graphql';

import { CC_REQUIRED_FIELD_REGEXP } from './payment-form-steps/payment-form-steps.options';
import { PaymentFormValuesInterface } from './payment-form.interface';

const beneficiaryDetailsInitial: Partial<BeneficiaryDetails> = {
    beneficiaryEntityType: PaymentEntityType.company,
    bicSwift: '',
};

export const paymentFormInitials: PaymentFormValuesInterface = {
    id: null,
    accountId: '',
    amount: 0,
    currency: '',
    beneficiary: beneficiaryDetailsInitial,
    reason: '',
    purpose: '',
    type: PaymentType.local,
    attachedFiles: [],
};

export const validationSchemaInitial = {
    acctNumber: null,
    bankAddress: null,
    bankCode: null,
    bankCountry: CC_REQUIRED_FIELD_REGEXP,
    bankName: null,
    beneficiaryAddress: null,
    beneficiaryCity: null,
    beneficiaryCompanyName: null,
    beneficiaryCountry: null,
    beneficiaryEntityType: PaymentEntityType.individual,
    beneficiaryFirstName: null,
    beneficiaryLastName: null,
    beneficiaryPostcode: null,
    beneficiaryStateOrProvince: null,
    bicSwift: null,
    branchCode: null,
    clabe: null,
    cnaps: null,
    iban: null,
    ifsc: null,
    sortCode: null,
    paymentType: PaymentType.sepa,
    purpose: null,
    charityNumber: null,
    invoiceDate: null,
    invoiceNumber: null,
    beneficiaryInvoiceNumber: null,
    beneficiaryInvoiceDate: null,
    beneficiaryCharityNumber: null,
    nationality: null,
};
