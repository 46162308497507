import { Form, Formik } from 'formik';
import React from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ModalFooter, ModalFormRowWrapper } from '@component/modal/client-modal.styles';
import { ChangeEmailFormEnum } from '@component/modal/modals/user-profile/user-short-profile-change-email/user-short-profile-change-email.enum';
import { useUserShortProfileChangeEmail } from '@component/modal/modals/user-profile/user-short-profile-change-email/user-short-profile-change-email.hook';
import { ChangeEmailFormInitial } from '@component/modal/modals/user-profile/user-short-profile-change-email/user-short-profile-change-email.initial';
import { UserShortProfileChangeEmailWrapper } from '@component/modal/modals/user-profile/user-short-profile-change-email/user-short-profile-change-email.styles';
import { ChangeEmailFormValidationSchema } from '@component/modal/modals/user-profile/user-short-profile-change-email/user-short-profile-change-email.validation';

export const UserShortProfileChangeEmail = () => {
    const { onSubmit, isLoading } = useUserShortProfileChangeEmail();

    return (
        <UserShortProfileChangeEmailWrapper>
            <Formik
                initialValues={ChangeEmailFormInitial}
                validationSchema={ChangeEmailFormValidationSchema}
                onSubmit={onSubmit}
                validateOnChange={false}
                enableReinitialize
            >
                <Form>
                    <ModalFormRowWrapper>
                        <CustomInput
                            label={LocalizationEnum.CommonEmail}
                            name={ChangeEmailFormEnum.Email}
                            placeholder="yourmail@example.com"
                            isMobile
                        />
                    </ModalFormRowWrapper>
                    <ModalFooter>
                        <Button
                            isSubmit
                            isLoading={isLoading}
                            title={LocalizationEnum.CommonSave}
                            size={ButtonSizeEnum.L}
                            type={ButtonTypeEnum.COLOR}
                        />
                    </ModalFooter>
                </Form>
            </Formik>
        </UserShortProfileChangeEmailWrapper>
    );
};
