import { useMemo } from 'react';

import { CurrenciesEnum } from '@shared-enum/currencies.enum';

import { useAccountSelector } from '@selector/account/use-account.selector';

const AVAILABLE_PAYMENT_CURRENCIES = [CurrenciesEnum.EUR, CurrenciesEnum.USD, CurrenciesEnum.GBP];

export const useAvailablePaymentAccounts = () => {
    const [{ ccAccounts }] = useAccountSelector();

    return useMemo(
        () =>
            ccAccounts?.filter(({ currency: { code } }) =>
                AVAILABLE_PAYMENT_CURRENCIES.includes(code as CurrenciesEnum)
            ) ?? [],
        [ccAccounts]
    );
};
