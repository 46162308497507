import { useCallback, useEffect, useState } from 'react';

import { DevicesEnum } from '@shared-enum/devices.enum';
import { useWindowResize } from '@shared-hook/utils/use-window-resize.hook';

const defaultCurrentDevice = {
    isMobileS: true,
    isMobileM: false,
    isMobileL: false,
    isTablet: false,
    isMaxMobile: false,
    isLaptop: false,
    isDesktop: false,
    isDesktopL: false,
    isFullHD: false,
};

const isMinWidth = (minWidth: DevicesEnum) => window.matchMedia(`(min-width: ${minWidth}px)`).matches;

export const useDevices = () => {
    const [currentDevice, setCurrentDevice] = useState(defaultCurrentDevice);

    const handleWindowResize = useCallback(
        () =>
            setCurrentDevice({
                isMobileS: isMinWidth(DevicesEnum.MobileS),
                isMobileM: isMinWidth(DevicesEnum.MobileM),
                isMobileL: isMinWidth(DevicesEnum.MobileL),
                isTablet: isMinWidth(DevicesEnum.Tablet),
                isMaxMobile: isMinWidth(DevicesEnum.MaxMobile),
                isLaptop: isMinWidth(DevicesEnum.Laptop),
                isDesktop: isMinWidth(DevicesEnum.Desktop),
                isDesktopL: isMinWidth(DevicesEnum.DesktopL),
                isFullHD: isMinWidth(DevicesEnum.FullHD),
            }),
        []
    );

    useEffect(handleWindowResize, [handleWindowResize]);
    useWindowResize(handleWindowResize);

    return currentDevice;
};
