import { CurrencyExchangeInformationInterface } from '@component/modal/modals/currency-exchange/currency-exchange-form/currency-exchange-information/currency-exchange-information.interface';

export const currencyExchangeInformationInitials: CurrencyExchangeInformationInterface = {
    buyAmount: 0,
    buyCurrency: '',
    sellAmount: 0,
    sellCurrency: '',
    fee: '',
    total: '',
    rate: 0,
    settlementTime: new Date(),
};
