import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { CustomCountrySelect } from '@shared-atom/elpaso-kit/custom-country-select/custom-country-select';
import { CustomSelect } from '@shared-atom/elpaso-kit/custom-select/custom-select';
import { CustomInputAmount } from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount';
import { CustomInputCard } from '@shared-atom/elpaso-kit/input/custom-input-card/custom-input-card';
import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { Label } from '@shared-atom/elpaso-kit/input/input.styles';
import { AccountSelectOption } from '@shared-atom/elpaso-kit/select-account/select-account-option/select-account-option';
import { Translate } from '@shared-atom/translate/translate';
import { PaymentFiles } from '@shared-component/payment-files/payment-files';
import { Scroll } from '@shared-component/scroll/scroll';
import { FormHalfRow, FormRow } from '@shared-form/form-common/form-common.styles';
import { Currency, Fee } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { InformationCopyRow } from '@component/modal/modals/common/information-copy-row/information-copy-row';
import { BlockTitle } from '@component/modal/modals/payment/payment-confirmation/payment-confirmation.style';
import { PaymentToCardDetailsFormEnum } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-details-form/payment-to-card-details-form.enum';
import { useFeeAndTotalHook } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-details-form/payment-to-card-details-form.hook';
import {
    RecipientComponent,
    SelectedAccountComponent,
} from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form-steps/payment-to-card-details-form/payment-to-card-details-form.styles';
import { ukraineRegions } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form.options';
import { PAYMENT_DOCUMENT_SIZE } from '@component/modal/modals/payment/payment.modal-options';
import { FormComponentWrapper, FormWrapper } from '@component/modal/modals/payment/payment.modal-style';

interface PaymentToCardDetailsFormProps {
    fee: Fee;
    balance: number;
    currency: Currency;
    isNeedToAskRegion: boolean;
}

export const PaymentToCardDetailsForm = observer(
    ({ fee, balance, currency, isNeedToAskRegion }: PaymentToCardDetailsFormProps) => {
        const amountPlaceholder = useLocalizationText(LocalizationEnum.CommonEnterAmount);
        const receiverNamePlaceholder = useLocalizationText(LocalizationEnum.PaymentFormBeneficiaryStepNamePlaceholder);
        const receiverLastNamePlaceholder = useLocalizationText(
            LocalizationEnum.PaymentFormBeneficiaryStepLastNamePlaceholder
        );
        const purposePlaceholder = useLocalizationText(LocalizationEnum.CommonPaymentForCoffee);
        const selectRegionPlaceholder = useLocalizationText(LocalizationEnum.CommonSelectRegion);
        const nationalityLabel = useLocalizationText(LocalizationEnum.CommonNationality);
        const nationalityPlaceholder = useLocalizationText(LocalizationEnum.CommonSelectNationality);

        const { totalAmount, paymentFee } = useFeeAndTotalHook(fee);
        const [, { error }] = useField(PaymentToCardDetailsFormEnum.PaymentCurrency);

        const DynamicFormRow = isNeedToAskRegion ? FormHalfRow : FormRow;

        return (
            <FormWrapper>
                <Scroll>
                    <FormComponentWrapper>
                        <Label hasError={Boolean(error)}>
                            <Translate langKey={LocalizationEnum.AccountYouPayFrom} />
                        </Label>
                        <SelectedAccountComponent>
                            <AccountSelectOption currency={currency} balance={balance} isMobile />
                        </SelectedAccountComponent>
                    </FormComponentWrapper>
                    <FormComponentWrapper>
                        <CustomInputAmount
                            title={LocalizationEnum.CommonAmount}
                            name={PaymentToCardDetailsFormEnum.Amount}
                            placeholder={amountPlaceholder}
                            isMobile
                        />
                    </FormComponentWrapper>
                    <FormComponentWrapper>
                        <RecipientComponent>
                            <FormComponentWrapper lowDimensions>
                                <BlockTitle>
                                    <Translate langKey={LocalizationEnum.CommonRecipient} />
                                </BlockTitle>
                                <CustomInputCard
                                    name={PaymentToCardDetailsFormEnum.CardNumber}
                                    label={LocalizationEnum.CommonCardNumber}
                                    placeholder="XXXX-XXXX-XXXX-XXXX"
                                    isMobile
                                />
                            </FormComponentWrapper>
                            <FormComponentWrapper lowDimensions>
                                <DynamicFormRow>
                                    <CustomCountrySelect
                                        label={nationalityLabel}
                                        placeholder={nationalityPlaceholder}
                                        name={PaymentToCardDetailsFormEnum.Nationality}
                                        isMobile
                                    />
                                    {isNeedToAskRegion && (
                                        <CustomSelect
                                            name={PaymentToCardDetailsFormEnum.Region}
                                            placeholder={selectRegionPlaceholder}
                                            label={LocalizationEnum.PleaseProvideRegionForUkraineRecipient}
                                            options={ukraineRegions}
                                            isSearchable={false}
                                            rowHeight={56}
                                            isMobile
                                        />
                                    )}
                                </DynamicFormRow>
                            </FormComponentWrapper>
                            <FormComponentWrapper lowDimensions>
                                <FormHalfRow>
                                    <CustomInput
                                        placeholder={receiverNamePlaceholder}
                                        label={LocalizationEnum.PaymentFormBeneficiaryStepNameBlockTitle}
                                        name={PaymentToCardDetailsFormEnum.ReceiverFirstName}
                                        isMobile
                                    />
                                    <CustomInput
                                        placeholder={receiverLastNamePlaceholder}
                                        label={LocalizationEnum.PaymentFormBeneficiaryStepLastName}
                                        name={PaymentToCardDetailsFormEnum.ReceiverSurname}
                                        isMobile
                                    />
                                </FormHalfRow>
                            </FormComponentWrapper>
                            <FormComponentWrapper lowDimensions>
                                <PaymentFiles
                                    name={PaymentToCardDetailsFormEnum.AttachedFiles}
                                    limit={PAYMENT_DOCUMENT_SIZE}
                                />
                            </FormComponentWrapper>
                        </RecipientComponent>
                    </FormComponentWrapper>
                    <FormComponentWrapper>
                        <CustomInput
                            label={LocalizationEnum.CommonPaymentPurpose}
                            name={PaymentToCardDetailsFormEnum.Reason}
                            placeholder={purposePlaceholder}
                            isMobile
                        />
                    </FormComponentWrapper>
                    <FormComponentWrapper>
                        <InformationCopyRow
                            title={LocalizationEnum.CommonPaymentFee}
                            data={paymentFee}
                            isCopied={false}
                        />
                        <InformationCopyRow
                            title={LocalizationEnum.PaymentFormCurrencyAmountStepPayIn}
                            data={totalAmount}
                            isCopied={false}
                        />
                    </FormComponentWrapper>
                </Scroll>
            </FormWrapper>
        );
    }
);
