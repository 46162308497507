import { Field, FieldProps, useField } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, Dispatch, FocusEvent, SetStateAction } from 'react';

import { FieldError } from '@shared-component/field-error/field-error';
import { otpMaskRegExp } from '@shared-component/input/input-otp/input-otp.options';
import { InputCodeWrapper, InputOtpComponent, OtpInputStyle } from '@shared-component/input/input-otp/input-otp.styles';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';
import { noop } from '@shared-util/noop';

interface InputOtpProps {
    name: string;
    onChange?: Dispatch<SetStateAction<string>>;
}

export const InputOtp = observer(({ name, onChange = noop }: InputOtpProps) => {
    const [, { error }] = useField(name);

    const hasError = Boolean(error) !== false;

    const handleChange = (field: FieldInputProps<string>) => (event: ChangeEvent<HTMLInputElement>) => {
        field.onChange(event);
        onChange('');
    };

    const handleBlur = (field: FieldInputProps<string>) => (event: FocusEvent<HTMLInputElement>) => {
        field.onBlur(event);
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleKeyPress = () => (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.which === 13) {
            event.preventDefault();
        }
    };

    const autocompleteProps = { autocomplete: 'one-time-code' };

    const OtpInputField = ({ field }: FieldProps<string>) => (
        <OtpInputStyle
            {...field}
            {...autocompleteProps}
            type={InputTypeEnum.Text}
            mask={otpMaskRegExp}
            onChange={handleChange(field)}
            onBlur={handleBlur(field)}
            onKeyPress={handleKeyPress()}
            showMask={false}
            guide={false}
            placeholder="0  0  0  0  0  0"
            autoFocus
        />
    );

    return (
        <InputOtpComponent>
            <InputCodeWrapper otpError={hasError}>
                <Field name={name} render={OtpInputField} />
            </InputCodeWrapper>
            <FieldError name={name} />
        </InputOtpComponent>
    );
});
