import { PropsWithChildren } from 'react';

import { ItemLink, LinkDisable, SecondaryItemLink } from './link.styles';

export interface LinkAnchorProps<T> {
    href?: string;
    anchor?: T;
    isDisable?: boolean;
    isSecondary?: boolean;
    target?: string;
}

export const LinkAnchor = ({
    href,
    anchor,
    children,
    isDisable = false,
    isSecondary = false,
    target = '_blank',
    ...props
}: PropsWithChildren<LinkAnchorProps<any>>) => {
    const ItemLinkComponent = isSecondary ? SecondaryItemLink : ItemLink;
    return (
        <>
            {isDisable && (
                <LinkDisable isSecondary={isSecondary} {...props}>
                    {children}
                </LinkDisable>
            )}
            {!isDisable && (
                <ItemLinkComponent
                    as="a"
                    target={target}
                    href={`${href ?? ''}${anchor !== undefined ? `#${anchor}` : ''}`}
                    {...props}
                >
                    {children}
                </ItemLinkComponent>
            )}
        </>
    );
};
