import React, { useContext } from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import { ModelContext } from '@shared-component/modal/modal.context';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ChainBlocks } from '@component/chain-blocks/chain-blocks';
import {
    HomeServiceModalCustomerDescription,
    HomeServiceModalCustomerIcon,
    HomeServiceModalCustomerInfo,
    HomeServiceModalCustomerInfoContent,
    HomeServiceModalCustomerName,
    HomeSolutionsModalContent,
    HomeSolutionsModalTitle,
    HomeSolutionsModalWrapper,
} from '@component/modal/modals/home-solutions/home-service.styles';

export const HomeSolutionsModal = () => {
    const { props, onClose } = useContext(ModelContext);

    return (
        <HomeSolutionsModalWrapper>
            <HomeSolutionsModalTitle>
                <Translate langKey={props.componentProps?.solution.title} />
            </HomeSolutionsModalTitle>
            <HomeSolutionsModalContent>
                <HomeServiceModalCustomerInfo>
                    <HomeServiceModalCustomerIcon alt="user-avatar" src={props.componentProps?.solution.user.avatar} />
                    <HomeServiceModalCustomerInfoContent>
                        <HomeServiceModalCustomerName>
                            <Translate langKey={props.componentProps?.solution.user.name} />
                        </HomeServiceModalCustomerName>
                        {Boolean(props.componentProps?.solution.user.role) && (
                            <HomeServiceModalCustomerDescription>
                                <Translate langKey={props.componentProps?.solution.user.role} />
                            </HomeServiceModalCustomerDescription>
                        )}
                        {Boolean(props.componentProps?.solution.user.location) && (
                            <HomeServiceModalCustomerDescription>
                                <Translate langKey={props.componentProps?.solution.user.location} />
                            </HomeServiceModalCustomerDescription>
                        )}
                    </HomeServiceModalCustomerInfoContent>
                </HomeServiceModalCustomerInfo>
                <ChainBlocks chain={props.componentProps?.solution.chain} />
            </HomeSolutionsModalContent>
            <Button
                onClick={onClose}
                type={ButtonTypeEnum.BLACK_STROKE}
                size={ButtonSizeEnum.L}
                title={LocalizationEnum.CommonGoBack}
            />
        </HomeSolutionsModalWrapper>
    );
};
