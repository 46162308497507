import { reaction } from 'mobx';
import { Instance } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserCompanyRelationType } from '@shared-graphql';

import { CompanyModel } from '@model/company-model/company-model';

const relationPersonsLength = $enum(UserCompanyRelationType).size;

export const initializeCompanyPersonsReaction = (companyStore: Instance<typeof CompanyModel>) =>
    reaction(
        () => {
            const relationsSet = new Set();

            companyStore.company.persons.forEach(({ relation }) =>
                relation.companyRelation.forEach(currentRelation => relationsSet.add(currentRelation))
            );

            return relationsSet.size;
        },
        resultSizes => companyStore.company.companyStatus.setHasCompanyPersons(relationPersonsLength === resultSizes)
    );
