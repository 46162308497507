import { useMemo } from 'react';

import { AccountBank, Account } from '@shared-graphql';
import { DEFAULT_CURRENCY } from '@shared-util/constants';

import { useAccountSelector } from '@selector/account/use-account.selector';

interface AccountDetailsHookInterface {
    banks: AccountBank[];
    selectedAccount: Account;
}

export const useAccountDetails = (): AccountDetailsHookInterface => {
    const [{ ccAccounts, selectedAccount }] = useAccountSelector();

    const selectedCurrencyCode = useMemo(() => selectedAccount?.currency?.code ?? DEFAULT_CURRENCY, [selectedAccount]);

    const accountBanks = useMemo(
        () =>
            ccAccounts.find(({ currency: { code } }) => code === selectedCurrencyCode)?.banks ?? ({} as AccountBank[]),
        [ccAccounts, selectedCurrencyCode]
    ) as AccountBank[];

    return { banks: accountBanks, selectedAccount };
};
