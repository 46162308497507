import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { FormEvent, FC, SVGProps, RefObject, useState, ReactNode, JSX } from 'react';

import { OnEventType } from '../../types/on-event.type';
import { FieldError } from '../field-error/field-error';
import { InputTagEnum } from './input-tag.enum';
import { InputTypeEnum } from './input-type.enum';
import { CustomLabel, CustomInputStyle, CustomInputWrapperStyle, CustomInputWrapper } from './input.styles';

export interface InputProps extends Partial<Pick<HTMLInputElement, 'type' | 'tabIndex' | 'disabled'>> {
    id?: string;
    title: string;
    name: string;
    onFocus?: OnEventType<boolean>;
    icon?: FC<SVGProps<SVGSVGElement>>;
    inputRef?: RefObject<HTMLInputElement>;
    tag?: InputTagEnum;
    placeholder?: string;
    isError?: string | undefined;
    autofocus?: boolean | undefined;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const CustomInput: FC<InputProps> = observer(
    ({
        id = 'input',
        title,
        name,
        type = InputTypeEnum.Text,
        icon,
        tag,
        inputRef,
        onFocus,
        disabled,
        children,
        placeholder,
        isError,
        autofocus,
        ...props
    }) => {
        const [field, { error }, { setError, setValue }] = useField(name);
        const [isFocused, setFocused] = useState(Boolean(autofocus));
        const hasIcon = icon !== undefined;
        const hasError = Boolean(isError) === true ? isError : error !== undefined;

        const toggleFocus = (isFocus: boolean) => () => {
            setFocused(isFocus);
            if (onFocus instanceof Function) {
                onFocus(isFocus);
            }
        };
        const handleBlur = () => {
            toggleFocus(false)();
            if (type === InputTypeEnum.Text) {
                setValue(field.value?.trim() ?? null);
            }
        };

        const handleChange = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
            setValue(
                type === InputTypeEnum.Number && value !== ''
                    ? parseFloat(value)
                    : name.includes('email')
                    ? value.toLowerCase()
                    : value
            );
            if (Boolean(hasError) && tag !== InputTagEnum.TextArea) {
                setError(undefined);
            }
        };

        return (
            <CustomInputWrapper>
                <CustomLabel>{title}</CustomLabel>
                <CustomInputWrapperStyle hasError={Boolean(hasError)} isFocused={isFocused}>
                    <CustomInputStyle
                        {...props}
                        {...field}
                        ref={inputRef}
                        type={type}
                        value={field.value ?? ''}
                        disabled={disabled}
                        hasIcon={hasIcon}
                        placeholder={placeholder}
                        onFocus={toggleFocus(true)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id={id}
                        autoFocus={isFocused}
                    />
                    {children}
                </CustomInputWrapperStyle>
                <FieldError name={name} />
            </CustomInputWrapper>
        );
    }
);
