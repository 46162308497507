import styled from 'styled-components';

import Company from '../../assets/vector/suitcase.svg?react';
import Individual from '../../assets/vector/user-1.svg?react';
import { Primary500 } from '../../styles/colors';

export const IndividualIcon = styled(Individual)`
    fill: ${Primary500};
`;

export const CompanyIcon = styled(Company)`
    fill: ${Primary500};
`;
