import { types } from 'mobx-state-tree';

import { PaymentEntityType } from '../../../graphql';
import { BeneficiaryModelTypeSchema } from '../../beneficiary-model/beneficiary-model.type-schema';

export const AddressBookBeneficiaryType = types
    .model({
        ...BeneficiaryModelTypeSchema,
        bankCountry: types.maybeNull(types.string),
        acctNumber: types.string,
    })
    .views(self => ({
        get beneficiaryName() {
            const { beneficiaryEntityType, beneficiaryCompanyName, beneficiaryFirstName, beneficiaryLastName } = self;

            return beneficiaryEntityType === PaymentEntityType.company
                ? beneficiaryCompanyName
                : `${beneficiaryFirstName} ${beneficiaryLastName}`;
        },
    }));
