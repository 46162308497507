import styled, { css } from 'styled-components';

import CheckIcon from '../../assets/check.svg?react';
import { Typography } from '../../atoms/typography/typography';
import { Concrete, Neutral400, Neutral500, Primary500, White } from '../../styles/colors';
import { Flex } from '../../styles/flex';

interface CheckBoxProps {
    isChecked: boolean;
    isDisabled: boolean;
}

interface CheckTitleProps {
    isExist: boolean;
}

const checkboxStyles = css`
    cursor: pointer;

    :hover {
        opacity: 0.8;
    }
`;

export const CheckBoxStyle = styled.label<Pick<CheckBoxProps, 'isDisabled'>>`
    ${Flex.InlineFlex.Row.Style};
    min-height: 24px;
    user-select: none;

    ${({ isDisabled }) => !isDisabled && checkboxStyles}
`;

export const CheckBoxWrapper = styled.div`
    ${Flex.Column.Style};
    gap: 4px;
    width: 100%;
`;
export const CheckBoxContainer = styled.div`
    ${Flex.InlineFlex.Row.Style};
    width: 100%;
`;

const getBackgroundColor = (isChecked: boolean, isDisabled: boolean) => {
    if (isDisabled && !isChecked) {
        return Concrete;
    }

    return isChecked ? Primary500 : 'inherit';
};

export const CheckIconWrapper = styled.div<CheckBoxProps>`
    min-width: 16px;
    margin-top: 3px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    overflow: hidden;
    border: ${({ isChecked, isDisabled }) =>
        isDisabled ? 'none' : `2px solid ${isChecked ? Primary500 : Neutral400}`};
    background-color: ${({ isChecked, isDisabled }) => getBackgroundColor(isChecked, isDisabled)};
    ${Flex.VerticalCenter.HorizontalCenter.Style}

    opacity: ${({ isChecked, isDisabled }) => (isDisabled && isChecked ? '0.5' : null)};

    :hover {
        border: ${({ isChecked, isDisabled }) => (isChecked || isDisabled ? 'none' : `2px solid ${Neutral500}`)};
        opacity: ${({ isChecked, isDisabled }) => (isChecked && !isDisabled ? 0.8 : null)};
    }
`;

export const Check = styled(CheckIcon)`
    width: 14px;
    height: 14px;
    fill: ${White};
`;

export const CheckTitle = styled.span<CheckTitleProps>`
    margin-left: 8px;
    width: 100%;
    ${Typography.Paragraph12.Normal.Neutral700.Style};
    line-height: 16px;
`;
