import { ApolloProvider } from '@apollo/client';
import React, { memo } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { LocalizationStoreProvider } from '@shared-store/store-localization.provider';

import { MainApplication } from '@application/main-application';
import { apolloClient } from '@connection/apollo-client';
import { StoreProvider } from '@store/store-context.provider';

export const Application = memo(() => (
    <ApolloProvider client={apolloClient}>
        <LocalizationStoreProvider>
            <StoreProvider>
                <GoogleReCaptchaProvider
                    reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
                    container={{
                        parameters: {
                            badge: 'bottomleft',
                        },
                    }}
                >
                    <MainApplication />
                </GoogleReCaptchaProvider>
            </StoreProvider>
        </LocalizationStoreProvider>
    </ApolloProvider>
));
