import { PageTitle } from '@shared-atom/page-title/page-title';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { AuthPageContentWrapper, AuthPageFormTitle } from '@page/auth-pages/common.styles';
import { ResetPasswordForm } from '@page/auth-pages/reset-password/reset-password.form';
import { ResetPasswordFormWrapper } from '@page/auth-pages/reset-password/reset-password.styles';
import { SignWrapper } from '@page/sign-in/SignWrapper';

const ResetPasswordPage = () => (
    <SignWrapper>
        <PageTitle title={LocalizationEnum.RecoveryYourPassword} />
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={LocalizationEnum.RecoveryYourPassword} />
            </AuthPageFormTitle>
            <ResetPasswordFormWrapper>
                <ResetPasswordForm />
            </ResetPasswordFormWrapper>
        </AuthPageContentWrapper>
    </SignWrapper>
);

export default ResetPasswordPage;
