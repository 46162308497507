import { useMutation } from '@apollo/client';
import { useContext, useEffect, useMemo, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal.context';
import { ModalHeightEnum } from '@shared-component/modal/modal.enum';
import { EventEmitterEnum } from '@shared-enum/event-emitter.enum';
import { Conversion, CreateConversion, Mutation } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { emit } from '@shared-util/event-emitter';
import { isExist, isFalse } from '@shared-util/is-data';

import { CurrencyExchangeFormInterface } from './currency-exchange-form.interface';

export const useCurrencyExchangeForm = () => {
    const { setModalHeight } = useContext(ModelContext);
    const { onOpen } = useContext(InformationModalContext);
    const [transactionInfo, setTransactionInfo] = useState({ amountBuy: '0', amountSell: '0' });

    const [createConversion, { data: conversionResult, loading: isConversionResultLoading, error: conversionError }] =
        useMutation<Pick<Mutation, 'createConversion'>>(CreateConversion);

    const onConfirmationSubmit = (conversation: CurrencyExchangeFormInterface) => {
        createConversion({ variables: { conversionReservationId: conversation.conversionReservationId } }).then(() =>
            emit(EventEmitterEnum.TransactionsUpdate)
        );
    };
    const isFormSubmitted = useMemo(
        () => isExist(conversionResult) || isExist(conversionError),
        [conversionResult, conversionError]
    );
    const successMessage = useLocalizationText(LocalizationEnum.SuccessCurrencyExchangeMessage);
    const successMessageNormalized = successMessage
        .replace('{from}', transactionInfo.amountSell)
        .replace('{to}', transactionInfo.amountBuy);
    const amountBuy = useCurrencyFormat(
        (conversionResult?.createConversion as Conversion)?.buyAmount ?? 0,
        (conversionResult?.createConversion as Conversion)?.buyCurrency?.code
    );
    const amountSell = useCurrencyFormat(
        (conversionResult?.createConversion as Conversion)?.amount ?? 0,
        (conversionResult?.createConversion as Conversion)?.sellCurrency?.code
    );

    useEffect(() => {
        if (isFormSubmitted) {
            setModalHeight(ModalHeightEnum.Half);
        }
    }, [isFormSubmitted]);

    useEffect(() => {
        setTransactionInfo({ amountBuy, amountSell });
    }, [amountBuy, amountSell]);

    useEffect(() => {
        if (conversionError) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: conversionError?.message ?? LocalizationEnum.FailureCurrencyExchangeMessage,
                timeout: 5000,
            });
        }
    }, [conversionError]);

    useEffect(() => {
        if (isExist(conversionResult?.createConversion)) {
            if (isFalse((conversionResult?.createConversion as Conversion)?.updatedAt)) {
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: LocalizationEnum.FailureCurrencyExchangeMessage,
                    timeout: 5000,
                });
            }
        }
    }, [conversionResult]);

    return {
        isFormSubmitted,
        isConversionResultLoading,
        conversionError,
        onSubmit: onConfirmationSubmit,
        successMessageNormalized,
    };
};
