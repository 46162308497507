import { Instance } from 'mobx-state-tree';

import { ElcoinPaymentStatus, OperationType, PaymentStatus, TransactionType } from '@shared-graphql';
import { TransactionModelType } from '@shared-model/transaction-model/transaction-model.type';

export const initialTransactionModel: Instance<typeof TransactionModelType> = {
    amount: 0,
    balance: null,
    beneficiary: null,
    buyAmount: null,
    buyBalance: null,
    buyCurrency: null,
    client: null,
    createdAt: null,
    currency: null,
    details: null,
    elcoinStatus: ElcoinPaymentStatus.NEW,
    exchangeType: null,
    fee: null,
    id: '',
    operation: OperationType.Credit,
    paymentType: null,
    rate: null,
    reason: null,
    sellAmount: null,
    sellBalance: null,
    sellCurrency: null,
    sender: null,
    status: PaymentStatus.Created,
    total: 0,
    type: TransactionType.Payment,
    updatedAt: null,
};

export const initialTransactionsModel: { elements: Instance<typeof TransactionModelType>[]; total: number } = {
    elements: [],
    total: 0,
};
