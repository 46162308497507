import { useRef, useState } from 'react';
import { v4 } from 'uuid';

import deFlag from '@shared-asset/country-flags/de.png';
import esFlag from '@shared-asset/country-flags/es.png';
import frFlag from '@shared-asset/country-flags/fr.png';
import gbFlag from '@shared-asset/country-flags/gb.png';
import { Translate } from '@shared-atom/translate/translate';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { useOutsideClick } from '@shared-hook/utils/use-outside-click';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { LangKeysEnum } from '@shared-store/store-localization.interface';
import { noop } from '@shared-util/noop';

import { languageOptions } from '@page/main-pages/main-page-common/header/components/language-select/language-select.options';
import {
    FlagIcon,
    LanguageInlineSelectWrapper,
    LanguageInlineSelectOption,
    LanguageSelectArrow,
    LanguageSelectOption,
    LanguageSelectOptionsList,
    LanguageSelectWrapper,
    SelectedLanguage,
    FlagIconWrapper,
    LanguageSelectArrowWrapper,
} from '@page/main-pages/main-page-common/header/components/language-select/language-select.styles';

export interface LanguageSelectProps {
    isBusinessPage?: boolean;
    isMobile?: boolean;
    customClick?: any;
}

const languageMapping = {
    [LangKeysEnum.EN]: {
        icon: gbFlag,
        langKey: LocalizationEnum.CommonEnglish,
    },
    [LangKeysEnum.FR]: {
        icon: frFlag,
        langKey: LocalizationEnum.CommonFrench,
    },
    [LangKeysEnum.DE]: {
        icon: deFlag,
        langKey: LocalizationEnum.CommonGerman,
    },
    [LangKeysEnum.ES]: {
        icon: esFlag,
        langKey: LocalizationEnum.CommonSpanish,
    },
};

export const LanguageSelect = ({ isBusinessPage, isMobile = false, customClick = noop }: LanguageSelectProps) => {
    const { lang, setLanguage } = useLocalizationStore();

    const [selectedLanguage, setOptionValue] = useState(lang);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const languageSelectRef = useRef<HTMLDivElement | null>(null);

    const handleLanguageChange = (selectedLanguageOption: any) => {
        setLanguage(selectedLanguageOption.value);
        setOptionValue(selectedLanguageOption.value);
        setMenuOpen(false);
        customClick();
        window.location.reload();
    };
    useOutsideClick(languageSelectRef, () => setMenuOpen(false));

    return (
        <>
            {isMobile && (
                <LanguageInlineSelectWrapper>
                    {languageOptions.map(languageOption => (
                        <LanguageInlineSelectOption
                            key={v4()}
                            onClick={() => handleLanguageChange(languageOption)}
                            selected={languageOption.value === selectedLanguage}
                        >
                            <Translate langKey={languageMapping[languageOption.value].langKey} />
                        </LanguageInlineSelectOption>
                    ))}
                </LanguageInlineSelectWrapper>
            )}
            {!isMobile && (
                <LanguageSelectWrapper
                    onClick={() => setMenuOpen(!isMenuOpen)}
                    isOpen={isMenuOpen}
                    isDarkMode={isBusinessPage}
                    ref={languageSelectRef}
                >
                    <SelectedLanguage>{selectedLanguage}</SelectedLanguage>
                    <LanguageSelectArrowWrapper isOpen={isMenuOpen} isDarkMode={isBusinessPage}>
                        <LanguageSelectArrow />
                    </LanguageSelectArrowWrapper>
                    <LanguageSelectOptionsList isOpen={isMenuOpen}>
                        {languageOptions.map(languageOption => (
                            <LanguageSelectOption
                                key={v4()}
                                onClick={() => handleLanguageChange(languageOption)}
                                selected={languageOption.value === selectedLanguage}
                            >
                                <FlagIconWrapper>
                                    <FlagIcon src={languageMapping[languageOption.value].icon} />
                                </FlagIconWrapper>
                                <Translate langKey={languageMapping[languageOption.value].langKey} />
                            </LanguageSelectOption>
                        ))}
                    </LanguageSelectOptionsList>
                </LanguageSelectWrapper>
            )}
        </>
    );
};
