import styled from 'styled-components';

import { Flex } from '@shared-style/flex';

import { PaymentConfirmationWrapper } from '@component/modal/modals/payment/payment-confirmation/payment-confirmation.style';

export const PaymentAgreementFormWrapper = styled(PaymentConfirmationWrapper)`
    margin-top: auto;
    padding-top: 18px;
    padding-bottom: 18px;
    ${Flex.Column.JustifyRight.Style}

    form {
        justify-content: flex-end;
    }
`;
