import { Dark } from './colors';

export const resetInputStyle =
    ':-webkit-autofill{' +
    'background-color:transparent !important;' +
    'border:none;' +
    '-webkit-box-shadow:none;' +
    'transition:background-color 5000s ease-in-out 0s;' +
    `color:${Dark} !important;` +
    `-webkit-text-fill-color:${Dark} !important;}` +
    ':focus{outline:0;}' +
    '::-webkit-outer-spin-button,::-webkit-inner-spin-button{' +
    '-webkit-appearance:none;margin:0;}';
