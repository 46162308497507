import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

export const SessionContent = styled.div`
    ${Flex.Row.JustifyLeft.VerticalCenter.Style};
    gap: 16px;
`;
export const SessionPreview = styled.img`
    width: 24px;
    height: 24px;
`;
export const SessionInfo = styled.div`
    ${Flex.Column.Style};
    gap: 4px;
`;
export const SessionInfoRow = styled.div`
    ${Flex.Row.Style};
`;
export const MainInfo = styled.span`
    ${Typography.Paragraph14.Neutral900.SemiBold.Style};
`;
export const AdditionalInfo = styled.span`
    ${Typography.Paragraph12.Neutral500.SemiBold.Style};
`;
export const ActivationInfo = styled.span`
    ${Typography.Paragraph12.Primary500.SemiBold.Style};
`;
