import { types } from 'mobx-state-tree';

import { initialCompanyModel } from '@shared-model/company-model/company-model.initial';

import { CompanyModelTypeInstance } from '@model/company-model/company-model.type';

import { companyModelActions } from './company-model-actions/company-model.actions';

export const CompanyModelInstance = types.model('CompanyModel', {
    company: CompanyModelTypeInstance,
    loading: types.model('CompanyLoading', {
        isCompanyLoading: types.boolean,
        isCompanyAddressLoading: types.boolean,
        isCompanyIndustriesLoading: types.boolean,
        isCompanyPersonsLoading: types.boolean,
        isCompanyDocumentsLoading: types.boolean,
        isCompanyServiceLoading: types.boolean,
    }),
    error: types.optional(types.string, ''),
});

export const CompanyModel = CompanyModelInstance.actions(companyModelActions);

export const getInitialCompanyModel = () =>
    CompanyModel.create({
        company: initialCompanyModel as any,
        loading: {
            isCompanyLoading: false,
            isCompanyAddressLoading: false,
            isCompanyIndustriesLoading: false,
            isCompanyPersonsLoading: false,
            isCompanyDocumentsLoading: false,
            isCompanyServiceLoading: false,
        },
    });
