import styled from 'styled-components';

import { LabelWrapper } from '@shared-atom/elpaso-kit/custom-select/custom-select.styles';
import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

export const PaymentFlowSelectorContainer = styled.div`
    padding: 16px 24px;
    height: 100%;
    ${Flex.Column.Style};
    form {
        height: auto;
    }
    ${LabelWrapper} {
        ${Typography.Paragraph14.Medium.Neutral700.Style};
    }
`;
