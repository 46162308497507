import styled from 'styled-components';

import { ElpasoButton } from '@shared-atom/elpaso-kit/button/style';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import { ModalContentWrapper } from '@component/modal/client-modal.styles';

export const UserProfileContentWrapper = styled(ModalContentWrapper)`
    padding: 32px 24px 24px;
    ${Flex.Column.VerticalCenter.Style};

    ${ElpasoButton} {
        margin-top: 8px;
    }

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 16px 20px;
        ${Flex.Column.VerticalCenter.SpaceBetween.Style};
    }
`;
