import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { EventEmitterEnum } from '@shared-enum/event-emitter.enum';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { useOn } from '@shared-util/event-emitter';

import { ModalHeader, ModalTitle } from '@component/modal/client-modal.styles';
import {
    RequestStatementContentWrapper,
    RequestStatementModalWrapper,
} from '@component/modal/modals/request-statement/request-statement.styles';
import { StatementForm } from '@component/modal/modals/request-statement/statement-form/statement-form';
import { useAccountActionsSelector } from '@selector/account/use-account.actions-selector';

export const RequestStatementModal = observer(() => {
    const { loadClientAccounts } = useAccountActionsSelector();

    useEffect(() => {
        loadClientAccounts();
    }, []);

    useOn(EventEmitterEnum.AccountsUpdate, loadClientAccounts);

    return (
        <RequestStatementModalWrapper>
            <ModalHeader>
                <ModalTitle>
                    <Translate langKey={LocalizationEnum.DashboardTransactionsButtonTitle} />
                </ModalTitle>
            </ModalHeader>
            <RequestStatementContentWrapper>
                <StatementForm />
            </RequestStatementContentWrapper>
        </RequestStatementModalWrapper>
    );
});
