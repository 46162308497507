import { useStore } from '@store/store-context.hook';

export const useCompanyActionsSelector = () => {
    const { loadCompany, loadPersons } = useStore().company.company;

    return {
        loadCompany,
        loadPersons,
    };
};
