import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useMemo, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal.context';
import {
    AvailableCurrency,
    ClientSendMessage,
    ClientSendMessageMutationVariables,
    GetAvailableCurrencies,
    Mutation,
    Query,
} from '@shared-graphql';
import { DEFAULT_CURRENCY } from '@shared-util/constants';

const useAvailableCurrencies = () => {
    const [getAvailableCurrencies, { data }] =
        useLazyQuery<Pick<Query, 'getAvailableCurrencies'>>(GetAvailableCurrencies);
    useEffect(() => {
        getAvailableCurrencies();
    }, []);
    return data?.getAvailableCurrencies.currencies;
};

export const useAcquiringRequestFormHook = () => {
    const { onClose } = useContext(ModelContext);
    const [sendMessage, { loading: isLoading }] = useMutation<Pick<Mutation, 'sendMessage'>>(ClientSendMessage);
    const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([DEFAULT_CURRENCY]);
    const currencies = useAvailableCurrencies();
    const { onOpen } = useContext(InformationModalContext);

    useEffect(() => {
        if (currencies !== undefined) {
            setAvailableCurrencies((currencies as AvailableCurrency[])?.map(({ code }) => code));
        }
    }, [currencies]);

    const onSubmit = (values: ClientSendMessageMutationVariables) => {
        sendMessage({ variables: { ...values } }).finally(() => {
            onClose();
            onOpen(InformationModalEnum.SuccessInformationModal);
        });
    };

    const availableCurrenciesToOptions = useMemo(
        () =>
            availableCurrencies.map(currency => ({
                value: currency,
                label: currency,
            })),
        [availableCurrencies]
    );

    return {
        onSubmit,
        isLoading,
        availableCurrencies: availableCurrenciesToOptions,
    };
};
