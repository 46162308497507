import styled from 'styled-components';

import { Whisper } from '../../styles/colors';
import { Flex } from '../../styles/flex';

export const LoaderAnimationWrapper = styled.div`
    max-width: 292px;
    max-height: 80px;
`;

export const PageLoaderWrapper = styled.div`
    background-color: ${Whisper};
    min-height: 100%;
    width: 100%;
    flex: 1;
    ${Flex.HorizontalCenter.VerticalCenter.Style}
`;
