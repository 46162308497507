import { User, Maybe } from '@shared-graphql';

export enum UserKeysEnum {
    Login = 'login',
    Password = 'password',
    Phone = 'phone',
    TelegramLink = 'telegramLink',
    CompanyRole = 'companyRole',
    // CompanyPermissions = 'companyPermissions',
    AcceptAgreement = 'isAcceptAgreement',
}

export interface UserInterface extends User {
    [UserKeysEnum.Password]: string;
    [UserKeysEnum.AcceptAgreement]: Maybe<boolean>;
}
