import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { PaymentEntityType } from '@shared-graphql';

const beneficiaryEntityTypeValues = $enum(PaymentEntityType).getValues();

export const BeneficiaryModelType = types.model('BeneficiaryModel', {
    acctNumber: types.maybe(types.maybeNull(types.string)),
    bankAddress: types.maybe(types.maybeNull(types.string)),
    bankCode: types.maybe(types.maybeNull(types.string)),
    bankCountry: types.maybe(types.maybeNull(types.string)),
    bankName: types.maybe(types.maybeNull(types.string)),
    beneficiaryAddress: types.maybe(types.maybeNull(types.string)),
    beneficiaryCity: types.maybe(types.maybeNull(types.string)),
    beneficiaryCompanyName: types.maybe(types.maybeNull(types.string)),
    beneficiaryCountry: types.maybe(types.maybeNull(types.string)),
    beneficiaryEntityType: types.enumeration(beneficiaryEntityTypeValues),
    beneficiaryFirstName: types.maybe(types.maybeNull(types.string)),
    beneficiaryLastName: types.maybe(types.maybeNull(types.string)),
    beneficiaryPostcode: types.maybe(types.maybeNull(types.string)),
    beneficiaryStateOrProvince: types.maybe(types.maybeNull(types.string)),
    bicSwift: types.maybe(types.maybeNull(types.string)),
    branchCode: types.maybe(types.maybeNull(types.string)),
    charityNumber: types.maybe(types.maybeNull(types.string)),
    clabe: types.maybe(types.maybeNull(types.string)),
    cnaps: types.maybe(types.maybeNull(types.string)),
    iban: types.maybe(types.maybeNull(types.string)),
    ifsc: types.maybe(types.maybeNull(types.string)),
    invoiceDate: types.maybe(types.maybeNull(types.string)),
    invoiceNumber: types.maybe(types.maybeNull(types.string)),
    nationality: types.maybe(types.maybeNull(types.string)),
    sortCode: types.maybe(types.maybeNull(types.string)),
});
