import { v4 } from 'uuid';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import {
    ChainBlocksWrapper,
    ChainBlock,
    ChainBlockIcon,
    ChainBlockText,
    ChainBlocksRow,
} from '@component/chain-blocks/chain-blocks.styles';

export interface ChainBlockInterface {
    icon: string;
    description: LocalizationEnum;
}
export interface ChainBlocksInterface {
    chain: ChainBlockInterface[];
}

export const ChainBlocks = ({ chain }: ChainBlocksInterface) => (
    <ChainBlocksWrapper>
        <ChainBlocksRow>
            {[...chain].slice(0, 2).map(({ icon, description }) => (
                <ChainBlock key={v4()}>
                    <ChainBlockIcon alt="emoji" src={icon} />
                    <ChainBlockText>
                        <Translate langKey={description} />
                    </ChainBlockText>
                </ChainBlock>
            ))}
        </ChainBlocksRow>
        <ChainBlocksRow>
            {[...chain].slice(2, 4).map(({ icon, description }) => (
                <ChainBlock key={v4()}>
                    <ChainBlockIcon alt="emoji" src={icon} />
                    <ChainBlockText>
                        <Translate langKey={description} />
                    </ChainBlockText>
                </ChainBlock>
            ))}
        </ChainBlocksRow>
    </ChainBlocksWrapper>
);
