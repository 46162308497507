import styled from 'styled-components';

export const DatePickerCaptionWrapper = styled.div`
    z-index: 1;
    display: grid;
    grid-template-columns: auto auto;
    gap: 0 8px;
`;

export const MonthYearSelectWrapper = styled.div``;
