import styled from 'styled-components';

import { ElpasoButton } from '@shared-atom/elpaso-kit/button/style';
import {
    EmptyUpload,
    LoaderWrapper,
    SelectedFile,
    SelectedFileWrapper,
    UploadStatus,
} from '@shared-component/file-upload/document-file-upload/document-file-upload.styles';
import { DocumentCommentWrapper } from '@shared-form/private-document-form/document-comment/document-comment.styles';
import { Neutral200, Primary500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import {
    InformationRowDataWrapper,
    InformationRowWrapper,
} from '@component/modal/modals/common/information-copy-row/information-copy-row.styles';

interface ButtonsWrapperProps {
    isDisable?: boolean;
    isBackBtn?: boolean;
}

interface FormComponentWrapperProps {
    lowDimensions?: boolean;
}

export const ButtonsWrapper = styled.div<ButtonsWrapperProps>`
    ${({ isDisable = false }) => (isDisable ? 'display: none' : Flex.Row.SpaceBetween.Style)};
    margin-top: auto;
    border-top: 1px solid ${Neutral200};
    padding: 16px 24px;
    height: 84px;
    z-index: 1;
    ${ElpasoButton} {
        min-width: 120px;

        :last-child,
        :nth-child(2) {
            margin-left: auto;
        }
        :nth-child(2):not(&:last-child) {
            margin-right: 12px;
        }

        svg {
            fill: ${Primary500};
            width: 20px;
        }
    }
`;
export const PaymentFormStepsWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const PaymentErrorWrapper = styled.div`
    position: absolute;
    bottom: 125px;
    width: calc(100% - 48px);
    left: 24px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: calc(100% - 40px);
        left: 20px;
    }
`;

export const FormWrapper = styled.div`
    height: calc(100% - 40px);
    width: 100%;
`;
export const FormComponentWrapper = styled.div<FormComponentWrapperProps>`
    margin-top: 16px;
    padding: ${({ lowDimensions = false }) => (lowDimensions ? '0 16px' : '0 24px')};

    ${InformationRowDataWrapper} {
        text-align: end;
    }
    ${InformationRowWrapper} {
        :first-child {
            margin-top: 20px;
        }
    }
    ${UploadStatus} {
        padding: 0;
        ${EmptyUpload} {
            display: none;
        }
        ${SelectedFile} {
            :before {
                width: 12px;
                left: 0px;
                top: 6px;
            }
        }
        ${LoaderWrapper} {
            padding: 8px;
        }
        ${SelectedFileWrapper} {
            padding: 10px 16px 10px 24px;
        }
    }
    ${DocumentCommentWrapper} {
        display: none;
    }
`;

export const PaymentFormWrapper = styled(FormWrapper)`
    height: 100%;
    width: 100%;
    form {
        ${Flex.Column.Style};
    }
`;
export const PaymentLoading = styled.div`
    height: 80px;
    width: 292px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
