import React from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import { Animation } from '@shared-component/animation/animation';
import Loader from '@shared-component/animation/animation-loading/assets/green-loader.animation.json';
import { Timer } from '@shared-component/timer/timer';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OTHER_METHODS_BUTTON_VISIBILITY_DELAY } from '@shared-util/constants';
import { noop } from '@shared-util/noop';

import {
    AuthPageButtonWithTimerWrapper,
    AuthPageButtonWrapper,
    AuthPageContentDescriptionText,
    AuthPageContentDescriptionWrapper,
    AuthPageContentWrapper,
    AuthPageFormTitle,
    AuthPageFormWrapper,
} from '@page/auth-pages/common.styles';
import { useLoginMethodSelector } from '@page/auth-pages/login/login-steps/login-method-selector/login-method-selector.hook';
import { usePushNotificationLoginStep } from '@page/auth-pages/login/login-steps/push-notification-login-step/push-notification-login-step.hook';
import { PushNotificationLoginStepInterface } from '@page/auth-pages/login/login-steps/push-notification-login-step/push-notification-login-step.interface';
import { LoaderWrapper } from '@page/auth-pages/login/login-steps/push-notification-login-step/push-notification-login-step.style';

export const PushNotificationLoginStep = ({
    onSubmit,
    setLoginSelectorVisible,
    shouldShowOtherMethodsWithoutTimer = false,
}: PushNotificationLoginStepInterface) => {
    const { isOtherLoginMethodsAvailable } = useLoginMethodSelector();

    usePushNotificationLoginStep({ onSubmit });

    return (
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={LocalizationEnum.TwoFactorAuthentication} />
            </AuthPageFormTitle>
            <AuthPageContentDescriptionWrapper>
                <AuthPageContentDescriptionText>
                    <Translate langKey={LocalizationEnum.WeHaveSentAPushNotificationToYourSendsApplication} />
                </AuthPageContentDescriptionText>
            </AuthPageContentDescriptionWrapper>
            <AuthPageFormWrapper>
                <LoaderWrapper>
                    <Animation animationData={Loader} isLoop />
                </LoaderWrapper>
                {isOtherLoginMethodsAvailable || shouldShowOtherMethodsWithoutTimer ? (
                    <AuthPageButtonWrapper>
                        <Button
                            onClick={() => setLoginSelectorVisible(true)}
                            type={ButtonTypeEnum.GHOST}
                            size={ButtonSizeEnum.L}
                            title={LocalizationEnum.CommonOtherMethods}
                        />
                    </AuthPageButtonWrapper>
                ) : (
                    <AuthPageButtonWithTimerWrapper>
                        <Translate langKey={LocalizationEnum.OtherMethodsAreAvailableVia} />{' '}
                        <Timer time={OTHER_METHODS_BUTTON_VISIBILITY_DELAY} onComplete={noop} />
                    </AuthPageButtonWithTimerWrapper>
                )}
            </AuthPageFormWrapper>
        </AuthPageContentWrapper>
    );
};
