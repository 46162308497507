import { FC, SVGProps } from 'react';
import MaskedInput from 'react-text-mask';
import styled, { css } from 'styled-components';

import { getAnimationStyle } from '../../styles/animation';
import {
    Concrete,
    Dark,
    DarkGrey,
    Mystic,
    NileBlue,
    PacificBlue,
    Raven,
    Red,
    White,
    WhiteDark,
} from '../../styles/colors';
import { resetInputStyle } from '../../styles/reset-style';
import { ThemeTypeInterface } from '../../types/theme-props.type';
import { FieldErrorWrapper } from '../field-error/field-error.styles';

export interface LabelProps {
    hasValue: boolean;
    isFocused: boolean;
    hasError: boolean;
}
export interface CustomInputWrapperProps {
    hasError: boolean;
    isFocused: boolean;
}
export interface IconWrapperProps extends ThemeTypeInterface {
    hasValue: boolean;
}

interface WrapperProps extends ThemeTypeInterface {
    isFocused: boolean;
    hasError: boolean;
    isDisabled?: boolean;
}

interface InputStyleProps {
    hasIcon?: boolean;
}

export const Label = styled.span<LabelProps>`
    font-size: 16px;
    left: 16px;
    color: ${Raven};
    background-color: inherit;
    pointer-events: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform: translateY(100%);
    ${getAnimationStyle(['transform', 'font-size', 'color'])}

    ${({ hasValue, isFocused }) => (hasValue || isFocused) && 'transform: translateY(8px); font-size: 11px;'}
    
    
    ${({ isFocused }) => isFocused && `color: ${PacificBlue}; `}
    
    ${({ hasError }) => hasError === true && `color: ${Red}; `}
`;

export const getInputStyles = () => css`
    border: none;
    flex: 1;
    font-size: 16px;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    padding: 34px 16px 8px;
    background-color: transparent;
    width: 100%;
`;

export const InputStyle = styled.input<InputStyleProps>`
    ${getInputStyles}
    ${resetInputStyle}
    ${({ hasIcon = false }) =>
        hasIcon &&
        css`
            padding: 0 0 0 56px;
        `}
`;
export const CustomInputWrapperStyle = styled.div<CustomInputWrapperProps>`
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 16px 12px;
    display: flex;
    position: relative;
    border: 1px solid #e8e8e8;
    :hover {
        background: #f1fffc;
    }

    ${({ isFocused }) => isFocused === true && `border: 1px solid #00997A`}
    ${({ hasError }) => hasError === true && `border: 1px solid red`}
`;
export const CustomInputStyle = styled.input<InputStyleProps>`
    ${resetInputStyle};
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #090909;
    border: none;
    height: 100%;
    width: 100%;
    background-color: transparent !important;
    outline: none;
`;

const getLabelWrapperBoxShadowColor = ({ hasError, isFocused, theme: { isDarkTheme } }: WrapperProps) => {
    if (hasError === true) {
        return Red;
    }
    if (isFocused) {
        return PacificBlue;
    }
    return isDarkTheme ? WhiteDark : Mystic;
};

export const LabelWrapper = styled.label<WrapperProps>`
    display: inline-flex;
    position: relative;
    width: 100%;
    background-color: inherit;
    border-radius: 8px;
    box-shadow: 0 0 0 1px ${getLabelWrapperBoxShadowColor};
    height: 56px;
    ${getAnimationStyle(['box-shadow'])}

    ${({ isDisabled = false }) => isDisabled && `background-color: ${Concrete}`}
`;
export const CustomLabel = styled.label`
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #1f1f1f;
`;

export const IconWrapper = (icon: FC<SVGProps<SVGSVGElement>>) => styled(icon)<IconWrapperProps>`
    position: absolute;
    width: 24px;
    height: 24px;
    left: 16px;
    top: 16px;

    fill: ${({ hasValue }: IconWrapperProps) => (hasValue ? NileBlue : DarkGrey)};
`;

export const MaskedInputStyle = styled(MaskedInput)`
    width: inherit;
    border: none;
    font-size: 40px;
    line-height: 48px;
    color: ${Dark};

    ${resetInputStyle}
`;

export const ErrorWrapper = styled(FieldErrorWrapper)``;

export const InputWrapper = styled.div``;
export const CustomInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 4px;
`;
