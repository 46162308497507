import styled from 'styled-components';

import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import { ModalContentWrapper, ModalWrapper } from '@component/modal/client-modal.styles';

export const AccountDetailsModalWrapper = styled(ModalWrapper)`
    height: auto;
    max-height: 100%;
    overflow-y: scroll;

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        height: 100%;
    }
`;
export const AccountDetailsModalContentWrapper = styled(ModalContentWrapper)`
    padding: 16px 24px;
    ${Flex.Column.Style};
    height: auto;

    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 24px 20px;
    }
`;
export const PaymentTypeTilesWrapper = styled.div`
    ${Flex.Row.Style};
    gap: 8px;
`;
