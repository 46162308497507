import styled from 'styled-components';

import { Primary500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

interface StepsWrapperProps {
    ibanPaymentFlow?: boolean;
}

export const StepsWrapper = styled.div<StepsWrapperProps>`
    height: ${({ ibanPaymentFlow = false }) => (ibanPaymentFlow ? '100%' : 'calc(100% - 40px)')};
    ${Flex.Column.Style};
`;
export const TotalAmount = styled.span`
    color: ${Primary500};
`;
