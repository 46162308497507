import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { AddCurrencyButton } from './currency-add-button/currency-add-button';
import { useAddCurrency } from './currency-add.hook';
import { AddCurrencySubTitle, AddCurrencyTitle, FormWrapper } from './currency-add.styles';
import { CurrencyCheckBox } from './currency-checkbox/currency-checkbox';

export const AddCurrency = observer(() => {
    const { currencies, activeCurrencies, onSubmit } = useAddCurrency();

    return (
        <>
            <AddCurrencyTitle>
                <Translate langKey={LocalizationEnum.AddCurrencyModalTitle} />
            </AddCurrencyTitle>
            <AddCurrencySubTitle>
                <Translate langKey={LocalizationEnum.AddCurrencyModalDescription} />
            </AddCurrencySubTitle>
            <FormWrapper>
                <Formik initialValues={activeCurrencies} onSubmit={onSubmit}>
                    <Form>
                        {currencies.map(CurrencyCheckBox)}
                        <AddCurrencyButton activeCurrencies={activeCurrencies} />
                    </Form>
                </Formik>
            </FormWrapper>
        </>
    );
});
