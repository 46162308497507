import * as Sentry from '@sentry/react';
import { flow, Instance } from 'mobx-state-tree';

import { Mutation, UserExit } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { UserModelInstance } from '@model/user-model/user-model';

import { userModelMainActions } from './user-model-main.actions';

interface UserModelExitActionsInterface
    extends Instance<typeof UserModelInstance>,
        ReturnType<typeof userModelMainActions> {}

const exitUser = () =>
    apolloClient.mutate<Pick<Mutation, 'userExit'>>({ mutation: UserExit }).then(result => result.data?.userExit);

export const userModelExitActions = (self: UserModelExitActionsInterface) => ({
    logoutUser: flow(function* logoutUser() {
        self.setUserLoading(true);

        try {
            const { status } = yield exitUser();

            if (status === true) {
                self.clearUserData();
            }
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        } finally {
            self.setUserLoading(false);
        }
    }),
});
