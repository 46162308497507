import styled from 'styled-components';

import ArrowLeft from '../../assets/vector/arrow-left.svg?react';
import Close from '../../assets/vector/close.svg?react';
import { ButtonTransparentStyle } from '../../atoms/button/button.style';
import { Neutral900 } from '../../styles/colors';
import { Flex } from '../../styles/flex';
import { MAX_MOBILE_SIZE } from '../../styles/media-sizes';

export interface HeaderButtonProps {
    isVisible?: boolean;
}

export const CloseIcon = styled(Close)`
    fill: ${Neutral900};
    width: 12px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 14px;
    }
`;
export const BackIcon = styled(ArrowLeft)`
    fill: ${Neutral900};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 20px;
    }
`;

export const ModalHeaderButton = styled(ButtonTransparentStyle)`
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    z-index: 1;
    ${Flex.VerticalCenter.HorizontalCenter.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 56px;
        height: 56px;
    }
`;

export const ButtonCloseWrapper = styled(ModalHeaderButton)`
    right: 0;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
    }
`;
export const ButtonBackWrapper = styled(ModalHeaderButton)<HeaderButtonProps>`
    left: 0;
    display: none;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${({ isVisible = false }) => (isVisible ? Flex.VerticalCenter.HorizontalCenter.Style : 'display: none')};
    }
`;
