import React from 'react';

import { BaseOptionInterface } from '@shared-atom/elpaso-kit/custom-select/custom-select.props';

import {
    CustomSelectRow,
    CustomSelectValue,
} from '@component/modal/modals/payment/payment-form/payment-form-steps/amount-currency-form/amount-currency-form.styles';

export const customSelectRowRender = <T extends BaseOptionInterface>(item: T) => (
    <CustomSelectRow>{item.label}</CustomSelectRow>
);
export const customSelectValueRender = (props: any) => {
    const item = props.getValue()[0] ?? {};

    return <CustomSelectValue>{item.label}</CustomSelectValue>;
};
