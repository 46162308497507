export enum PaymentToCardDetailsFormEnum {
    AccountId = 'accountId',
    Amount = 'amount',
    AttachedFiles = 'attachedFiles',
    CardNumber = 'cardNumber',
    PaymentCurrency = 'currency',
    Reason = 'reason',
    ReceiverFirstName = 'receiverName',
    ReceiverSurname = 'receiverSurname',
    Region = 'region',
    Nationality = 'nationality',
    Fee = 'fee',
}
