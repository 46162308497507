import { Field, FieldProps } from 'formik';
import { FC } from 'react';

import { Translate } from '@shared-atom//translate/translate';
import { cardMaskRegExp } from '@shared-atom/elpaso-kit/input/custom-input-card/input-card.helper';
import { useInputCard } from '@shared-atom/elpaso-kit/input/custom-input-card/input-card.hook';
import { InputCardProps } from '@shared-atom/elpaso-kit/input/custom-input-card/input-card.interface';
import { CardMaskedStyle } from '@shared-atom/elpaso-kit/input/custom-input-card/input-card.styles';
import { ErrorWrapper, InputWrapper, Label, LabelWrapper } from '@shared-atom/elpaso-kit/input/input.styles';
import { FieldError } from '@shared-component/field-error/field-error';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';
import { isString } from '@shared-util/is-data';

export const CustomInputCard: FC<InputCardProps> = ({ name, label, placeholder = '', isMobile = false }) => {
    const [handleChange, isFocused, handleFocus, hasError] = useInputCard(name);
    const shouldRenderLabel = isString(label);

    return (
        <InputWrapper>
            {shouldRenderLabel && (
                <Label hasError={hasError}>
                    <Translate langKey={label} />
                </Label>
            )}
            <LabelWrapper hasError={hasError} isFocused={isFocused} isMobile={isMobile}>
                <Field
                    name={name}
                    render={({ field }: FieldProps<string>) => (
                        <CardMaskedStyle
                            {...field}
                            type={InputTypeEnum.Text}
                            mask={cardMaskRegExp}
                            guide={false}
                            showMask={false}
                            onChange={handleChange}
                            placeholder={placeholder}
                            onFocus={handleFocus(true)}
                            onBlur={handleFocus(false)}
                            isMobile={isMobile}
                        />
                    )}
                />
            </LabelWrapper>
            <ErrorWrapper>
                <FieldError name={name} />
            </ErrorWrapper>
        </InputWrapper>
    );
};
