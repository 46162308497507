import * as Sentry from '@sentry/react';
import { flow, Instance } from 'mobx-state-tree';

import { GetCurrentUser, GetCurrentUserDocuments, GetStatus, Query, User } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { UserModelInstance } from '@model/user-model/user-model';
import { initialUserModel } from '@model/user-model/user-model-initials/user-model.initial';

import { userModelMainActions } from './user-model-main.actions';

interface UserModelLoadActionsInterface
    extends Instance<typeof UserModelInstance>,
        ReturnType<typeof userModelMainActions> {}

const getCurrentUser = () =>
    apolloClient
        .query<Pick<Query, 'getCurrentUser'>>({ query: GetCurrentUser })
        .then(result => result.data?.getCurrentUser as User);

const getUserDocuments = () =>
    apolloClient
        .query<Pick<Query, 'getCurrentUser'>>({ query: GetCurrentUserDocuments })
        .then(result => (result.data?.getCurrentUser as User).documents);

const getStatus = () =>
    apolloClient
        .query<Pick<Query, 'getStatus'>>({ query: GetStatus })
        .then(result => result.data?.getStatus.activation);

export const userModelLoadActions = (self: UserModelLoadActionsInterface) => ({
    loadCurrentUser: flow(function* loadCurrentUser() {
        self.setUserLoading(true);
        try {
            const user = yield getCurrentUser();
            const normalizedUser = user ?? initialUserModel;

            self.setUserData(normalizedUser);

            (self as unknown as ReturnType<typeof userModelLoadActions>).getUserActivationStatus();
            self.setUserLoading(false);
            return normalizedUser;
        } catch (error: any) {
            Sentry.captureException(error);
            self.setUserLoading(false);
            throw new Error(error);
        }
    }),
    loadUserDocuments: flow(function* loadUserDocuments() {
        try {
            const documents = yield getUserDocuments();

            (self as any).setUserDocuments(documents);
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        }
    }),
    getUserActivationStatus: flow(function* getUserActivationStatus() {
        try {
            const isUserActivated = yield getStatus();

            if (isUserActivated !== undefined) {
                self.setUserActivationStatus(isUserActivated);
            }
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        }
    }),
});
