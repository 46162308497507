import { useMemo } from 'react';

import { UserType } from '@shared-graphql';
import { prefillFreshdeskWidget } from '@shared-util/freshdesk-widget';

import {
    CABINET_ACQUIRING_NAV_LINK_OPTION,
    COMMISSION_PROFILE_NAV_LINK_OPTION,
    DASHBOARD_NAV_LINK_OPTION,
    NOTIFICATION_NAV_LINK_OPTION,
    PageMenuListInterface,
    PERMITTED_COUNTRIES_NAV_LINK_OPTION,
    SETTINGS_NAV_LINK_OPTION,
} from '@page/workspace/workspace.options';
import { useUserSelector } from '@selector/user/use-user.selector';

export const useNavigationListOptions = () => {
    const [{ telegramLink, type, email, profile }] = useUserSelector();
    prefillFreshdeskWidget({ profile, email });

    const pageMenuList: PageMenuListInterface[] = useMemo(() => [DASHBOARD_NAV_LINK_OPTION], []);
    const commissionList: PageMenuListInterface[] = useMemo(
        () => [
            COMMISSION_PROFILE_NAV_LINK_OPTION,
            ...(type === UserType.Legal ? [CABINET_ACQUIRING_NAV_LINK_OPTION] : []),
            PERMITTED_COUNTRIES_NAV_LINK_OPTION,
        ],
        [type]
    );
    const additionalMenuList: PageMenuListInterface[] = useMemo(
        () => [
            {
                ...NOTIFICATION_NAV_LINK_OPTION,
                temporaryLink: telegramLink,
            },
        ],
        [telegramLink]
    );
    const optionsMenuList: PageMenuListInterface[] = useMemo(() => [SETTINGS_NAV_LINK_OPTION], []);

    return {
        pageMenuList,
        commissionList,
        additionalMenuList,
        optionsMenuList,
    };
};
