import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Fiord, Geyser, NileBlue, White } from '@shared-style/colors';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const AddCurrencyTitle = styled.h2`
    ${Typography.XXL.Center.Style}
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : NileBlue)};
    @media (max-width: 560px) {
        font-size: 34px;
    }
`;

export const AddCurrencySubTitle = styled.p`
    margin-top: 16px;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? Geyser : Fiord)};
    ${Typography.M.Style}
`;

export const FormWrapper = styled.div`
    margin-top: 32px;
`;
