import styled from 'styled-components';

import { ElpasoButton } from '@shared-atom/elpaso-kit/button/style';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import { SessionsList } from '@page/workspace/settings/section/security/subsections/sessions/styles';

export const SessionsListScrolled = styled(SessionsList)`
    overflow-y: scroll;
    width: 100%;
    flex: 1;
`;
export const ButtonWrapper = styled.div`
    padding-top: 28px;

    text-align: right;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${ElpasoButton} {
            width: 100%;
        }
    }
`;
export const ModalContent = styled.div`
    padding: 32px 24px;
    height: 100%;
    ${Flex.Column.Style};
`;
