import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const ForgotText = styled.p`
    ${Typography.S.Medium.SantasGray.Style}
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1f1f1f;
    margin-top: 24px !important;
    text-align: center;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;
