import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

import {
    InformationRowDataWrapper,
    InformationRowTitle,
} from '@component/modal/modals/common/information-copy-row/information-copy-row.styles';

export const BlockWrapper = styled.div`
    margin-top: 28px;
    ${InformationRowDataWrapper} {
        text-align: right;
        padding: 4px;
    }
    ${InformationRowTitle} {
        padding: 4px 0;
    }
`;

export const BlockTitle = styled.h3`
    margin-bottom: 12px;
    ${Typography.Paragraph16.SemiBold.Neutral900.Style}
`;

export const PaymentConfirmationWrapper = styled.div`
    width: 100%;
    padding: 0 24px;
    height: 100%;
`;
