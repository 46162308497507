import React from 'react';

import { Currency } from '../../../../graphql';
import { useCurrencyFormat } from '../../../../hooks/number-format/currency-format.hook';
import { CurrencyIcon } from '../../../currency-icon/currency-icon';
import { AccountOptionWrapper, AccountCurrencyLogo, AccountBalance } from './select-account-option.styles';

export const AccountSelectOption = ({
    currency,
    balance,
    isMobile = false,
}: {
    currency: Currency;
    balance: number;
    isMobile?: boolean;
}) => {
    const selectedAccountBalance = balance != null ? balance : 0;
    const normalizedBalance = useCurrencyFormat(selectedAccountBalance, currency?.code, true);

    return (
        <AccountOptionWrapper>
            <AccountCurrencyLogo>
                <CurrencyIcon code={currency.code} />
            </AccountCurrencyLogo>
            <AccountBalance isMobile={isMobile}>{normalizedBalance}</AccountBalance>
        </AccountOptionWrapper>
    );
};
