import { memo } from 'react';

import { LoginSteps } from '@page/auth-pages/login/login-steps/login-steps';
import { SignWrapper } from '@page/sign-in/SignWrapper';

const LoginPage = memo(() => (
    <SignWrapper>
        <LoginSteps />
    </SignWrapper>
));

export default LoginPage;
