import { createContext } from 'react';

import { ModalHeightEnum } from '@shared-component/modal/modal.enum';
import { ModelContextInterface } from '@shared-component/modal/modal.interface';
import { noop } from '@shared-util/noop';

export const ModelContext = createContext<ModelContextInterface>({
    selectedModal: null,
    props: {},
    removeModalProps: noop,
    onClose: noop,
    onOpen: noop,
    setModalHeight: noop,
    modalHeight: ModalHeightEnum.Full,
    onBack: noop,
    setOnBack: noop,
});
