import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PageEnum } from '@enum/page.enum';

import cl from './styles/footer.module.css';
import classes from './styles/signWrapper.module.css';

export const Footer = () => (
    <div className={cl.footerMainWrapper}>
        <div className={classes.container}>
            <div className={cl.footerWrapper}>
                <div className={cl.leftSection}>
                    <p>
                        © <Translate langKey={LocalizationEnum.CommonSends} /> {new Date().getFullYear()}
                    </p>
                </div>
                <div className={cl.rightSection}>
                    <ul className={cl.footerList}>
                        <li>
                            <a href={PageEnum.PrivacyPolicy}>
                                <Translate langKey={LocalizationEnum.PrivacyPolicyItemsTitle} />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
);
