import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import {
    UserActivatedStatus,
    UserCompanyRelationType,
    UserType,
    WebPreferredLoginMethod,
    /* UserRolesType, */
} from '@shared-graphql';
import { AddressType } from '@shared-model/address-type/address.type';
import { UserDeclarationType } from '@shared-model/user-model/user-model-private/user-declaration.type';
import { UserDocumentsType } from '@shared-model/user-model/user-model-private/user-documents.type';
import { UserServiceType } from '@shared-model/user-model/user-model-private/user-service.type';

import { CompanyModelTypeInstance } from '@model/company-model/company-model.type';
import { initialUserProfileModel } from '@model/user-model/user-model-initials/user-profile-model.initial';
import {
    UserLegalStatusModelInstance,
    UserPermissionsInstance,
} from '@model/user-model/user-model-legal/user-legal-status-model';
import { UserPrivateStatusModelInstance } from '@model/user-model/user-model-private/user-private-status-model';

import { UserProfileTypeInstance } from './user-model-private/user-profile.type';

const companyRelationValues = $enum(UserCompanyRelationType).getValues();
// const companyRoleValues = $enum(UserRolesType).getValues();
const userActivatedStatusValues = $enum(UserActivatedStatus).getValues();
const webPreferredLoginMethodValues = $enum(WebPreferredLoginMethod).getValues();

export const UserModelType = types
    .model('User', {
        id: types.identifier,
        email: types.string,
        password: types.string,
        otp: types.string,
        onboardingStep: types.string,
        phone: types.string,
        telegramLink: types.string,
        type: types.maybeNull(types.enumeration([UserType.Private, UserType.Legal])),
        status: types.enumeration(userActivatedStatusValues),
        privateStatus: UserPrivateStatusModelInstance,
        legalStatus: UserLegalStatusModelInstance,
        profile: UserProfileTypeInstance,
        address: types.maybeNull(AddressType),
        service: types.maybeNull(UserServiceType),
        isAcceptAgreement: types.maybeNull(types.boolean),
        declaration: types.maybeNull(UserDeclarationType),
        documents: types.array(UserDocumentsType),
        companyRelation: types.maybeNull(types.enumeration(companyRelationValues)),
        companyRole: types.string, // types.maybeNull(types.enumeration(companyRoleValues)),
        companyPermissions: UserPermissionsInstance, // types.array(types.string),
        companies: types.map(CompanyModelTypeInstance),
        firstName: types.string,
        lastName: types.string,
        companyName: types.string,
        nationality: types.string,
        residenceCountry: types.string,
        legalCountry: types.string,
        postalCountry: types.string,
        webPreferredLoginMethod: types.enumeration(webPreferredLoginMethodValues),
        webAvailableLoginMethods: types.array(types.maybeNull(types.enumeration([...webPreferredLoginMethodValues]))),
        isUserActivated: types.boolean,
    })
    .views(self => ({
        get fullName() {
            if (self.profile.name === initialUserProfileModel.name) {
                return null;
            }

            return `${self.profile.name} ${self.profile.lastName}`.trim();
        },
        get isLegal() {
            return self.type === UserType.Legal;
        },
        get activeCompanyName() {
            return self.companies.entries();
        },
    }));
