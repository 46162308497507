import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Black, Neutral50, Neutral900, Primary500 } from '@shared-style/colors';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const Item = styled.li`
    &:not(:first-child) {
        &:not(:last-child) {
            margin-left: ${({ theme }: ThemeTypeInterface) => (theme.size.isMaxMobile ? 30 : 0)}px;
        }
        margin-top: ${({ theme }: ThemeTypeInterface) => (theme.size.isMaxMobile ? 0 : 16)}px;
    }
    a {
        text-decoration: none !important;
    }
`;

export const Text = styled.p`
    color: ${Black};

    ${({ theme }: ThemeTypeInterface) =>
        theme.size.isMaxMobile ? Typography.S.Normal.Style : Typography.XL.Normal.Style}

    &:hover {
        text-decoration: underline;
    }
`;

export const TextHeaderItem = styled.p`
    color: ${({ isDark }: { isDark: boolean }) => (isDark ? Neutral900 : Neutral50)};
    ${Typography.Paragraph14.Medium.Center.Style};
    white-space: nowrap;
    transition: all 0.2s ease-out;

    &:hover {
        color: ${Primary500};
    }
`;
