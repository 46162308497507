import { createContext } from 'react';

import { noop } from '@shared-util/noop';

import { initialProps } from '@application/application.initials';

export const ApplicationContext = createContext({
    props: initialProps,
    setProps: noop,
});
