import { combine } from 'flexible-chain';
import styleToCss from 'style-object-to-css-string';
import { CSSObject } from 'styled-components';

const FlexDirections = {
    Row: {
        flexDirection: 'row',
    },
    Column: {
        flexDirection: 'column',
    },
    RowReverse: {
        flexDirection: 'row-reverse',
    },
    ColumnReverse: {
        flexDirection: 'column-reverse',
    },
};

const FlexWrap = {
    Wrap: {
        flexWrap: 'wrap',
    },
    WrapReverse: {
        flexWrap: 'wrap-reverse',
    },
};

const FlexAligns = {
    VerticalCenter: {
        alignItems: 'center',
    },
    VerticalBaseline: {
        alignItems: 'baseline',
    },
    VerticalStretch: {
        alignItems: 'stretch',
    },
    HorizontalCenter: {
        justifyContent: 'center',
    },
    SpaceBetween: {
        justifyContent: 'space-between',
    },
    JustifyLeft: {
        justifyContent: 'flex-start',
    },
    FlexStart: {
        alignItems: 'flex-start',
    },
    JustifyRight: {
        justifyContent: 'flex-end',
    },
    FlexEnd: {
        alignItems: 'flex-end',
    },
    SpaceAround: {
        justifyContent: 'space-around',
    },
};

const FlexDisplay = {
    InlineFlex: {
        display: 'inline-flex',
    },
};

export const FlexResult = {
    Style: (style: CSSObject): string => styleToCss({ display: 'flex', ...style }),
};

export const Flex = combine(
    {
        ...FlexDirections,
        ...FlexAligns,
        ...FlexDisplay,
        ...FlexWrap,
    },
    FlexResult
);
