import { ApolloError } from '@apollo/client/errors';
import { FC, ReactNode, useContext } from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { ModelContext } from '@shared-component/modal/modal.context';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import {
    FinallyActionContentWrapper,
    FinallyActionContent,
    FinallyActionFooter,
    FinallyActionMessage,
    FinallyActionIconWrapper,
    FinallyActionIcon,
    FailureActionIcon,
} from '@component/modal/modals/common/finally-action/finally-action.styles';

interface CurrencyExchangeSuccessErrorProps {
    error?: ApolloError | boolean;
    successMessage?: any;
    errorMessage?: string | ReactNode;
    successBtnText?: string;
    errorBtnText?: string;
}

export const FinallyAction: FC<CurrencyExchangeSuccessErrorProps> = ({
    error,
    successMessage,
    errorMessage,
    successBtnText,
    errorBtnText,
}) => {
    const { onClose } = useContext(ModelContext);
    const isErrorVisible = isTrue(error);

    return (
        <FinallyActionContentWrapper>
            <FinallyActionContent>
                <FinallyActionIconWrapper isErrorVisible={isErrorVisible}>
                    {isErrorVisible ? <FailureActionIcon /> : <FinallyActionIcon />}
                </FinallyActionIconWrapper>
                <FinallyActionMessage>{isErrorVisible ? errorMessage : successMessage}</FinallyActionMessage>
            </FinallyActionContent>
            <FinallyActionFooter>
                <Button
                    onClick={onClose}
                    type={ButtonTypeEnum.COLOR}
                    size={ButtonSizeEnum.L}
                    title={LocalizationEnum.CommonConfirm}
                    titleText={isErrorVisible ? errorBtnText : successBtnText}
                />
            </FinallyActionFooter>
        </FinallyActionContentWrapper>
    );
};
