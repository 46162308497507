import { values } from 'mobx';
import { Instance, types } from 'mobx-state-tree';

import { AddressBookType } from '@shared-model/address-book-model/address-book-model.type';

import { addressBookMainActions } from './addres-book-actions/addres-book-main.actions';

export const AddressBookModelType = types
    .model('AddressBook', {
        elements: types.map(AddressBookType),
        totalCount: types.number,
        selectedId: types.maybeNull(types.string),
        selectedAddressBook: AddressBookType,
    })
    .views(self => ({
        get addressBooks() {
            return values(self.elements) as unknown as Instance<typeof AddressBookType>[];
        },
        get selectedItem() {
            if (self.selectedId === null) {
                return self.selectedAddressBook;
            }
            return self.elements.get(self.selectedId) ?? self.selectedAddressBook;
        },
    }));

export const AddressBookModelTypeInstance = AddressBookModelType.actions(addressBookMainActions);
