import { LangKeysEnum } from '@shared-store/store-localization.interface';

export const DateFormatMap = {
    [LangKeysEnum.EN]: 'MM/dd/yyyy',
    [LangKeysEnum.ES]: 'dd/MM/yyyy',
    [LangKeysEnum.DE]: 'dd.MM.yyyy',
    [LangKeysEnum.FR]: 'dd/MM/yyyy',
};

export const DateFormatWithTime = {
    [LangKeysEnum.EN]: `${DateFormatMap[LangKeysEnum.EN]} - HH:mm`,
    [LangKeysEnum.ES]: `${DateFormatMap[LangKeysEnum.ES]} - HH:mm`,
    [LangKeysEnum.DE]: `${DateFormatMap[LangKeysEnum.DE]} - HH:mm`,
    [LangKeysEnum.FR]: `${DateFormatMap[LangKeysEnum.FR]} - HH:mm`,
};

export const DateFormatWithTimeSplitComma = {
    [LangKeysEnum.EN]: `${DateFormatMap[LangKeysEnum.EN]}, HH:mm`,
    [LangKeysEnum.ES]: `${DateFormatMap[LangKeysEnum.ES]}, HH:mm`,
    [LangKeysEnum.DE]: `${DateFormatMap[LangKeysEnum.DE]}, HH:mm`,
    [LangKeysEnum.FR]: `${DateFormatMap[LangKeysEnum.FR]}, HH:mm`,
};

const makeRegMask = (format: string) => format.split('').map(val => (/[a-z]/i.test(val) ? /\d/ : val));

export const DateLocaleMaskFormatMap = {
    [LangKeysEnum.EN]: makeRegMask(DateFormatMap[LangKeysEnum.EN]),
    [LangKeysEnum.ES]: makeRegMask(DateFormatMap[LangKeysEnum.ES]),
    [LangKeysEnum.DE]: makeRegMask(DateFormatMap[LangKeysEnum.DE]),
    [LangKeysEnum.FR]: makeRegMask(DateFormatMap[LangKeysEnum.FR]),
};
