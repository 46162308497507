import * as Sentry from '@sentry/react';
import { flow, getParent, Instance } from 'mobx-state-tree';

import { GetActiveCompany, Query } from '@shared-graphql';
import { initialCompanyModel } from '@shared-model/company-model/company-model.initial';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { apolloClient } from '@connection/apollo-client';
import { CompanyModel } from '@model/company-model/company-model';
import { CompanyModelType } from '@model/company-model/company-model.type';

import { companyApplyActions } from './company-apply.actions ';

interface CompanyMainActionsInterface
    extends Instance<typeof CompanyModelType>,
        ReturnType<typeof companyApplyActions> {}

const queryCompany = () =>
    apolloClient
        .query<Pick<Query, 'getActiveCompany'>>({ query: GetActiveCompany })
        .then(result => result.data.getActiveCompany)
        .catch((error: any) => {
            Sentry.captureException(error);
            throw new Error(error);
        });

export const companyMainActions = (self: CompanyMainActionsInterface) => ({
    applyCompanyMutation: flow(function* applyCompanyMutation<T>(mutation: OnEventEmptyType<Promise<T>>) {
        const companyModel = getParent(self) as Instance<typeof CompanyModel>;

        if (companyModel.loading.isCompanyLoading) {
            return;
        }

        companyModel.setCompanyLoading(true);

        try {
            const company = yield mutation();

            self.applyCompany(company ?? initialCompanyModel);
            return company;
        } catch (error: any) {
            Sentry.captureException(error);
            throw new Error(error);
        } finally {
            companyModel.setCompanyLoading(false);
        }
    }),

    loadCompany: () => (self as unknown as ReturnType<typeof companyMainActions>).applyCompanyMutation(queryCompany),
});
