import { useCallback, useMemo } from 'react';

import { LocalizationEnum } from '../../locale/localization.enum';
import { useLocalizationStore } from './use-localization-store.hook';

type LocalizationType = LocalizationEnum | string | number;
type ReplacementTType = { key: string; value: string };

export type LocalizationTextType = LocalizationType | LocalizationType[];

export const useLocalizationText = <T = string>(langKey: LocalizationTextType): T => {
    const { keys, isKeysEmpty } = useLocalizationStore();

    const getText = useCallback(
        (key: LocalizationType) => (isKeysEmpty ? '' : keys[key as LocalizationEnum] ?? key),
        [keys, isKeysEmpty]
    );

    const text = useMemo(
        () => (Array.isArray(langKey) ? langKey.map(getText) : getText(langKey)),
        [keys, langKey, isKeysEmpty]
    ) as unknown as T;

    return text;
};

export const useLocalizationTextWithReplacedValue = <T = string>(
    langKey: LocalizationTextType,
    replacements: ReplacementTType[]
): T => {
    const result = useLocalizationText(langKey);

    return replacements.reduce((acc, { key, value }) => acc.replace(key, value), result) as unknown as T;
};
