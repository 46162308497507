import { ApolloError, useMutation } from '@apollo/client';

import {
    Maybe,
    Mutation,
    UserPushNotificationLogin,
    UserPushNotificationLoginMutationVariables,
} from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';

type UserPushNotificationLoginMutationType = Pick<Mutation, 'userPushNotificationLogin'>;

export const usePushNotificationLogin = (): [
    Maybe<UserPushNotificationLoginMutationType> | undefined,
    ApolloError | undefined,
    boolean,
    OnEventType<UserPushNotificationLoginMutationVariables>
] => {
    const [userPushNotificationLogin, { data, loading: isLoading, error }] =
        useMutation<UserPushNotificationLoginMutationType>(UserPushNotificationLogin);

    const handlePushNotificationLogin = (variables: UserPushNotificationLoginMutationVariables) =>
        userPushNotificationLogin({ variables });

    return [data, error, isLoading, handlePushNotificationLogin];
};
