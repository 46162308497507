import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql/index';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { EventEmitterEnum } from '@shared-enum/event-emitter.enum';
import { ConfirmSignPayment, CreatePayment, DefaultAnswer, Mutation, Payment, StartSignPayment } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { emit } from '@shared-util/event-emitter';
import { getGraphQLErrorMessages } from '@shared-util/get-graphql-error-code';
import { isExist, isFalse, isTrue } from '@shared-util/is-data';

import { PaymentUpdateStepsEnum } from '@component/modal/modals/payment/payment-update-steps/payment-update-hook.enum';

export const usePaymentUpdate = (
    existPaymentId: string | null,
    setPaymentError: Dispatch<SetStateAction<boolean>>,
    isSignMode: boolean
) => {
    const { onOpen } = useContext(InformationModalContext);

    const [step, setStep] = useState(isSignMode ? PaymentUpdateStepsEnum.Otp : PaymentUpdateStepsEnum.ConfirmPayment);
    const [isPayNowActivated, setPayNowActivated] = useState(false);

    const [signPaymentError, setSignPaymentError] = useState('');
    const OtpCodeIncorrectError = useLocalizationText(LocalizationEnum.OtpCodeIncorrectError);

    const [createPayment, { data: createPaymentData, loading: isLoadingPayment, error: createPaymentError }] =
        useMutation<Pick<Mutation, 'createPayment'>>(CreatePayment, {
            onError: error => {
                if (isTrue(error)) {
                    setPaymentError(true);
                }
            },
            onCompleted: result => {
                if (result === null) {
                    setPaymentError(true);
                }
            },
        });
    const [
        startSignPayment,
        { data: startSignPaymentData, loading: isStartSignPaymentLoading, error: startSignPaymentError },
    ] = useMutation<Pick<Mutation, 'startSignPayment'>>(StartSignPayment, {
        onError: error => {
            if (isTrue(error)) {
                setPaymentError(true);
            }
        },
        onCompleted: result => {
            if (result === null || isFalse((result?.startSignPayment as DefaultAnswer)?.status)) {
                setPaymentError(true);
            }
        },
    });

    const [
        confirmSignPayment,
        { data: confirmSignPaymentData, loading: isConfirmSignPaymentLoading, error: confirmSignPaymentError },
    ] = useMutation<Pick<Mutation, 'confirmSignPayment'>>(ConfirmSignPayment, {
        onError: error => {
            if (isTrue(error)) {
                setSignPaymentError(OtpCodeIncorrectError);
            }
        },
        onCompleted: result => {
            if (result === null || isFalse(result?.confirmSignPayment?.status)) {
                setSignPaymentError(OtpCodeIncorrectError);
            }
        },
    });

    const paymentId = (createPaymentData?.createPayment as Payment)?.id ?? existPaymentId;
    const isPaymentSigned = (confirmSignPaymentData?.confirmSignPayment as DefaultAnswer)?.status ?? false;

    useEffect(() => {
        if (isExist(createPaymentData)) {
            if (isPayNowActivated) {
                startSignPayment({ variables: { paymentId } });
            } else {
                setStep(PaymentUpdateStepsEnum.PaymentCreated);
            }
        }
    }, [createPaymentData, paymentId]);

    useEffect(() => {
        if ((startSignPaymentData?.startSignPayment as DefaultAnswer)?.status) {
            setStep(PaymentUpdateStepsEnum.Otp);
        }
    }, [startSignPaymentData]);

    useEffect(() => void (isPaymentSigned && setStep(PaymentUpdateStepsEnum.PaymentSigned)), [isPaymentSigned]);

    const onPaymentSign = () => setPayNowActivated(true);
    const onSendOtpCode = () => startSignPayment({ variables: { paymentId } });

    const onOtpSubmit = (otp: string) => {
        if (!isExist(paymentId)) {
            return;
        }

        confirmSignPayment({
            variables: {
                paymentId,
                otp,
            },
        })
            .catch(() => setPaymentError(true))
            .finally(() => {
                emit(EventEmitterEnum.TransactionsUpdate);
                emit(EventEmitterEnum.AccountsUpdate);
            });
    };

    useEffect(() => {
        if (isSignMode && isTrue(paymentId)) {
            onSendOtpCode();
        }
    }, [isSignMode, paymentId]);

    useEffect(() => {
        if (isExist(confirmSignPaymentError)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    getGraphQLErrorMessages(confirmSignPaymentError?.graphQLErrors as GraphQLError[])[0] ??
                    confirmSignPaymentError?.message ??
                    LocalizationEnum.FailureCurrencyExchangeMessage,
                timeout: 5000,
            });
        }
        if (isExist(startSignPaymentError)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    getGraphQLErrorMessages(startSignPaymentError?.graphQLErrors as GraphQLError[])[0] ??
                    startSignPaymentError?.message ??
                    LocalizationEnum.FailureCurrencyExchangeMessage,
                timeout: 5000,
            });
        }
        if (isExist(createPaymentError)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    getGraphQLErrorMessages(createPaymentError?.graphQLErrors as GraphQLError[])[0] ??
                    createPaymentError?.message ??
                    LocalizationEnum.FailureCurrencyExchangeMessage,
                timeout: 5000,
            });
        }
    }, [confirmSignPaymentError, startSignPaymentError, createPaymentError]);

    return {
        paymentUpdateStep: step,
        signPaymentError,
        setSignPaymentError,
        createPayment,
        isLoadingPayment: isLoadingPayment || isStartSignPaymentLoading || isConfirmSignPaymentLoading,
        onOtpSubmit,
        onPaymentSign,
        onSendOtpCode,
    };
};
