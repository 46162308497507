import { Instance } from 'mobx-state-tree';

import { AddressBookInstance } from '@model/address-book-model/address-book-model';

export const addressBookModelActions = (self: Instance<typeof AddressBookInstance>) => ({
    setAddressBookLoading: (loading: boolean) => {
        self.loading.isAddressBookLoading = loading;
    },
    setAddressBookError: (error: string) => {
        self.error = error;
    },
});
