import { Form, Formik } from 'formik';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { ModalFormRowWrapper } from '@component/modal/client-modal.styles';
import { GoogleAuthActivateFormEnum } from '@component/modal/modals/google-auth-activate-modal/google-auth-activate-form/google-auth-activate-form.enum';
import { useGoogleAuthActivateForm } from '@component/modal/modals/google-auth-activate-modal/google-auth-activate-form/google-auth-activate-form.hook';
import { GoogleAuthActivateFormInitial } from '@component/modal/modals/google-auth-activate-modal/google-auth-activate-form/google-auth-activate-form.initials';
import { GoogleAuthActivateFormValidation } from '@component/modal/modals/google-auth-activate-modal/google-auth-activate-form/google-auth-activate-form.validation';
import { GoogleAuthActivateModalFooting } from '@component/modal/modals/google-auth-activate-modal/google-auth-activate-modal.styles';

export const GoogleAuthActivateForm = () => {
    const { isLoading, onSubmit } = useGoogleAuthActivateForm();

    return (
        <Formik
            initialValues={GoogleAuthActivateFormInitial}
            validationSchema={GoogleAuthActivateFormValidation}
            onSubmit={onSubmit}
            validateOnChange={false}
        >
            <Form>
                <ModalFormRowWrapper>
                    <CustomInput
                        label=""
                        name={GoogleAuthActivateFormEnum.Code}
                        placeholder={LocalizationEnum.EnterTheCode}
                        isMobile
                    />
                </ModalFormRowWrapper>
                <GoogleAuthActivateModalFooting>
                    <Button
                        title={LocalizationEnum.CommonSave}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                        isSubmit
                        isLoading={isLoading}
                    />
                </GoogleAuthActivateModalFooting>
            </Form>
        </Formik>
    );
};
