import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

import { useLocalizationText } from '../../../hooks/localization/use-localization-text.hook';
import { LocalizationEnum } from '../../../locale/localization.enum';
import { UserCredentialsStepFormEnum } from './user-credentials-step-form.enum';

const EMPTY_ERROR = ' ';

export const useUserCredentialsStepFormFields = (error: string | undefined) => {
    const [{ value: emailValue }, { error: emailError }] = useField(UserCredentialsStepFormEnum.Email);
    const [{ value: passwordValue }, { error: passwordError }] = useField(UserCredentialsStepFormEnum.Password);

    const { setErrors } = useFormikContext();

    const emailTitle = useLocalizationText(LocalizationEnum.CommonEmail);
    const passwordTitle = useLocalizationText(LocalizationEnum.CommonPassword);

    useEffect(
        () =>
            void (
                error !== undefined &&
                error !== '' &&
                setErrors({
                    [UserCredentialsStepFormEnum.Email]: EMPTY_ERROR,
                    [UserCredentialsStepFormEnum.Password]: error,
                })
            ),
        [error]
    );

    useEffect(() => {
        const hasEmailFieldError = emailError === EMPTY_ERROR;
        const hasPasswordFieldError = passwordError === error;

        if (hasEmailFieldError || hasPasswordFieldError) {
            setErrors({});
        }
    }, [emailValue, passwordValue]);

    return { emailTitle, passwordTitle };
};
