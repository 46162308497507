import { useEffect, useState } from 'react';

import { useWindowResize } from './use-window-resize.hook';

export const useBoundingSize = <T>(elementRef: T | null | undefined) => {
    const [size, setSize] = useState<DOMRect>();

    const setWrapperSize = () => void setSize((elementRef as unknown as HTMLElement)?.getBoundingClientRect());

    useEffect(setWrapperSize, [elementRef]);
    useWindowResize(setWrapperSize);

    return size;
};
