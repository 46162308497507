import { Instance } from 'mobx-state-tree';

import { CompanyModelTypeInstance } from '@model/company-model/company-model.type';
import { useStore } from '@store/store-context.hook';

interface LoadingInterface {
    isCompanyLoading: boolean;
    isCompanyAddressLoading: boolean;
    isCompanyIndustriesLoading: boolean;
    isCompanyPersonsLoading: boolean;
    isCompanyServiceLoading: boolean;
    isCompanyDocumentsLoading: boolean;
}

export const useCompanySelector = (): [Instance<typeof CompanyModelTypeInstance>, LoadingInterface, string] => {
    const { company, loading, error } = useStore().company;

    return [company, loading, error];
};
