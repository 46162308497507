import { Instance } from 'mobx-state-tree';

import { initialCompanyModel } from '@shared-model/company-model/company-model.initial';

import { CompanyModelInstance } from '@model/company-model/company-model';

export const companyModelActions = (self: Instance<typeof CompanyModelInstance>) => ({
    setCompanyLoading: (loading: boolean) => {
        self.loading.isCompanyLoading = loading;
    },
    setCompanyAddressLoading: (loading: boolean) => {
        self.loading.isCompanyAddressLoading = loading;
    },
    setCompanyIndustriesLoading: (loading: boolean) => {
        self.loading.isCompanyIndustriesLoading = loading;
    },
    setCompanyPersonsLoading: (loading: boolean) => {
        self.loading.isCompanyPersonsLoading = loading;
    },
    setCompanyDocumentsLoading: (loading: boolean) => {
        self.loading.isCompanyDocumentsLoading = loading;
    },
    setCompanyServiceLoading: (loading: boolean) => {
        self.loading.isCompanyServiceLoading = loading;
    },
    clearCompanyData: () => {
        self.company = initialCompanyModel as any;
    },
});
