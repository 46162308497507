import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useCallback } from 'react';

import editIcon from '@shared-asset/vector/edit-1.svg';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Link } from '@shared-atom/link/link';
import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { TooltipDataForEnum } from '@shared-atom/tooltip/tooltip-data-for.enum';
import { Translate } from '@shared-atom/translate/translate';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { clearFreshdeskContactForm } from '@shared-util/freshdesk-widget';
import { isTrue } from '@shared-util/is-data';

import { useUserBlockedNotify } from '@component/dashboard-notify/user-blocked-notify/user-blocked-notify';
import {
    UserProfileInformationItemData,
    UserProfileInformationItemEdit,
    UserProfileInformationItemTitle,
    UserProfileInformationItemWrapper,
    UserProfileInformationWrapper,
} from '@component/modal/modals/user-profile/user-short-profile-information/user-short-profile-information.styles';
import { PageEnum } from '@enum/page.enum';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';

export interface UserShortProfileInformationInterface {
    setChangePasswordOpen: Dispatch<SetStateAction<boolean>>;
    setChangeEmailOpen: Dispatch<SetStateAction<boolean>>;
}

export const UserShortProfileInformation = observer(
    ({ setChangePasswordOpen, setChangeEmailOpen }: UserShortProfileInformationInterface) => {
        const navigate = useRouterNavigate();
        const [{ email, phone }, { isUserLoading }] = useUserSelector();
        const { logoutUser } = useUserActionsSelector();
        const blockedNotify = useUserBlockedNotify();

        const onLogout = useCallback(() => {
            logoutUser().finally(() => {
                navigate(PageEnum.Login);
                clearFreshdeskContactForm();
            });
        }, [logoutUser]);

        return (
            <>
                <UserProfileInformationWrapper>
                    <UserProfileInformationItemWrapper>
                        <UserProfileInformationItemTitle>
                            <Translate langKey={LocalizationEnum.CommonPhone} />
                        </UserProfileInformationItemTitle>
                        <UserProfileInformationItemData>{phone ?? '-'}</UserProfileInformationItemData>
                    </UserProfileInformationItemWrapper>
                    <UserProfileInformationItemWrapper>
                        <UserProfileInformationItemTitle>
                            <Translate langKey={LocalizationEnum.CommonEmail} />
                        </UserProfileInformationItemTitle>
                        <UserProfileInformationItemData>
                            {email ?? '-'}
                            <Tooltip
                                dataFor={TooltipDataForEnum.ChangeEmail}
                                content={blockedNotify}
                                isShow={isTrue(blockedNotify)}
                                effect="solid"
                                clickable
                            >
                                <UserProfileInformationItemEdit
                                    src={editIcon}
                                    onClick={() => setChangeEmailOpen(true)}
                                    isDisabled={isTrue(blockedNotify)}
                                />
                            </Tooltip>
                        </UserProfileInformationItemData>
                    </UserProfileInformationItemWrapper>
                    <UserProfileInformationItemWrapper>
                        <UserProfileInformationItemTitle>
                            <Translate langKey={LocalizationEnum.CommonPassword} />
                        </UserProfileInformationItemTitle>
                        <UserProfileInformationItemData>
                            ***************
                            <Tooltip
                                dataFor={TooltipDataForEnum.ChangePassword}
                                content={blockedNotify}
                                isShow={isTrue(blockedNotify)}
                                effect="solid"
                                clickable
                            >
                                <UserProfileInformationItemEdit
                                    src={editIcon}
                                    onClick={() => setChangePasswordOpen(true)}
                                    isDisabled={isTrue(blockedNotify)}
                                />
                            </Tooltip>
                        </UserProfileInformationItemData>
                    </UserProfileInformationItemWrapper>
                    <UserProfileInformationItemWrapper>
                        <UserProfileInformationItemTitle>
                            <Translate langKey={LocalizationEnum.ForAllOtherIssues} />
                        </UserProfileInformationItemTitle>
                        <UserProfileInformationItemData>
                            <Link route={PageEnum.ContactUs}>
                                <Translate langKey={LocalizationEnum.UserShortProfileLinkContactUs} />
                            </Link>
                        </UserProfileInformationItemData>
                    </UserProfileInformationItemWrapper>
                </UserProfileInformationWrapper>
                <Button
                    title={LocalizationEnum.CommonLogOut}
                    size={ButtonSizeEnum.L}
                    isLoading={isUserLoading}
                    type={ButtonTypeEnum.DANGER_GHOST}
                    onClick={onLogout}
                />
            </>
        );
    }
);
