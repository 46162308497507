import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Input } from '@shared-component/input/input';
import { PaymentBeneficiaryRadioButtons } from '@shared-component/payment-beneficiary-radio-buttons/payment-beneficiary-radio-buttons';
import { Select } from '@shared-component/select/select';
import { FormHalfRow } from '@shared-form/form-common/form-common.styles';

import { CreatePartnerFormAcctNumber } from './create-partner-form-acct-number/create-partner-form-acct-number';
import { CreatePartnerFormAddress } from './create-partner-form-address/create-partner-form-address';
import { CreatePartnerFormAvatar } from './create-partner-form-avatar/create-partner-form-avatar';
import { CreatePartnerFormName } from './create-partner-form-name/create-partner-form-name';
import { CreatePartnerFormEnum } from './create-partner-form.enum';
import { useCreatePartnerForm } from './create-partner-form.hook';
import { useCreatePartnerFormLocalization } from './create-partner-form.localization-hook';
import { BeneficiaryTitle, ButtonSubmitWrapper } from './create-partner-form.styles';
import { CreatePartnerFormValidation } from './create-partner-form.validation';

export const CreatePartnerForm = observer(() => {
    const { beneficiaryTitle, currencyTitle, swiftTitle, submitTitle, bankNameTitle } =
        useCreatePartnerFormLocalization();
    const { initialValues, currenciesActiveSelect, handleSubmit } = useCreatePartnerForm();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={CreatePartnerFormValidation}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            <Form>
                <CreatePartnerFormAvatar />
                <BeneficiaryTitle>{beneficiaryTitle}</BeneficiaryTitle>
                <PaymentBeneficiaryRadioButtons name={CreatePartnerFormEnum.EntityType} />
                <CreatePartnerFormName />
                <FormHalfRow>
                    <CreatePartnerFormAcctNumber />
                    <Input name={CreatePartnerFormEnum.BicSwift} title={swiftTitle} />
                </FormHalfRow>
                <FormHalfRow>
                    <Select
                        title={currencyTitle}
                        name={CreatePartnerFormEnum.Currency}
                        options={currenciesActiveSelect}
                    />
                    <Input name={CreatePartnerFormEnum.BankName} title={bankNameTitle} />
                </FormHalfRow>
                <CreatePartnerFormAddress />
                <ButtonSubmitWrapper>
                    <ButtonPrimary type={ButtonTypeEnum.Submit}>{submitTitle}</ButtonPrimary>
                </ButtonSubmitWrapper>
            </Form>
        </Formik>
    );
});
