import styled from 'styled-components';

import { Flex } from '../../../../../../styles/flex';
import { ButtonTransparent } from '../../../../../button/button-transparent';
import { Typography } from '../../../../../typography/typography';

export const Arrow = styled.svg`
    margin-left: 4px;
    cursor: pointer;
`;

export const DateButtonWrapper = styled(ButtonTransparent)`
    margin-top: unset !important;
    max-width: unset !important;
    min-width: unset !important;
    padding-left: unset !important;
`;

export const DateTitleSelect = styled.p`
    ${Flex.HorizontalCenter.VerticalCenter.Style}
`;

export const DateTitle = styled.span`
    ${Typography.Raven.Style}
`;
export const MonthYearViewWrapper = styled.div``;
