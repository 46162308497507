import { rgba } from 'polished';
import styled from 'styled-components';

import { Neutral100, Neutral50, Neutral900, Primary50 } from '../../../../../../styles/colors';
import { Flex } from '../../../../../../styles/flex';
import { Typography } from '../../../../../typography/typography';

interface DateListOptionProps {
    isChecked: boolean;
}

interface DateListPositionProps {
    isLeftSide?: boolean;
}

export const DateListWrapper = styled.div<DateListPositionProps>`
    position: absolute;
    top: 32px;
    width: 112px;
    height: 160px;
    transform: translateX(-16px);
    box-shadow: 0 0 10px ${rgba(Neutral900, 0.12)};
`;

export const DateList = styled.div<DateListPositionProps>`
    padding-bottom: 8px;
    border-radius: 8px;
    box-shadow: 0 0 10px ${rgba(Neutral900, 0.12)};
    background-color: ${Neutral50};
    height: 154px;
    ${({ isLeftSide = false }) => isLeftSide && 'left: 0'};
    ${Flex.Column.Style}
`;

export const DateListOption = styled.span<DateListOptionProps>`
    padding: 8px 16px;
    cursor: pointer;
    background-color: ${({ isChecked }) => (isChecked ? Primary50 : 'inherit')};

    :hover {
        background-color: ${({ isChecked }) => (isChecked ? Primary50 : Neutral100)};
    }
`;

export const ListOptionTitle = styled.span`
    ${Typography.S.Black.Style}
`;
