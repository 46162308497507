export const typographySizes = {
    StepBackgroundTitle: {
        fontSize: '36rem',
        lineHeight: '36rem',
    },
    MobileStepBackgroundTitle: {
        fontSize: '30rem',
        lineHeight: '30rem',
    },
    FirstScreenTitle: {
        fontSize: '13rem',
        lineHeight: '13rem',
    },
    StepButtonNumber: {
        fontSize: '9rem',
        lineHeight: '9rem',
    },
    AnimatedCardTitle: {
        fontSize: '8rem',
        lineHeight: '9.5rem',
    },
    MainPageTitle: {
        fontSize: '69px',
        lineHeight: '82px',
    },
    AboutPageTitle: {
        fontSize: '48px',
        lineHeight: '48px',
    },
    PricingCardTitle: {
        fontSize: '36px',
        lineHeight: '43px',
    },
    HelpLinksItemTitle: {
        fontSize: '30px',
        lineHeight: '36px',
    },
    PricesDownloadTitle: {
        fontSize: '20px',
        lineHeight: '24px',
    },
    XXXL: {
        fontSize: '58px',
        lineHeight: '69px',
    },
    TextTitle: {
        fontSize: '48px',
        lineHeight: '58px',
    },
    XXL: {
        fontSize: '40px',
        lineHeight: '47px',
    },
    XL: {
        fontSize: '24px',
        lineHeight: '28px',
    },
    L: {
        fontSize: '18px',
        lineHeight: '21px',
    },
    LLL: {
        fontSize: '18px',
        lineHeight: '24px',
    },
    LL: {
        fontSize: '18px',
        lineHeight: '32px',
    },
    M: {
        fontSize: '16px',
        lineHeight: '22px',
    },
    S: {
        fontSize: '14px',
        lineHeight: '17px',
    },
    XS: {
        fontSize: '12px',
        lineHeight: '14px',
    },
    XXS: {
        fontSize: '10px',
        lineHeight: '12px',
    },

    D: {
        fontSize: '52px',
        lineHeight: '56px',
    },

    DS: {
        fontSize: '44px',
        lineHeight: '48px',
    },

    H1Desktop: {
        fontSize: '40px',
        lineHeight: '48px',
    },
    H2Desktop: {
        fontSize: '32px',
        lineHeight: '40px',
    },
    H3Desktop: {
        fontSize: '24px',
        lineHeight: '32px',
    },
    H4Desktop: {
        fontSize: '20px',
        lineHeight: '28px',
    },
    H1Mob: {
        fontSize: '36px',
        lineHeight: '44px',
    },
    H2Mob: {
        fontSize: '28px',
        lineHeight: '36px',
    },
    H3Mob: {
        fontSize: '20px',
        lineHeight: '28px',
    },
    H4Mob: {
        fontSize: '18px',
        lineHeight: '24px',
    },
    Paragraph18: {
        fontSize: '18px',
        lineHeight: '28px',
    },
    Paragraph16: {
        fontSize: '16px',
        lineHeight: '24px',
    },
    Paragraph14: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    Paragraph12: {
        fontSize: '12px',
        lineHeight: '20px',
    },
    OverlineM: {
        fontSize: '14px',
    },
    OverlineS: {
        fontSize: '12px',
    },
};
