import { useContext } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalWrapper } from '@shared-component/information-modal/information-modal.styles';
import { FCWithChildrenType } from '@shared-type/common';

export const InformationModal: FCWithChildrenType = ({ children }) => {
    const { isInformationModalVisible } = useContext(InformationModalContext);

    return <InformationModalWrapper isVisible={isInformationModalVisible}>{children}</InformationModalWrapper>;
};
