import { useField } from 'formik';

import { FormRow } from '@shared-component/forms/form-common/form-common.styles';
import { DocumentComment } from '@shared-component/forms/private-document-form/document-comment/document-comment';
import { DocumentUploaded } from '@shared-component/forms/private-document-form/document-uploaded/document-uploaded';
import { AttachFile } from '@shared-component/payment-files/attach-file/attach-file';
import { PaymentFilesWrapper } from '@shared-component/payment-files/payment-files.styles';
import { File as FileUploadType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

interface PaymentFilesProps {
    name: string;
    limit: number;
    title?: LocalizationEnum;
    description?: LocalizationEnum;
}

export const PaymentFiles = ({
    name,
    limit,
    title = LocalizationEnum.PaymentFormDetailsStepAttachFileTitle,
    description = LocalizationEnum.PaymentFormDetailsStepAttachFileDescription,
}: PaymentFilesProps) => {
    const [, { value: uploadedFiles = [] }, { setValue }] = useField<FileUploadType[]>(name);

    const handleUploadedFile = (newFile: FileUploadType) => setValue([...uploadedFiles, newFile]);

    const handleDeleteFile = (path: string) => () => setValue(uploadedFiles.filter(file => file.path !== path));

    const shouldRenderAttachFile = limit !== uploadedFiles.length;

    return (
        <PaymentFilesWrapper>
            {uploadedFiles.map((uploadedFile, index) => (
                <FormRow key={uploadedFile.path}>
                    <DocumentUploaded
                        filename={uploadedFile.filename}
                        path={uploadedFile.path}
                        onDelete={handleDeleteFile(uploadedFile.path)}
                    />
                    <DocumentComment path={`${name}.${index}.comment`} />
                </FormRow>
            ))}

            {shouldRenderAttachFile && (
                <AttachFile onUploaded={handleUploadedFile} title={title} description={description} />
            )}
        </PaymentFilesWrapper>
    );
};
