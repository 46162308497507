import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const AcquiringRequestModalTitle = styled.h4`
    height: 96px;
    padding: 40px 24px 0;
    ${Typography.H4Desktop.SemiBold.Neutral900.Center.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 40px 20px 0;
        ${Typography.H4Mob.SemiBold.Neutral900.Center.Style};
    }
`;
export const AcquiringRequestModalContent = styled.div`
    width: 100%;
    height: calc(100% - 96px - 85px);
`;
