import { BeneficiaryDetails, PaymentEntityType } from '@shared-graphql';

export const initialBeneficiary: BeneficiaryDetails = {
    beneficiaryEntityType: PaymentEntityType.individual,
    acctNumber: '',
    bicSwift: '',
    bankCountry: '',
    beneficiaryAddress: null,
    beneficiaryCity: null,
    beneficiaryCountry: null,
    beneficiaryCompanyName: null,
    iban: null,
    beneficiaryFirstName: null,
    beneficiaryLastName: null,
    beneficiaryPostcode: null,
    beneficiaryStateOrProvince: null,
    cnaps: null,
    bankName: null,
    bankCode: null,
    branchCode: null,
    bankAddress: null,
    nationality: null,
    sortCode: null,
    ifsc: null,
    clabe: null,
    charityNumber: null,
    invoiceDate: null,
    invoiceNumber: null,
};
