import styleToCss from 'style-object-to-css-string';

export const HalfSecond = 1 / 2;

export const getAnimationObjectStyle = (types: string[], time = HalfSecond, animationType = 'ease-in-out') => ({
    transition: types.map(value => `${value} ${time}s ${animationType}`).join(', '),
    willChange: types.join(', '),
});

export const getAnimationStyle = (types: string[], time = HalfSecond, animationType = 'ease-in-out') =>
    styleToCss(getAnimationObjectStyle(types, time, animationType));
