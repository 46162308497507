import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import { Application } from '@application/application';
import { tagManagerData } from '@util/gtm.util';

import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './service-worker-registration';

const root = createRoot(document.getElementById('root') as Element);

if (import.meta.env.MODE === 'production') {
    ReactGA.initialize(import.meta.env.VITE_GA_ID_PROD);
    TagManager.initialize(tagManagerData);
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_URL,
        autoSessionTracking: true,
        integrations: [new Sentry.BrowserTracing()],
        release: 'elpaso-client@1.0.37',
        tracesSampleRate: 1.0,
    });
}

root.render(<Application />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
