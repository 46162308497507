import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import { ElRef } from 'react-select/base';

import { BaseOptionInterface } from '@shared-atom/elpaso-kit/custom-select/custom-select.props';
import { CustomInputAmountProps } from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount';
import {
    InputAmountView,
    InputAmountWithSelect,
} from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount.styles';
import { getAmount } from '@shared-atom/elpaso-kit/input/custom-input-amount/custom-input-amount.util';
import { ErrorWrapper, Label, LabelWrapper } from '@shared-atom/elpaso-kit/input/input.styles';
import { Translate } from '@shared-atom/translate/translate';
import { FieldError } from '@shared-component/field-error/field-error';
import {
    Neutral200,
    Neutral50,
    Neutral900,
    Primary100,
    Primary300,
    Primary500,
    Primary800,
} from '@shared-style/colors';
import { DEFAULT_CURRENCY } from '@shared-util/constants';
import { isNumber, isString } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

interface InputAmountWithSelectProps extends CustomInputAmountProps {
    selectFieldName: string;
    selectOptions: BaseOptionInterface[];
}

export const CustomInputAmountWithSelect = observer(
    ({
        name,
        title,
        disabled,
        placeholder = '',
        onChange = noop,
        isMobile = false,
        selectFieldName,
        selectOptions,
    }: InputAmountWithSelectProps) => {
        const [{ value: amount }, { error }, { setError, setValue }] = useField(name);
        const selectRef = useRef();
        const [selectField, , { setValue: setSelectFieldValue }] = useField(selectFieldName);

        const [isFocused, setFocused] = useState(false);
        const [amountValue, setAmountValue] = useState(amount);

        const value = useMemo(() => (isNumber(amount) && amount !== 0 ? amount.toFixed(2) : undefined), [amount]);

        const hasValue = value !== undefined;
        const hasError = error !== undefined;
        const shouldRenderLabel = isString(title);
        const optionValue = useMemo(
            () => selectOptions.find(({ value: selsctValue }) => selsctValue === (selectField.value as any as string)),
            [selectField]
        );
        const toggleFocus = (isFocus: boolean) => () => setFocused(isFocus);
        const defaultValue = useMemo(() => ({ value: DEFAULT_CURRENCY, label: DEFAULT_CURRENCY }), []);
        const handleChange = (newValue = '0') => {
            onChange(newValue);
            setValue(isString(newValue) ? getAmount(newValue) : undefined);
            setError(undefined);
            setAmountValue(newValue);
        };

        const handleSelectChange = (selectValue: any) => {
            setSelectFieldValue(selectValue?.value);
        };

        useEffect(() => {
            const amountValueNum = getAmount(`${amountValue ?? 0}`);

            if (amountValueNum !== amount && hasValue) {
                setAmountValue(value);
            }
        }, [amountValue, amount]);

        return (
            <InputAmountWithSelect>
                {shouldRenderLabel && (
                    <Label hasError={hasError}>
                        <Translate langKey={title} />
                    </Label>
                )}
                <LabelWrapper
                    hasError={hasError}
                    isFocused={isFocused}
                    isDisabled={disabled}
                    isMobile={isMobile}
                    isOverHide={false}
                    style={{ paddingRight: '75px' }}
                >
                    <InputAmountView
                        name={name}
                        title={title}
                        placeholder={placeholder}
                        value={amountValue === 0 || amountValue === '0' ? undefined : amountValue}
                        decimalsLimit={2}
                        onFocus={toggleFocus(true)}
                        onBlur={toggleFocus(false)}
                        onValueChange={handleChange}
                        isMobile={isMobile}
                    />
                </LabelWrapper>
                <ReactSelect
                    ref={selectRef as ElRef}
                    value={optionValue ?? defaultValue}
                    options={selectOptions}
                    onChange={handleSelectChange}
                    isSearchable={false}
                    openMenuOnFocus
                    maxMenuHeight={120}
                    placeholder="CUR"
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary: `${Primary800}`,
                            primary25: `${Primary100}`,
                            primary50: `${Primary300}`,
                            primary75: `${Primary500}`,
                        },
                    })}
                    styles={{
                        container: () => ({ width: '75px', position: 'absolute', right: 0, top: '24px', zIndex: 1 }),
                        valueContainer: () => ({ display: 'flex', alignItems: 'center' }),
                        singleValue: () => ({ fontSize: '14px', lineHeight: '20px', fontWeight: 'bold' }),
                        menu: () => ({
                            border: `1px solid ${Neutral200}`,
                            position: 'absolute',
                            top: '42px',
                            backgroundColor: `${Neutral50}`,
                            width: '75px',
                        }),
                        indicatorSeparator: () => ({ backgroundColor: 'transparent' }),
                        indicatorsContainer: (provided: any, state: any) => ({
                            ...provided,
                            marginLeft: '-8px',
                            cursor: 'pointer',
                            color: Neutral900,
                            transform: Boolean(state?.selectProps?.menuIsOpen) === true && 'rotate(180deg)',
                        }),
                        placeholder: () => ({ fontSize: '14px', lineHeight: '20px' }),
                        control: () => ({
                            backgroundColor: 'transparent',
                            borderLeft: `1px solid ${Neutral200}`,
                            height: '32px',
                            display: 'flex',
                            paddingLeft: '15px',
                            marginTop: '10px',
                        }),
                    }}
                />
                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
            </InputAmountWithSelect>
        );
    }
);
