import { observer } from 'mobx-react-lite';
import { Fragment, useState } from 'react';
import { v4 } from 'uuid';

import businessLogo from '@shared-asset/logo_black_business.svg';
import logo from '@shared-asset/logo_black.svg';
import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ButtonSizeEnum } from '@shared-atom/elpaso-kit/button/types';

import { PageEnum } from '@enum/page.enum';
import { LanguageSelect } from '@page/main-pages/main-page-common/header/components/language-select/language-select';
import { OurCompanySelect } from '@page/main-pages/main-page-common/header/components/our-company-select/our-company-select';
import { ProductSelect } from '@page/main-pages/main-page-common/header/components/product-select/product-select';
import { HeaderItem } from '@page/main-pages/main-page-common/header/header-item/header-item';
import { HeaderRightSideBtn } from '@page/main-pages/main-page-common/header/header-right-side/header-right-side-btn/header-right-side-btn';
import {
    CloseBtn,
    CloseLine,
    DesktopMenuNavigation,
    FlexLink,
    FlexWrapper,
    Hamburger,
    HamburgerWrapper,
    List,
    LogoWrapper,
    MobileMenuNavigation,
    MobileMenuWrapper,
    Navigation,
    MobileMenuHeading,
    MobileMenuHeadingLogo,
    MobileMenu,
    MobileMenuNavigationBtnWrapper,
    MobileNavigationList,
} from '@page/main-pages/main-page-common/header/header-right-side/header-right-side.styles';
import { businessListLink, listLink } from '@page/main-pages/main-page-common/header/header-right-side/header.options';
import { HeaderSideProps } from '@page/main-pages/main-page-common/header/header.types';
import { useUserSelector } from '@selector/user/use-user.selector';

export const HeaderRightSide = observer(({ isBusinessPage }: HeaderSideProps) => {
    const [isOpened, setOpen] = useState(false);
    const [{ id }] = useUserSelector();
    const handleOpen = (isOpen: boolean) => () => setOpen(isOpen);

    const headerLinksList = isBusinessPage ? businessListLink : listLink;
    const logoSource = isBusinessPage ? businessLogo : logo;
    return (
        <FlexWrapper>
            <MobileMenu>
                <HeaderRightSideBtn isBusinessPage={isBusinessPage} userId={id} isMobile />
                <ButtonTransparent onClick={handleOpen(true)}>
                    <HamburgerWrapper>
                        <Hamburger isDark={!isBusinessPage} />
                        <Hamburger isDark={!isBusinessPage} />
                        <Hamburger isDark={!isBusinessPage} />
                    </HamburgerWrapper>
                </ButtonTransparent>
            </MobileMenu>
            <MobileMenuWrapper isOpened={isOpened}>
                <MobileMenuHeading>
                    <LogoWrapper style={{ backgroundColor: 'unset' }}>
                        <FlexLink route={PageEnum.Home}>
                            <MobileMenuHeadingLogo alt="logo" src={logoSource} onClick={handleOpen(false)} />
                        </FlexLink>
                    </LogoWrapper>
                    <CloseBtn>
                        <ButtonTransparent onClick={handleOpen(false)} isFull>
                            <CloseLine />
                            <CloseLine />
                        </ButtonTransparent>
                    </CloseBtn>
                </MobileMenuHeading>
                <DesktopMenuNavigation>
                    <ProductSelect isBusinessPage={isBusinessPage} />
                    <Navigation>
                        <List>
                            {headerLinksList.map((item, index) => (
                                <Fragment key={v4()}>
                                    {index === headerLinksList.length - 1 && (
                                        <OurCompanySelect isBusinessPage={isBusinessPage} />
                                    )}
                                    <HeaderItem
                                        isDark={!isBusinessPage}
                                        title={item.title}
                                        link={item.link}
                                        externalLink={item.externalLink}
                                        onClick={handleOpen(false)}
                                    />
                                </Fragment>
                            ))}
                        </List>
                    </Navigation>
                    <LanguageSelect isBusinessPage={isBusinessPage} />
                    <HeaderRightSideBtn isBusinessPage={isBusinessPage} userId={id} />
                </DesktopMenuNavigation>
                <MobileMenuNavigation>
                    <ProductSelect isMobile customClick={handleOpen(false)} />
                    <MobileNavigationList>
                        {headerLinksList.map((item, index) => (
                            <Fragment key={v4()}>
                                {index === headerLinksList.length - 1 && (
                                    <OurCompanySelect isMobile customClick={handleOpen(false)} />
                                )}
                                <HeaderItem
                                    isDark={!isBusinessPage}
                                    title={item.title}
                                    link={item.link}
                                    externalLink={item.externalLink}
                                    onClick={handleOpen(false)}
                                />
                            </Fragment>
                        ))}
                    </MobileNavigationList>
                    <LanguageSelect isMobile customClick={handleOpen(false)} />
                    <MobileMenuNavigationBtnWrapper>
                        <HeaderRightSideBtn isBusinessPage={false} userId={id} btnSize={ButtonSizeEnum.XL} isMobile />
                    </MobileMenuNavigationBtnWrapper>
                </MobileMenuNavigation>
            </MobileMenuWrapper>
        </FlexWrapper>
    );
});
