import styled, { css, keyframes } from 'styled-components';

import LoaderIcon from '@shared-asset/vector/loader-primary.svg';
import { Typography } from '@shared-atom/typography/typography';
import { InputCodeWrapper } from '@shared-component/input/input-otp/input-otp.styles';
import { getAnimationStyle } from '@shared-style/animation';
import { Neutral200, Neutral50, Neutral700, Neutral900, Primary50, Primary500 } from '@shared-style/colors';

export type LoadingType = {
    isLoading?: boolean;
};

export type ActiveType = {
    isActive: boolean;
};

const rotate = keyframes`
    0% {
       transform: translate(-50%, -50%)  rotate(0);
    }
    100% {
        transform: translate(-50%, -50%)  rotate(360deg);
    }
`;

export const OtpFormWrapper = styled.div<LoadingType>`
    ${({ isLoading = false }) =>
        isLoading &&
        css`
            opacity: 0.5;
            pointer-events: none;
            ${InputCodeWrapper} {
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    top: 50%;
                    left: 50%;
                    background-image: url(${LoaderIcon});
                    background-size: 100% 100%;
                    z-index: 1;
                    background-repeat: no-repeat;
                    transform: translate(-50%, -50%) rotate(0);
                    animation: ${rotate} 3s linear infinite;
                }
            }
        `}}
`;

export const Tile = styled.div<ActiveType>`
    flex: 1;
    padding: 14px 16px;
    ${Typography.Paragraph14.SemiBold.Neutral900.Style};

    color: ${({ isActive }: ActiveType) => (isActive ? Primary500 : Neutral900)};
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid ${Neutral200};
    background-color: ${Neutral50};
    :hover {
        color: ${({ isActive }: ActiveType) => (isActive ? Primary500 : Neutral700)};
        border: 1px solid ${Primary500};
    }
    ${({ isActive }) =>
        isActive &&
        css`
            border: 1px solid ${Primary500};
            background-color: ${Primary50};
            color: ${Primary500};
        `}

    ${getAnimationStyle(['border', 'color', 'background-color'])}
`;
