import { object, string } from 'yup';

import { passwordValidation, repeatPasswordValidation } from '@shared-util/validation';

import { ChangePasswordFormEnum } from '@page/workspace/settings/section/security/subsections/password/change-password-form/change-password-form.enum';

export const ChangePasswordSchema = object().shape({
    [ChangePasswordFormEnum.Password]: passwordValidation(),
    [ChangePasswordFormEnum.RepeatPassword]: repeatPasswordValidation(ChangePasswordFormEnum.Password),
    [ChangePasswordFormEnum.CurrentPassword]: string().required(),
});
