import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const EmptyAccountsWrapper = styled.div`
    ${Flex.Column.VerticalCenter.HorizontalCenter.Style};
    margin: 0 auto 32px;
    max-width: 420px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        margin: 0 auto 36px;
    }
`;
export const EmptyAccountsPreview = styled.img`
    aspect-ratio: 236 / 227;
    max-width: 236px;
    margin-bottom: 32px;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        margin-bottom: 24px;
    }
`;
export const EmptyAccountsTitle = styled.h3`
    ${Typography.Paragraph16.Center.Neutral700.Medium.Style};
    margin-bottom: 8px;
`;
export const EmptyAccountsDescription = styled.p`
    ${Typography.Paragraph14.Center.Neutral500.Regular.Style};
`;
