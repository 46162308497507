import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { BaseOptionInterface } from '@shared-component/select/select.props';
import { UserType } from '@shared-graphql';

import { useCountries } from '@hook/countries/countries.hook';
import { AuthPageContentWrapper } from '@page/auth-pages/common.styles';
import { RegistrationAdditionalFieldsLegalForm } from '@page/registration/registration-additional-fields/registration-additional-fields-form/registration-additional-fields-form-legal/registration-additional-fields-form-legal';
import { RegistrationAdditionalFieldsPrivateForm } from '@page/registration/registration-additional-fields/registration-additional-fields-form/registration-additional-fields-form-private/registration-additional-fields-form-private';
import { useUserSelector } from '@selector/user/use-user.selector';

const RegistrationAdditionalFields = observer(() => {
    const [{ type }] = useUserSelector();
    const [countries] = useCountries();
    const normalizedAllowedCountries = useMemo(
        () =>
            countries.map(country => ({
                value: country.alpha2,
                label: country.name,
            })),
        [countries]
    ) as BaseOptionInterface<string>[];

    return (
        <AuthPageContentWrapper>
            {type === UserType.Legal && (
                <RegistrationAdditionalFieldsLegalForm countries={normalizedAllowedCountries} />
            )}
            {type === UserType.Private && (
                <RegistrationAdditionalFieldsPrivateForm countries={normalizedAllowedCountries} />
            )}
        </AuthPageContentWrapper>
    );
});

export default RegistrationAdditionalFields;
