import { FC, useMemo } from 'react';

import { isString } from '../../utils/is-data';
import { getFirstLetters, getRandomColor } from './avatar.helper';
import {
    AvatarIcon,

    // AvatarWrapper,
    ImagePreview,
    UserIcon,
    UserName,
} from './avatar.styles';
import './avatar.css';

interface AvatarProps {
    path?: string;
    name?: string | null;
    isSmall?: boolean;
    showStatus?: boolean;
}

export const Avatar: FC<AvatarProps> = ({ path, name, isSmall = false, showStatus = true }) => {
    const hasName = isString(name);

    const shouldRenderImage = path !== undefined;
    const shouldRenderName = !shouldRenderImage && hasName;
    const shouldRenderIcon = !shouldRenderImage && !hasName;

    const [monogramName, colorIndex] = useMemo(
        () => (shouldRenderName ? [getFirstLetters(name as string), getRandomColor(name as string)] : ['', -1]),
        [name]
    );

    return (
        // <AvatarWrapper>
        //     <AvatarIcon colorIndex={colorIndex}>
        //         {shouldRenderImage && <ImagePreview src={path} />}
        //         {shouldRenderName && <UserName isSmall={isSmall}>{monogramName}</UserName>}
        //         {shouldRenderIcon && <UserIcon />}
        //     </AvatarIcon>
        // </AvatarWrapper>
        <div className="styled__avatar-wrapper">
            <AvatarIcon colorIndex={colorIndex}>
                {shouldRenderImage && <ImagePreview src={path} />}
                {shouldRenderName && <UserName isSmall={isSmall}>{monogramName}</UserName>}
                {shouldRenderIcon && <UserIcon />}
            </AvatarIcon>
            {Boolean(showStatus) && <div className="user__status" />}
        </div>
    );
};
