import { useContext } from 'react';

import Logo from '@shared-asset/logo_black.svg?react';
import BusinessLogo from '@shared-asset/logo_white_business.svg?react';
import { AnchorContext } from '@shared-hook/navigation/anchor/anchor.context';

import { PageEnum } from '@enum/page.enum';
import { HeaderSideProps } from '@page/main-pages/main-page-common/header/header.types';

import { FlexWrapper, FlexLink, LogoWrapper } from './header-left-side.styles';

export const HeaderLeftSide = ({ isBusinessPage }: HeaderSideProps) => {
    const { scrollTop } = useContext(AnchorContext);
    const HeaderLogo = isBusinessPage ? BusinessLogo : Logo;
    return (
        <FlexWrapper>
            <LogoWrapper onClick={scrollTop}>
                <FlexLink route={PageEnum.Home}>
                    <HeaderLogo />
                </FlexLink>
            </LogoWrapper>
        </FlexWrapper>
    );
};
